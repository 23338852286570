<template>
    <section>
        <div class="item-volunteering cage">
            <div v-if="showVolunteeringList">
                <div class="item-input-sub-cage">
                    <div class="item-sub-list-cage" v-for="(volunteering,i) in volunteeringData.myVolunteering"
                         :key="i">
                        <div>
                            <p class="item-sub-list-text">{{volunteering.company}}</p>
                            <p class="item-sub-list-text-secondary d-flex align-items-center">
                                {{volunteering.mission}}</p>
                        </div>
                        <div class="d-flex">
                            <span class="material-icons item-sub-list-create-icon"
                                  @click="editVolunteering(volunteering)">create</span>
                            <span class="material-icons item-sub-list-delete-icon"
                                  @click="deleteVolunteering(volunteering)">delete</span>
                        </div>
                    </div>
                </div>
                <div class="item-footer-button-cage">
                    <v-btn class="yellow-button w-100" @click="addVolunteering">Add New Volunteering</v-btn>
                </div>
            </div>
            <div v-else>
                <div class="item-input-sub-cage">
                    <div class="item-input-cage">
                        <p class="item-input-header">Organizations</p>
                        <input class="primary-input w-100" @input="changeData"
                               v-model="volunteeringData.company"
                               @focusout="isEditing? updateVolunteering(false) : ''">
                    </div>
                    <div class="item-input-cage">
                        <p class="item-input-header">Mission</p>
                        <input class="primary-input w-100" @input="changeData"
                               v-model="volunteeringData.mission"
                               @focusout="isEditing? updateVolunteering(false) : ''">
                    </div>
                    <div class="item-input-cage">
                        <p class="item-input-header">Info</p>
                        <textarea class="primary-text-area w-100" rows="4" @input="changeData"
                                  v-model="volunteeringData.description"
                                  @focusout="isEditing? updateVolunteering(false) : ''"/>
                    </div>
                    <div class="d-flex flex-row">
                        <div class="item-input-cage mr-2">
                            <p class="item-input-header">Start Date</p>
                            <div class="d-flex flex-row">
                                <input class="primary-input w-100 mr-1" @input="changeData"
                                       v-model="volunteeringData.start_month"
                                       @focusout="isEditing? updateVolunteering(false) : ''"
                                       placeholder="Month">
                                <input class="primary-input w-100" @input="changeData"
                                       v-model="volunteeringData.start_year"
                                       @focusout="isEditing? updateVolunteering(false) : ''"
                                       placeholder="Year">
                            </div>
                        </div>
                        <div class="item-input-cage">
                            <p class="item-input-header">End Date</p>
                            <div class="d-flex flex-row">
                                <input class="primary-input w-100 mr-1" @input="changeData"
                                       v-model="volunteeringData.end_month"
                                       @focusout="isEditing? updateVolunteering(false) : ''"
                                       placeholder="Month">
                                <input class="primary-input w-100" @input="changeData" :disabled="volunteeringData.current"
                                       v-model="volunteeringData.end_year"
                                       @focusout="isEditing? updateVolunteering(false) : ''"
                                       placeholder="Year">
                            </div>
                        </div>
                    </div>
                    <div class="item-input-cage d-flex flex-row">
                        <div class="item-inner-cage-half mr-2">
                        </div>
                        <div class="item-inner-cage-half">
                            <checkbox label="Current" :model="volunteeringData.current" name="current"
                                      @changeEvent="isEditing? updateVolunteering(false) :''"
                                      @isChecked="volunteeringData.current = $event"></checkbox>

                        </div>
                    </div>
                </div>
                <div class="item-footer-button-cage">
                    <v-btn class="yellow-button w-100"
                           @click="isEditing ? updateVolunteering(false) : saveNewVolunteering()">
                        Save
                    </v-btn>
                </div>
            </div>
        </div>
        <v-snackbar v-model="showAddVolunteeringNotification" :timeout="2000" :color="'success'" top>
            Volunteering has been added.
        </v-snackbar>
        <v-snackbar v-model="showUpdateVolunteeringNotification" :timeout="2000" :color="'success'" top>
            Volunteering has been updated.
        </v-snackbar>
        <v-snackbar v-model="showDeleteVolunteeringNotification" :timeout="2000" :color="'success'" top>
            Volunteering has been deleted.
        </v-snackbar>
    </section>
</template>
<script>
    import {mapActions, mapState} from "vuex";
    import checkbox from "@/components/atomic/input/checkbox";
    import itemService from '../../../services/item-data'

    export default {
        name: 'itemVolunteering',
        components: {checkbox},
        data() {
            return {
                showVolunteeringList: true,
                showAddVolunteeringNotification: false,
                showUpdateVolunteeringNotification: false,
                showDeleteVolunteeringNotification: false,
                isEditing: false
            }
        },
        created() {
            this.showVolunteeringList = this.volunteeringData.myVolunteering.length > 0;
        },
        computed: {
            ...mapState({
                volunteeringData: (store) => store.volunteering.volunteeringData
            })
        },
        methods: {
            ...mapActions({
                updateVolunteeringData: 'volunteering/updateVolunteeringData',
                addNewVolunteering: 'volunteering/addNewVolunteering',
                deleteVolunteer: 'volunteering/deleteVolunteer',
                editVolunteer: 'volunteering/editVolunteer',
            }),
            changeData() {
                if (this.isEditing) {
                    const editedObject = {
                        data: this.setEditedObject()
                    };
                    this.editVolunteer(editedObject);
                }
            },
            editVolunteering(volunteering) {
                this.isEditing = true;
                this.volunteeringData.id = volunteering.id;
                this.volunteeringData.company = volunteering.company;
                this.volunteeringData.name = volunteering.name;
                this.volunteeringData.description = volunteering.description;
                this.volunteeringData.start_year = volunteering.start_year;
                this.volunteeringData.start_month = volunteering.start_month;
                this.volunteeringData.end_year = volunteering.end_year;
                this.volunteeringData.end_month = volunteering.end_month;
                this.volunteeringData.current = volunteering.current;
                this.volunteeringData.mission = volunteering.mission;
                this.showVolunteeringList = false;
            },
            deleteVolunteering(volunteering) {
                itemService.deleteVolunteering(volunteering.id).then((deleteResponse) => {
                    console.log(deleteResponse);
                    const volunteeringIndex = this.volunteeringData.myVolunteering.indexOf(volunteering);
                    this.deleteVolunteer(volunteeringIndex);
                    this.showDeleteVolunteeringNotification = true;
                    this.showVolunteeringList = this.volunteeringData.myVolunteering.length > 0;
                });
            },
            addVolunteering() {
                this.isEditing = false;
                this.volunteeringData.company = '';
                this.volunteeringData.name = '';
                this.volunteeringData.description = '';
                this.volunteeringData.start_year = '';
                this.volunteeringData.start_month = '';
                this.volunteeringData.end_year = '';
                this.volunteeringData.end_month = '';
                this.volunteeringData.current = '';
                this.volunteeringData.mission = '';
                this.showVolunteeringList = false;
            },
            setEditedObject() {
                const editedObject = {
                    company: this.volunteeringData.company,
                    name: this.volunteeringData.name,
                    description: this.volunteeringData.description,
                    start_year: this.volunteeringData.start_year,
                    start_month: this.volunteeringData.start_month,
                    end_year: this.volunteeringData.end_year,
                    end_month: this.volunteeringData.end_month,
                    current: this.volunteeringData.current,
                    mission: this.volunteeringData.mission,
                    id: this.volunteeringData.id
                };
              if(editedObject.current) {
                editedObject.end_year = 'Current'
                this.volunteeringData.end_year = 'Current'
              }
                return editedObject;
            },
            updateVolunteering(goBack) {
                const editedVolunteering = {
                    company: this.volunteeringData.company,
                    name: this.volunteeringData.name,
                    description: this.volunteeringData.description,
                    start_year: this.volunteeringData.start_year,
                    start_month: this.volunteeringData.start_month,
                    end_year: this.volunteeringData.end_year,
                    end_month: this.volunteeringData.end_month,
                    current: this.volunteeringData.current,
                    mission: this.volunteeringData.mission,
                    id: this.volunteeringData.id
                };
                if(editedVolunteering.current) {
                  editedVolunteering.end_year = 'Current'
                  this.volunteeringData.end_year = 'Current'
                }
                itemService.updateVolunteering(editedVolunteering.id, editedVolunteering).then((updateResponse) => {
                    this.editVolunteer(updateResponse);
                    this.showUpdateVolunteeringNotification = true;
                    this.showVolunteeringList = goBack;
                })
            },
            saveNewVolunteering() {
                const newVolunteering = {
                    company: this.volunteeringData.company,
                    name: this.volunteeringData.name,
                    description: this.volunteeringData.description,
                    start_year: this.volunteeringData.start_year,
                    start_month: this.volunteeringData.start_month,
                    end_year: this.volunteeringData.end_year,
                    end_month: this.volunteeringData.end_month,
                    current: this.volunteeringData.current,
                    mission: this.volunteeringData.mission,
                };
                itemService.createVolunteering(newVolunteering).then((createResponse) => {
                    this.addNewVolunteering(createResponse.data);
                    this.showAddVolunteeringNotification = true;
                    this.showVolunteeringList = true;
                });
            }
        },
    }
</script>
<style></style>
