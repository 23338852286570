<template>
    <section>
        <div class="item-projects cage">
            <div v-if="showProjectList">
                <div class="item-input-sub-cage">
                    <div class="item-sub-list-cage" v-for="(project,i) in projectsData.myProjects" :key="i">
                        <div>
                            <p class="item-sub-list-text">{{project.name}}</p>
                            <p class="item-sub-list-text-secondary">{{project.url}}</p>
                        </div>
                        <div class="d-flex">
                            <span class="material-icons item-sub-list-create-icon"
                                  @click="editProjects(project)">create</span>
                            <span class="material-icons item-sub-list-delete-icon"
                                  @click="deleteProjects(project)">delete</span>
                        </div>
                    </div>
                </div>
                <div class="item-footer-button-cage">
                    <v-btn class="yellow-button w-100" @click="addProject">Add New Project</v-btn>
                </div>
            </div>
            <div v-if="!showProjectList">
                <div class="item-input-sub-cage">
                    <div class="item-input-cage">
                        <p class="item-input-header">Project Name</p>
                        <input class="primary-input w-100" v-model="projectsData.name" @input="changeData"
                               @focusout="isEditing? updateProject(false) : ''">
                    </div>
                    <div class="item-input-cage">
                        <p class="item-input-header">Project Detail</p>
                        <textarea class="primary-text-area w-100" rows="6"
                                  v-model="projectsData.description" @input="changeData"
                                  @focusout="isEditing? updateProject(false) : ''"></textarea>
                    </div>
                    <div class="d-flex flex-row">
                        <div class="item-input-cage mr-2">
                            <p class="item-input-header">Start Date <span class="item-input-optional">(optional)</span>
                            </p>
                            <div class="d-flex flex-row">
                                <input class="primary-input w-100 mr-1" v-model="projectsData.start_month" @input="changeData"
                                       placeholder="Month" @focusout="isEditing? updateProject(false) : ''">
                                <input class="primary-input w-100" v-model="projectsData.start_year" @input="changeData"
                                       placeholder="Year" @focusout="isEditing? updateProject(false) : ''">
                            </div>
                        </div>
                        <div class="item-input-cage">
                            <p class="item-input-header">End Date <span class="item-input-optional">(optional)</span>
                            </p>
                            <div class="d-flex flex-row">
                                <input class="primary-input w-100 mr-1" v-model="projectsData.end_month" @input="changeData"
                                       placeholder="Month" @focusout="isEditing? updateProject(false) : ''">
                                <input class="primary-input w-100" v-model="projectsData.end_year" @input="changeData"
                                       placeholder="Year" @focusout="isEditing? updateProject(false) : ''">
                            </div>
                        </div>
                    </div>
                    <div class="item-input-cage">
                        <p class="item-input-header">Project URL</p>
                        <input class="primary-input w-100" v-model="projectsData.url">
                    </div>
                </div>
                <div class="item-footer-button-cage">
                    <v-btn class="yellow-button w-100" @click="isEditing ? updateProject(true) : saveNewProject()">
                        Save
                    </v-btn>
                </div>
            </div>
        </div>
        <v-snackbar v-model="showAddProjectNotification" :timeout="2000" :color="'success'" top>
            Project has been added.
        </v-snackbar>
        <v-snackbar v-model="showUpdateProjectNotification" :timeout="2000" :color="'success'" top>
            Project has been updated.
        </v-snackbar>
        <v-snackbar v-model="showDeleteProjectNotification" :timeout="2000" :color="'success'" top>
            Project has been deleted.
        </v-snackbar>
    </section>
</template>
<script>
    import {mapActions, mapState} from "vuex";
    import itemService from '../../../services/item-data'

    export default {
        name: 'itemProjects',
        data() {
            return {
                showProjectList: true,
                showAddProjectNotification: false,
                showUpdateProjectNotification: false,
                showDeleteProjectNotification: false,
                isEditing: '',
            }
        },
        created() {
            this.showProjectList = this.projectsData.myProjects.length > 0;
        },
        computed: {
            ...mapState({
                projectsData: (store) => store.projects.projectsData
            })
        },
        methods: {
            ...mapActions({
                updateProjectsData: 'projects/updateProjectsData',
                addNewProjects: 'projects/addNewProjects',
                editProject: 'projects/editProject',
                deleteProject: 'projects/deleteProject',
            }),
            changeData() {
                if (this.isEditing) {
                    const editedObject = {
                        data: this.setEditedObject()
                    };
                    this.editProject(editedObject);
                }
            },
            addProject() {
                this.isEditing = false;
                this.projectsData.name = '';
                this.projectsData.start_month = '';
                this.projectsData.start_year = '';
                this.projectsData.end_month = '';
                this.projectsData.end_year = '';
                this.projectsData.url = '';
                this.projectsData.description = '';
                this.showProjectList = false;
            },
            deleteProjects(project) {
                itemService.deleteProject(project.id).then((deleteResponse) => {
                    console.log('delete resp', deleteResponse);
                    const projectIndex = this.projectsData.myProjects.indexOf(project);
                    this.deleteProject(projectIndex);
                    this.showDeleteProjectNotification = true;
                    this.showProjectList = this.projectsData.myProjects.length > 0;
                });
            },
            editProjects(project) {
                this.isEditing = true;
                this.projectsData.name = project.name;
                this.projectsData.start_month = project.start_month;
                this.projectsData.start_year = project.start_year;
                this.projectsData.end_month = project.end_month;
                this.projectsData.end_year = project.end_year;
                this.projectsData.url = project.url;
                this.projectsData.description = project.description;
                this.projectsData.id = project.id;
                this.showProjectList = false;
            },
            setEditedObject(){
                const editedObject = {
                    name: this.projectsData.name,
                    start_month: this.projectsData.start_month,
                    start_year: this.projectsData.start_year,
                    end_month: this.projectsData.end_month,
                    end_year: this.projectsData.end_year,
                    url: this.projectsData.url,
                    description: this.projectsData.description,
                    id: this.projectsData.id
                };
                return editedObject;
            },
            updateProject(goBack) {
                const editedProject = {
                    name: this.projectsData.name,
                    start_month: this.projectsData.start_month,
                    start_year: this.projectsData.start_year,
                    end_month: this.projectsData.end_month,
                    end_year: this.projectsData.end_year,
                    url: this.projectsData.url,
                    description: this.projectsData.description,
                    id: this.projectsData.id
                };
                itemService.updateProject(editedProject.id, editedProject).then((updateResponse) => {
                    this.editProject(updateResponse);
                    this.showUpdateProjectNotification = true;
                    this.showProjectList = goBack;
                });
            },
            saveNewProject() {
                const newProject = {
                    name: this.projectsData.name,
                    start_month: this.projectsData.start_month,
                    start_year: this.projectsData.start_year,
                    end_month: this.projectsData.end_month,
                    end_year: this.projectsData.end_year,
                    url: this.projectsData.url,
                    description: this.projectsData.description
                };
                itemService.createProject(newProject).then((createResponse) => {
                    this.addNewProjects(createResponse.data);
                    this.showAddProjectNotification = true;
                    this.showProjectList = true;
                });
            }
        }
    }
</script>
<style lang="scss" scoped></style>
