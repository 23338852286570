export default {
    namespaced: true,
    state: {
        hobbiesData: {
            item_header: 'Hobbies',
            item_id:'',
            html:{},
            name: '',
            icon: '',
            description: '',
            position: 0,
            id: '',
            myHobbies: []
        },
    },
    actions: {
        updateHobbiesData({commit}, data) {
            commit('UPDATE_HOBBIES_DATA', data);
        },
        addNewHobby({commit}, data) {
            commit('ADD_NEW_HOBBY', data);
        },
        deleteHobby({commit}, data) {
            commit('DELETE_HOBBY', data);
        },
        editHobby({commit}, data) {
            commit('EDIT_HOBBY', data);
        },
        updateHobbiesHtml({commit}, data) {
            commit('UPDATE_HOBBIES_HTML', data);
        },
        updateHobbiesTitle({commit}, data) {
            commit('UPDATE_HOBBIES_TITLE', data);
        },
        updateHobbiesId({commit}, data) {
            commit('UPDATE_HOBBIES_ID', data)
        }
    },
    mutations: {
        UPDATE_HOBBIES_DATA(state, payload) {
            state.hobbiesData.myHobbies = payload;
        },
        ADD_NEW_HOBBY(state, payload) {
            state.hobbiesData.myHobbies.push(payload);
        },
        DELETE_HOBBY(state, payload) {
            state.hobbiesData.myHobbies.splice(payload);
        },
        EDIT_HOBBY(state, payload) {
            const updatedHobby = state.hobbiesData.myHobbies.find(x => x.id === payload.data.id);
            Object.assign(updatedHobby,payload.data);
        },
        UPDATE_HOBBIES_HTML(state, payload) {
            state.hobbiesData.html = payload;
        },
        UPDATE_HOBBIES_TITLE(state, payload) {
            state.hobbiesData.item_header = payload;
        },
        UPDATE_HOBBIES_ID(state, payload) {
            state.hobbiesData.item_id = payload;
        }
    }
}
