<template>
    <section>
        <div class="web-login main-cage row">
            <div class="web-login banner-cage col-4"  v-if="!isMobile()">
                <div class="web-login inner-banner-cage">
                    <div class="web-login banner-text-cage">
                        <img class="web-login login-icon" src="../../assets/img/white-logo.svg">
                        <p class="web-login banner-head">Let’s create a resume for your dream job</p>
                        <p class="web-login banner-sub-text">Choose from the best resume templates we've created for
                            you, just follow the instructions and you'll have a great-looking resume.</p>
                    </div>
                </div>
            </div>
            <div :class="isMobile() ? 'web-login content-cage col-12': 'web-login content-cage col-8'">
                <div class="web-login form-cage">
                    <p class="web-login form-header" v-if="currentLoginTab === 'register'">Create an account</p>
                    <p class="web-login form-header" v-else>Login</p>
                    <login-form v-if="currentLoginTab === 'login'"></login-form>
                    <register-form v-if="currentLoginTab === 'register'"
                                   @changeLoginTab="currentLoginTab =$event"></register-form>
                    <div class="web-login content-footer-action">
                        <p class="web-login content-footer-text" v-if="currentLoginTab === 'login'"
                           @click="currentLoginTab = 'register'">Register to <span>Anycv</span></p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    import loginForm from "@/components/login/user-login-form";
    import registerForm from "@/components/login/user-register-form";

    export default {
        name: 'loginPage',
        components: {loginForm, registerForm},
        data() {
            return {
                currentLoginTab: 'login'
            }
        },
        beforeCreate() {
            const haveToken = localStorage.getItem('access-token') ? true : false;
            const isDummy = localStorage.getItem('isDummy') === 'true' ? true : false;
            if (!isDummy && haveToken) {
                this.$router.push({name: 'resumeDashboard'})
            }
        },
        methods: {
            isMobile() {
    return this.$vuetify.breakpoint.sm||this.$vuetify.breakpoint.xs
 },
     
        },
    }
</script>
<style lang="scss" scoped>
    .web-login {
        &.main-cage {
            background: #ffffff;
            // düzelt bunu
            height: 100vh;
        }

        &.form-cage {
            max-width: 371px;
            margin: 0 auto;
            display: flex;
            width: 100%;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            position: relative;
        }

        &.banner-cage {
            height: 100%;
            background: linear-gradient(180deg, #742774 0%, #9D3E9D 100%);
        }

        &.login-icon {
            max-width: 100px;
            width: 100%;
        }

        &.inner-banner-cage {
            height: 100%;
            background-image: url('../../assets/img/web-site/web-login-background.svg');
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &.banner-head {
            font-size: 36px;
            font-weight: 600;
            font-family: "Segoe UI";
            color: #ffffff;
            margin-bottom: 30px !important;
            text-align: start;
        }

        &.banner-sub-text {
            font-size: 16px;
            font-weight: 400;
            font-family: "Segoe UI";
            color: #ffffff;
            line-height: 27px;
            text-align: start;
        }

        &.banner-text-cage {
            max-width: 382px;
            padding: 0 20px;
        }

        &.content-footer-action {
            margin-top: 20px;
        }

        &.content-footer-text {
            cursor: pointer;
            font-family: "Segoe UI";
            font-weight: 400;
            font-size: 14px;
            color: #742774;

            & > span {
                font-weight: 600;
            }
        }

        &.form-header {
            font-family: Roboto;
            font-weight: 500;
            font-size: 24px;
            color: #000000;
            margin-bottom: 35px !important;
        }
    }
</style>
