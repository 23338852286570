<template>
    <section>
        <dialog-modal :button-name="'Tips'" :header="currentItemTab">
            <template v-slot:content>
                <section>
                    <div v-for="(item,i) in tipsData" :key="i">
                        <div class="menu-tips cage" v-if="currentItemTab === item.itemTab">
                            <div class="menu-tips content-row" v-for="(data,index) in item.data" :key="index">
                                <div class="d-flex align-items-center" v-if="data.header">
                                    <p class="menu-tips text-point"></p>
                                    <p class="menu-tips description-header">{{data.header}}</p>
                                </div>
                                <div class="d-flex align-items-center">
                                    <p class="menu-tips text-point mr-0" v-if="!data.header"></p>
                                    <p class="menu-tips description-text">{{data.description}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </template>
        </dialog-modal>
    </section>
</template>
<script>
    import dialogModal from '../../atomic/dialog-modal.vue'

    export default {
        name: 'menuTips',
        components: {dialogModal},
        data() {
            return {
                tipsData: [{
                    itemTab: 'Summary', data: [
                        {
                            header: '',
                            description: 'For US, UK and Canadian resumes, you are strongly advised NOT to include a photo.'
                        },
                        {
                            header: '',
                            description: 'Make sure your email address and website are appropriate for any potential employer to view.'
                        },
                        {
                            header: '',
                            description: 'Most jobseekers use the standard email format of lastname.firstname@emailcompany.com.'
                        },
                        {
                            header: '',
                            description: 'Make sure your LinkedIn profile is up to date and that it matches your resume as much as possible.'
                        },
                        {
                            header: '',
                            description: 'For European resumes, it’s almost expected to add a professional headshot.'
                        },
                        {
                            header: '',
                            description: 'This picture should be similar to a passport photo, showing only the head and shoulders - no full-length photos - with good lighting for a clear appearance.'
                        },
                        {
                            header: '',
                            description: 'Remember that your picture is your first impression to a prospective employer, so care about it.'
                        },
                        {
                            header: 'For Professional Summary',
                            description: 'This is the first section a potential employer is likely to read after your name. Try to be clear and provide a strong, competently concise objective statement that describes the position you seek and the industry in which you are looking to work.'
                        },
                        {
                            header: '',
                            description: 'If you would like to include a summary statement, be sure to provide a brief overview of your career history and qualifications that show insight into your past.'
                        },
                    ]
                },
                    {
                        itemTab: 'Experience', data: [
                            {header: '', description: 'It is recommended to begin with the most recent job.'},
                            {
                                header: '',
                                description: 'A job\'s requisite skills should be a major factor - focus on the skills you could bring to a potential job.'
                            },
                            {header: '', description: 'Use action verbs consistently to describe your work duties.'},
                            {
                                header: '',
                                description: 'Outline your accomplishments and the results you\'ve achieved; don\'t simply tell a potential employer what you did, tell them how you did it and the results you achieved.'
                            },
                            {
                                header: 'Some questions for inspiration:',
                                description: 'When did you go above and beyond your job duties?'
                            },
                            {
                                header: '',
                                description: 'How was your special project different from other similar projects you have worked on?'
                            },
                            {header: '', description: 'Did you change what you were doing to improve efficiency?'},
                            {header: '', description: 'How much did your employer save as a result of your work?'},
                            {
                                header: '',
                                description: 'What measures to improve the company\'s operations or processes did you put in place? What did you eliminate or implement?'
                            },
                            {
                                header: '',
                                description: 'Did you receive a promotion to a managerial or supervisory position?'
                            },
                            {
                                header: 'Examples',
                                description: 'Increased conversion rates by 15% by running various social media contests.'
                            },
                            {
                                header: '',
                                description: 'Came up with new business opportunities and markets to develop effective strategies.'
                            },
                            {
                                header: '',
                                description: 'Successfully supervised the activities of 7 team members from multiple locations.'
                            },

                        ]
                    },
                    {
                        itemTab: 'Education', data: [
                            {
                                header: '',
                                description: 'Keep in mind that you should give more weight to the studies which are more closely related to the job you\'re looking for.'
                            },
                            {
                                header: '',
                                description: 'The education section can also include professional training courses if this is more relevant to your background.'
                            },
                            {
                                header: '',
                                description: 'If you’re a student, putting the education section at the top of your resume is more likely to attract attention. Even if you have some experience already, employers will be interested to know that you’re still a student.'
                            },
                            {
                                header: '',
                                description: 'If you are a college drop out, it may make sense to put even unfinished education or college attendance on your resume. Employment gaps will always make a client a little curious.Even if you do not have a degree as of yet, you can still put it on your resume. All you have to do is draw attention to the fact that you are still working on attaining it.'
                            },
                            {
                                header: '',
                                description: 'After you become an experienced professional, your education section takes a backseat to your work experience. Your interviews will focus on your work experience and professional achievements instead of your academic career. That is to say, you can simply list your degrees in reverse-chronological order — and nothing more.You can remove specific details about your education such as GPA, extracurricular activities, and related coursework, including attendance dates.'
                            },

                        ]
                    },
                    {
                        itemTab: 'Technical Skills',
                        data: [
                            {
                                header: '',
                                description: 'The easiest method for adding skills is to stick to the job advertisement. Print it out, get a marker, and attempt to highlight all skills essential for the job. These are the terms that both the hiring managers and ATS will be looking for.'
                            },
                            {
                                header: '',
                                description: 'Once you have listed those skills, count how many you already have. Put them in your skills section.'
                            }

                        ]
                    },
                    {
                        itemTab: 'Certifications',
                        data: [
                            {
                                header: '',
                                description: 'Getting your resume a little more interesting, add licenses and certifications. The nice thing is that there are all different types of licenses and certifications.'
                            },
                            {
                                header: '',
                                description: 'Fortunately, listing great skills on a resume isn’t hard to do. You simply have to know which skills are better to lay out on the document and how to do it.'
                            },
                            {
                                header: '',
                                description: 'The trick is to put your professional certifications front and center. That way, hiring managers know that you add value from the very first instant.'
                            },

                        ]
                    },
                    {
                        itemTab: 'Awards',
                        data: [
                            {
                                header: '',
                                description: 'Keep it brief. Don\'t write long paragraphs and use bullet points instead.'
                            },
                            {
                                header: '',
                                description: 'Consider priority. If you decide to focus on your achievements in an entire section, imagine which ones are the most significant. Would you be impressed by it if you were a potential employer?'
                            },
                            {
                                header: '',
                                description: 'Avoid mentioning controversial subjects. Some awards and achievements can be too personal to list. Avoid listing any political and religious accomplishments.'
                            },
                            {
                                header: '',
                                description: 'Don’t overstate. Avoid making something up. You might be asked at your job interview about your achievements and awards. Or they might contact your references. Lying or overstating can lose you a job.'
                            },

                        ]
                    },
                    {
                        itemTab: 'Hobbies',
                        data: [
                            {
                                header: '',
                                description: 'Do not put any sensitive subjects like politics, any controversial issues, sex or religion on your CV.'
                            },
                            {
                                header: '',
                                description: 'Try not to mention risky and time-consuming activities. Your future employer wants to have you in good health and physically capable of working. Any activities or hobbies that take too much of your time may scare them off.'
                            },
                            {
                                header: '',
                                description: 'Try not to brag too much. Listing too many skills and interests gets eye-rolls from recruiters because it makes your resume look bloated. Keep it clean, list only the best ones and leave the valuable space on your resume for more important information.'
                            },

                        ]
                    },
                    {
                        itemTab: 'References',
                        data: [
                            {
                                header: '',
                                description: 'Only list the positive remarks. Ask only those people who say good things about you. You don\'t want a common reference to ruin your job application.'
                            }, {
                                header: '',
                                description: 'Relevance is what matters. If you want your references to be helpful, you should provide only those that have something relevant to share. If you need to showcase your team-work, it makes sense to list your former colleagues who are aware of how you function as part of a team.'
                            },

                        ]
                    },
                    {
                        itemTab: 'See My Social Media',
                        data: [
                            {
                                header: '',
                                description: 'Remove any controversial or offensive content. Drunk party photos and anything controversial or negative may cost you a job offer.'
                            },
                            {
                                header: '',
                                description: 'Be truthful on your resume, the same applies to your social media profiles. Make sure what is stated on your resume is in accordance with what is posted on your social media profiles.'
                            },
                            {
                                header: '',
                                description: 'If you’re a graphic designer or photographer, create a professional account on Instagram and use it as your digital portfolio. It’s the ideal spot to integrate a seamless digital brand. Use these options to help you find a job. Connect with industry leaders, write or share relevant articles, engage yourself in industry-relevant topics. Become visible.'
                            },
                            {
                                header: '',
                                description: 'Try googling yourselves. And spend some time going through the results. See what potential employers will discover when they go through your online presence. Manage your online presence, otherwise, it might cost you a job offer.'
                            },

                        ]
                    },
                    {
                        itemTab: 'Volunteering',
                        data: [
                            {
                                header: '',
                                description: 'Relate it to your skills. If you developed or got several skills because of your volunteer work, list these in the description. Concentrate especially on those skills that match your resume.'
                            },
                            {
                                header: '',
                                description: 'Use action verbs. This helps hiring managers to see you as a dedicated member of a team.'
                            },
                            {
                                header: '',
                                description: 'Don\'t focus on negative aspects you wish to change, and instead, emphasize how you\'re helping the community.'
                            },

                        ]
                    }


                ]
            }
        },
        props: ['currentItemTab']
    }
</script>
<style lang="scss" scoped>
    .menu-tips {
        &.cage {
            height: 500px;
            overflow: scroll;
        }

        &.content-row {
            display: flex;
            flex-direction: column;
            padding: 5px 10px;
            background: #ffffff;
            border-radius: 5px;
            margin-bottom: 10px;

            &:first-child {
                margin-top: 20px;
            }

            &:nth-child(odd) {
                background: rgba(116, 39, 116, 0.03);
            }
        }

        &.text-point {
            height: 5px;
            width: 5px;
            border-radius: 50%;
            background: #742774;
            margin-right: 14px;
        }

        &.description-header {
            font-weight: 600;
            font-size: 14px;
            color: #000000;
            font-family: "Segoe UI";
        }

        &.description-text {
            font-size: 12px;
            color: #000000;
            font-family: "Segoe UI";
            margin-left: 20px;
            text-align: start;
            max-width: 370px;
        }

    }
</style>
