<template>
    <section>
        <div class="item-references cage">
            <div v-if="showReferencesList">
                <div class="item-input-sub-cage">
                    <div class="item-sub-list-cage" v-for="(reference,i) in referencesData.myReferences" :key="i">
                        <div>
                            <p class="item-sub-list-text">{{reference.name}}</p>
                            <p class="item-sub-list-text-secondary">{{reference.company}}</p>
                        </div>
                        <div class="d-flex">
                            <span class="material-icons item-sub-list-create-icon"
                                  @click="editReferences(reference)">create</span>
                            <span class="material-icons item-sub-list-delete-icon"
                                  @click="deleteReferences(reference)">delete</span>
                        </div>
                    </div>
                </div>
                <div class="item-footer-button-cage">
                    <v-btn class="yellow-button w-100" @click="addReference">Add New Reference</v-btn>
                </div>
            </div>
            <div v-else>
                <div class="item-input-sub-cage">
                    <div class="item-input-cage">
                        <p class="item-input-header">Reference Name</p>
                        <input class="primary-input w-100" v-model="referencesData.name" @input="changeData"
                               @focusout="isEditing? updateReference(false) : ''">
                    </div>
                    <div class="item-input-cage">
                        <p class="item-input-header">Company</p>
                        <input class="primary-input w-100" v-model="referencesData.company" @input="changeData"
                               @focusout="isEditing? updateReference(false) : ''">
                    </div>
                    <div class="item-input-cage">
                        <p class="item-input-header">Position</p>
                        <input class="primary-input w-100" v-model="referencesData.title" @input="changeData"
                               @focusout="isEditing? updateReference(false) : ''">
                    </div>
                    <div class="item-input-cage">
                        <p class="item-input-header">Phone Number</p>
                        <input class="primary-input w-100" v-model="referencesData.phone" @input="changeData"
                               @focusout="isEditing? updateReference(false) : ''">
                    </div>
                    <div class="item-input-cage">
                        <p class="item-input-header">Email Address</p>
                        <input class="primary-input w-100" v-model="referencesData.email" @input="changeData"
                               @focusout="isEditing? updateReference(false) : ''">
                    </div>
                </div>
                <div class="item-footer-button-cage">
                    <v-btn class="yellow-button w-100" @click="isEditing ? updateReference(true) : saveNewReference()">
                        Save
                    </v-btn>
                </div>
            </div>
        </div>
        <v-snackbar v-model="showAddReferenceNotification" :timeout="2000" :color="'success'" top>
            Reference has been added.
        </v-snackbar>
        <v-snackbar v-model="showUpdateReferenceNotification" :timeout="2000" :color="'success'" top>
            Reference has been updated.
        </v-snackbar>
        <v-snackbar v-model="showDeleteReferenceNotification" :timeout="2000" :color="'success'" top>
            Reference has been deleted.
        </v-snackbar>
    </section>
</template>
<script>
    import {mapActions, mapState} from "vuex";
    import itemService from '../../../services/item-data'

    export default {
        name: 'itemReferences',
        data() {
            return {
                showReferencesList: true,
                showAddReferenceNotification: false,
                showUpdateReferenceNotification: false,
                showDeleteReferenceNotification: false,
                isEditing: false
            }
        },
        created() {
            this.showReferencesList = this.referencesData.myReferences.length > 0;
        },
        computed: {
            ...mapState({
                referencesData: (store) => store.references.referencesData
            })
        },
        methods: {
            ...mapActions({
                updateReferencesData: 'references/updateReferencesData',
                addNewReference: 'references/addNewReference',
                deleteReference: 'references/deleteReference',
                editReference: 'references/editReference'
            }),
            changeData() {
                if (this.isEditing) {
                    const editedObject = {
                        data: this.setEditedObject()
                    };
                    this.editReference(editedObject);
                }
            },
            editReferences(reference) {
                this.isEditing = true;
                this.referencesData.id = reference.id;
                this.referencesData.name = reference.name;
                this.referencesData.email = reference.email;
                this.referencesData.company = reference.company;
                this.referencesData.phone = reference.phone;
                this.referencesData.position = reference.position;
                this.referencesData.title = reference.title;
                this.showReferencesList = false;
            },
            deleteReferences(reference) {
                itemService.deleteReference(reference.id).then((deleteResponse) => {
                    console.log('delete resp', deleteResponse);
                    const referenceIndex = this.referencesData.myReferences.indexOf(reference);
                    this.deleteReference(referenceIndex);
                    this.showDeleteReferenceNotification = true;
                    this.showReferencesList = this.referencesData.myReferences.length > 0;

                });
            },
            addReference() {
                this.isEditing = false;
                this.referencesData.name = '';
                this.referencesData.email = '';
                this.referencesData.company = '';
                this.referencesData.phone = '';
                this.referencesData.position = '';
                this.referencesData.title = '';
                this.showReferencesList = false;
            },
            setEditedObject(){
                const editedObject = {
                    name: this.referencesData.name,
                    email: this.referencesData.email,
                    company: this.referencesData.company,
                    phone: this.referencesData.phone,
                    position: this.referencesData.position,
                    title: this.referencesData.title,
                    id: this.referencesData.id
                };
                return editedObject;
            },
            updateReference(goBack) {
                const editedReference = {
                    name: this.referencesData.name,
                    email: this.referencesData.email,
                    company: this.referencesData.company,
                    phone: this.referencesData.phone,
                    position: this.referencesData.position,
                    title: this.referencesData.title,
                    id: this.referencesData.id
                };
                itemService.updateReference(editedReference.id, editedReference).then((updateResponse) => {
                    this.editReference(updateResponse);
                    this.showUpdateReferenceNotification = true;
                    this.showReferencesList = goBack;
                });
            },
            saveNewReference() {
                const newReference = {
                    name: this.referencesData.name,
                    email: this.referencesData.email,
                    company: this.referencesData.company,
                    phone: this.referencesData.phone,
                    position: this.referencesData.position,
                    title: this.referencesData.title
                };
                itemService.createReference(newReference).then((createResponse) => {
                    this.addNewReference(createResponse.data);
                    this.showAddReferenceNotification = true;
                    this.showReferencesList = true;
                });
            }
        },
        watch: {
            hobbiesData: {
                deep: true,
                handler() {
                    //  this.updateReferencesData(this.referencesData);
                }
            }
        }
    }
</script>
<style></style>
