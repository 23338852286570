<template>
    <section>
        <div class="item-experience cage">
            <div v-if="showExperienceList">
                <div class="item-input-sub-cage">
                    <div class="item-sub-list-cage" v-for="(experience,i) in experienceData.myExperience" :key="i">
                        <div>
                            <p class="item-sub-list-text">{{experience.title}}</p>
                            <p class="item-sub-list-text-secondary">{{experience.company}}</p>
                        </div>
                        <div class="d-flex">
                            <span class="material-icons item-sub-list-create-icon"
                                  @click="editExperiences(experience)">create</span>
                            <span class="material-icons item-sub-list-delete-icon"
                                  @click="deleteExperience(experience)">delete</span>
                        </div>
                    </div>
                </div>
                <div class="item-footer-button-cage">
                    <v-btn class="yellow-button w-100" @click="addExperience">Add New Experience</v-btn>
                </div>
            </div>
            <div v-else>
                <div class="item-input-sub-cage">
                    <div class="item-input-cage">
                        <p class="item-input-header">Company</p>
                        <input class="primary-input w-100" v-model="experienceData.company"
                               @input="changeData"
                               @focusout="isEditing? updateExperience(false) : ''">
                    </div>
                    <div class="item-input-cage">
                        <p class="item-input-header">Job Title</p>
                        <input class="primary-input w-100"
                               v-model="experienceData.title"
                               @input="changeData"
                               @focusout="isEditing? updateExperience(false) : ''">
                    </div>
                    <div class="item-input-cage">
                        <p class="item-input-header">City</p>
                        <input class="primary-input w-100" v-model="experienceData.city"
                               @input="changeData"
                               @focusout="isEditing? updateExperience(false) : ''">
                    </div>
                    <div class="item-input-cage">
                        <p class="item-input-header">Country</p>
                        <input class="primary-input w-100" v-model="experienceData.country"
                               @input="changeData"
                               @focusout="isEditing? updateExperience(false) : ''">
                    </div>
                    <div class="d-flex flex-row">
                        <div class="item-input-cage mr-2">
                            <p class="item-input-header">Start Date</p>
                            <div class="d-flex flex-row">
                                <input class="primary-input w-100 mr-1" v-model="experienceData.start_month"
                                       @input="changeData"
                                       placeholder="Month" @focusout="isEditing? updateExperience(false) : ''">
                                <input class="primary-input w-100" v-model="experienceData.start_year"
                                       @input="changeData"
                                       placeholder="Year" @focusout="isEditing? updateExperience(false) : ''">
                            </div>
                        </div>
                        <div class="item-input-cage">
                            <p class="item-input-header">End Date</p>
                            <div class="d-flex flex-row">
                                <input class="primary-input w-100 mr-1" v-model="experienceData.end_month"
                                       @input="changeData"
                                       placeholder="Month" @focusout="isEditing? updateExperience(false) : ''">
                                <input class="primary-input w-100" v-model="experienceData.end_year" :disabled="experienceData.current"
                                       @input="changeData"
                                       placeholder="Year" @focusout="isEditing? updateExperience(false) : ''">
                            </div>
                        </div>
                    </div>
                    <div class="item-input-cage d-flex flex-row">
                        <div class="item-inner-cage-half mr-2">
                            <checkbox label="Don't Show" :model="experienceData.show_start_date" name="show_start_date"
                                      @isChecked="experienceData.show_start_date = $event" @input="changeData"
                                      @changeEvent="isEditing? updateExperience(false) : ''"></checkbox>
                            <checkbox label="Only Year" :model="experienceData.start_only_year" name="start_only_year"
                                      @isChecked="experienceData.start_only_year = $event"
                                      @changeEvent="isEditing? updateExperience(false) : ''"></checkbox>
                        </div>
                        <div class="item-inner-cage-half">
                            <checkbox label="Present" :model="experienceData.current" name="current"
                                      @isChecked="experienceData.current = $event" @input="changeData"
                                      @changeEvent="isEditing? updateExperience(false) : ''"></checkbox>
                            <checkbox label="Don't Show" :model="experienceData.show_end_date" name="show_end_date"
                                      @isChecked="experienceData.show_end_date = $event" @input="changeData"
                                      @changeEvent="isEditing? updateExperience(false) : ''"></checkbox>
                            <checkbox label="Only Year" :model="experienceData.end_only_year" name="end_only_year"
                                      @isChecked="experienceData.end_only_year = $event" @input="changeData"
                                      @changeEvent="isEditing? updateExperience(false) : ''"></checkbox>
                        </div>
                    </div>
                    <div class="item-input-cage">
                        <div class="item-input-tips-cage">
                            <p class="item-input-header">Description</p>
                            <experience-tips @addDescriptionTip="setExperienceTips($event)"></experience-tips>
                        </div>
                        <textarea class="primary-text-area w-100" rows="3"
                                  v-model="experienceData.description" @input="changeData"
                                  @focusout="isEditing? updateExperience(false) : ''"></textarea>
                    </div>
                    <div class="item-input-cage">
                        <p class="item-input-header">Employment Type</p>
                        <div class=" d-flex flex-row">
                            <div class="item-inner-cage-half mr-2">
                                <div class="d-flex">
                                    <input id="checkbox-1" class="checkbox-custom" type="radio" value="0" disabled
                                           name="employmentType" v-model="experienceData.employment_type"
                                           @input="isEditing? updateExperience(false) : ''">
                                    <label for="checkbox-1" class="checkbox-custom-label">Part Time</label>
                                </div>
                                <div class="d-flex">
                                    <input id="checkbox-2" class="checkbox-custom" type="radio" value="1" disabled
                                           name="employmentType" v-model="experienceData.employment_type"
                                           @input="isEditing? updateExperience(false) : ''">
                                    <label for="checkbox-2" class="checkbox-custom-label">Remote</label>
                                </div>
                            </div>
                            <div class="item-inner-cage-half">
                                <div class="d-flex">
                                    <input id="checkbox-3" class="checkbox-custom" type="radio" value="2" disabled
                                           name="employmentType" v-model="experienceData.employment_type"
                                           @input="isEditing? updateExperience(false) : ''">
                                    <label for="checkbox-3" class="checkbox-custom-label">Full Time</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="item-footer-button-cage">
                    <v-btn class="yellow-button w-100"
                           @click="isEditing ? updateExperience(true) : saveNewExperience()">
                        Save
                    </v-btn>
                </div>
            </div>
        </div>
        <v-snackbar v-model="showAddExperienceNotification" :timeout="2000" :color="'success'" top>
            Experience has been added.
        </v-snackbar>
        <v-snackbar v-model="showUpdateExperienceNotification" :timeout="2000" :color="'success'" top>
            Experience has been updated.
        </v-snackbar>
        <v-snackbar v-model="showDeleteExperienceNotification" :timeout="2000" :color="'success'" top>
            Experience has been deleted.
        </v-snackbar>
    </section>
</template>
<script>
    import {mapActions, mapState} from "vuex";
    import checkbox from '../../atomic/input/checkbox.vue'
    import itemService from '../../../services/item-data'
    import ExperienceTips from "@/components/contents/items/tips/experience-tips";

    export default {
        name: 'itemExperience',
        components: {ExperienceTips, checkbox},
        data() {
            return {
                showExperienceList: true,
                isEditing: '',
                selectedIndex: '',
                showAddExperienceNotification: false,
                showUpdateExperienceNotification: false,
                showDeleteExperienceNotification: false,
            }
        },
        created() {
            this.showExperienceList = this.experienceData.myExperience.length > 0;
        },
        computed: {
            ...mapState({
                experienceData: (store) => store.experience.experienceData
            })
        },
        methods: {
            ...mapActions({
                updateExperienceData: 'experience/updateExperienceData',
                addNewExperience: 'experience/addNewExperience',
                deleteExperiences: 'experience/deleteExperiences',
                editExperience: 'experience/editExperience'
            }),
            changeData() {
                if (this.isEditing) {
                    const editedObject = {
                        data: this.setEditedObject()
                    };
                    this.editExperience(editedObject);
                }
            },
            setExperienceTips(tip){
                this.experienceData.description += tip;
                if(this.isEditing){
                    this.updateExperience(false);
                }else{
                    this.changeData();
                }
            },
            addExperience() {
                this.isEditing = false;
                this.experienceData.company = '';
                this.experienceData.title = '';
                this.experienceData.city = '';
                this.experienceData.country = '';
                this.experienceData.start_year = '';
                this.experienceData.start_month = '';
                this.experienceData.start_only_year = false;
                this.experienceData.show_start_date = false;
                this.experienceData.end_year = '';
                this.experienceData.end_month = '';
                this.experienceData.end_only_year = false;
                this.experienceData.show_end_date = false;
                this.experienceData.current = false;
                this.experienceData.description = '';
                this.experienceData.employment_type = '';
                this.showExperienceList = false;
            },
            editExperiences(experience) {
                this.isEditing = true;
                this.experienceData.title = experience.title;
                this.experienceData.id = experience.id;
                this.experienceData.company = experience.company;
                this.experienceData.city = experience.city;
                this.experienceData.country = experience.country;
                this.experienceData.start_month = experience.start_month;
                this.experienceData.start_year = experience.start_year;
                this.experienceData.end_month = experience.end_month;
                this.experienceData.end_year = experience.end_year;
                this.experienceData.start_only_year = experience.start_only_year;
                this.experienceData.end_only_year = experience.end_only_year;
                this.experienceData.show_start_date = experience.show_start_date;
                this.experienceData.show_end_date = experience.show_end_date;
                this.experienceData.description = experience.description;
                this.experienceData.employment_type = experience.employment_type;
                this.experienceData.current = experience.current;
                this.showExperienceList = false;
            },
            deleteExperience(experience) {
                itemService.deleteExperience(experience.id).then((deleteResponse) => {
                    console.log('delete resp', deleteResponse);
                    const experienceIndex = this.experienceData.myExperience.indexOf(experience);
                    this.deleteExperiences(experienceIndex);
                    this.showDeleteExperienceNotification = true;
                    this.showExperienceList = this.experienceData.myExperience.length > 0;
                });
            },
            setEditedObject() {
                const editedObject = {
                    title: this.experienceData.title,
                    company: this.experienceData.company,
                    city: this.experienceData.city,
                    country: this.experienceData.country,
                    company_logo: '',
                    start_month: this.experienceData.start_month,
                    start_year: this.experienceData.start_year,
                    end_month: this.experienceData.end_month,
                    end_year: this.experienceData.end_year,
                    show_start_date: this.experienceData.show_start_date,
                    show_end_date: this.experienceData.show_end_date,
                    start_only_year: this.experienceData.start_only_year,
                    end_only_year: this.experienceData.end_only_year,
                    description: this.experienceData.description,
                    employment_type: parseInt(this.experienceData.employment_type, 10),
                    current: this.experienceData.current,
                    html: {name:'Efe'},
                    id: this.experienceData.id
                };
              if(editedObject.current) {
                editedObject.end_year = 'Present'
                this.experienceData.end_year = 'Present'
              }
                return editedObject;
            },
            updateExperience(goBack) {
                const editedExperience = {
                    title: this.experienceData.title,
                    company: this.experienceData.company,
                    city: this.experienceData.city,
                    country: this.experienceData.country,
                    company_logo: '',
                    start_month: this.experienceData.start_month,
                    start_year: this.experienceData.start_year,
                    end_month: this.experienceData.end_month,
                    end_year: this.experienceData.end_year,
                    show_start_date: this.experienceData.show_start_date,
                    show_end_date: this.experienceData.show_end_date,
                    start_only_year: this.experienceData.start_only_year,
                    end_only_year: this.experienceData.end_only_year,
                    description: this.experienceData.description,
                    employment_type: parseInt(this.experienceData.employment_type, 10),
                    current: this.experienceData.current,
                    html: '',
                    id: this.experienceData.id
                };
                if(editedExperience.current) {
                  editedExperience.end_year = 'Present'
                  this.experienceData.end_year = 'Present'
                }
                itemService.updateExperience(editedExperience.id, editedExperience).then((updateResponse) => {
                    this.editExperience(updateResponse);
                    this.showUpdateExperienceNotification = true;
                    this.showExperienceList = goBack;
                });
            },
            saveNewExperience() {
                const newExperience = {
                    experience: {
                        title: this.experienceData.title,
                        company: this.experienceData.company,
                        city: this.experienceData.city,
                        country: this.experienceData.country,
                        company_logo: '',
                        start_month: this.experienceData.start_month,
                        start_year: this.experienceData.start_year,
                        end_month: this.experienceData.end_month,
                        end_year: this.experienceData.end_year,
                        start_only_year: this.experienceData.start_only_year,
                        end_only_year: this.experienceData.end_only_year,
                        description: this.experienceData.description,
                        employment_type: parseInt(this.experienceData.employment_type, 10),
                        current: this.experienceData.current,
                        html: ''
                    }
                };
                itemService.createExperience(newExperience).then((createResponse) => {
                    console.log('create resp.', createResponse);
                    this.addNewExperience(createResponse.data);
                    this.showAddExperienceNotification = true;
                    this.showExperienceList = true;
                })
            }
        }
    }
</script>
<style lang="scss" scoped>
</style>
