<template>
    <section class="position-relative">
        <p class="create-resume main-header">Select Template</p>
        <p class="create-resume main-description">Select template and create resume</p>
        <div class="create-resume table-cage">
            <div class="create-resume table-head-line">
                <p class="create-resume table-header">Templates</p>
            </div>
            <div class="theme-selector cage">
                <div class="theme-selector theme-cage" v-for="(theme,i) in showThemeList" :key="i">
                    <div class="position-relative" :class="{'selected-theme': selectedTheme.id === theme.id}">
                        <img :class="{'disabled': theme.paid_version && !userInfo.isUserPro}"
                             @click="theme.paid_version && !userInfo.isUserPro ? openPurchaseModal():setSelectedTheme(theme)"
                             class="theme-selector image-cage" :src="theme.url">
                        <img class="theme-selector lock-icon" v-if="theme.paid_version && !userInfo.isUserPro"
                             src="../../../assets/custom-icons/lock-icon.svg">
                        <span class="material-icons theme-selector visible-icon" @click="showThemeDetail(theme)">visibility</span>
                    </div>
                    <p class="theme-selector theme-name">{{theme.title}}</p>
                </div>
            </div>
        </div>
        <v-pagination v-model="currentPage" class="pagination-cage" :length="totalPage" color="#742774"></v-pagination>
        <modal :show="showThemeDetailVisibility" refs="themeDetailModal" :ext="'modal-dialog--medium'"
               @close="showThemeDetailVisibility = false" >
            <img class="theme-selector modal-image-cage" :src="selectedThemeImageUrl" alt="themeImage">
        </modal>
    </section>
</template>
<script>
    import resumeService from '../../../services/resume'
    import {mapActions, mapState} from "vuex";
    import modal from "@/components/atomic/modal";

    export default {
        name: 'themeSelector',
        components: {modal},
        props: ['selectedThemeData'],
        data() {
            return {
                themeList: [],
                showThemeList: [],
                totalPage: 0,
                currentPage: 1,
                showThemeDetailVisibility: false,
                selectedTheme: '',
                selectedThemeImageUrl: '',
            }
        },
        created() {
            this.getThemeList();
            this.selectedTheme = this.selectedThemeData;
        },
        methods: {
            ...mapActions({
                updateCheckoutModalShow: 'general/updateCheckoutModalShow'
            }),
            getThemeList() {
                resumeService.getThemeList('resume').then((themeList) => {
                    this.themeList = themeList.data.data;
                    this.totalPage = Math.ceil(this.themeList.length / 10);
                    this.getThemeListWithPage();
                })
            },
            getThemeListWithPage() {
                const startModel = (this.currentPage - 1) * 10;
                const endModel = (this.currentPage) * 10;
                this.showThemeList = [];
                for (let i = startModel; i < endModel; i++) {
                    if (this.themeList[i]) {
                        this.showThemeList.push(this.themeList[i])
                    }
                }
            },
            showThemeDetail(theme) {
                this.selectedThemeImageUrl = theme.url;
                this.showThemeDetailVisibility = true;
            },
            setSelectedTheme(theme) {
                console.log('theme', theme);
                if (theme.paid_version && !this.userInfo.isUserPro) {
                    console.log('error')
                } else {
                    this.selectedTheme = theme;
                    this.$emit('changeTheme', this.selectedTheme.id);
                }
            },
            openPurchaseModal() {
                this.updateCheckoutModalShow(true);
            }
        },
        computed: {
            ...mapState({
                userInfo: (store) => store.authorization.userInfo
            })
        },
        watch: {
            currentPage: {
                handler() {
                    this.getThemeListWithPage();
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    .theme-selector {
        &.cage {
            padding: 18px 20px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            height: 620px;
            overflow-y: auto;

        }

        &.theme-cage {
            width: 182px;
        }

        &.theme-name {
            font-family: Poppins;
            font-weight: 600;
            font-size: 16px;
            color: #000000;
            text-align: start;
            margin-top: 10px;
        }

        &.image-cage {
            height: 252px;
            width: 100%;
        }

        &.visible-icon {
            position: absolute;
            right: 15px;
            top: 10px;
            color: rgb(90, 90, 90);
            background: rgb(159, 167, 176);
            cursor: pointer;
            border-radius: 2px;
            padding: 2px;
        }
        &.modal-image-cage{
            width: auto;
            height: 80vh;
        }
    }

    .lock-icon {
        position: absolute;
        bottom: 10px;
        right: 15px;
    }

    .disabled {
        filter: opacity(40%) !important;
    }

    .selected-theme {
        outline: 2px solid #742774;
    }

    .pagination-cage {
        margin-top: 16px;
        margin-bottom: 80px !important;
    }
</style>
