<template>
    <section>
        <div class="item-experience cage">
            <div class="item-input-sub-cage">
                <div class="item-input-cage">
                    <p class="item-input-header">Skill</p>
                    <input class="primary-input w-100" v-model="technicalSkillsData.name">
                </div>
                <div class="item-input-cage">
                    <p class="item-input-header">Skill Rate</p>
                    <v-rating v-model="technicalSkillsData.rate" background-color="orange lighten-3" color="orange"
                              large></v-rating>
                </div>
                <div class="item-input-cage">
                    <v-btn class="yellow-button w-100" @click="addSkill">Add New Skill</v-btn>
                </div>
                <div class="item-input-cage my-skill-cage" v-if="technicalSkillsData.mySkills.length > 0">
                    <p class="item-input-header">My Skills</p>
                    <div v-for="(skill,i) in technicalSkillsData.mySkills" :key="i">
                        <div class="d-flex justify-space-between align-items-center">
                            <div class="d-flex align-items-start flex-column">
                                <p>{{skill.name}}</p>
                                <v-rating v-model="skill.rate" @input="updateSkills(skill)"
                                          background-color="orange lighten-3" color="orange"
                                          medium></v-rating>
                            </div>
                            <div class="d-flex flex-column align-items-start">
                                <p class="item-content-list-button-red" @click="eraseSkill(skill)">
                                    <span class="material-icons">delete</span>
                                    Delete
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <v-snackbar v-model="showDeletedSkill" :timeout="2000" :color="'success'" top>
            Skill has been deleted.
        </v-snackbar>
        <v-snackbar v-model="showUpdateSkill" :timeout="2000" :color="'success'" top>
            Skill has been updated.
        </v-snackbar>
        <v-snackbar v-model="showAddSkill" :timeout="2000" :color="'success'" top>
            Skill has been added.
        </v-snackbar>
    </section>
</template>
<script>
    import {mapActions, mapState} from "vuex";
    import itemService from '../../../services/item-data';

    export default {
        name: 'technicalSkills',
        data() {
            return {
                showDeletedSkill: false,
                showAddSkill: false,
                showUpdateSkill: false
            }
        },
        computed: {
            ...mapState({
                technicalSkillsData: (store) => store.technicalSkills.technicalSkillsData
            })
        },
        methods: {
            ...mapActions({
                updateTechnicalSkillsData: 'technicalSkills/updateTechnicalSkillsData',
                addNewSkill: 'technicalSkills/addNewSkill',
                deleteSkill: 'technicalSkills/deleteSkill',
                editSkill: 'technicalSkills/editSkill',
            }),
            addSkill() {
                const newSkill = {
                    name: this.technicalSkillsData.name,
                    rate: this.technicalSkillsData.rate
                };
                itemService.createSkill(newSkill).then((createResponse) => {
                    this.addNewSkill(createResponse.data);
                    this.showAddSkill = true;
                    this.technicalSkillsData.name = '';
                    this.technicalSkillsData.rate = 0;
                });
            },
            eraseSkill(skill) {
                itemService.deleteSkill(skill.id).then(() => {
                    const skillIndex = this.technicalSkillsData.mySkills.indexOf(skill);
                    this.deleteSkill(skillIndex);
                    this.showDeletedSkill = true;
                })
            },
            updateSkills(skill) {
                itemService.updateSkill(skill.id, skill).then(() => {
                    this.showUpdateSkill = true;
                })
            }
        }
    }
</script>
<style lang="scss" scoped>

    .skill-edit-btn {
        font-family: "Segoe UI", sans-serif;
        font-size: 14px;
        font-weight: 400;
        color: #717378;
        cursor: pointer;
    }

    .my-skill-cage {
        margin-top: 25px !important;
    }
</style>
