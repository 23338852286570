<template>
    <section>
        <div class="navigator-cage">
            <a class="navigator-link" :class="{'active':currentTab==='resume'}"
               @click="setCurrentTab('resume')">Resume</a>
            <a class="navigator-link" :class="{'active':currentTab==='cover_letter'}"
               @click="setCurrentTab('cover_letter')">Cover Letter</a>
        </div>
    </section>
</template>
<script>
    export default {
        name: 'publicPageNavigator',
        data() {
            return {
                currentTab: 'resume'
            }
        },
        methods: {
            setCurrentTab(tabName) {
                this.currentTab = tabName;
                this.$emit('changeTab', this.currentTab);
            }
        }
    }
</script>
<style lang="scss" scoped>
    .navigator-cage {
        display: flex;
        justify-content: space-around;
        margin-bottom: 20px;

    }

    .navigator-link {
        font-family: "Segoe UI";
        font-weight: 500;
        width: 100%;
        text-decoration: none;
        color: #000000;
        padding: 10px 0;
        &.active {
            font-weight: 600;
            border-bottom: 2px solid #742774;
        }
    }
</style>
