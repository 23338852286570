import Vue from 'vue';
import Vuex from 'vuex';
import authorization from './modules/authorization'
import resume from './modules/resume'
import summary from './modules/item-data/summary'
import experience from './modules/item-data/experience'
import education from './modules/item-data/education'
import technicalSkills from './modules/item-data/technical-skills'
import languageSkills from './modules/item-data/language-skills'
import certificate from './modules/item-data/certificate'
import awards from './modules/item-data/awards'
import projects from './modules/item-data/projects'
import publications from './modules/item-data/publications'
import myTime from './modules/item-data/my-time'
import hobbies from './modules/item-data/hobbies'
import philosophy from './modules/item-data/philosophy'
import social from './modules/item-data/social'
import strengths from './modules/item-data/strengths'
import volunteering from './modules/item-data/volunteering'
import conferences from './modules/item-data/conferences'
import references from './modules/item-data/references'
import general from './modules/general'
import greeting from './modules/cl-data/greeting'
import opening from './modules/cl-data/opening'
import body from './modules/cl-data/body'
import closing from './modules/cl-data/closing'

Vue.use(Vuex);


export default new Vuex.Store({
    namespaced: true,
    modules: {
        summary,
        experience,
        awards,
        projects,
        conferences,
        references,
        strengths,
        philosophy,
        volunteering,
        social,
        publications,
        myTime,
        hobbies,
        certificate,
        technicalSkills,
        education,
        languageSkills,
        authorization,
        general,
        resume,
        greeting,
        opening,
        body,
        closing
    }
});
