<template>
    <section>
        <div class="pricing-page cage">
            <div class="pricing-page container">
                <div>
                    <p class="web-site bold-header">Pricing</p>
                    <p class="web-site sub-header-text">No more effort for the best looking resume designs, choose from
                        the
                        best templates we’ve created for you and just follow the instructions</p>
                </div>
                <div class="price-table cage">
                    <div class="price-table row">
                        <div class="col-4">
                        </div>
                        <div class="price-table inner-cage h-auto col-8">
                            <div class="price-table head-column head-inner">
                                <p class="price-table basic-head">Basic</p>
                                <p class="price-table price-text">$0</p>
                                <p class="price-table time-text">Forever Free</p>
                                <v-btn class="white-button background-none">Current Plan</v-btn>
                            </div>
                            <div class="price-table head-column head-inner">
                                <p class="price-table pro-head">Pro</p>
                                <p class="price-table price-text price-purple" v-if="!planMonthly">$19.99 <span
                                        class="price-table monthly-text">/mo</span></p>
                                <p class="price-table price-text price-purple" v-else>$14.99 <span
                                        class="price-table monthly-text">/mo</span></p>
                                <toggle-box class="price-table time-selector" :first-label="'Monthly'"
                                            :second-label="'Yearly'"
                                            :toggleValue="planMonthly"
                                            @changeToggle="planMonthly = $event">
                                </toggle-box>
                                <v-btn class="yellow-button" @click="$router.push({name:'checkoutPage'})">Select Plan
                                </v-btn>
                            </div>
                        </div>
                    </div>
                    <div class="price-table row" v-for="(priceDetail,i) in priceData" :key="i">
                        <div class="col-4">
                            <p class="price-table row-head">{{priceDetail.rowHead}}</p>
                        </div>
                        <div class="price-table inner-cage col-8">
                            <div class="price-table head-column">
                                <p v-if="priceDetail.type === 'text'">{{priceDetail.basicText}}</p>
                                <div v-else>
                                    <img v-if="!priceDetail.basicText"
                                         src="../../assets/custom-icons/web-site-icons/price-none-icon.svg" alt="none">
                                    <img v-else src="../../assets/custom-icons/web-site-icons/price-tick-icon.svg"
                                         alt="tick">
                                </div>
                            </div>
                            <div class="price-table head-column">
                                <p v-if="priceDetail.type === 'text'">{{priceDetail.proText}}</p>
                                <div v-else>
                                    <img v-if="!priceDetail.proText"
                                         src="../../assets/custom-icons/web-site-icons/price-none-icon.svg" alt="none">
                                    <img v-else src="../../assets/custom-icons/web-site-icons/price-tick-icon.svg"
                                         alt="tick">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    import toggleBox from '../../components/atomic/input/toggle-box.vue'

    export default {
        name: 'priceTable',
        components: {toggleBox},
        data() {
            return {
                planMonthly: true,
                priceData: [
                    {rowHead: 'Templates', basicText: '3 Templates', proText: '20 Templates', type: 'text'},
                    {rowHead: 'Fonts', basicText: '3 Fonts', proText: '20 Fonts', type: 'text'},
                    {rowHead: 'Backgrounds', basicText: false, proText: true, type: 'icon'},
                    {rowHead: 'PDF Download', basicText: true, proText: true, type: 'icon'},
                    {rowHead: 'Professional Tips', basicText: false, proText: true, type: 'icon'},
                    {rowHead: 'Personal URL', basicText: true, proText: true, type: 'icon'},
                    {rowHead: 'Customized Url', basicText: false, proText: true, type: 'icon'},
                    {rowHead: 'CV Reviews / Ratings', basicText: false, proText: true, type: 'icon'},
                ]
            }
        },
    }
</script>
<style lang="scss" scoped>
    .price-table {
        &.cage {
            margin-top: 54px;
        }

        &.row {
            align-items: center;
        }

        &.inner-cage {
            padding: 25px;
            display: flex;
            height: 73px;
            align-items: center;
            border: 1px solid rgba(116, 39, 116, 0.1);
        }

        &.head-inner {
            flex-direction: column;
            height: auto !important;
            padding: 25px;
        }

        &.head-column {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border-right: 1px solid rgba(116, 39, 116, 0.1);

            &:last-child {
                border-right: none !important;
            }
        }

        &.row-head {
            font-size: 16px;
            font-weight: 600;
            text-align: start;
            font-family: "Segoe UI";
            color: rgba(40, 43, 52, 0.6);
        }

        &.basic-head {
            font-size: 18px;
            font-weight: 400;
            font-family: "Segoe UI";
            color: #282B34;
        }

        &.time-text {
            font-size: 15px;
            font-weight: 500;
            font-family: Roboto;
            color: #282b34;
            margin-bottom: 35px !important;
            margin-top: 25px;
        }

        &.pro-head {
            font-size: 18px;
            font-weight: 400;
            font-family: "Segoe UI";
            color: #742774;
        }

        &.price-text {
            font-family: "Segoe UI";
            font-weight: 600;
            font-size: 36px;
            color: #282b34;
        }

        &.monthly-text {
            font-family: "Segoe UI";
            font-size: 18px;
            font-weight: 600;
            color: #742774;
        }

        &.price-purple {
            color: #742774 !important;
        }

        &.time-selector {
            margin-top: 30px !important;
            margin-bottom: 35px !important;
        }
    }

    .pricing-page {
        &.cage {
            background: linear-gradient(180deg, #F8FBFF 0%, rgba(250, 252, 255, 0) 100%);
            position: absolute;
            width: 100%;
            top: 0;
            height: 100%;
            z-index: 0;
            overflow-y: auto;
        }

        &.container {
            margin-top: 44px;
            overflow-y: auto;
            margin-bottom: 80px;
        }
    }
</style>
