import axios from 'axios';
import Vue from 'vue'
import Vuex from 'vuex';
import general from '../store/modules/general';

const apiUrl = 'https://apicv.anycv.com';// Env. base Url gelicek.
//const apiUrl = 'https://api.anycv.com';// Env. base Url gelicek.
const success = (res) => res;

Vue.use(Vuex);


const store = new Vuex.Store({
    namespaced: true,
    modules: {
        general
    }
});

const error = (err) => {
    if (err.response.status === 401) {
        localStorage.clear();
       // window.location.href = '/website/auth';
    }
    // Request hata alırsa notify kullan error göster !!!
    return Promise.reject(err || err.response || err.response.data);
};

let common = {};
if (localStorage.getItem('access-token')) {
    common['access-token'] = localStorage.getItem('access-token');
    common.client = localStorage.getItem('client');
    common.uid = localStorage.getItem('uid');
}
// common = header parametleri koy !

const gateWay = axios.create({
    baseURL: apiUrl,
    headers: {common},
});
gateWay.interceptors.request.use((config) => {
    store.commit('general/UPDATE_API_LOADER_STATUS', true);
    return config;
}, (error) => {
    return error;
});
gateWay.interceptors.response.use((res) => {
    store.commit('general/UPDATE_API_LOADER_STATUS', false);
    success(res);
    return res;
}, (err) => error(err));

export default gateWay;
