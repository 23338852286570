<template>
    <section>
        <div class="navigate-controller cage">
            <div class="container">
                <a class="back-button" @click="changeTab('back')">Back</a>
                <v-btn class="yellow-button" :disabled="controlStepper()" @click="changeTab('next')">Next</v-btn>
            </div>
        </div>
    </section>
</template>
<script>
    export default {
        name: 'navigateController',
        props: ['currentTab', 'selectedJob', 'likedinUrl'],
        data() {
            return {
                navigateList: [
                    {currentTab: 'linkedinImport', beforeTab: 'startTypeSelector', nextTab: 'industrySelector'},
                    {currentTab: 'industrySelector', beforeTab: 'startTypeSelector', nextTab: 'themeSelector'},
                    {currentTab: 'themeSelector', beforeTab: 'industrySelector', nextTab: 'createResume'}
                ]
            }
        },
        methods: {
            changeTab(eventType) {
                const currentNavigate = this.navigateList.find(tab => tab.currentTab === this.currentTab);
                if (eventType === 'back') {
                    this.$emit('changeCurrentTab', currentNavigate.beforeTab);
                } else {
                    this.$emit('changeCurrentTab', currentNavigate.nextTab);
                }
            },
            controlStepper() {
                const currentNavigate = this.navigateList.find(tab => tab.currentTab === this.currentTab);
                if (currentNavigate.currentTab === 'industrySelector') {
                    if (this.selectedJob.subCategory === '' || this.selectedJob.experienceId === '') {
                        return true;
                    }
                }
                if (currentNavigate.currentTab === 'linkedinImport') {
                    if (this.likedinUrl === '') {
                        return true;
                    }
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    .navigate-controller {
        &.cage {
            border-top: 2px solid #F1F7FF;
            position: fixed;
            left: 0;
            bottom: 0;
            width: 100%;
            padding: 19px 0;
            background: #fff;
        }
    }

    .back-button {
        text-decoration: none;
        cursor: pointer;
        font-family: Roboto;
        font-size: 14px;
        color: #9EA2B1;
        margin-right: 25px;
    }

    .container {
        padding-bottom: 0 !important;
        padding-top: 0 !important;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
</style>
