<template>
    <div class="theme-outer-cage mobile-cage-content" id="element-to-print">
        <div class="theme-main-cage" v-if="listedMenu.length >0"
             :style="[{backgroundColor:resumeOptions.backgroundColorModel},{backgroundImage:'url('+resumeOptions.backgroundDecoration.url+')'}]">
            <section>
                <div class="cl-mp-theme  header-area"
                     :style="{order:listedMenu.find(x => x.name ==='Salutation and Greeting').position}">
                    <div class="cl-mp-theme item-data-row">
                        <div class="ml-43"
                             @click="openEditor('greetingNameEditor',$event, greetingData, 'name', 'greeting')"
                             :class="{'active-editor':editorList.greetingNameEditor}">
                            <p class="cl-mp-theme name-text greeting-text"
                               v-if="greetingData.name"
                               :class="greetingData.html ? greetingData.html.greetingNameEditor : ''"
                               @focusout="updateItemData($event, greetingData, 'name', 'greeting')"
                               spellcheck="false" contenteditable>{{greetingData.name}}</p>
                             <text-editor @addPoint="addListPoint()" v-if="editorList.greetingNameEditor"
                                         :id-name="'greeting-text'"
                                         @addClass="updateEditorClass()"></text-editor>
                        </div>
                        <div class="cl-mp-theme header-black-row"></div>
                    </div>
                </div>
                <div class="cl-mp-theme list-cage">
                    <p class="cl-mp-theme cl-header">COVER LETTER</p>
                    <div class="cl-mp-theme full-cage salutation-area"
                         :style="{order:listedMenu.find(x => x.name ==='Salutation and Greeting').position}">
                        <div class="cl-mp-theme item-data-row">
                            <div @click="openEditor('greetingTitleEditor',$event, greetingData, 'title', 'greeting')"
                                 :class="{'active-editor':editorList.greetingTitleEditor}">
                                <p class="cl-mp-theme secondary-content-text greeting-title-text mt-25 mgb-15"
                                   :class="greetingData.html ? greetingData.html.greetingTitleEditor : ''"
                                   @focusout="updateItemData($event, greetingData, 'title', 'greeting')"
                                   spellcheck="false" contenteditable>{{greetingData.title}}</p>
                            </div>
                             <text-editor @addPoint="addListPoint()" v-if="editorList.greetingTitleEditor"
                                         :id-name="'greeting-title-text'"
                                         @addClass="updateEditorClass()"></text-editor>
                        </div>
                    </div>
                    <div class="cl-mp-theme full-cage opening-area"
                         :style="{order:listedMenu.find(x => x.name ==='Opening Paragraph').position}">
                        <div class="cl-mp-theme item-data-row">
                            <div @click="openEditor('openingClEditor',$event, openingData, 'opening_body', 'opening')"
                                 :class="{'active-editor':editorList.openingClEditor}">
                                <p class="cl-mp-theme content-text opening-description-text"
                                   :class="openingData.html ? openingData.html.openingClEditor : ''"
                                   @focusout="updateItemData($event, openingData, 'opening_body', 'opening')"
                                   spellcheck="false" contenteditable>{{openingData.opening_body}}</p>
                            </div>
                             <text-editor @addPoint="addListPoint()" v-if="editorList.openingClEditor"
                                         :id-name="'opening-description-text'"
                                         @addClass="updateEditorClass()"></text-editor>
                        </div>
                    </div>
                    <div class="cl-mp-theme full-cage body-area mgt-10"
                         :style="{order:listedMenu.find(x => x.name ==='Cover Letter Body').position}">
                        <div class="cl-mp-theme item-data-row">
                            <div @click="openEditor('bodyClEditor',$event, bodyData, 'cover_body', 'body')"
                                 :class="{'active-editor':editorList.bodyClEditor}">
                                <p class="cl-mp-theme content-text body-description-text"
                                   :class="bodyData.html ? bodyData.html.bodyClEditor : ''"
                                   @focusout="updateItemData($event, bodyData, 'cover_body', 'body')"
                                   spellcheck="false" contenteditable>{{bodyData.cover_body}}</p>
                            </div>
                             <text-editor @addPoint="addListPoint()" v-if="editorList.bodyClEditor"
                                         :id-name="'body-description-text'"
                                         @addClass="updateEditorClass()"></text-editor>
                        </div>
                    </div>
                    <div class="cl-mp-theme full-cage closing-area mt-25"
                         :style="{order:listedMenu.find(x => x.name ==='Closing and Signature').position}">
                        <div class="cl-mp-theme item-data-row">
                            <div @click="openEditor('closingTitleEditor',$event, closingData, 'closing_title', 'closing')"
                                 :class="{'active-editor':editorList.closingTitleEditor}">
                                <p class="cl-mp-theme content-text closing-title-text"
                                   :class="closingData.html ? closingData.html.closingTitleEditor : ''"
                                   @focusout="updateItemData($event, closingData, 'closing_title', 'closing')"
                                   spellcheck="false" contenteditable>{{closingData.closing_title}}</p>
                            </div>
                             <text-editor @addPoint="addListPoint()" v-if="editorList.closingTitleEditor"
                                         :id-name="'closing-title-text'"
                                         @addClass="updateEditorClass()"></text-editor>
                        </div>
                        <div class="cl-mp-theme item-data-row">
                            <div @click="openEditor('closingNameEditor',$event, closingData, 'closing_name', 'closing')"
                                 :class="{'active-editor':editorList.closingNameEditor}">
                                <p class="cl-mp-theme secondary-content-text closing-name-text"
                                   :class="closingData.html ? closingData.html.closingNameEditor : ''"
                                   @focusout="updateItemData($event, closingData, 'closing_name', 'closing')"
                                   spellcheck="false" contenteditable>{{closingData.closing_name}}</p>
                            </div>
                             <text-editor @addPoint="addListPoint()" v-if="editorList.closingNameEditor"
                                         :id-name="'closing-name-text'"
                                         @addClass="updateEditorClass()"></text-editor>
                        </div>

                    </div>
                </div>
            </section>
            <div class="text-editor-bg" v-if="showEditorBg" @click="closeEditor"></div>
            <div class="text-editor-close-bg" v-if="showEditorBg" @click="closeEditor"></div>
        </div>
    </div>
</template>
<script>
    import {mapState} from "vuex";
    import themeUpdater from "../../../../mixins/theme-update-helper";
    import textEditor from "../../../atomic/text-editor";

    export default {
        name: 'clMadisonParryTheme',
        mixins: [themeUpdater],
        components: {textEditor},
        computed: {
            ...mapState({
                resumeOptions: (store) => store.resume.resumeOptions,
                listedMenu: (store) => store.resume.listedMenu,
            }),
            headerStyle() {
                return {
                    color: this.resumeOptions.textColorModel,
                    fontFamily: this.resumeOptions.fontFamily
                }
            },
            primaryFontStyle() {
                return {
                    color: this.resumeOptions.textColorModel,
                    fontFamily: this.resumeOptions.fontFamily
                }
            },
        }
    }
</script>
<style lang="scss" scoped>
    .theme-main-cage {
        width: 567px;
        margin-bottom: 0;
        min-height: 812px;
    }

    .theme-outer-cage {
        padding: 14px;
        background: #4C4C4B;
        margin-bottom: 20px;
    }

    .cl-mp-theme {
        position: relative;

        &.cl-header {
            font-size: 11px;
            color: #4C4C4B;
            font-weight: 700;
            text-align: start;
            margin-top: 20px;
        }

        &.secondary-content-text {
            font-size: 9px;
            color: #4C4C4B;
            font-weight: 700;
            text-align: start;
        }

        &.content-text {
            font-size: 9px;
            font-weight: 400;
            color: #7D7D7D;
            text-align: start;
        }

        &.name-text {
            font-size: 32px;
            color: #4C4C4B;
            font-weight: 700;
            text-align: center;
            line-height: 30px;
            padding-top: 15px;
        }

        &.list-cage {
            padding-left: 25px;
            padding-right: 20px;
            padding-bottom: 20px;
        }

        &.full-cage {
            margin-bottom: 15px;
            transition: width 2s, height 2s, transform 2s;
        }
    }

</style>