export default {
    namespaced: true,
    state: {
        summaryMenuData: {
            item_header: 'Summary',
            item_id: '',
            html: {},
        },
        summaryData: {
            address: '123 Street, City',
            email: 'example@example.com',
            full_name: '',
            id: '',
            image: 'https://www.americanaircraftsales.com/wp-content/uploads/2016/09/no-profile-img.jpg',
            phone: '(123) 123 1234',
            addressSecondary: '',
            summary: '',
        },
    },
    actions: {
        updateSummaryData({commit}, data) {
            commit('UPDATE_SUMMARY_DATA', data);
        },
        updateProfilePhoto({commit}, data) {
            commit('UPDATE_PROFILE_PHOTO', data);
        },
        updateSummaryHtml({commit}, data) {
            commit('UPDATE_SUMMARY_HTML', data);
        },
        updateSummaryTitle({commit}, data) {
            commit('UPDATE_SUMMARY_TITLE', data);
        },
        updateSummaryId({commit}, data) {
            commit('UPDATE_SUMMARY_ID', data);
        }
    },
    mutations: {
        UPDATE_SUMMARY_DATA(state, payload) {
            state.summaryData = payload;
        },
        UPDATE_PROFILE_PHOTO(state, payload) {
            state.summaryData.image = payload
        },
        UPDATE_SUMMARY_HTML(state, payload) {
            state.summaryMenuData.html = payload
        },
        UPDATE_SUMMARY_TITLE(state, payload) {
            state.summaryMenuData.item_header = payload;
        },
        UPDATE_SUMMARY_ID(state, payload) {
            state.summaryMenuData.item_id = payload;
        }
    }
}
