<template>
    <div id="app">
        <v-app id="inspire">
            <app-header v-if="$route.meta.platform === 'app'"></app-header>
            <web-header v-if="$route.meta.platform === 'web'"></web-header>
            <router-view/>
        </v-app>
    </div>
</template>

<script>
    import {mapActions} from "vuex";
    import authService from './services/auth';
    import appHeader from './components/header.vue';
    import webHeader from './components/web-header.vue';

    export default {
        name: 'App',
        components: {appHeader, webHeader},
        data() {
            return {
                pageLoading: false,
                scrollPosition: 0
            }
        },
        created() {
            this.controlUserCredential();
        },
        methods: {
            ...mapActions({
                updateLoginModalStatus: 'general/updateLoginModalStatus',
                updateCredentialInfo: 'authorization/updateCredentialInfo',
                updateUserInfo: 'authorization/updateUserInfo',
                updateDummyUserStatus: 'authorization/updateDummyUserStatus',
                updateUserDetail: 'authorization/updateUserDetail',
            }),
            async controlUserCredential() {
                const haveToken = localStorage.getItem('access-token') ? true : false;
                this.updateLoginModalStatus(!haveToken);
                if (haveToken) {
                    authService.validateToken().then((tokenResponse) => {
                        this.updateUserInfo(tokenResponse.data.data);
                        this.updateDummyUserStatus(tokenResponse.data.data.dummy);
                    });
                    authService.getUserInfo().then((userDetailInfo) => {
                        this.updateUserDetail(userDetailInfo.data.data)
                    })
                } else {
                    authService.mergeStart().then((loginResponse) => {
                        this.updateDummyUserStatus(loginResponse.data.user.dummy);
                        const credentialObject = {
                            access_token: loginResponse.data.auth['access-token'],
                            client: loginResponse.data.auth['client'],
                            uid: loginResponse.data.auth['uid'],
                            isDummy: loginResponse.data.user.dummy
                        };
                        this.updateCredentialInfo(credentialObject);
                        authService.validateToken().then((tokenResponse) => {
                            this.updateUserInfo(tokenResponse.data.data);
                            this.updateDummyUserStatus(tokenResponse.data.data.dummy);
                        });
                        authService.getUserInfo().then((userDetailInfo) => {
                            this.updateUserDetail(userDetailInfo.data.data)
                        });
                        window.location.reload();
                    })
                }
            },

        },
        watch: {
            '$route': {
                handler: (to) => {
                    document.title = to.meta.title || 'Your Website'
                },
                immediate: true
            }
        }
    }
</script>

<style>
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        margin-top: 60px;
    }
    .modal.d-block {
    overflow: auto !important;
} 
</style>
