export default {
    namespaced: true,
    state: {
        certificateData: {
            item_header: 'Certificates',
            item_id: '',
            html: {},
            company: '',
            image: '',
            id: '',
            link: '',
            name: '',
            position: 0,
            valid_year: '',
            valid_month: '',
            myCertificates: [
                {
                    name: 'Microsoft BI Certificate', company: 'Bilge Adam', id: 1,
                    valid_year: 1995, valid_month: 2, link: 'www.google.com.tr'
                },
                {
                    name: 'SEO Certificate', company: 'Facebook', id: 2,
                    valid_year: 2000, valid_month: 6, link: 'www.google.com.tr'
                }
            ]
        },
    },
    actions: {
        updateCertificateData({commit}, data) {
            commit('UPDATE_EDUCATION_DATA', data);
        },
        addNewCertificate({commit}, data) {
            commit('ADD_NEW_CERTIFICATE', data);
        },
        deleteCertificate({commit}, data) {
            commit('DELETE_CERTIFICATE', data);
        },
        editCertificate({commit}, data) {
            commit('EDIT_CERTIFICATE', data);
        },
        updateCertificateHtml({commit}, data) {
            commit('UPDATE_CERTIFICATE_HTML', data);
        },
        updateCertificateTitle({commit}, data) {
            commit('UPDATE_CERTIFICATE_TITLE', data);
        },
        updateCertificateId({commit}, data) {
            commit('UPDATE_CERTIFICATE_ID', data)
        }
    },
    mutations: {
        UPDATE_EDUCATION_DATA(state, payload) {
            state.certificateData.myCertificates = payload;
        },
        ADD_NEW_CERTIFICATE(state, payload) {
            state.certificateData.myCertificates.push(payload);
        },
        DELETE_CERTIFICATE(state, payload) {
            state.certificateData.myCertificates.splice(payload, 1);
        },
        EDIT_CERTIFICATE(state, payload) {
            const updatedCertificate = state.certificateData.myCertificates.find(x => x.id === payload.data.id);
            Object.assign(updatedCertificate,payload.data);
        },
        UPDATE_CERTIFICATE_HTML(state, payload) {
            state.certificateData.html = payload;
        },
        UPDATE_CERTIFICATE_TITLE(state, payload) {
            state.certificateData.item_header = payload;
        },
        UPDATE_CERTIFICATE_ID(state, payload) {
            state.certificateData.item_id = payload;
        }
    }
}
