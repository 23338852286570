<template>
    <section>
        <p class="web-site secondary-bold-header">Profile Settings</p>
        <div class="container">
            <profile></profile>
        </div>
    </section>
</template>
<script>
    import profile from "../../components/website/profile-settings/profile";

    export default {
        name: 'profileSettings',
        components: {profile}
    }
</script>
<style></style>
