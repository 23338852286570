<template>
    <section>
        <div class="container d-flex flex-row justify-space-between align-items-center mt-50">
            <p class="resume-actions header">My Resumes</p>
            <div class="resume-actions cage">
                <v-btn class="resume-actions yellow-button" @click="createResume">+ New Resume</v-btn>
                <v-btn class="resume-actions yellow-button" @click="createCoverLetter">+ New Cover Letter</v-btn>
            </div>
        </div>
        <menu-tabs></menu-tabs>
    </section>
</template>
<script>
    import menuTabs from "@/components/header/web-header/tabs";
    import {mapActions} from "vuex";
    import resumeService from "../../../services/resume";
    // import resumeService from "@/services/resume";

    export default {
        name: 'resumeListActions',
        components: {menuTabs},
        methods: {
            ...mapActions({
                updateResumeList: 'resume/updateResumeList',
            }),
            createResume() {
                this.$router.push({name: 'createResume'})
            },
            createCoverLetter() {
                const createObject = {
                    job_id: 22,
                    theme_id: 23,
                    experience: 0,
                    linkedin_url: '',
                    template_type: 'cover_letter'
                };
                
                resumeService.createResume(createObject).then((createResponse) => {
                    
                    const createdResumeId = createResponse.data[0].current_user_template.id;
                    this.$router.push('/app/dashboard/' + createdResumeId);
                    // this.showLoader = false;
                });
            }
        }
    }
</script>
<style lang="scss" scoped>
    .mt-50 {
        margin-top: 50px;
    }

    .resume-actions {
        &.header {
            font-family: 'Segoe UI Bold';
            color: #000000;
            font-size: 36px;
        }

        &.yellow-button:first-child {
            margin-right: 20px;
        }
    }
    .container.d-flex.flex-row.justify-space-between.align-items-center.mt-50 {
    @media (max-width: 450px) {
        display:unset !important;
    }
}
</style>
