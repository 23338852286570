<template>
    <section v-if="!isMobile()" class="web-header z-index-2">
        <div class="container" v-if="pageLoaded">
            <div class="d-flex flex-row justify-space-between">
                <a href="/">
                    <img class="web-header main-logo" src="../assets/img/anycv-logo.svg" alt="anycv">
                </a>
                <div class="web-header navigator-cage"
                     v-if="$route.name !== 'createResume' && $route.name !== 'resumeDashboard'">
                    <p class="web-header navigator-link" @click="navigateUrl('/')">Home</p>
                    <p class="web-header navigator-link">About</p>
                    <p class="web-header navigator-link" @click="navigateUrl('/website/pricing')">Pricing</p>
                    <p class="web-header navigator-link">Blog</p>
                    <p class="web-header navigator-link" @click="navigateUrl('/website/resume')">Resume Examples</p>
                </div>
                <div class="d-flex flex-row align-items-center" v-if="userInfo.uid && !dummyUser">
                    <div class="header-actions cage">
                        <v-menu bottom left nudge-right="50" nudge-bottom="15" offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <img class="header-actions icon" v-bind="attrs" v-on="on" @click="getNotifications"
                                     src="../assets/custom-icons/thunder-icon.svg"
                                     alt="thunder">
                            </template>
                            <div class="action-cage">
                                <v-list v-if="notificationList.length > 0">
                                    <v-list-item v-for="(notification,i) in notificationList" class="action-item"
                                                 :key="i">
                                        <div class="d-flex align-items-center"
                                             @click="getNotificationDetail(notification.id)">
                                            <img v-if="notification.status === 'fresh'" class="mr-2"
                                                 src="../assets/custom-icons/comment-icon.svg" alt="purpleCommentIcon">
                                            <img v-else class="mr-2" src="../assets/custom-icons/comment-black-icon.svg"
                                                 alt="purpleCommentIcon">
                                            <div>
                                                <p class="action-item action-link border-0">{{notification.title}}</p>
                                                <p class="notification-description">{{notification.description}}</p>
                                            </div>
                                        </div>
                                    </v-list-item>
                                </v-list>
                            </div>
                        </v-menu>
                        <img class="header-actions icon" @click="controlFeedbackStatus()"
                             src="../assets/custom-icons/comment-icon.svg"
                             alt="comment">
                    </div>
                    <p class="header-actions name-text">{{userInfo.name + ' ' + userInfo.surname }}</p>
                    <img class="header-actions profile-img" :src="userInfo.image" alt="">
                    <v-menu bottom left offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                                <div>
                                    <span class="material-icons header-actions arrow-menu">keyboard_arrow_down</span>
                                </div>
                            </v-btn>
                        </template>
                        <div class="action-cage">
                            <v-list>
                                <v-list-item class="action-item" @click="navigateUrl('/website/create-resume')">
                                    <p class="action-item action-link">Create Resume</p>
                                </v-list-item>
                                <v-list-item v-if="userInfo.code" class="action-item"
                                             @click="navigateUrl('/user/'+userInfo.code)">
                                    <p class="action-item action-link">Public Page</p>
                                </v-list-item>
                                <v-list-item class="action-item" @click="navigateUrl('/website/profile-settings')">
                                    <p class="action-item action-link">My Profile</p>
                                </v-list-item>
                                <v-list-item class="action-item" @click="navigateUrl('/website/resume')">
                                    <p class="action-item action-link">My Resumes</p>
                                </v-list-item>
                                <v-list-item class="action-item" @click="signOut()">
                                    <p class="action-item action-link">Sign Out</p>
                                </v-list-item>
                            </v-list>
                        </div>
                    </v-menu>
                </div>
                <div class="d-flex align-center" v-else>
                    <p class="header-login-text" @click="$router.push({name:'authPage'})">Login</p>
                    <v-btn class="yellow-button start-button" @click="$router.push({name:'createResume'})">Start</v-btn>
                </div>
            </div>
        </div>
        <resume-list-actions v-if="$route.name === 'resumeDashboard'"></resume-list-actions>
        <feedback-modal></feedback-modal>
    </section>
    <div  v-else >
    <section class="mobile-header z-index-2 d-flex justify-content-between align-items-center py-3 px-5 text-white">
  
        <a href="/">
                    <img class="web-header main-logo" src="../assets/img/anycv-logo.svg" alt="anycv">
        </a>
  
  <nav>
    <a id="link" href="#" @click="openmenu()"><span id="burger" :class="open"></span></a>
   
    <ul :class="'list-unstyled ' + open">
      <li><p class="web-header navigator-link" @click="navigateUrl('/')">Home</p></li>
      <li><p class="web-header navigator-link" @click="navigateUrl('/website/pricing')">Pricing</p></li>
      <li><p class="web-header navigator-link" @click="navigateUrl('/website/resume')">Resume Examples</p></li>
      <li><div class="d-flex flex-row align-items-center" v-if="userInfo.uid && !dummyUser">
                    <div class="header-actions cage">
                        <v-menu bottom left nudge-right="50" nudge-bottom="15" offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <img class="header-actions icon" v-bind="attrs" v-on="on" @click="getNotifications"
                                     src="../assets/custom-icons/thunder-icon.svg"
                                     alt="thunder">
                            </template>
                            <div class="action-cage">
                                <v-list v-if="notificationList.length > 0">
                                    <v-list-item v-for="(notification,i) in notificationList" class="action-item"
                                                 :key="i">
                                        <div class="d-flex align-items-center"
                                             @click="getNotificationDetail(notification.id)">
                                            <img v-if="notification.status === 'fresh'" class="mr-2"
                                                 src="../assets/custom-icons/comment-icon.svg" alt="purpleCommentIcon">
                                            <img v-else class="mr-2" src="../assets/custom-icons/comment-black-icon.svg"
                                                 alt="purpleCommentIcon">
                                            <div>
                                                <p class="action-item action-link border-0">{{notification.title}}</p>
                                                <p class="notification-description">{{notification.description}}</p>
                                            </div>
                                        </div>
                                    </v-list-item>
                                </v-list>
                            </div>
                        </v-menu>
                        <img class="header-actions icon" @click="controlFeedbackStatus()"
                             src="../assets/custom-icons/comment-icon.svg"
                             alt="comment">
                    </div>
                    <p class="header-actions name-text">{{userInfo.name + ' ' + userInfo.surname }}</p>
                    <img class="header-actions profile-img" :src="userInfo.image" alt="">
                    <v-menu bottom left offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                                <div>
                                    <span class="material-icons header-actions arrow-menu">keyboard_arrow_down</span>
                                </div>
                            </v-btn>
                        </template>
                        <div class="action-cage">
                            <v-list>
                                <v-list-item class="action-item" @click="navigateUrl('/website/create-resume')">
                                    <p class="action-item action-link">Create Resume</p>
                                </v-list-item>
                                <v-list-item v-if="userInfo.code" class="action-item"
                                             @click="navigateUrl('/user/'+userInfo.code)">
                                    <p class="action-item action-link">Public Page</p>
                                </v-list-item>
                                <v-list-item class="action-item" @click="navigateUrl('/website/profile-settings')">
                                    <p class="action-item action-link">My Profile</p>
                                </v-list-item>
                                <v-list-item class="action-item" @click="navigateUrl('/website/resume')">
                                    <p class="action-item action-link">My Resumes</p>
                                </v-list-item>
                                <v-list-item class="action-item" @click="signOut()">
                                    <p class="action-item action-link">Sign Out</p>
                                </v-list-item>
                            </v-list>
                        </div>
                    </v-menu>
                </div>
                <div class=" align-center" style="
    display: inline-flex;
" v-else>
                    <p class="header-login-text" @click="$router.push({name:'authPage'})">Login</p>
                    <v-btn class="yellow-button start-button" @click="$router.push({name:'createResume'})">Start</v-btn>
                </div></li>
    </ul>
  </nav>
  
       
</section>
        <resume-list-actions v-if="$route.name === 'resumeDashboard'"></resume-list-actions>
</div>
</template>
<script>
    import {mapActions, mapState} from "vuex";
    import resumeListActions from '../components/header/web-header/resume-list-actions.vue';
    import utilsService from "../services/utils";
    import feedbackModal from '../components/modal-views/feedback-modal.vue';

    export default {
        name: 'webHeader',
        components: {resumeListActions, feedbackModal},
        data() {
            return {
                notificationList: [],
                pageLoaded: false,
                open:''
            }
        },
        computed: {
            ...mapState({
                userInfo: (store) => store.authorization.userInfo,
                dummyUser: (store) => store.authorization.dummyUser,
            }),
        },
        created() {
            this.getNotifications();
            setTimeout(() => {
                this.pageLoaded = true;
            }, 500)
        },
        methods: {
            ...mapActions({
                updateFeedbackModalStatus: 'general/updateFeedbackModalStatus'
            }),
 isMobile() {
    return this.$vuetify.breakpoint.sm||this.$vuetify.breakpoint.xs
 },
       openmenu(){
if(this.open=="open"){
    this.open='';
}else{
    this.open="open";
}
    },     signOut() {
                localStorage.clear();
                window.location.reload();
            },
            navigateUrl(url) {
                window.location.href = url;
            },
            getNotifications() {
                utilsService.getNotifications().then((notifications) => {
                    this.notificationList = notifications.data;
                })
            },
            getNotificationDetail(notificationId) {
                utilsService.getNotificationDetail(notificationId).then();
            },
            controlFeedbackStatus() {
                this.updateFeedbackModalStatus(true);
                //   this.$router.push('/website/feedback');
            }
        }
    }
</script>
<style lang="scss" scoped>
    .mt-50 {
        margin-top: 50px;
    }

    .z-index-2 {
        z-index: 99;
    }

    .web-header {
        &.main-logo{
            width: 144px;
            height: 50px;
        }
        &.navigator-cage {
            max-width: 383px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &.navigator-link {
            font-size: 14px;
            font-weight: 400;
            font-family: Roboto;
            color: rgba(40, 43, 52, 0.6);
            cursor: pointer;

            &:hover {
                color: rgba(40, 43, 52, 0.9);
            }
        }
    }

    .header-actions {
        &.cage {
            margin-right: 25px;
            display: flex;
            align-items: center;
        }

        &.icon:first-child {
            margin-right: 10px;
        }

        &.name-text {
            font-family: Roboto, sans-serif !important;
            font-size: 14px;
            font-weight: 500;
            color: #282B34;
            margin-bottom: 0 !important;
        }

        &.profile-img {
            width: 28px;
            height: 28px;
            border-radius: 50%;
            margin-left: 10px;
            margin-right: 15px;
        }

        &.arrow-menu {
            color: #000000;
            cursor: pointer;
        }
    }

    .resume-actions {
        &.header {
            font-family: 'Segoe UI Bold';
            color: #000000;
            font-size: 36px;
        }

        &.yellow-button:first-child {
            margin-right: 20px;
        }
    }

    .header-login-text {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        color: #9ea2b1;
        margin-right: 20px;
        cursor: pointer;
    }

    .start-button {
        padding: 19px 33px !important;
    }

    .action-item {
        border-bottom: 1px solid #F2F2F3;
        cursor: pointer;

        &:hover {
            background: rgb(238 240 245);
        }

        &:last-child {
            border-bottom: none !important;
        }

        &.action-link {
            font-family: Roboto, sans-serif !important;
            font-size: 14px !important;
            font-weight: 500 !important;
            color: #282B34 !important;
            text-decoration: none !important;
            margin-bottom: 0 !important;
        }
    }

    .notification-description {
        font-size: 12px;
        color: #c4c4c4;
        font-family: "Segoe UI", sans-serif;
    }
    // mobile
    .mobile-header a {color:inherit}

.mobile-header #burger {
  width:30px;
  height:2px;
  background:#742774;
  display:inline-block;
  position:relative;
}
.mobile-header #burger::before,
.mobile-header #burger::after {
  content:'';
  width:30px;
  height:2px;
  background:#742774;
  display:inline-block;
  position:absolute;
  transform-origin: center;
  transition: all 0.3s ease;
  left: 0px;
}
.mobile-header #burger::before {
  top:-7px;
}
.mobile-header #burger::after {
  top:7px;
}

.mobile-header #burger.open {
  background:transparent;
}
.mobile-header #burger.open::before {
  transform: rotate(45deg);
  top:0;
}
.mobile-header #burger.open::after {
  transform: rotate(-45deg);
  top:0;
}

.mobile-header ul {
  background:#742774;
  position:absolute;
  width:100%;
  left:0;
  top:60px;
  padding:10px 0;
  transform:scaleY(0);
  transform-origin:center top;
  transition:all 0.3s ease;
  z-index: 999;
}
.mobile-header ul.open {
  transform:scaleY(1);
}
.mobile-header ul li {
  padding:10px 0;
  text-align:center
}
.mobile-header .navigator-link,
.mobile-header .header-actions.name-text,
.mobile-header .header-actions.arrow-menu,
.mobile-header .header-login-text{
    color: white !important;
}

</style>
