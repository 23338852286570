import gateWay from './index.js';

export default {
    setPublicPage(object) {
        return gateWay.post('v1/web/public_pages', object);
    },
    updatePageCode(codeObject) {
        return gateWay.put('v1/web/users/update_code', codeObject);
    }
}
