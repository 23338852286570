<template>
    <section>
        <div >
            <v-menu content-class="menuHeader" :close-on-content-click="onClose"  bottom offset-y>
                <template v-slot:activator="{ on }" >
                    <div class="menu-item cage" v-on="on">
                        <img class="menu-item icon" :src="iconUrl">
                        <p class="menu-item text">{{menuHead}}</p>
                        <img class="menu-item mini-icon" src="../../assets/custom-icons/arrow-down.svg">
                    </div>
                </template>
                <div class="menu-item slot-cage">
                    <slot></slot>
                </div>
            </v-menu>
        </div>

    </section>
</template>
<script>
    export default {
        name: 'openerMenu',
        props: ['menuHead', 'menuIcon','onClose'],
        data() {
            return {
                iconUrl: require('../../assets/custom-icons/' + this.menuIcon + '.svg')
            }
        }
    }
</script>
<style lang="scss" scoped>
    .menu-item {
        &.cage {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-right: 30px;
            cursor: pointer;
        }

        &.slot-cage {
            background-color: #ffffff;
            height: 100%;
        }

        &.icon {
            margin-right: 6.25px;
        }

        &.mini-icon {
            margin-left: 12.75px;
        }

        &.text {
            color: #282B34;
            font-weight: 500
        }
    }
</style>
