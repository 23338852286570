<template>
    <section>
        <div class="header-status main-cage">
            <img @click="$router.push({name:'resumeDashboard'})" class="header-status back-icon"
                 src="../../../assets/custom-icons/arrow-left.svg">
            <p class="header-status resume-name">{{userInfo.name}} {{userInfo.surname}}</p>
            <img class="header-status status-icon" src="../../../assets/custom-icons/edit-pen-bg.svg">
            <p class="header-status status-text" v-if="!showApiLoader">Saved</p>
            <div class="d-flex" v-else>
                <p class="header-status status-text">Saving</p>
                <div class="spinner-border" role="status" >
                    <span class="sr-only">Loading...</span>
                </div>
            </div>

        </div>
    </section>
</template>
<script>
    import {mapState} from "vuex";

    export default {
        name: 'itemHeaderInfo',
        computed: {
            ...mapState({
                userInfo: (store) => store.authorization.userInfo,
                showApiLoader:(store) => store.general.showApiLoader
            })
        },
    }
</script>
<style lang="scss" scoped>
    .header-status {
        &.main-cage {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: row;
        }

        &.back-icon {
            cursor: pointer;
            margin-right: 12px;
        }

        &.resume-name {
            font-size: 14px;
            color: #353535;
            margin-right: 8px;
        }

        &.status-icon {
            margin-right: 15px;
        }

        &.status-text {
            font-size: 12px;
            color: #9EA2B1;
            font-weight: 500;
        }
    }
    .spinner-border {
        width: 15px;
        height: 15px;
        border: 2px solid #9EA2B1;
        border-right-color: transparent;
        margin-left: 10px;
    }
</style>
