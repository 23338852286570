import gateWay from './index.js';

export default {
    getFeedBackData(resumeId) {
        return gateWay.get('v1/web/feedbacks?user_template_id=' + resumeId);
    },
    getRandomResume() {
        return gateWay.get('v1/web/feedbacks/random');
    },
    postComment(commentObject) {
        return gateWay.post('v1/web/feedbacks', commentObject);
    }
}
