<template>
    <section>
        <div class="menu-tabs cage">
            <div class="container d-flex flex-row py-0">
                <p class="menu-tabs tab" :class="{'active': currentTab === 'all'}" @click="setCurrentTab('all')">All Documents</p>
                <p class="menu-tabs tab" :class="{'active': currentTab === 'cv'}" @click="setCurrentTab('cv')">Resumes</p>
                <p class="menu-tabs tab" :class="{'active': currentTab === 'cl'}" @click="setCurrentTab('cl')">Cover Letters</p>
                <!-- <p class="menu-tabs tab" @click="$router.push({name:'videoCv'})">Video CV</p> -->
            </div>
        </div>
    </section>
</template>
<script>
    import {mapActions} from "vuex";

    export default {
        name:'webHeaderTabs',
        data(){
            return {
                currentTab :'all'
            }
        },
        methods:{
            ...mapActions({
                updateResumeListTab: 'general/updateResumeListTab'
            }),
            setCurrentTab(tabName){
                this.currentTab = tabName;
                this.updateResumeListTab(this.currentTab);
            }
        }
    }
</script>
<style lang="scss" scoped>
    .menu-tabs{
        &.cage{
            display: flex;
            flex-direction: row;
            margin-top: 42px;
            box-shadow: 0px 4px 6px 0px rgba(241,247,255,1);
        }
        &.tab{
            font-family: Roboto, sans-serif !important;
            font-weight: 500;
            font-size: 14px;
            color: #282B34;
            opacity: 60%;
            margin-right: 50px;
            cursor:pointer;
        }
        &.tab:last-child{
            margin-right: 0 !important;
        }
        &.active{
            color: #742774;
            opacity: 1 !important;
            border-bottom: 2px solid #742774;
            padding-bottom: 20px;
        }
    }
</style>
