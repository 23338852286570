export default {
    namespaced: true,
    state: {
        bodyData: {
            item_header: 'Cover Letter Body',
            item_id:'',
            html:{},
            cover_body: '',
            id: '',
        },
    },
    actions: {
        updateBodyData({commit}, data) {
            commit('UPDATE_BODY_DATA', data);
        },
        editBody({commit}, data) {
            commit('EDIT_BODY', data);
        },
        updateBodyHtml({commit}, data) {
            commit('UPDATE_BODY_HTML', data);
        },
        updateBodyTitle({commit}, data) {
            commit('UPDATE_BODY_TITLE', data);
        },
        updateBodyId({commit}, data) {
            commit('UPDATE_BODY_ID', data)
        }
    },
    mutations: {
        UPDATE_BODY_DATA(state, payload) {
            state.bodyData.cover_body = payload.cover_body;
            state.bodyData.id = payload.id;
        },
        EDIT_BODY(state,payload){
            Object.assign(state.bodyData,payload.data);
        },
        UPDATE_BODY_HTML(state, payload) {
            state.bodyData.html = payload;
        },
        UPDATE_BODY_TITLE(state, payload) {
            state.bodyData.item_header = payload;
        },
        UPDATE_BODY_ID(state, payload) {
            state.bodyData.item_id = payload;
        }
    }
}
