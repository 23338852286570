import gateWay from './index.js';

export default {
    getResumeList(type) {
        // type geç cv - all - cl
        return gateWay.get('v1/web/user_templates?type=' + type)
    },
    createResume(createObject) {
        return gateWay.post('v1/web/user_templates', createObject)
    },
    deleteResume(resumeId) {
        return gateWay.delete('v1/web/user_templates/' + resumeId)
    },
    getResumeDetail(resumeId) {
        return gateWay.get('v1/web/user_templates/' + resumeId)
    },
    
    getResumeDetaildownload(createObject) {
        return gateWay.post('v1/web/user_template_downloads', createObject)
    },
    
    getResumeDetailFromCode(resumeCode) {
        return gateWay.get('v1/web/public_pages/details?code=' + resumeCode)
    },
    getJobMainCategories() {
        return gateWay.get('v1/web/job_categories'); 
    },
    getJobSubCategories(mainCategoryId) {
        return gateWay.get('v1/web/job_categories/' + mainCategoryId + '/jobs');
    },
    getThemeList(type) {
        return gateWay.get('v1/web/themes?theme_type=' + type);
    },
    getBackgroundImages() {
        return gateWay.get('v1/web/backgrounds');
    },
    duplicateResume(resumeId) {
        return gateWay.post('v1/web/user_templates/' + resumeId + '/duplicate');
    },
    updateHeader(updateBody) {
        return gateWay.put('v1/web/user_templates/update_header', updateBody)
    }
}
