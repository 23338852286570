<template>
    <section class="video-cv-cage">
        <v-carousel  height="650" hide-delimiter-background  show-arrows-on-hover v-if="videoUrlList.length >0">
            <v-carousel-item v-for="(videoObject, i) in videoUrlList" :key="i">
                <v-sheet color="#fff" height="100%">
                    <v-row class="fill-height" align="center" justify="center">
                        <div class="display-3">
                            <p class="video-question-text">{{videoObject.question}}</p>
                            <video v-if="videoObject" width="100%" height="500" disablePictureInPicture controls controlsList="nodownload">
                                <source :src="videoObject.url" type="video/mp4">
                            </video>
                        </div>
                    </v-row>
                </v-sheet>
            </v-carousel-item>
        </v-carousel>
    </section>
</template>
<script>

    export default {
        name: 'videoCvGallery',
        data() {
            return {
                videoUrlList: [],
                code:'',
            }
        },
        created() {
          this.getVideoCvDetail();
            this.code = this.$route.query.vCode;
        },
        props:['videoCvDetail'],
        methods: {
            getVideoCvDetail() {
                    this.videoCvDetail.video_cv_questions.forEach((videoObject) => {
                        if (videoObject.download_link) {
                            const obj = {
                                url : videoObject.download_link,
                                question:videoObject.question
                            };
                            this.videoUrlList.push(obj)
                        }
                    })

            }
        }
    }
</script>
<style lang="scss" scoped>
    .video-question-text{
        font-family: "Segoe UI Bold";
        font-size: 15px;
        color: #000000;
        margin-bottom: 13px !important;
        line-height: normal;
    }
    ::v-deep .v-carousel__controls__item{
        color: #742774 !important;
    }
</style>
