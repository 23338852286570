<template>
    <section>
        <div class="font-selector cage">
                <v-list>
                    <v-list-item v-for="(font, i) in fontArray" :key="i" @click="selectedFont = font">
                        <v-list-item-title>
                            <p class="font-selector item" :style="{fontFamily:font.style}">{{ font.name }}</p>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>

        </div>
    </section>
</template>
<script>
    import resumeService from "../../../../services/resume";

    export default {
        name: 'fontSelector',
        data() {
            return {
                selectedFont: '',
                fontArray: [
                    {id: 0, name: 'Open Sans', style: '"Open Sans", sans-serif', usageType: 'free'},
                    {id: 1, name: 'Calibri', style: '"Calibri", sans-serif', usageType: 'free'},
                    {id: 2, name: 'Georgia', style: '"Georgia", sans-serif', usageType: 'free'},
                    {id: 3, name: 'Playfair Display', style: '"Playfair Display", sans-serif',usageType:'pro'},
                    {id: 4, name: 'Poppins', style: '"Poppins", sans-serif',usageType:'pro'},
                    {id: 5, name: 'Oswald', style: '"Oswald", sans-serif',usageType:'pro'},
                    {id: 6, name: 'Montserrat', style: '"Montserrat", sans-serif',usageType:'pro'},
                ]
            }
        },
        watch: {
            selectedFont: {
                deep: true,
                handler() {
                    const fontObject = {
                        font_id: this.selectedFont.id
                    };
                    resumeService.updateHeader(fontObject).then(() => {
                        this.$emit('changeSelectedFont', this.selectedFont.style)
                    });
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    .font-selector {
        &.cage {
            width: 217px;
        }

        &.item {
            display: flex;
            font-size: 16px;
            font-weight: 500;
        }
    }
</style>
