// import itemService from '../../../services/item-data'
export default {
    namespaced: true,
    state: {
        experienceData: {
            item_header: 'Experience',
            item_id: '',
            html: {},
            company: '',
            title: '',
            city: '',
            country: '',
            start_year: '',
            start_month: '',
            start_only_year: false,
            show_start_date: true,
            end_year: '',
            end_month: '',
            end_only_year: false,
            show_end_date: false,
            current: false,
            description: '',
            id: '',
            employment_type: 1,
            myExperience: []
        },
    },
    actions: {
        updateExperienceData({commit}, data) {
            commit('UPDATE_EXPERIENCE_DATA', data);
        },
        addNewExperience({commit}, data) {
            commit('ADD_NEW_EXPERIENCE', data);
        },
        deleteExperiences({commit}, data) {
            commit('DELETE_EXPERIENCES', data);
        },
        editExperience({commit}, data) {
            commit('EDIT_EXPERIENCE', data);
        },
        updateExperienceHtml({commit}, data) {
            commit('UPDATE_EXPERIENCE_HTML', data);
        },
        updateExperienceTitle({commit}, data) {
            commit('UPDATE_EXPERIENCE_TITLE', data);
        },
        updateExperienceId({commit}, data) {
            commit('UPDATE_EXPERIENCE_ID', data)
        }

    },
    mutations: {
        UPDATE_EXPERIENCE_DATA(state, payload) {
            state.experienceData.myExperience = payload;
            state.experienceData.myExperience.sort((a, b) => (a.position) - (b.position))
        },
        ADD_NEW_EXPERIENCE(state, payload) {
            state.experienceData.myExperience.push(payload);
            state.experienceData.myExperience.sort((a, b) => (a.position) - (b.position))
        },
        DELETE_EXPERIENCES(state, payload) {
            state.experienceData.myExperience.splice(payload, 1);
        },
        EDIT_EXPERIENCE(state, payload) {
            const updatedExperience = state.experienceData.myExperience.find(x => x.id === payload.data.id);
            console.log('updatedExperience',updatedExperience)
            Object.assign(updatedExperience, payload.data);
        },
        UPDATE_EXPERIENCE_HTML(state, payload) {
            state.experienceData.html = payload;
        },
        UPDATE_EXPERIENCE_TITLE(state, payload) {
            state.experienceData.item_header = payload;
        },
        UPDATE_EXPERIENCE_ID(state, payload) {
            state.experienceData.item_id = payload;
        }
    }
}
