<template>
    <section>
        <div class="item-publications cage">
            <div v-if="showPublicationList">
                <div class="item-input-sub-cage">
                    <div class="item-sub-list-cage" v-for="(publication,i) in publicationData.myPublications" :key="i">
                        <div>
                            <p class="item-sub-list-text">{{publication.name}}</p>
                            <p class="item-sub-list-text-secondary">{{publication.publisher}}</p>
                        </div>
                        <div class="d-flex">
                            <span class="material-icons item-sub-list-create-icon"
                                  @click="editPublications(publication)">create</span>
                            <span class="material-icons item-sub-list-delete-icon"
                                  @click="deletePublications(publication)">delete</span>
                        </div>
                    </div>
                </div>
                <div class="item-footer-button-cage">
                    <v-btn class="yellow-button w-100" @click="addPublication">Add New Project</v-btn>
                </div>
            </div>
            <div v-if="!showPublicationList">
                <div class="item-input-sub-cage">
                    <div class="item-input-cage">
                        <p class="item-input-header">Book Name</p>
                        <input class="primary-input w-100" v-model="publicationData.name" @input="changeData"
                               @focusout="isEditing? updatePublication(false) : ''">
                    </div>
                    <div class="item-input-cage">
                        <p class="item-input-header">Publisher</p>
                        <input class="primary-input w-100" v-model="publicationData.publisher" @input="changeData"
                               @focusout="isEditing? updatePublication(false) : ''">
                    </div>
                    <div class="item-input-cage">
                        <p class="item-input-header">Description</p>
                        <textarea class="primary-text-area w-100" rows="4"
                                  v-model="publicationData.description" @input="changeData"
                                  @focusout="isEditing? updatePublication(false) : ''"></textarea>
                    </div>
                    <div class="item-input-cage">
                        <p class="item-input-header">Contributors</p>
                        <textarea class="primary-text-area w-100" rows="2"
                                  v-model="publicationData.contributors" @input="changeData"
                                  @focusout="isEditing? updatePublication(false) : ''"></textarea>
                    </div>
                    <div class="item-input-cage">
                        <p class="item-input-header">Publication URL</p>
                        <input class="primary-input w-100" v-model="publicationData.url" @input="changeData"
                               @focusout="isEditing? updatePublication(false) : ''">
                    </div>
                </div>
                <div class="item-footer-button-cage">
                    <v-btn class="yellow-button w-100"
                           @click="isEditing ? updatePublication(true) : saveNewPublication()">
                        Save
                    </v-btn>
                </div>
            </div>
        </div>
        <v-snackbar v-model="showAddPublicationNotification" :timeout="2000" :color="'success'" top>
            Publication has been added.
        </v-snackbar>
        <v-snackbar v-model="showUpdatePublicationNotification" :timeout="2000" :color="'success'" top>
            Publication has been updated.
        </v-snackbar>
        <v-snackbar v-model="showDeletePublicationNotification" :timeout="2000" :color="'success'" top>
            Publication has been deleted.
        </v-snackbar>
    </section>
</template>
<script>
    import {mapActions, mapState} from "vuex";
    import itemService from '../../../services/item-data';

    export default {
        name: 'itemPublications',
        data() {
            return {
                showPublicationList: true,
                showAddPublicationNotification: false,
                showUpdatePublicationNotification: false,
                showDeletePublicationNotification: false,
                isEditing: false
            }
        },
        computed: {
            ...mapState({
                publicationData: (store) => store.publications.publicationData
            })
        },
        created(){
          this.showPublicationList = this.publicationData.myPublications.length > 0
        },
        methods: {
            ...mapActions({
                updatePublicationData: 'publications/updatePublicationData',
                addNewPublications: 'publications/addNewPublications',
                deletePublication: 'publications/deletePublication',
                editPublication: 'publications/editPublication',
            }),
            changeData() {
                if (this.isEditing) {
                    const editedObject = {
                        data: this.setEditedObject()
                    };
                    this.editPublication(editedObject);
                }
            },
            addPublication() {
                this.isEditing = false;
                this.publicationData.publisher = '';
                this.publicationData.contributors = '';
                this.publicationData.name = '';
                this.publicationData.description = '';
                this.publicationData.url = '';
                this.showPublicationList = false;
            },
            editPublications(publication) {
                this.isEditing = true;
                this.publicationData.publisher = publication.publisher;
                this.publicationData.contributors = publication.contributors;
                this.publicationData.name = publication.name;
                this.publicationData.description = publication.description;
                this.publicationData.url = publication.url;
                this.publicationData.id = publication.id;
                this.showPublicationList = false;
            },
            deletePublications(publication) {
                itemService.deletePublication(publication.id).then((deleteResponse) => {
                    console.log('delete resp', deleteResponse);
                    const publicationIndex = this.publicationData.myPublications.indexOf(publication);
                    this.deletePublication(publicationIndex);
                    this.showDeletePublicationNotification = true;
                    this.showPublicationList = this.awardsData.myAwards.length > 0;
                });
            },
            saveNewPublication() {
                const newPublication = {
                    publisher: this.publicationData.publisher,
                    contributors: this.publicationData.contributors,
                    name: this.publicationData.name,
                    description: this.publicationData.description,
                    url: this.publicationData.url,
                };
                itemService.createPublication(newPublication).then((createResponse) => {
                    this.addNewPublications(createResponse.data);
                    this.showAddPublicationNotification = true;
                    this.showPublicationList = true;
                })
            },
            setEditedObject(){
                const editedObject = {
                    publisher: this.publicationData.publisher,
                    contributors: this.publicationData.contributors,
                    name: this.publicationData.name,
                    description: this.publicationData.description,
                    url: this.publicationData.url,
                    id: this.publicationData.id
                };
                return editedObject;
            },
            updatePublication(goBack) {
                const editedPublication = {
                    publisher: this.publicationData.publisher,
                    contributors: this.publicationData.contributors,
                    name: this.publicationData.name,
                    description: this.publicationData.description,
                    url: this.publicationData.url,
                    id: this.publicationData.id
                };
                itemService.updatePublication(editedPublication.id, editedPublication).then((updateResponse) => {
                    this.editPublication(updateResponse);
                    this.showUpdatePublicationNotification = true;
                    this.showPublicationList = goBack;
                })
            }
        },
    }
</script>
<style lang="scss" scoped></style>
