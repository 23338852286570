import publicPageService from '@/services/public-page.js'
import {mapActions, mapState} from "vuex";
import authService from "../services/auth";

export default {
    data() {
        return {};
    },
    computed:{
      ...mapState({
          userDetailInfo: (store) => store.authorization.userDetailInfo,
      })
    },
    methods: {
        ...mapActions({
            updateUserDetail: 'authorization/updateUserDetail',
        }),
        setPublicPageActive(resume) {
            let publicPageObject = {};
            if (resume.template_type === 'cover_letter') {
                publicPageObject = {
                    user_cover_letter_id: resume.id,
                    video_cv_id: this.userDetailInfo.video_cv_id,
                }
            } else {
                publicPageObject = {
                    user_resume_id: resume.id,
                    video_cv_id: this.userDetailInfo.video_cv_id,
                }
            }
             publicPageService.setPublicPage(publicPageObject).then(() => {
                 authService.getUserInfo().then((userDetailInfo) => {
                     this.updateUserDetail(userDetailInfo.data.data);
                     this.showPublicPageNotification = true;
                 })
             })
        }
    }

};
