<template>
    <section>
        <div class="item-detail head-cage">
            <div class="d-flex">
                <img src="../../assets/custom-icons/arrow-left.svg" class="c-pointer" @click="backToMenu()">
                <p class="item-detail tab-name">{{currentItemTab}}</p>
            </div>
            <menu-tips :currentItemTab="currentItemTab"></menu-tips>
        </div>
        <div class="item-detail content-cage">
            <item-summary v-if="currentItemTab=== 'Summary'"></item-summary>
            <item-experience v-if="currentItemTab ==='Experience'"></item-experience>
            <item-education v-if="currentItemTab === 'Education'"></item-education>
            <item-technical-skills v-if="currentItemTab === 'Technical Skills'"></item-technical-skills>
            <item-language-skills v-if="currentItemTab === 'Language Skills'"></item-language-skills>
            <item-certificate v-if="currentItemTab === 'Certifications'"></item-certificate>
            <item-awards v-if="currentItemTab === 'Awards'"></item-awards>
            <item-projects v-if="currentItemTab === 'Projects'"></item-projects>
            <item-publications v-if="currentItemTab === 'Publications'"></item-publications>
            <item-my-time v-if="currentItemTab === 'My Time'"></item-my-time>
            <item-hobbies v-if="currentItemTab=== 'Hobbies'"></item-hobbies>
            <item-philosophy v-if="currentItemTab === 'Mottos'"></item-philosophy>
            <item-social v-if="currentItemTab ==='See My Social Media'"></item-social>
            <item-strengths v-if="currentItemTab === 'Strengths'"></item-strengths>
            <item-volunteering v-if="currentItemTab === 'Volunteering'"></item-volunteering>
            <item-conferences v-if="currentItemTab === 'Conferences'"></item-conferences>
            <item-references v-if="currentItemTab === 'References'"></item-references>
            <item-greeting v-if="currentItemTab === 'Salutation and Greeting'"></item-greeting>
            <item-opening v-if="currentItemTab === 'Opening Paragraph'"></item-opening>
            <item-opening v-if="currentItemTab === 'Opening Paragraph'"></item-opening>
            <item-closing v-if="currentItemTab === 'Closing and Signature'"></item-closing>
            <item-body v-if="currentItemTab === 'Cover Letter Body'"></item-body>
        </div>
    </section>
</template>
<script>
    import itemSummary from '../contents/items/summary.vue'
    import itemExperience from '../contents/items/experience.vue'
    import itemEducation from '../contents/items/education.vue'
    import itemTechnicalSkills from '../contents/items/skils.vue'
    import itemLanguageSkills from '../contents/items/language-skills.vue'
    import itemCertificate from '../contents/items/certificate.vue'
    import itemAwards from '../contents/items/awards.vue'
    import itemProjects from '../contents/items/projects.vue'
    import itemPublications from '../contents/items/publications.vue'
    import itemMyTime from '../contents/items/my-time.vue'
    import itemHobbies from '../contents/items/hobbies.vue'
    import itemPhilosophy from '../contents/items/philosophy.vue'
    import itemSocial from '../contents/items/social.vue'
    import itemStrengths from '../contents/items/strengths.vue'
    import itemVolunteering from '../contents/items/volunteering.vue'
    import itemConferences from '../contents/items/conferences.vue'
    import itemReferences from '../contents/items/references.vue'
    import itemGreeting from '../contents/cover-letter/greeting.vue'
    import itemOpening from '../contents/cover-letter/opening.vue'
    import itemBody from '../contents/cover-letter/body.vue'
    import itemClosing from '../contents/cover-letter/closing.vue'
    import menuTips from './tips/menu-tips.vue'

    export default {
        name: 'itemDetailNavigator',
        props: ['currentItemTab'],
        components: {
            itemSummary, itemExperience, itemEducation, itemTechnicalSkills, itemLanguageSkills, itemCertificate,
            itemAwards, itemProjects, itemPublications, itemMyTime, itemHobbies, itemPhilosophy, itemSocial,
            itemStrengths, itemVolunteering, itemConferences, itemReferences, menuTips, itemGreeting, itemOpening,
            itemBody, itemClosing
        },
        methods: {
            backToMenu() {
                this.$emit('changeMenuTab', 'mainMenu');
            }
        }
    }
</script>
<style lang="scss" scoped>
    .item-detail {
        &.head-cage {
            border-bottom: 1px solid #F1F7FF;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 21px 20px;
        }

        &.content-cage {
            position: relative;
            padding: 20px;
            height: 100%;
        }

        &.tab-name {
            font-size: 17px;
            color: #353535;
            margin-left: 12px;
            font-weight: 600;
        }

        &.tips-button {
            border: 1px solid rgba(40, 43, 52, 0.6);
            border-radius: 5px;
            font-weight: 500;
            color: #282B34;
            font-size: 14px;
            padding: 5px 17px;
            cursor: pointer;
        }
    }
</style>
