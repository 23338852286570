export default {
    namespaced: true,
    state: {
        mottoData: {
            item_header: 'Motto',
            item_id:'',
            html:{},
            description: '',
            author: '',
            myMottos: []
        },
    },
    actions: {
        updateMottoData({commit}, data) {
            commit('UPDATE_MOTTO_DATA', data);
        },
        addNewMotto({commit}, data) {
            commit('ADD_NEW_MOTTO', data);
        },
        deleteMotto({commit}, data) {
            commit('DELETE_MOTTO', data);
        },
        editMotto({commit}, data) {
            commit('EDIT_MOTTO', data);
        },
        updateMottoHtml({commit}, data) {
            commit('UPDATE_MOTTO_HTML', data);
        },
        updateMottoTitle({commit}, data) {
            commit('UPDATE_MOTTO_TITLE', data);
        },
        updateMottoId({commit}, data) {
            commit('UPDATE_MOTTO_ID', data)
        }
    },
    mutations: {
        UPDATE_MOTTO_DATA(state, payload) {
            state.mottoData.myMottos = payload;
        },
        ADD_NEW_MOTTO(state, payload) {
            state.mottoData.myMottos.push(payload);
        },
        DELETE_MOTTO(state, payload) {
            state.mottoData.myMottos.splice(payload, 1)
        },
        EDIT_MOTTO(state, payload) {
            const updatedMotto = state.mottoData.myMottos.find(x => x.id === payload.data.id);
            Object.assign(updatedMotto,payload.data);
        },
        UPDATE_MOTTO_HTML(state, payload) {
            state.mottoData.html = payload;
        },
        UPDATE_MOTTO_TITLE(state, payload) {
            state.mottoData.item_header = payload;
        },
        UPDATE_MOTTO_ID(state, payload) {
            state.mottoData.item_id = payload;
        }

    }
}
