export default {
    namespaced: true,
    state: {
        educationData: {
            item_header: 'Education',
            item_id: '',
            html: {},
            id: '',
            name: '',
            department: '',
            description: '',
            education_grade: '',
            grade: '',
            position: 0,
            city: '',
            country: '',
            start_year: '',
            start_month: '',
            end_year: '',
            end_month: '',
            start_only_year: false,
            end_only_year: false,
            current: false,
            show_start_date: false,
            show_end_date: false,
            myEducations: []
        },
    },
    actions: {
        updateEducationData({commit}, data) {
            commit('UPDATE_EDUCATION_DATA', data);
        },
        addNewEducation({commit}, data) {
            commit('ADD_NEW_EDUCATION', data);
        },
        deleteEducation({commit}, data) {
            commit('DELETE_EDUCATION', data);
        },
        editEducation({commit}, data) {
            commit('EDIT_EDUCATION', data);
        },
        updateEducationHtml({commit}, data) {
            commit('UPDATE_EDUCATION_HTML', data);
        },
        updateEducationTitle({commit}, data) {
            commit('UPDATE_EDUCATION_TITLE', data);
        },
        updateEducationId({commit}, data) {
            commit('UPDATE_EDUCATION_ID', data);
        },

    },
    mutations: {
        UPDATE_EDUCATION_DATA(state, payload) {
            state.educationData.myEducations = payload;
            state.educationData.myEducations.sort((a, b) => (a.position) - (b.position))
        },
        ADD_NEW_EDUCATION(state, payload) {
            state.educationData.myEducations.push(payload);
            state.educationData.myEducations.sort((a, b) => (a.position) - (b.position))
        },
        DELETE_EDUCATION(state, payload) {
            state.educationData.myEducations.splice(payload, 1);
        },
        EDIT_EDUCATION(state, payload) {
            const updatedEducation = state.educationData.myEducations.find(x => x.id === payload.data.id);
            console.log('updatedEducation',updatedEducation)

            Object.assign(updatedEducation, payload.data);
        },
        UPDATE_EDUCATION_HTML(state,payload){
            state.educationData.html = payload;
        },
        UPDATE_EDUCATION_TITLE(state,payload){
            state.educationData.item_header = payload;
        },
        UPDATE_EDUCATION_ID(state,payload){
            state.educationData.item_id = payload;
        },
    }
}
