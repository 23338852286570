export default {
    namespaced: true,
    state: {
        showLoginModal: true,
        showApiLoader: false,
        showCheckoutModal: false,
        showFeedBackModal:false,
        resumeListTab: 'all',
    },
    actions: {
        updateLoginModalStatus({commit}, data) {
            commit('UPDATE_LOGIN_MODAL_STATUS', data);
        },
        updateFeedbackModalStatus({commit}, data) {
            commit('UPDATE_FEEDBACK_MODAL_STATUS', data);
        },
        updateApiLoaderStatus({commit}, data) {
            commit('UPDATE_API_LOADER_STATUS', data);
        },
        updateCheckoutModalShow({commit}, data) {
            commit('UPDATE_CHECKOUT_MODAL_SHOW', data);
        },
        updateResumeListTab({commit}, data) {
            commit('UPDATE_RESUME_LIST_TAB', data);
        }
    },
    mutations: {
        UPDATE_LOGIN_MODAL_STATUS(state, payload) {
            state.showLoginModal = payload;
        },
        UPDATE_FEEDBACK_MODAL_STATUS(state, payload) {
            state.showFeedBackModal = payload;
        },
        UPDATE_API_LOADER_STATUS(state, payload) {
            state.showApiLoader = payload;
        },
        UPDATE_CHECKOUT_MODAL_SHOW(state, payload) {
            state.showCheckoutModal = payload;
        },
        UPDATE_RESUME_LIST_TAB(state, payload) {
            state.resumeListTab = payload;
        }
    }
}
