<template>
    <section class="h-100">
        <resume-list></resume-list>
    </section>
</template>
<script>
    import resumeList from '../../components/website/resume-list'
    export default {
        name:'websiteDashboard',
        components:{resumeList}
    }
</script>
<style></style>
