<template>
    <section>
        <div class="item-experience cage">
            <div v-if="showLanguageList">
                <div class="item-input-sub-cage">
                    <div class="item-sub-list-cage" v-for="(language,i) in languageSkillsData.myLanguages" :key="i">
                        <div>
                            <p class="item-sub-list-text">{{language.name}}</p>
                            <v-rating v-model="language.rate" background-color="orange lighten-3" color="orange"
                                      medium readonly></v-rating>
                        </div>
                        <div class="d-flex">
                            <span class="material-icons item-sub-list-create-icon"
                                  @click="editLanguages(language)">create</span>
                            <span class="material-icons item-sub-list-delete-icon"
                                  @click="deleteLanguages(language)">delete</span>
                        </div>
                    </div>
                </div>
                <div class="item-footer-button-cage">
                    <v-btn class="yellow-button w-100" @click="addLanguage">Add New Language</v-btn>
                </div>
            </div>
            <div v-else>
                <div class="item-input-sub-cage">
                    <div class="item-input-cage">
                        <p class="item-input-header">Language</p>
                        <input class="primary-input w-100" v-model="languageSkillsData.name" @input="changeData"
                               @focusout="isEditing? updateLanguage(false) : ''">
                    </div>
                    <div class="item-input-cage">
                        <p class="item-input-header">Additional Information</p>
                        <input class="primary-input w-100" v-model="languageSkillsData.information" @input="changeData"
                               @focusout="isEditing? updateLanguage(false) : ''">
                    </div>
                    <div class="item-input-cage">
                        <p class="item-input-header">Success Points</p>
                        <input class="primary-input w-100" v-model="languageSkillsData.success_point"
                               @input="changeData"
                               @focusout="isEditing? updateLanguage(false) : ''">
                    </div>
                    <div class="item-input-cage">
                        <p class="item-input-header">Language Level</p>
                        <v-rating v-model="languageSkillsData.rate" background-color="orange lighten-3" color="orange"
                                  @input="changeData"
                                  large></v-rating>
                    </div>
                    <div class="item-input-cage">
                        <v-btn class="yellow-button w-100"
                               @click="isEditing ? updateLanguage(true) : saveNewLanguage()">
                            Save
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>
        <v-snackbar v-model="showDeleteLanguage" :timeout="2000" :color="'success'" top>
            Language has been deleted.
        </v-snackbar>
        <v-snackbar v-model="showUpdateLanguage" :timeout="2000" :color="'success'" top>
            Language has been updated.
        </v-snackbar>
        <v-snackbar v-model="showAddLanguage" :timeout="2000" :color="'success'" top>
            Language has been added.
        </v-snackbar>
    </section>
</template>
<script>
    import {mapActions, mapState} from "vuex";
    import itemService from '../../../services/item-data'

    export default {
        name: 'languageSkillsItem',
        data() {
            return {
                isEditing: false,
                showDeleteLanguage: false,
                showAddLanguage: false,
                showUpdateLanguage: false,
                showLanguageList: true
            }
        },
        created() {
            this.showLanguageList = this.languageSkillsData.myLanguages.length > 0;
        },
        computed: {
            ...mapState({
                languageSkillsData: (store) => store.languageSkills.languageSkillsData
            })
        },
        methods: {
            ...mapActions({
                updateLanguageSkillsData: 'languageSkills/updateLanguageSkillsData',
                addNewLanguage: 'languageSkills/addNewLanguage',
                deleteLanguage: 'languageSkills/deleteLanguage',
                editLanguage: 'languageSkills/editLanguage',
            }),
            changeData() {
                if (this.isEditing) {
                    const editedObject = {
                        data: this.setEditedObject()
                    };
                    this.editLanguage(editedObject);
                }
            },
            editLanguages(language) {
                this.isEditing = true;
                this.languageSkillsData.name = language.name;
                this.languageSkillsData.information = language.information;
                this.languageSkillsData.rate = language.rate;
                this.languageSkillsData.id = language.id;
                this.languageSkillsData.success_point = language.success_point;
                this.showLanguageList = false;
            },
            deleteLanguages(language) {
                itemService.deleteLanguage(language.id).then((deleteResponse) => {
                    console.log('delete Resp', deleteResponse);
                    const languageIndex = this.languageSkillsData.myLanguages.indexOf(language);
                    this.deleteLanguage(languageIndex);
                    this.showDeleteLanguage = true;
                    this.showLanguageList = this.languageSkillsData.myLanguages.length > 0;
                });
            },
            addLanguage() {
                this.isEditing = false;
                this.languageSkillsData.name = '';
                this.languageSkillsData.information = '';
                this.languageSkillsData.rate = 1;
                this.languageSkillsData.success_point = null;
                this.showLanguageList = false;
            },
            saveNewLanguage() {
                const newLanguage = {
                    name: this.languageSkillsData.name,
                    information: this.languageSkillsData.information,
                    rate: this.languageSkillsData.rate,
                    success_point: this.languageSkillsData.success_point
                };
                itemService.createLanguage(newLanguage).then((createResponse) => {
                    this.addNewLanguage(createResponse.data);
                    this.showAddLanguage = true;
                    this.showLanguageList = true;
                });

            },
            setEditedObject() {
                const editedObject = {
                    name: this.languageSkillsData.name,
                    information: this.languageSkillsData.information,
                    rate: this.languageSkillsData.rate,
                    id: this.languageSkillsData.id,
                    success_point: this.languageSkillsData.success_point
                };
                return editedObject;
            },
            updateLanguage(goBack) {
                const editedLanguage = {
                    name: this.languageSkillsData.name,
                    information: this.languageSkillsData.information,
                    rate: this.languageSkillsData.rate,
                    id: this.languageSkillsData.id,
                    success_point: this.languageSkillsData.success_point
                };
                itemService.updateLanguage(editedLanguage.id, editedLanguage).then((updateResponse) => {
                    this.editLanguage(updateResponse);
                    this.showUpdateLanguage = true;
                    this.showLanguageList = goBack;
                })
            }
        }
    }
</script>
<style></style>
