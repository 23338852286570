export default {
    namespaced: true,
    state: {
        conferencesData: {
            item_header: 'Conferences',
            item_id:'',
            html:{},
            id:'',
            company: '',
            name: '',
            issue_month: '',
            issue_year: '',
            position: 0,
            myConferences: []

        },
    },
    actions: {
        updateConferenceData({commit}, data) {
            commit('UPDATE_CONFERENCE_DATA', data);
        },
        addNewConference({commit}, data) {
            commit('ADD_NEW_CONFERENCE', data);
        },
        deleteConference({commit}, data) {
            commit('DELETE_CONFERENCE', data);
        },
        editConference({commit}, data) {
            commit('EDIT_CONFERENCE', data);
        },
        updateConferenceHtml({commit}, data) {
            commit('UPDATE_CONFERENCE_HTML', data);
        },
        updateConferenceTitle({commit}, data) {
            commit('UPDATE_CONFERENCE_TITLE', data);
        },
        updateConferenceId({commit}, data) {
            commit('UPDATE_CONFERENCE_ID', data)
        }
    },
    mutations: {
        UPDATE_CONFERENCE_DATA(state, payload) {
            state.conferencesData.myConferences = payload;
        },
        ADD_NEW_CONFERENCE(state, payload) {
            state.conferencesData.myConferences.push(payload);
        },
        DELETE_CONFERENCE(state, payload) {
            state.conferencesData.myConferences.splice(payload, 1);
        },
        EDIT_CONFERENCE(state, payload) {
            state.conferencesData.myConferences.forEach((conference, i) => {
                if (conference.id === payload.data.id) {
                    state.conferencesData.myConferences[i] = payload.data;
                }
            })
        },
        UPDATE_CONFERENCE_HTML(state, payload) {
            state.conferencesData.html = payload;
        },
        UPDATE_CONFERENCE_TITLE(state, payload) {
            state.conferencesData.item_header = payload;
        },
        UPDATE_CONFERENCE_ID(state, payload) {
            state.conferencesData.item_id = payload;
        }
    }
}
