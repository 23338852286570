import gateWay from './index.js';

export default {
    getSideMenuList() {
        // type geç cv - all - cl
        return gateWay.get('v1/web/subjects')
    },
    getSideMenuListByCode(code){
        return gateWay.get('v1/web/subjects/by_code?code=' + code)
    },
    updateSideMenuList(menuList) {
        return gateWay.put('v1/web/subjects/update_bulk', menuList)
    },
    updateMenuItems(menuId, menuItems) {
        return gateWay.put('v1/web/subjects/' + menuId, menuItems)
    }
}
