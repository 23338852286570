<template>
    <section class="container feedback cage" v-if="showResumeComment">
        <p class="feedback header">My Feedbacks</p>
        <div class="w-100" v-if="myFeedbackData.length === 0">
            <p class="feedback no-comment-text text-ellipsis">You have not comment.</p>
        </div>
        <div class="d-flex justify-space-between">
            <div class="w-100 mr-5" v-if="myFeedbackData.length > 0">
                <img src="../../assets/img/web-site/demo-resume.png" alt="resumeDemo">
            </div>
            <div class="w-100 feedback comment-flex">
                <div class="feedback comment-row" v-for="(comment,i) in myFeedbackData" :key="i">
                    <v-rating v-model="comment.rate"
                              background-color="orange lighten-3" color="orange"
                              medium readonly></v-rating>
                    <p class="feedback comment-text">{{comment.comment}}</p>
                </div>
            </div>
        </div>
    </section>
    <section class="container feedback cage" v-else>
        <p class="feedback header">Comment Resume</p>
        <div class="d-flex justify-space-between">
            <div class="w-100 mr-5">
                <img src="../../assets/img/web-site/demo-resume.png" alt="resumeDemo">
            </div>
            <div class="w-100 feedback comment-form">
                <div class="item-input-cage">
                    <p class="feedback input-head">Ratings</p>
                    <v-rating v-model="comment.rate"
                              background-color="orange lighten-3" color="orange"
                              medium></v-rating>
                </div>
                <div class="item-input-cage">
                    <p class="feedback input-head mb-2">Feedback</p>
                    <textarea class="primary-text-area w-100" rows="3" placeholder="Your Comment"
                              v-model="comment.text"></textarea>
                </div>
                <v-btn class="yellow-button">
                    Send Feedback
                </v-btn>
            </div>
        </div>
    </section>
</template>
<script>
    import feedbackService from "../../services/feedback";

    export default {
        name: 'feedbackPage',
        data() {
            return {
                showResumeComment: false,
                myFeedbackData: '',
                comment: {
                    rate: 0,
                    text: ''
                }
            }
        },
        created() {
            if (this.$route.query.resumeId) {
                this.showResumeComment = true;
                this.getMyFeedbackData();
            } else {
                this.getRandomResume();
            }
        },
        methods: {
            getMyFeedbackData() {
                const resumeId = this.$route.query.resumeId;
                feedbackService.getFeedBackData(resumeId).then((feedbackData) => {
                    this.myFeedbackData = feedbackData.data;
                    /*   this.myFeedbackData = [
                           {
                               "id": 1,
                               "giver_id": 14,
                               "receiver_id": 1,
                               "user_template_id": 1,
                               "rate": 2,
                               "comment": "test comment",
                               "created_at": "2020-12-31T07:00:36.870Z",
                               "updated_at": "2020-12-31T07:00:36.870Z"
                           },
                           {
                               "id": 2,
                               "giver_id": 14,
                               "receiver_id": 8,
                               "user_template_id": 25,
                               "rate": 2,
                               "comment": "test comment",
                               "created_at": "2020-12-31T07:03:43.688Z",
                               "updated_at": "2020-12-31T07:03:43.688Z"
                           },
                           {
                               "id": 2,
                               "giver_id": 14,
                               "receiver_id": 8,
                               "user_template_id": 25,
                               "rate": 3,
                               "comment": "test comment",
                               "created_at": "2020-12-31T07:03:43.688Z",
                               "updated_at": "2020-12-31T07:03:43.688Z"
                           },
                           {
                               "id": 2,
                               "giver_id": 14,
                               "receiver_id": 8,
                               "user_template_id": 25,
                               "rate": 5,
                               "comment": "test comment",
                               "created_at": "2020-12-31T07:03:43.688Z",
                               "updated_at": "2020-12-31T07:03:43.688Z"
                           },
                           {
                               "id": 2,
                               "giver_id": 14,
                               "receiver_id": 8,
                               "user_template_id": 25,
                               "rate": 1,
                               "comment": "test comment",
                               "created_at": "2020-12-31T07:03:43.688Z",
                               "updated_at": "2020-12-31T07:03:43.688Z"
                           },
                           {
                               "id": 2,
                               "giver_id": 14,
                               "receiver_id": 8,
                               "user_template_id": 25,
                               "rate": 1,
                               "comment": "test comment",
                               "created_at": "2020-12-31T07:03:43.688Z",
                               "updated_at": "2020-12-31T07:03:43.688Z"
                           },

                           {
                               "id": 2,
                               "giver_id": 14,
                               "receiver_id": 8,
                               "user_template_id": 25,
                               "rate": 1,
                               "comment": "test comment",
                               "created_at": "2020-12-31T07:03:43.688Z",
                               "updated_at": "2020-12-31T07:03:43.688Z"
                           },

                           {
                               "id": 2,
                               "giver_id": 14,
                               "receiver_id": 8,
                               "user_template_id": 25,
                               "rate": 1,
                               "comment": "test comment",
                               "created_at": "2020-12-31T07:03:43.688Z",
                               "updated_at": "2020-12-31T07:03:43.688Z"
                           }
                       ];*/
                })
            },
            getRandomResume() {
                feedbackService.getRandomResume().then((randomData) => {
                    console.log('random', randomData);
                })
            }
        }
    }
</script>
<style lang="scss" scoped>
    .feedback {
        &.header {
            font-size: 36px;
            font-weight: 600;
            margin-bottom: 30px !important;
        }

        &.cage {

        }

        &.comment-row {
            border: 1px solid #EBEBEB;
            padding: 10px;
            margin-bottom: 15px;
            border-radius: 4px;
        }

        &.comment-text {
            font-family: "Segoe UI", sans-serif;
            font-size: 16px;
            font-weight: 600;
            text-align: start;
        }

        &.no-comment-text {
            font-family: "Segoe UI", sans-serif;
            font-weight: 700;
            font-size: 18px;
        }

        &.comment-flex {
            height: 60vh;
            overflow-y: scroll;
        }

        &.input-head {
            font-family: "Segoe UI", sans-serif;
            font-weight: 600;
            font-size: 24px;
            color: #000000;
            text-align: start;
        }

        &.comment-form {
            max-width: 500px;
            width: 100%;
            display: block;
            margin: 0 auto;
        }
    }

    .yellow-button {
        max-width: 214px;
        width: 100%;
        float: right;
    }
</style>
