export default {
    namespaced: true,
    state: {
        resumeOptions: {
            selectedTemplate: '',
            backgroundColorModel: '',
            backgroundDecoration: '',
            textColorModel: '',
            fontFamily: '',
            fontId: '',
            totalPage: 1,
        },
        staticFontList: [{id: 0, name: 'Open Sans', style: '"Open Sans", sans-serif', usageType: 'free'},
            {id: 1, name: 'Calibri', style: '"Calibri", sans-serif', usageType: 'free'},
            {id: 2, name: 'Georgia', style: '"Georgia", sans-serif', usageType: 'free'},
            {id: 3, name: 'Playfair Display', style: '"Playfair Display", sans-serif', usageType: 'pro'},
            {id: 4, name: 'Poppins', style: '"Poppins", sans-serif', usageType: 'pro'},
            {id: 5, name: 'Oswald', style: '"Oswald", sans-serif', usageType: 'pro'},
            {id: 6, name: 'Montserrat', style: '"Montserrat", sans-serif', usageType: 'pro'},],
        currentUserTemplate: {},
        myResumes: {},
        itemOrderList: [],
        listedMenu: [],
        decorationList: [],
        resumeType: '',
        themeArray: [
            {
                id: 0,
                previewImage: require('../../assets/img/black-theme.png'),
                isActive: false,
                usageType: 'free',
                name: 'greenTemplate'
            },
            {
                id: 1,
                previewImage: require('../../assets/img/red-theme.png'),
                isActive: 'active',
                usageType: 'free',
                name: 'peterMiller'
            },
            {
                id: 2,
                previewImage: require('../../assets/img/black-theme.png'),
                isActive: false,
                usageType: 'pro'
            },
            {
                id: 3,
                previewImage: require('../../assets/img/black-theme.png'),
                isActive: false,
                usageType: 'pro'
            },
            {
                id: 4,
                previewImage: require('../../assets/img/red-theme.png'),
                isActive: false,
                usageType: 'pro'
            },
            {
                id: 5,
                previewImage: require('../../assets/img/black-theme.png'),
                isActive: false,
                usageType: 'pro'
            },
            {
                id: 6,
                previewImage: require('../../assets/img/red-theme.png'),
                isActive: false,
                usageType: 'pro'
            },
            {
                id: 7,
                previewImage: require('../../assets/img/black-theme.png'),
                isActive: false,
                usageType: 'pro'
            },
            {
                id: 8,
                previewImage: require('../../assets/img/red-theme.png'),
                isActive: false,
                usageType: 'pro'
            },
            {
                id: 9,
                previewImage: require('../../assets/img/black-theme.png'),
                isActive: false,
                usageType: 'pro'
            }
        ],
    },
    actions: {
        updateCurrentUserTemplate({commit}, data) {
            commit('UPDATE_CURRENT_USER_TEMPLATE', data);
        },
        updateCurrentUserTemplateThemeId({commit}, data) {
            commit('UPDATE_CURRENT_USER_TEMPLATE_THEME_ID', data);
        },
        updateFontList({commit}, data) {
            commit('UPDATE_FONT_LIST', data);
        },
        updateThemeArray({commit}, data) {
            commit('UPDATE_THEME_ARRAY', data);
        },
        updateResumeOptions({commit}, data) {
            commit('UPDATE_RESUME_OPTIONS', data);
        },
        updateResumeType({commit}, data) {
            commit('UPDATE_RESUME_TYPE', data);
        },
        updateResumeList({commit}, data) {
            commit('UPDATE_RESUME_LIST', data);
        },
        updateSelectedTheme({commit}, data) {
            commit('UPDATE_SELECTED_THEME', data);
        },
        updateBackground({commit}, data) {
            commit('UPDATE_BACKGROUND', data);
        },
        updateBackgroundDecoration({commit}, data) {
            commit('UPDATE_BACKGROUND_DECORATION', data);
        },
        updateBackgroundDecorationList({commit}, data) {
            commit('UPDATE_BACKGROUND_DECORATION_LIST', data);
        },
        updateFontFamily({commit}, data) {
            commit('UPDATE_FONT_FAMILY', data);
        },
        updateFontColor({commit}, data) {
            commit('UPDATE_FONT_COLOR', data);
        },
        updateFontId({commit}, data) {
            commit('UPDATE_FONT_ID', data);
        },
        setTotalPage({commit}, data) {
            commit('SET_TOTAL_PAGE', data);
        },
        setUpdateOrderList({commit}, data) {
            commit('SET_UPDATE_ORDER_LIST', data);
        },
        setOrderListEmpty({commit}, data) {
            commit('SET_ORDER_LIST_EMPTY', data);
        }
    },
    mutations: {
        UPDATE_CURRENT_USER_TEMPLATE(state, payload) {
            state.currentUserTemplate = payload;
        },
        UPDATE_CURRENT_USER_TEMPLATE_THEME_ID(state, payload) {
            state.currentUserTemplate.theme_id = payload;
        },
        UPDATE_FONT_LIST(state, payload) {
            state.fontList = payload;
        },
        UPDATE_RESUME_OPTIONS(state, payload) {
            state.resumeOptions = payload;
        },
        UPDATE_THEME_ARRAY(state, payload) {
            state.themeArray = payload;
        },
        UPDATE_RESUME_TYPE(state, payload) {
            state.resumeType = payload;
        },
        UPDATE_RESUME_LIST(state, payload) {
            state.myResumes = payload;
        },
        UPDATE_SELECTED_THEME(state, payload) {
            state.resumeOptions.selectedTemplate = payload;
        },
        UPDATE_BACKGROUND(state, payload) {
            state.resumeOptions.backgroundColorModel = payload;
        },
        UPDATE_BACKGROUND_DECORATION(state, payload) {
            state.decorationList.forEach(item => {
                if (item.id === payload) {
                    state.resumeOptions.backgroundDecoration = item;
                }
            })
        },
        UPDATE_BACKGROUND_DECORATION_LIST(state, payload) {
            state.decorationList = payload;
        },
        UPDATE_FONT_FAMILY(state, payload) {
            state.resumeOptions.fontFamily = payload;
        },
        UPDATE_FONT_COLOR(state, payload) {
            state.resumeOptions.textColorModel = payload;
        },
        UPDATE_FONT_ID(state, payload) {
            state.resumeOptions.fontId = payload;
            state.staticFontList.forEach(staticFont => {
                if (staticFont.id === payload) {
                    state.resumeOptions.fontFamily = staticFont.style
                }
            });
        },
        SET_TOTAL_PAGE(state, payload) {
            state.resumeOptions.totalPage = payload;
        },
        SET_UPDATE_ORDER_LIST(state, payload) {
            state.itemOrderList = payload;
            state.listedMenu = [];
            state.itemOrderList.forEach(item => {
                const itemObject = {
                    name: item.static_title,
                    position: item.position,
                };
                state.listedMenu.push(itemObject);
            });
        },
        SET_ORDER_LIST_EMPTY(state, payload) {
            state.listedMenu = payload;
        }
    }
}
