export default {
    namespaced: true,
    state: {
        projectsData: {
            item_header: 'Projects',
            item_id:'',
            html:{},
            id:'',
            name: '',
            description: '',
            url: '',
            position: 0,
            start_month: '',
            start_year: '',
            end_month: '',
            end_year: '',
            myProjects: []
        },
    },
    actions: {
        updateProjectsData({commit}, data) {
            commit('UPDATE_PROJECTS_DATA', data);
        },
        addNewProjects({commit}, data) {
            commit('ADD_NEW_PROJECTS', data);
        },
        deleteProject({commit}, data) {
            commit('DELETE_PROJECTS', data);
        },
        editProject({commit}, data) {
            commit('EDIT_PROJECT', data);
        },
        updateProjectHtml({commit}, data) {
            commit('UPDATE_PROJECT_HTML', data);
        },
        updateProjectTitle({commit}, data) {
            commit('UPDATE_PROJECT_TITLE', data);
        },
        updateProjectId({commit}, data) {
            commit('UPDATE_PROJECT_ID', data)
        }
    },
    mutations: {
        UPDATE_PROJECTS_DATA(state, payload) {
            state.projectsData.myProjects = payload;
        },
        ADD_NEW_PROJECTS(state, payload) {
            state.projectsData.myProjects.push(payload);
        },
        DELETE_PROJECTS(state, payload) {
            state.projectsData.myProjects.splice(payload, 1);
        },
        EDIT_PROJECT(state, payload) {
            const updatedProject = state.projectsData.myProjects.find(x => x.id === payload.data.id);
            Object.assign(updatedProject,payload.data);
        },
        UPDATE_PROJECT_HTML(state, payload) {
            state.projectsData.html = payload;
        },
        UPDATE_PROJECT_TITLE(state, payload) {
            state.projectsData.item_header = payload;
        },
        UPDATE_PROJECT_ID(state, payload) {
            state.projectsData.item_id = payload;
        }
    }
}
