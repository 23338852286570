import gateWay from './index.js';

const serviceBaseUrl = 'v1/web/';

export default {
    // Summary
    getSummaryData() {
        return gateWay.get(serviceBaseUrl + 'profiles');
    },
    updateSummaryData(summaryId, summaryObject) {
        return gateWay.put(serviceBaseUrl + 'profiles/' + summaryId, summaryObject);
    },

    // Profile
    updateUserData(profileData) {
        return gateWay.put(serviceBaseUrl + 'users/update_information', profileData);
    },

    // Experience
    getExperienceData() {
        return gateWay.get(serviceBaseUrl + 'experiences');
    },
    createExperience(experienceObject) {
        return gateWay.post(serviceBaseUrl + 'experiences', experienceObject);
    },
    updateExperience(experienceId, experienceObject) {
        return gateWay.put(serviceBaseUrl + 'experiences/' + experienceId, experienceObject);
    },
    deleteExperience(experienceId) {
        return gateWay.delete(serviceBaseUrl + 'experiences/' + experienceId);
    },
    getExperienceTipsList() {
        return gateWay.get(serviceBaseUrl + 'experiences/tips');
    },

    // Awards
    getAwardsData() {
        return gateWay.get(serviceBaseUrl + 'awards');
    },
    createAward(awardObject) {
        return gateWay.post(serviceBaseUrl + 'awards', awardObject);
    },
    updateAwardData(awardId, awardObject) {
        return gateWay.put(serviceBaseUrl + 'awards/' + awardId, awardObject);
    },
    deleteAward(awardId) {
        return gateWay.delete(serviceBaseUrl + 'awards/' + awardId);
    },

    // Education
    getEducationData() {
        return gateWay.get(serviceBaseUrl + 'educations')
    },
    createEducation(educationObject) {
        return gateWay.post(serviceBaseUrl + 'educations', educationObject);
    },
    updateEducation(educationId, educationObject) {
        return gateWay.put(serviceBaseUrl + 'educations/' + educationId, educationObject);
    },
    deleteEducation(educationId) {
        return gateWay.delete(serviceBaseUrl + 'educations/' + educationId);
    },

    // Skills
    getSkillsData() {
        return gateWay.get(serviceBaseUrl + 'skills');
    },
    createSkill(skillObject) {
        return gateWay.post(serviceBaseUrl + 'skills', skillObject);
    },
    updateSkill(skillId, skillObject) {
        return gateWay.put(serviceBaseUrl + 'skills/' + skillId, skillObject);
    },
    deleteSkill(skillId) {
        return gateWay.delete(serviceBaseUrl + 'skills/' + skillId);
    },

    // Languages
    getLanguagesData() {
        return gateWay.get(serviceBaseUrl + 'languages');
    },
    createLanguage(languageObject) {
        return gateWay.post(serviceBaseUrl + 'languages', languageObject);
    },
    updateLanguage(languageId, languageObject) {
        return gateWay.put(serviceBaseUrl + 'languages/' + languageId, languageObject);
    },
    deleteLanguage(languageId) {
        return gateWay.delete(serviceBaseUrl + 'languages/' + languageId);
    },

    // Certification
    getCertification() {
        return gateWay.get(serviceBaseUrl + 'certificates');
    },
    createCertificate(certificateObject) {
        return gateWay.post(serviceBaseUrl + 'certificates', certificateObject);
    },
    updateCertificate(certificateId, certificateObject) {
        return gateWay.put(serviceBaseUrl + 'certificates/' + certificateId, certificateObject);
    },
    deleteCertificate(certificateId) {
        return gateWay.delete(serviceBaseUrl + 'certificates/' + certificateId);
    },

    // Projects
    getProjectsData() {
        return gateWay.get(serviceBaseUrl + 'projects');
    },
    createProject(projectObject) {
        return gateWay.post(serviceBaseUrl + 'projects', projectObject);
    },
    updateProject(projectId, projectObject) {
        return gateWay.put(serviceBaseUrl + 'projects/' + projectId, projectObject);
    },
    deleteProject(projectId) {
        return gateWay.delete(serviceBaseUrl + 'projects/' + projectId);

    },

    // Publications
    getPublicationsData() {
        return gateWay.get(serviceBaseUrl + 'publications');
    },
    createPublication(publicationObject) {
        return gateWay.post(serviceBaseUrl + 'publications', publicationObject);
    },
    updatePublication(publicationId, publicationObject) {
        return gateWay.put(serviceBaseUrl + 'publications/' + publicationId, publicationObject);
    },
    deletePublication(publicationId) {
        return gateWay.delete(serviceBaseUrl + 'publications/' + publicationId);
    },

    // My Times
    getMyTimeData() {
        return gateWay.get(serviceBaseUrl + 'my_times');
    },
    createMyTime(myTimeObject) {
        return gateWay.post(serviceBaseUrl + 'my_times', myTimeObject);
    },
    updateMyTime(myTimeId, myTimeObject) {
        return gateWay.put(serviceBaseUrl + 'my_times/' + myTimeId, myTimeObject);
    },
    deleteMyTime(myTimeId) {
        return gateWay.delete(serviceBaseUrl + 'my_times/' + myTimeId);
    },

    // Hobbies
    getHobbiesData() {
        return gateWay.get(serviceBaseUrl + 'hobbies');
    },
    createHobby(hobbyObject) {
        return gateWay.post(serviceBaseUrl + 'hobbies', hobbyObject);
    },
    updateHobby(hobbyId, hobbyObject) {
        return gateWay.put(serviceBaseUrl + 'hobbies/' + hobbyId, hobbyObject);
    },
    deleteHobby(hobbyId) {
        return gateWay.delete(serviceBaseUrl + 'hobbies/' + hobbyId);
    },

    // Mottos
    getMottosData() {
        return gateWay.get(serviceBaseUrl + 'mottos');
    },
    createMotto(mottoObject) {
        return gateWay.post(serviceBaseUrl + 'mottos', mottoObject);
    },
    updateMotto(mottoId, mottoObject) {
        return gateWay.put(serviceBaseUrl + 'mottos/' + mottoId, mottoObject);
    },
    deleteMotto(mottoId) {
        return gateWay.delete(serviceBaseUrl + 'mottos/' + mottoId);
    },

    // Strengths
    getStrengthData() {
        return gateWay.get(serviceBaseUrl + 'strengths');
    },
    createStrength(strengthObject) {
        return gateWay.post(serviceBaseUrl + 'strengths', strengthObject);
    },
    updateStrength(strengthId, strengthObject) {
        return gateWay.put(serviceBaseUrl + 'strengths/' + strengthId, strengthObject);
    },
    deleteStrength(strengthId) {
        return gateWay.delete(serviceBaseUrl + 'strengths/' + strengthId);
    },
    getStrengthTipsList() {
        return gateWay.get(serviceBaseUrl + 'strengths/tips');
    },

    // Volunteering
    getVolunteeringData() {
        return gateWay.get(serviceBaseUrl + 'volunteers');
    },
    createVolunteering(volunteeringObject) {
        return gateWay.post(serviceBaseUrl + 'volunteers', volunteeringObject);
    },
    updateVolunteering(volunteeringId, volunteeringObject) {
        return gateWay.put(serviceBaseUrl + 'volunteers/' + volunteeringId, volunteeringObject);
    },
    deleteVolunteering(volunteeringId) {
        return gateWay.delete(serviceBaseUrl + 'volunteers/' + volunteeringId);
    },

    // Conferences
    getConferenceData() {
        return gateWay.get(serviceBaseUrl + 'conferences');
    },
    createConference(conferenceObject) {
        return gateWay.post(serviceBaseUrl + 'conferences', conferenceObject);
    },
    updateConference(conferenceId, conferenceObject) {
        return gateWay.put(serviceBaseUrl + 'conferences/' + conferenceId, conferenceObject);
    },
    deleteConference(conferenceId) {
        return gateWay.delete(serviceBaseUrl + 'conferences/' + conferenceId);
    },

    // References
    getReferenceData() {
        return gateWay.get(serviceBaseUrl + 'references');
    },
    createReference(referenceObject) {
        return gateWay.post(serviceBaseUrl + 'references', referenceObject)
    },
    updateReference(referenceId, referenceObject) {
        return gateWay.put(serviceBaseUrl + 'references/' + referenceId, referenceObject);
    },
    deleteReference(referenceId) {
        return gateWay.delete(serviceBaseUrl + 'references/' + referenceId);
    },

    // Socials
    updateSocials(socialData) {
        return gateWay.put(serviceBaseUrl + 'socials/update_bulk', socialData);
    },

    //Letter Greeting
    updateGreeting(greetingId, greetingData) {
        return gateWay.put(serviceBaseUrl + 'letter_salutations/' + greetingId, greetingData);
    },

    // Letter Closing
    updateClosing(closingId, closingData) {
        return gateWay.put(serviceBaseUrl + 'letter_closings/' + closingId, closingData);
    },

    // Letter Body
    updateLetterBody(bodyId, bodyData) {
        return gateWay.put(serviceBaseUrl + 'letter_bodies/' + bodyId, bodyData);
    },

    // Letter Opening
    updateOpening(openingId, openingData) {
        return gateWay.put(serviceBaseUrl + 'letter_openings/' + openingId, openingData);
    }
}
