<template>
    <section>
        <div class="item-philosophy cage">
            <div v-if="showMottosList">
                <div class="item-input-sub-cage">
                    <div class="item-sub-list-cage" v-for="(motto,i) in mottoData.myMottos" :key="i">
                        <div>
                            <p class="item-sub-list-text">{{motto.description}}</p>
                            <p class="item-sub-list-text-secondary">{{motto.author}}</p>
                        </div>
                        <div class="d-flex">
                            <span class="material-icons item-sub-list-create-icon"
                                  @click="editMottos(motto)">create</span>
                            <span class="material-icons item-sub-list-delete-icon"
                                  @click="deleteMottos(motto)">delete</span>
                        </div>
                    </div>
                </div>
                <div class="item-footer-button-cage">
                    <v-btn class="yellow-button w-100" @click="addMottos">Add New Motto</v-btn>
                </div>
            </div>
            <div v-else>
                <div class="item-input-sub-cage">
                    <div class="item-input-cage">
                        <p class="item-input-header">Favorite Quote</p>
                        <input class="primary-input w-100" v-model="mottoData.description" @input="changeData"
                               @focusout="isEditing? updateMotto(false) : ''">
                    </div>
                    <div class="item-input-cage">
                        <p class="item-input-header">Who Sad That?</p>
                        <input class="primary-input w-100" v-model="mottoData.author" @input="changeData"
                               @focusout="isEditing? updateMotto(false) : ''">
                    </div>
                </div>
                <div class="item-footer-button-cage">
                    <v-btn class="yellow-button w-100" @click="isEditing ? updateMotto(true) : saveNewMotto()">
                        Save
                    </v-btn>
                </div>
            </div>
        </div>
        <v-snackbar v-model="showAddMottosNotification" :timeout="2000" :color="'success'" top>
            Motto has been added.
        </v-snackbar>
        <v-snackbar v-model="showUpdateMottosNotification" :timeout="2000" :color="'success'" top>
            Motto has been updated.
        </v-snackbar>
        <v-snackbar v-model="showDeleteMottosNotification" :timeout="2000" :color="'success'" top>
            Motto has been deleted.
        </v-snackbar>
    </section>
</template>
<script>
    import {mapActions, mapState} from "vuex";
    import itemService from '../../../services/item-data';

    export default {
        name: 'itemMottos',
        data() {
            return {
                showMottosList: false,
                showAddMottosNotification: false,
                showUpdateMottosNotification: false,
                showDeleteMottosNotification: false,
                isEditing: '',
            }
        },
        computed: {
            ...mapState({
                mottoData: (store) => store.philosophy.mottoData
            })
        },
        created() {
            this.showMottosList = this.mottoData.myMottos.length > 0;
        },
        methods: {
            ...mapActions({
                updateMottoData: 'philosophy/updateMottoData',
                addNewMotto: 'philosophy/addNewMotto',
                deleteMotto: 'philosophy/deleteMotto',
                editMotto: 'philosophy/editMotto',
            }),
            changeData() {
                if (this.isEditing) {
                    const editedObject = {
                        data: this.setEditedObject()
                    };
                    this.editMotto(editedObject);
                }
            },
            editMottos(motto) {
                this.isEditing = true;
                this.mottoData.description = motto.description;
                this.mottoData.author = motto.author;
                this.mottoData.id = motto.id;
                this.showMottosList = false;
            },
            deleteMottos(motto) {
                itemService.deleteMotto(motto.id).then((deleteResponse) => {
                    console.log('delete Resp', deleteResponse);
                    const mottoIndex = this.mottoData.myMottos.indexOf(motto);
                    this.deleteMotto(mottoIndex);
                    this.showDeleteMottosNotification = true;
                    this.showMottosList = this.mottoData.myMottos.length > 0;

                });
            },
            addMottos() {
                this.isEditing = false;
                this.mottoData.description = '';
                this.mottoData.author = '';
                this.mottoData.id = '';
                this.showMottosList = false;
            },
            setEditedObject(){
                const editedObject = {
                    description: this.mottoData.description,
                    author: this.mottoData.author,
                    id: this.mottoData.id
                };
                return editedObject;
            },
            updateMotto(goBack) {
                const editedMotto = {
                    description: this.mottoData.description,
                    author: this.mottoData.author,
                    id: this.mottoData.id
                };
                itemService.updateMotto(editedMotto.id, editedMotto).then((updateResponse) => {
                    this.editMotto(updateResponse);
                    this.showUpdateMottosNotification = true;
                    this.showMottosList = goBack;
                });
            },
            saveNewMotto() {
                const newMotto = {
                    description: this.mottoData.description,
                    author: this.mottoData.author
                };
                itemService.createMotto(newMotto).then((createResponse) => {
                    this.addNewMotto(createResponse.data);
                    this.showAddMottosNotification = true;
                    this.showMottosList = true;
                })
            }
        },
        watch: {
            myTimeData: {
                deep: true,
                handler() {
                    //  this.updateMottoData(this.mottoData)
                }
            }
        }
    }
</script>
<style></style>
