export default {
    namespaced: true,
    state: {
        socialData: {
            item_header: 'Social',
            item_id: '',
            html: {},
            mySocialData: [],
        },
    },
    actions: {
        updateSocialData({commit}, data) {
            commit('UPDATE_SOCIAL_DATA', data);
        },
        editSocialData({commit}, data) {
            commit('EDIT_SOCIAL_DATA', data);
        },
        updateSocialHtml({commit}, data) {
            commit('UPDATE_SOCIAL_HTML', data);
        },
        updateSocialTitle({commit}, data) {
            commit('UPDATE_SOCIAL_TITLE', data);
        },
        updateSocialId({commit}, data) {
            commit('UPDATE_SOCIAL_ID', data)
        }
    },
    mutations: {
        UPDATE_SOCIAL_DATA(state, payload) {
            state.socialData.mySocialData = payload;
            state.socialData.mySocialData.sort((a, b) => (a.position) - (b.position))
        },
        EDIT_SOCIAL_DATA(state, payload) {
            const updatedSocial = state.socialData.mySocialData.find(x => x.id === payload.data.id);
            Object.assign(updatedSocial, payload.data);
        },
        UPDATE_SOCIAL_HTML(state, payload) {
            state.socialData.html = payload;
        },
        UPDATE_SOCIAL_TITLE(state, payload) {
            state.socialData.item_header = payload;
        },
        UPDATE_SOCIAL_ID(state, payload) {
            state.socialData.item_id = payload;
        }
    }
}
