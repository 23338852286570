<template>
    <div class="theme-main-cage" id="element-to-print" :style="{backgroundColor:resumeOptions.backgroundColorModel}">
        <div class="green-theme header-cage">
            <img :src="summaryData.image" class="green-theme img-cage">
            <div class="w-100">
                <div :class="{'active-editor':editorList.headerEditor}">
                    <p class="theme-resume-header" :style="headerStyle" @click="openEditor('headerEditor')"
                       @focusout="changeText($event, summaryData, 'full_name')" contenteditable>
                        {{summaryData.full_name}}</p>
                </div>
                <div class="green-theme communication-area" @click="openEditor('communicationEditor')"
                     :class="{'active-editor':editorList.communicationEditor}">
                    <div class="green-theme c-inner-cage" v-if="summaryData.phone">
                        <span class="material-icons" :style="primaryFontStyle">call</span>
                        <p class="theme-primary-text communication-area-text" :style="primaryFontStyle"
                           @focusout="changeText($event, summaryData, 'phone')" contenteditable>
                            {{summaryData.phone}}</p>
                    </div>
                    <div class="green-theme c-inner-cage" v-if="summaryData.email">
                        <span class="material-icons" :style="primaryFontStyle">mail</span>
                        <p class="theme-primary-text communication-area-text" :style="primaryFontStyle"
                           @focusout="changeText($event, summaryData, 'email')" contenteditable>
                            {{summaryData.email}}</p>
                    </div>
                    <div class="green-theme c-inner-cage" v-if="summaryData.address">
                        <span class="material-icons" :style="primaryFontStyle">location_on</span>
                        <p class="theme-primary-text communication-area-text" :style="primaryFontStyle"
                           @focusout="changeText($event, summaryData, 'address')" contenteditable>
                            {{summaryData.address}}</p>
                    </div>
                </div>
                 <text-editor @addPoint="addListPoint()" v-if="editorList.communicationEditor" :id-name="'communication-area-text'"></text-editor>
                 <text-editor @addPoint="addListPoint()" v-if="editorList.headerEditor" :id-name="'theme-resume-header'"></text-editor>
            </div>
        </div>
        <div class="green-theme profile-cage">
            <div class="green-theme profile-inner-cage" @click="openEditor('profileHeaderEditor')"
                 :class="{'active-editor':editorList.profileHeaderEditor}">
                <p class="green-theme secondary-header profile-area-header" contenteditable>PROFILE</p>
                <img class="c-pointer" src="../../../assets/custom-icons/edit-pen-icon.svg">
            </div>
            <div class="w-100" @click="openEditor('profileTextEditor')"
                 v-if="summaryData.summary"
                 :class="{'active-editor':editorList.profileTextEditor}">
                <p class="green-theme content-text profile-area-text"
                   @focusout="changeText($event, summaryData, 'summary')"
                   contenteditable>{{summaryData.summary.toString()}}</p>
            </div>

             <text-editor @addPoint="addListPoint()" v-if="editorList.profileHeaderEditor" :id-name="'profile-area-header'"></text-editor>
             <text-editor @addPoint="addListPoint()" v-if="editorList.profileTextEditor" :id-name="'profile-area-text'"></text-editor>

        </div>
        <div class="text-editor-bg" v-if="showEditorBg" @click="closeEditor"></div>
        <div class="text-editor-close-bg" v-if="showEditorBg" @click="closeEditor"></div>
    </div>
</template>
<script>
    import {mapState} from "vuex";
    import textEditor from '../../atomic/text-editor.vue'

    export default {
        name: 'greenTheme',
        components: {textEditor},
        data() {
            return {
                openCommunicationEditor: false,
                openHeaderEditor: false,
                showEditorBg: false,
                editorList: {
                    communicationEditor: false,
                    headerEditor: false,
                    profileHeaderEditor: false,
                    profileTextEditor: false
                }
            }
        },
        methods: {
            changeText(event, group, model) {
                group[model] = event.target.outerText;
            },
            openEditor(editorName) {
                this.editorList[editorName] = true;
                this.showEditorBg = Object.keys(this.editorList).find(key => this.editorList[key] === true)
            },
            closeEditor() {
                this.showEditorBg = false;
                Object.keys(this.editorList).forEach(key => this.editorList[key] = false)
            },

        },
        computed: {
            ...mapState({
                resumeOptions: (store) => store.resume.resumeOptions,
                summaryData: (store) => store.summary.summaryData,
            }),
            headerStyle() {
                return {
                    color: this.resumeOptions.textColorModel,
                    fontFamily: this.resumeOptions.fontFamily
                }
            },
            primaryFontStyle() {
                return {
                    color: this.resumeOptions.textColorModel,
                    fontFamily: this.resumeOptions.fontFamily
                }
            }
        },
        watch: {
            summaryData: {
                deep: true,
                handler() {
                    console.log('summaryData', this.summaryData)
                }
            },
            resumeOptions: {
                deep: true,
                handler() {
                    console.log('resumeOptions', this.resumeOptions)
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    .theme-main-cage {
        position: relative;
        background: #fff;
        height: 842px;
        width: 595px;
    }

    .green-theme {
        overflow: hidden;

        &.header-cage {
            display: flex;
            align-items: center;
            flex-direction: row;
            padding: 25px 25px 0;
        }

        &.profile-cage {
            display: flex;
            flex-direction: column;
            padding: 20px 25px 25px 90px;
        }

        &.profile-inner-cage {
            display: flex;
            align-items: center;
        }

        &.secondary-header {
            font-size: 18px;
            color: #6ebaa6;
            letter-spacing: 3px;
            text-align: left;
            margin-right: 7px;
            padding: 5px;
        }

        &.img-cage {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            max-height: 100px;
            max-width: 100px;
            margin-right: 35px;
        }

        &.communication-area {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &.c-inner-cage {
            display: flex;
            flex-direction: row;
            margin-right: 8px;
            align-items: center;

            &:last-child {
                margin-right: 0 !important;
            }
        }


        &.content-text {
            font-size: 12px;
            text-align: left;
            padding: 5px;
        }

    }

    .theme-primary-text {
        font-size: 12px;
    }

    .theme-resume-header {
        font-size: 50px;
        color: #6ebaa6;
        letter-spacing: 3px;
        text-align: center;
    }

    .communication-area-text {
        padding: 3px;
    }

    .material-icons {
        font-size: 15px;
        margin-right: 5px;
    }
</style>
