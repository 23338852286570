<template>
    <section>
        <div class="d-flex justify-content-start align-items-center">
            <opener-menu :menuHead="'Theme'" :menuIcon="'folder-open'" :onClose="true">
                <theme-selector></theme-selector>
            </opener-menu>
            <opener-menu :menuHead="'Background'" :menuIcon="'palette-line'" :onClose="false">
                <background-selector @colorModelChange="updateBackgrounds($event)"
                                     @decorationModelChange="updateDecoration($event)"></background-selector>
            </opener-menu>
            <opener-menu :menuHead="'Font'" :menuIcon="'font-size'" :onClose="false">
                <font-selector @changeSelectedFont="updateTextFamily($event)"></font-selector>
            </opener-menu>
            <opener-menu :menuHead="'Color'" :menuIcon="'contrast-drop-line'" :onClose="false">
                <color-selector @colorModelChange="updateTextColor($event)"></color-selector>
            </opener-menu>
        </div>
    </section>
</template>
<script>
    import openerMenu from '../../atomic/opener-menu.vue';
    import colorSelector from './specifications/color-selector.vue'
    import backgroundSelector from './specifications/background-selector.vue'
    import fontSelector from './specifications/font-selector.vue'
    import themeSelector from './specifications/theme-selector.vue'
    import {mapActions} from "vuex";

    export default {
        name: 'headerSpecifications',
        components: {openerMenu, colorSelector, fontSelector, themeSelector, backgroundSelector},
        methods: {
            ...mapActions({
                updateResumeOptions: 'resume/updateResumeOptions',
                updateBackground: 'resume/updateBackground',
                updateBackgroundDecoration: 'resume/updateBackgroundDecoration',
                updateFontFamily: 'resume/updateFontFamily',
                updateFontColor: 'resume/updateFontColor'
            }),
            updateBackgrounds(background) {
                // servise gönder
                this.updateBackground(background)
            },
            updateDecoration(decorationId) {
                // servise gönder
                this.updateBackgroundDecoration(decorationId);
            },
            updateTextColor(color) {
                // servise gönder
                this.updateFontColor(color)
            },
            updateTextFamily(fontFamily) {
                // servise gönder
                this.updateFontFamily(fontFamily);
            }
        },
    }
</script>
<style lang="scss" scoped>
    .v-color-picker {
        display: flex !important;
        flex-direction: column-reverse !important;
    }

    .color-palette {
        &.cage {
            display: flex;
            min-width: 217px;
            width: 100%;
            max-width: 217px;
            flex-direction: row;
            flex-wrap: wrap;
            padding: 20px 10px 20px 15px;

            &.more-color-active {
                max-width: 316px !important;
            }
        }

        &.picker {
            height: 38px;
            width: 38px;
            border-radius: 50%;
            margin-right: 10px;
            margin-bottom: 10px;
        }
    }
</style>
