<template>
    <div class="container h-100 position-relative mt-30 mb-30">
        <public-page-navigator @changeTab="changeTab($event)"></public-page-navigator>
        <contents class="resume-cage-inner" v-if="currentTab === 'resume' && resumeType === 'resume'"></contents>
        <contents class="resume-cage-inner"
                  v-if="currentTab === 'cover_letter' && resumeType === 'cover_letter'"></contents>
        <video-cv-gallery v-if="currentTab === 'videoCv'" :videoCvDetail="videoCvDetail"></video-cv-gallery>
    </div>
</template>
<script>
    import {mapState} from "vuex";
    import contents from "../../components/contents";
    import publicPageNavigator from "../../components/website/public-page/public-page-navigator.vue";
    import videoCvGallery from "../../components/website/public-page/video-cv-gallery.vue";
    import dashboardHelper from "@/mixins/dashboard-helper.js";

    export default {
        name: 'publicPage',
        components: {contents, publicPageNavigator, videoCvGallery},
        data() {
            return {
                currentTab: 'resume',
                resumeCode: '',
                resumeDetail: '',
                coverLetterDetail: '',
            }
        },
        mixins: [dashboardHelper],
        created() {
            this.resumeCode = this.$route.params.user;
            this.getResumeDetailFromCode(this.resumeCode, this.currentTab);
        },
        methods: {
            changeTab(tabName) {
                this.currentTab = tabName;
                this.getResumeDetailFromCode(this.resumeCode, this.currentTab);
            }
        },
        computed: {
            ...mapState({
                resumeType: (store) => store.resume.resumeType,
                listedMenu: (store) => store.resume.listedMenu,
            })
        }

    }
</script>
<style lang="scss" scoped>
    ::v-deep .theme-main-divider {
        display: none !important;
    }

    .resume-cage {
        z-index: 3;
        background: none;
        width: 100%;
        height: 100% !important;
        position: absolute;
        left: 0;
        height: 0;
    }

    .resume-cage-inner {
        z-index: 2;
    }

    .mt-30 {
        margin-top: 30px;
    }

    .mb-30 {
        margin-bottom: 30px;
    }

    ::v-deep .resume-content.cage {
        pointer-events: none;
    }
</style>
