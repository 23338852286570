<template>
    <section v-if="questionList.length > 0">
        <div class="container">
            <p class="video-cv header">Video Cv</p>
            <v-stepper v-model="currentStep" vertical>
                <div v-for="(question,i) in questionList" :key="`${i}-step`" :step="i+1">
                    <v-stepper-step :step="i+1" color="#742774" editable>
                        {{question.question}}
                    </v-stepper-step>

                    <v-stepper-content :step="i+1">
                        <div class="mb-12" height="350px">
                            <div class="d-flex align-items-center justify-center">
                                <div class="w-100 mr-5">
                                    <p>Upload Your Answer</p>
                                    <v-file-input
                                            label="Upload Video"
                                            accept="video/*"
                                            :id="question.file_name"
                                            @change="newUploader($event, question)"
                                            outlined
                                            dense
                                    ></v-file-input>
                                </div>
                                <div>
                                    <video v-if="question.download_link" width="500" height="350" disablePictureInPicture controls controlsList="nodownload">
                                        <source :src="question.download_link" type="video/mp4">
                                    </video>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <v-btn color="#742774" dark @click="currentStep += 1">Next</v-btn>
                        <v-btn v-if="currentStep > 1" @click="currentStep -= 1" text>Back</v-btn>
                    </v-stepper-content>
                </div>
            </v-stepper>
        </div>
        <v-snackbar v-model="showVideoSizeError" :timeout="5000" :color="'error'" top>
            File size must be less than 200 mb.
        </v-snackbar>
        <v-snackbar v-model="showErrorMessage" :timeout="5000" :color="'error'" top>
            {{errorMessage}}
        </v-snackbar>
    </section>
</template>
<script>
    import videoCvService from '../../services/video-cv.js'


    export default {
        name: 'videoCv',
        data() {
            return {
                currentStep: 1,
                questionList: [],
                showVideoSizeError: false,
                errorMessage:'',
                showErrorMessage:false
            }
        },
        created() {
            this.getQuestions();
        },
        methods: {
            getQuestions() {
                videoCvService.getVideoCv().then((getResponse) => {
                    this.questionList = getResponse.data.data.video_cv_questions;
                }).catch(err => {
                    if (err.response.status === 403) {
                        const videoCvObject = {
                            title: 'My Video Cv'
                        };
                        videoCvService.createVideoCv(videoCvObject).then((createResponse) => {
                            this.questionList = createResponse.data.video_cv_questions;
                        })
                    }
                    if (err.response) {
                        this.errorMessage = err.response.data.errors[0];
                        this.showErrorMessage = true;
                    }
                })
            },
            async newUploader(event, question) {
                const uploader = document.getElementById(question.file_name);
                if (uploader.files[0].size > 15000000) {
                    this.showVideoSizeError = true;
                } else {
                    const fileExtension = '.' + uploader.files[0].name.split('.').pop();
                    const aws = require('aws-sdk');
                    aws.config.update({
                        accessKeyId: 'AKIAYVNMFWXTEO65JXHK',
                        secretAccessKey: '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'
                    });
                    const s3 = new aws.S3({params: {Bucket: 'anycv-videos'}});
                    await s3.upload({
                        Bucket: 'anycv-videos',
                        Key: question.file_name + fileExtension,
                        Body: uploader.files[0]
                    }).promise();
                }

            }
        }
    }
</script>
<style lang="scss" scoped>
    .video-cv{
        &.header{
            font-size: 36px;
            font-weight: 600;
            margin-bottom: 30px !important;
        }
    }
</style>
