<template>
    <section>
        <div class="item-conference cage">
            <div v-if="showConferencesList">
                <div class="item-input-sub-cage">
                    <div class="item-sub-list-cage" v-for="(conference,i) in conferencesData.myConferences" :key="i">
                        <div>
                            <p class="item-sub-list-text">{{conference.name}}</p>
                            <p class="item-sub-list-text-secondary d-flex align-items-center">{{conference.company}}</p>
                        </div>
                        <div class="d-flex">
                            <span class="material-icons item-sub-list-create-icon"
                                  @click="editConferences(conference)">create</span>
                            <span class="material-icons item-sub-list-delete-icon"
                                  @click="deleteConferences(conference)">delete</span>
                        </div>
                    </div>
                </div>
                <div class="item-footer-button-cage">
                    <v-btn class="yellow-button w-100" @click="addConference">Add New Conference</v-btn>
                </div>
            </div>
            <div v-else>
                <div class="item-input-sub-cage">
                    <div class="item-input-cage">
                        <p class="item-input-header">Conference Name</p>
                        <input class="primary-input w-100" v-model="conferencesData.name"
                               @focusout="isEditing? updateConference(false) : ''">
                    </div>
                    <div class="item-input-cage">
                        <p class="item-input-header">Organizations</p>
                        <input class="primary-input w-100" v-model="conferencesData.company"
                               @focusout="isEditing? updateConference(false) : ''">
                    </div>
                    <div class="item-input-cage">
                        <p class="item-input-header">Date</p>
                        <div class="d-flex flex-row">
                            <input class="primary-input w-100 mr-1" v-model="conferencesData.issue_month"
                                   placeholder="Month" @focusout="isEditing? updateConference(false) : ''">
                            <input class="primary-input w-100" v-model="conferencesData.issue_year"
                                   placeholder="Year" @focusout="isEditing? updateConference(false) : ''">
                        </div>
                    </div>
                </div>
                <div class="item-footer-button-cage">
                    <v-btn class="yellow-button w-100"
                           @click="isEditing ? updateConference(true) : saveNewConference()">
                        Save
                    </v-btn>
                </div>
            </div>
        </div>
        <v-snackbar v-model="showAddConferenceNotification" :timeout="2000" :color="'success'" top>
            Conference has been added.
        </v-snackbar>
        <v-snackbar v-model="showUpdateConferenceNotification" :timeout="2000" :color="'success'" top>
            Conference has been updated.
        </v-snackbar>
        <v-snackbar v-model="showDeleteConferenceNotification" :timeout="2000" :color="'success'" top>
            Conference has been deleted.
        </v-snackbar>
    </section>
</template>
<script>
    import {mapActions, mapState} from "vuex";
    import itemService from '../../../services/item-data'

    export default {
        name: 'itemConferences',
        data() {
            return {
                showConferencesList: true,
                showAddConferenceNotification: false,
                showUpdateConferenceNotification: false,
                showDeleteConferenceNotification: false,
                isEditing: false,
            }
        },
        created() {
            this.showConferencesList = this.conferencesData.myConferences.length > 0;
        },
        computed: {
            ...mapState({
                conferencesData: (store) => store.conferences.conferencesData
            })
        },
        methods: {
            ...mapActions({
                updateConferenceData: 'conferences/updateConferenceData',
                addNewConference: 'conferences/addNewConference',
                deleteConference: 'conferences/deleteConference',
                editConference: 'conferences/editConference',
            }),
            editConferences(conference) {
                this.isEditing = true;
                this.conferencesData.id = conference.id;
                this.conferencesData.company = conference.company;
                this.conferencesData.name = conference.name;
                this.conferencesData.issue_month = conference.issue_month;
                this.conferencesData.issue_year = conference.issue_year;
                this.showConferencesList = false;
            },
            deleteConferences(conference) {
                itemService.deleteConference(conference.id).then((deleteResponse) => {
                    console.log('delete resp', deleteResponse);
                    const conferenceIndex = this.conferencesData.myConferences.indexOf(conference);
                    this.deleteConference(conferenceIndex);
                    this.showDeleteConferenceNotification = true;
                    this.showConferencesList = this.conferencesData.myConferences.length > 0;
                });
            },
            addConference() {
                this.isEditing = false;
                this.conferencesData.company = '';
                this.conferencesData.name = '';
                this.conferencesData.issue_month = '';
                this.conferencesData.issue_year = '';
                this.showConferencesList = false;
            },
            updateConference(goBack) {
                const editedConference = {
                    id: this.conferencesData.id,
                    company: this.conferencesData.company,
                    name: this.conferencesData.name,
                    issue_month: this.conferencesData.issue_month,
                    issue_year: this.conferencesData.issue_year
                };
                itemService.updateConference(editedConference.id, editedConference).then((updateResponse) => {
                    this.editConference(updateResponse);
                    this.showUpdateConferenceNotification = true;
                    this.showConferencesList = goBack;
                })
            },
            saveNewConference() {
                const newConference = {
                    company: this.conferencesData.company,
                    name: this.conferencesData.name,
                    issue_month: this.conferencesData.issue_month,
                    issue_year: this.conferencesData.issue_year
                };
                itemService.createConference(newConference).then((createResponse) => {
                    this.addNewConference(createResponse.data);
                    this.showAddConferenceNotification = true;
                    this.showConferencesList = true;
                });
            }
        },
    }
</script>
<style></style>
