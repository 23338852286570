import Vue from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';
import vuetify from './utils/vuetify';
import Vuelidate from 'vuelidate'
import tinymce from 'vue-tinymce-editor'
import firebase from 'firebase/app';


// Style
import './assets/scss/main.scss';
import 'bootstrap/dist/css/bootstrap.css'
import {VSnackbar} from "vuetify/lib/components/VSnackbar";

// const firebaseConfig = {
//     apiKey: "AIzaSyDX2ZOU2hJtEh_CLKPcFDy8Qqiy83IowRo",
//     authDomain: "anycv-4370d.firebaseapp.com",
//     databaseURL: "https://anycv-4370d.firebaseio.com",
//     projectId: "anycv-4370d",
//     storageBucket: "anycv-4370d.appspot.com",
//     messagingSenderId: "418337026676",
//     appId: "1:418337026676:web:c8494de73a21083fe51721",
//     measurementId: "G-XLSPBF3BFK"
// };

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDDy36aIA74ih1lxNgI1I36oMNmpQO48Fs",
  authDomain: "anycv-com.firebaseapp.com",
  databaseURL: "https://anycv-com-default-rtdb.firebaseio.com",
  projectId: "anycv-com",
  storageBucket: "anycv-com.appspot.com",
  messagingSenderId: "412177536818",
  appId: "1:412177536818:web:eeb524127678346e05692b"
};


firebase.initializeApp(firebaseConfig);

Vue.config.productionTip = false;
Vue.use(Vuelidate);
Vue.component('tinymce', tinymce);
Vue.component('V-snackbar', VSnackbar);

Vue.prototype.window = window;

new Vue({
    router,
    vuetify,
    store,
    render: h => h(App),
}).$mount('#app')
