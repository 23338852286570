<template>
    <section>
        <div class="item-certificate cage">
            <div v-if="showCertificateList">
                <div class="item-input-sub-cage">
                    <div class="item-sub-list-cage" v-for="(certificate,i) in certificateData.myCertificates" :key="i">
                        <div>
                            <p class="item-sub-list-text">{{certificate.name}}</p>
                            <p class="item-sub-list-text-secondary">{{certificate.company}}</p>
                        </div>
                        <div class="d-flex">
                            <span class="material-icons item-sub-list-create-icon"
                                  @click="editCertificates(certificate)">create</span>
                            <span class="material-icons item-sub-list-delete-icon"
                                  @click="deleteCertificates(certificate)">delete</span>
                        </div>
                    </div>
                </div>
                <div class="item-footer-button-cage">
                    <v-btn class="yellow-button w-100" @click="addCertificate">Add New Certificate</v-btn>
                </div>
            </div>
            <div v-if="!showCertificateList">
                <div class="item-input-sub-cage">
                    <div class="item-input-cage">
                        <p class="item-input-header">Certificate Name</p>
                        <input class="primary-input w-100" v-model="certificateData.name" @input="changeData"
                               @focusout="isEditing? updateCertificates(false) : ''">
                    </div>
                    <div class="item-input-cage">
                        <p class="item-input-header">Issuing Organization</p>
                        <input class="primary-input w-100" v-model="certificateData.company" @input="changeData"
                               @focusout="isEditing? updateCertificates(false) : ''">
                    </div>
                    <div class="item-input-cage">
                        <p class="item-input-header">Certificate Link</p>
                        <input class="primary-input w-100" v-model="certificateData.link" @input="changeData"
                               @focusout="isEditing? updateCertificates(false) : ''">
                    </div>
                    <div class="d-flex flex-row">
                        <div class="item-input-cage mr-2">
                            <p class="item-input-header">Validity Date</p>
                            <div class="d-flex flex-row w-50">
                                <input class="primary-input w-100 mr-1" v-model="certificateData.valid_month" @input="changeData"
                                       placeholder="Month" @focusout="isEditing? updateCertificates(false) : ''">
                                <input class="primary-input w-100" v-model="certificateData.valid_year" @input="changeData"
                                       placeholder="Year" @focusout="isEditing? updateCertificates(false) : ''">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="item-footer-button-cage">
                    <v-btn class="yellow-button w-100"
                           @click="isEditing ? updateCertificates(true) : saveNewCertificate()">
                        Save
                    </v-btn>
                </div>
            </div>
        </div>
        <v-snackbar v-model="showDeleteCertificate" :timeout="2000" :color="'success'" top>
            Certificate has been deleted.
        </v-snackbar>
        <v-snackbar v-model="showAddCertificate" :timeout="2000" :color="'success'" top>
            Certificate has been added.
        </v-snackbar>
        <v-snackbar v-model="showUpdateCertificate" :timeout="2000" :color="'success'" top>
            Certificate has been updated.
        </v-snackbar>
    </section>
</template>
<script>
    import {mapActions, mapState} from "vuex";
    import itemService from '../../../services/item-data';

    export default {
        name: 'itemCertificate',
        data() {
            return {
                showCertificateList: false,
                showAddCertificate: false,
                showDeleteCertificate: false,
                showUpdateCertificate: false,
                isEditing: false,
            }
        },
        created() {
            this.showCertificateList = this.certificateData.myCertificates.length > 0;
        },
        computed: {
            ...mapState({
                certificateData: (store) => store.certificate.certificateData
            })
        },
        methods: {
            ...mapActions({
                updateCertificateData: 'certificate/updateCertificateData',
                addNewCertificate: 'certificate/addNewCertificate',
                deleteCertificate: 'certificate/deleteCertificate',
                editCertificate: 'certificate/editCertificate'
            }),
            changeData() {
                if (this.isEditing) {
                    const editedObject = {
                        data: this.setEditedObject()
                    };
                    this.editCertificate(editedObject);
                }
            },
            editCertificates(certificate) {
                this.isEditing = true;
                this.certificateData.name = certificate.name;
                this.certificateData.company = certificate.company;
                this.certificateData.valid_year = certificate.valid_year;
                this.certificateData.valid_month = certificate.valid_month;
                this.certificateData.link = certificate.link;
                this.certificateData.id = certificate.id;
                this.showCertificateList = false;
            },
            deleteCertificates(certificate) {
                itemService.deleteCertificate(certificate.id).then((deleteResponse) => {
                    console.log('delete resp', deleteResponse);
                    const certificateIndex = this.certificateData.myCertificates.indexOf(certificate);
                    this.deleteCertificate(certificateIndex);
                    this.showDeleteCertificate = true;
                    this.showCertificateList = this.certificateData.myCertificates.length > 0;
                });
            },
            setEditedObject(){
                const editedObject = {
                    name: this.certificateData.name,
                    company: this.certificateData.company,
                    valid_year: this.certificateData.valid_year,
                    valid_month: this.certificateData.valid_month,
                    link: this.certificateData.link,
                    id: this.certificateData.id
                };
                return editedObject;
            },
            updateCertificates(goBack) {
                const editedCertificate = {
                    certificate: {
                        name: this.certificateData.name,
                        company: this.certificateData.company,
                        valid_year: this.certificateData.valid_year,
                        valid_month: this.certificateData.valid_month,
                        link: this.certificateData.link,
                        id: this.certificateData.id
                    }
                };
                itemService.updateCertificate(editedCertificate.certificate.id, editedCertificate).then((updateResponse) => {
                    this.editCertificate(updateResponse);
                    this.showUpdateCertificate = true;
                    this.showCertificateList = goBack
                });
            },
            addCertificate() {
                this.isEditing = false;
                this.certificateData.name = '';
                this.certificateData.company = '';
                this.certificateData.valid_year = '';
                this.certificateData.valid_month = '';
                this.certificateData.link = '';
                this.showCertificateList = false;
            },
            saveNewCertificate() {
                const newCertificate = {
                    name: this.certificateData.name,
                    company: this.certificateData.company,
                    valid_year: this.certificateData.valid_year,
                    valid_month: this.certificateData.valid_month,
                    link: this.certificateData.link
                };
                itemService.createCertificate(newCertificate).then((createResponse) => {
                    this.addNewCertificate(createResponse.data);
                    this.showAddCertificate = true;
                    this.showCertificateList = true
                })
            }
        },
        watch: {
            certificateData: {
                deep: true,
                handler() {
                    //  this.updateCertificateData(this.certificateData)
                }
            }
        }
    }
</script>
<style></style>
