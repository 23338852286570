<template>
    <section>
        <div class="register-form cage">
            <div class="d-flex">
                <div class="register-form half-input-cage ">
                    <div class="position-relative">
                        <p class="register-form input-text mt-0">Name</p>
                        <input class="primary-input" v-model.trim="$v.name.$model">
                        <img class="input-icon" v-if="name"
                             src="../../assets/custom-icons/password-requirement-icon.svg">
                        <img class="input-icon error-icon" v-if="$v.name.$anyError"
                             src="../../assets/custom-icons/web-site-icons/close-circle-red.svg">
                        <div class="error-text" v-if="!$v.name.required && $v.name.$dirty">Field is required</div>
                    </div>
                </div>
                <div class="register-form half-input-cage ">
                    <div class="position-relative">
                        <p class="register-form input-text mt-0">Surname</p>
                        <input class="primary-input" v-model.trim="$v.surname.$model">
                        <img class="input-icon" v-if="surname"
                             src="../../assets/custom-icons/password-requirement-icon.svg">
                        <img class="input-icon error-icon" v-if="$v.surname.$anyError"
                             src="../../assets/custom-icons/web-site-icons/close-circle-red.svg">
                        <div class="error-text" v-if="!$v.surname.required && $v.surname.$dirty">Field is required</div>

                    </div>
                </div>
            </div>
            <div class="position-relative">
                <p class="register-form input-text">Email Address</p>
                <input class="primary-input w-100" v-model.trim="$v.emailAddress.$model">
                <img class="input-icon" v-if="emailAddress && $v.emailAddress.email && $v.emailAddress.$dirty"
                     src="../../assets/custom-icons/password-requirement-icon.svg">
                <img class="input-icon error-icon" v-if="$v.emailAddress.$anyError"
                     src="../../assets/custom-icons/web-site-icons/close-circle-red.svg">
                <div class="error-text" v-if="!$v.emailAddress.email && $v.emailAddress.$dirty">Not valid email</div>
                <div class="error-text" v-if="!$v.emailAddress.required && $v.emailAddress.$dirty">Field is required
                </div>
            </div>

            <v-menu rounded right :offset-y="true" :offset-x="true" v-model="passwordController" max-width="197"
                    nudge-right="20px" nudge-top="20px">
                <template v-slot:activator="{ on }">
                    <div class="position-relative" v-on="on">
                        <p class="register-form input-text">Password</p>
                        <input class="primary-input w-100" type="password" v-model="password" @input="controlPassword">
                        <img class="input-icon"
                             v-if="password && !$v.password.$anyError && passwordRequirements.allDone"
                             src="../../assets/custom-icons/password-requirement-icon.svg">
                        <img class="input-icon" v-if="password && $v.password.$invalid"
                             src="../../assets/custom-icons/web-site-icons/close-circle-red.svg">
                    </div>
                </template>
                <div class="password-requirement main-cage">
                    <p class="password-requirement requirement-header">Requirements</p>
                    <div class="password-requirement cage">
                        <div>
                            <img v-if="passwordRequirements.minimumLength"
                                 src="../../assets/custom-icons/password-requirement-icon.svg" alt="doneIcon">
                        </div>
                        <p class="password-requirement requirement-text">Minimum of 6 characters requirement</p>
                    </div>
                    <div class="password-requirement cage">
                        <div>
                            <img v-if="passwordRequirements.haveUpperCase"
                                 src="../../assets/custom-icons/password-requirement-icon.svg" alt="doneIcon">
                        </div>
                        <p class="password-requirement requirement-text">Contains an uppercase letter</p>
                    </div>
                    <div class="password-requirement cage">
                        <div>
                            <img v-if="passwordRequirements.haveNumber"
                                 src="../../assets/custom-icons/password-requirement-icon.svg" alt="doneIcon">
                        </div>
                        <p class="password-requirement requirement-text">Contains a number (0-9)</p>
                    </div>
                    <div class="password-requirement cage">
                        <div>
                            <img v-if="passwordRequirements.haveSpecialChar"
                                 src="../../assets/custom-icons/password-requirement-icon.svg" alt="doneIcon">
                        </div>
                        <p class="password-requirement requirement-text">Contains special characters (!@%$&)</p>
                    </div>
                </div>
            </v-menu>
            <div class="position-relative">
                <p class="register-form input-text">Password Retry</p>
                <input type="password" class="primary-input w-100" v-model="password_confirmation">
                <img class="input-icon" v-if="password_confirmation && password === password_confirmation"
                     src="../../assets/custom-icons/password-requirement-icon.svg">
                <img class="input-icon error-icon" v-if="password_confirmation && password !== password_confirmation"
                     src="../../assets/custom-icons/web-site-icons/close-circle-red.svg">
                <div class="error-text" v-if="password_confirmation && password !== password_confirmation">Please make
                    sure your passwords match.
                </div>
            </div>
        </div>
        <v-btn class="yellow-button w-100" @click="sendRegister">Register</v-btn>
        <p class="register-form have-account" @click="setLoginTab('login')">Have you an account? Login.</p>
        <v-snackbar v-model="showErrorMessage" :timeout="2000" :color="'error'" top>
            {{errorMessage}}
        </v-snackbar>
    </section>
</template>
<script>
    import authService from "@/services/auth";
    import {mapActions} from "vuex";
    import {email, required} from "vuelidate/lib/validators";

    export default {
        name: 'registerForm',
        data() {
            return {
                name: '',
                surname: '',
                emailAddress: '',
                password: '',
                password_confirmation: '',
                passwordController: '',
                passwordRequirements: {
                    minimumLength: false,
                    haveUpperCase: false,
                    haveNumber: false,
                    haveSpecialChar: false,
                    allDone: false
                },
                showErrorMessage: false,
                errorMessage: ''
            }
        },
        validations: {
            name: {
                required
            },
            surname: {
                required
            },
            emailAddress: {
                required,
                email
            },
            password: {
                required,
                valid: function () {
                    return this.passwordRequirements.allDone
                }

            }
        },
        methods: {
            ...mapActions({
                updateUserInfo: 'authorization/updateUserInfo',
                updateLoginModalStatus: 'general/updateLoginModalStatus',
                updateCredentialInfo: 'authorization/updateCredentialInfo',
            }),
            setLoginTab(tabName) {
                this.$emit('changeLoginTab', tabName)
            },
            sendRegister() {
                const registerObject = {
                    email: this.emailAddress,
                    password: this.password,
                    password_confirmation: this.password_confirmation,
                    name: this.name,
                    surname: this.surname,
                    gsm: '',
                };
                authService.register(registerObject).then((registerResponse) => {
                    const credentialObject = {
                        access_token: registerResponse.headers['access-token'],
                        client: registerResponse.headers['client'],
                        uid: registerResponse.headers['uid'],
                        isDummy: registerResponse.data.data.dummy
                    };
                    this.updateCredentialInfo(credentialObject);
                    this.updateLoginModalStatus(false);
                    this.updateUserInfo(registerResponse.data.data);
                    window.location.href = 'website/create-resume';
                }).catch(err => {
                    if (err.response) {
                        this.errorMessage = err.response.data.errors.full_messages[0];
                        this.showErrorMessage = true;
                    }
                })
            },
            controlPassword() {
                this.passwordController = true;
                this.passwordRequirements.minimumLength = this.password.length >= 6;
                this.passwordRequirements.haveUpperCase = (/[A-Z]/).test(this.password);
                this.passwordRequirements.haveNumber = (/[0-9]/).test(this.password);
                this.passwordRequirements.haveSpecialChar = (/[-!@%$_#&()=?'^+/%]/).test(this.password);
                this.passwordRequirements.allDone = this.passwordRequirements.minimumLength &&
                    this.passwordRequirements.haveUpperCase &&
                    this.passwordRequirements.haveNumber &&
                    this.passwordRequirements.haveSpecialChar;
            }
        }
    }
</script>
<style lang="scss" scoped>
    .register-form {
        &.input-text {
            text-align: start;
            margin-bottom: 8px !important;
            margin-top: 25px;
            font-family: "Segoe UI", sans-serif;
            font-weight: 600;
            font-size: 14px;
        }

        &.half-input-cage {
            &:first-child {
                margin-right: 17.5px !important;
            }

            &:last-child {
                margin-left: 17.5px !important;
            }
        }

        &.have-account {
            font-size: 14px;
            color: #742774;
            margin-top: 19px;
            cursor: pointer;
        }
    }

    .password-requirement {
        &.main-cage {
            padding: 9px 12px;
            background: #ffffff !important;
        }

        &.requirement-header {
            font-family: "Segoe UI";
            font-weight: 600;
            font-size: 10px;
            color: #000000;
            text-align: start;
            margin-bottom: 6px !important;
        }

        &.requirement-text {
            font-family: "Segoe UI";
            font-size: 9px;
            color: #000000;
            font-weight: 400;
            margin-left: 5px;
        }

        &.cage {
            display: flex;
            margin-bottom: 6px !important;

            &:last-child {
                margin-bottom: 0 !important;
            }

            & > div {
                display: flex;
                width: 10px;
            }
        }
    }

    .mt-0 {
        margin-top: 0 !important;
    }

    .yellow-button {
        margin-top: 25px;
    }

    .input-icon {
        position: absolute;
        right: 10px;
        bottom: 10px;
        width: 19px;
        height: 19px;

        &.error-icon {
            bottom: 30px;
        }
    }

    ::v-deep .v-snack__content {
        text-align: center !important;
    }
</style>
