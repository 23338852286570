
export default {
    namespaced: true,
    state: {
        awardsData: {
            item_header: 'Awards',
            item_id:'',
            html:{},
            name: '',
            degree: '',
            issue_year: '',
            issue_month: '',
            description: '',
            position: 0,
            id: '',
            myAwards: []
        },
    },
    actions: {
        updateAwardsData({commit}, data) {
            commit('UPDATE_AWARDS_DATA', data);
        },
        addNewAwards({commit}, data) {
            commit('ADD_NEW_AWARDS', data);
        },
        deleteAwards({commit}, data) {
            commit('DELETE_AWARDS', data);
        },
        editAward({commit}, data) {
            commit('EDIT_AWARD', data);
        },
        updateAwardHtml({commit}, data) {
            commit('UPDATE_AWARD_HTML', data);
        },
        updateAwardTitle({commit}, data) {
            commit('UPDATE_AWARD_TITLE', data);
        },
        updateAwardId({commit}, data) {
            commit('UPDATE_AWARD_ID', data)
        }
    },
    mutations: {
        UPDATE_AWARDS_DATA(state, payload) {
            state.awardsData.myAwards = payload;
            state.awardsData.myAwards.sort((a, b) => (a.position) - (b.position))

        },
        ADD_NEW_AWARDS(state, payload) {
            state.awardsData.myAwards.push(payload);
            state.awardsData.myAwards.sort((a, b) => (a.position) - (b.position))
        },
        DELETE_AWARDS(state, payload) {
            state.awardsData.myAwards.splice(payload, 1);
        },
        EDIT_AWARD(state, payload) {
            const updatedAward = state.awardsData.myAwards.find(x => x.id === payload.data.id);
            Object.assign(updatedAward,payload.data);
        },
        UPDATE_AWARD_HTML(state, payload) {
            state.awardsData.html = payload;
        },
        UPDATE_AWARD_TITLE(state, payload) {
            state.awardsData.item_header = payload;
        },
        UPDATE_AWARD_ID(state, payload) {
            state.awardsData.item_id = payload;
        }
    }
}
