<template>
    <div id="modal" v-if="show">
        <div class="modal d-block">
            <div class="modal-dialog modal-dialog-centered" :class="ext">
                <div class="modal-content">
                    <div class="modal-head-cage" v-if="!hideHead">
                        <p class="modal-head">{{modalHead}}</p>
                        <p class="close-text" @click="$emit('close', false)">Close</p>
                    </div>
                    <div class="secondary-close" v-if="secondaryClose">
                        <p class="close-text" @click="$emit('close', false)">Close</p>
                    </div>
                    <div class="head-hr" v-if="!hideHead"></div>
                    <div class="slot-content">
                        <slot/>
                    </div>
                </div>
            </div>
            <div class="close-content" @click="$emit('close', false)"></div>
        </div>
        <div class="modal-backdrop"/>
    </div>
</template>

<script>
    export default {
        name: 'Modal',
        props: ['show', 'ext', 'isCloseButton', 'modalHead', 'hideHead', 'secondaryClose'],
    };
</script>

<style lang="scss" scoped>
    .close-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .modal-dialog {
        z-index: 9999999 !important;
    }

    .modal-backdrop {
        z-index: 101;
    }

    .modal-content {
        position: relative;
    }

    .slot-content {
        padding: 25px;
    }

    .modal-head {
        font-size: 18px;
        color: #000000;
        font-weight: 500;
    }

    .modal-head-cage {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 25px;
    }

    .head-hr {
        border-bottom: 1px solid rgba(196, 196, 196, 0.08);
        width: 100%;
    }

    .close-text {
        font-size: 14px;
        color: #742774;
        cursor: pointer;
        height: 30px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .modal-close-button {
        cursor: pointer;
        font-size: 22px;
        color: #e1251b;
        position: absolute;
        top: 6px;
        right: 6px;
    }

    .modal-dialog--x-large {
        max-width: 1000px;
    }

    .modal-dialog--large {
        max-width: 915px;
    }

    .modal-dialog--medium {
        max-width: 770px;
    }

    .modal-dialog--small {
        max-width: 508px;
    }

    .modal-backdrop {
        opacity: 0.6 !important;
    }

    .secondary-close {
        position: absolute;
        right: 15px;
        top: 15px;
    }
</style>
