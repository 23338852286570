<template>
    <modal :show="showCheckoutModal" refs="checkoutModal" :ext="'modal-dialog--x-large'"
           @close="closeCheckoutModal" :hideHead="true" :secondaryClose="true">
    <checkout-view :fromModal="true"></checkout-view>
    </modal>
</template>
<script>
    import modal from '../../components/atomic/modal.vue';
    import checkoutView from '../../views/website/checkout-page.vue'
    import {mapActions, mapState} from "vuex";
    export default {
        name:'checkoutModal',
        components:{modal, checkoutView},
        methods:{
            ...mapActions({
                updateCheckoutModalShow:'general/updateCheckoutModalShow'
            }),
            closeCheckoutModal(){
             this.updateCheckoutModalShow(false);
            }
        },
        computed: {
            ...mapState({
                showCheckoutModal: (store) => store.general.showCheckoutModal
            })
        },
    }
</script>
<style></style>
