export default {
    namespaced: true,
    state: {
        publicationData: {
            item_header: 'Publication',
            item_id: '',
            html: {},
            id: '',
            publisher: '',
            contributors: '',
            name: '',
            description: '',
            position: 0,
            url: '',
            myPublications: []
        },
    },
    actions: {
        updatePublicationData({commit}, data) {
            commit('UPDATE_PUBLICATION_DATA', data);
        },
        addNewPublications({commit}, data) {
            commit('ADD_NEW_PUBLICATIONS', data);
        },
        deletePublication({commit}, data) {
            commit('DELETE_PUBLICATION', data);
        },
        editPublication({commit}, data) {
            commit('EDIT_PUBLICATION', data);
        },
        updatePublicationHtml({commit}, data) {
            commit('UPDATE_PUBLICATION_HTML', data);
        },
        updatePublicationTitle({commit}, data) {
            commit('UPDATE_PUBLICATION_TITLE', data);
        },
        updatePublicationId({commit}, data) {
            commit('UPDATE_PUBLICATION_ID', data)
        }
    },
    mutations: {
        UPDATE_PUBLICATION_DATA(state, payload) {
            state.publicationData.myPublications = payload;
        },
        ADD_NEW_PUBLICATIONS(state, payload) {
            state.publicationData.myPublications.push(payload);
        },
        DELETE_PUBLICATION(state, payload) {
            state.publicationData.myPublications.splice(payload, 1);
        },
        EDIT_PUBLICATION(state, payload) {
            const updatedPublication = state.publicationData.myPublications.find(x => x.id === payload.data.id);
            Object.assign(updatedPublication, payload.data);
        },
        UPDATE_PUBLICATION_HTML(state, payload) {
            state.publicationData.html = payload;
        },
        UPDATE_PUBLICATION_TITLE(state, payload) {
            state.publicationData.item_header = payload;
        },
        UPDATE_PUBLICATION_ID(state, payload) {
            state.publicationData.item_id = payload;
        }
    }
}
