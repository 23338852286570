<template>
    <section>
        <div class="item-education cage">
            <div v-if="showEducationList">
                <div class="item-input-sub-cage">
                    <div class="item-sub-list-cage" v-for="(education,i) in educationData.myEducations" :key="i">
                        <div>
                            <p class="item-sub-list-text">{{education.name}}</p>
                            <p class="item-sub-list-text-secondary">{{education.department}}</p>
                        </div>
                        <div class="d-flex">
                            <span class="material-icons item-sub-list-create-icon"
                                  @click="editEducations(education)">create</span>
                            <span class="material-icons item-sub-list-delete-icon"
                                  @click="deleteEducations(education)">delete</span>
                        </div>
                    </div>
                </div>
                <div class="item-footer-button-cage">
                    <v-btn class="yellow-button w-100" @click="addEducations">
                        Add New Education
                    </v-btn>
                </div>
            </div>
            <div v-else>
                <div class="item-input-sub-cage">
                    <div class="item-input-cage">
                        <p class="item-input-header">School</p>
                        <input class="primary-input w-100" v-model="educationData.name"
                               @input="changeData"
                               @focusout="isEditing? updateEducation(false) : ''">
                    </div>
                    <div class="item-input-cage">
                        <p class="item-input-header">Field Of Study</p>
                        <input class="primary-input w-100" v-model="educationData.department" @input="changeData"
                               @focusout="isEditing? updateEducation(false) : ''">
                    </div>
                    <div class="item-input-cage">
                        <p class="item-input-header">Degree</p>
                        <input class="primary-input w-100" v-model="educationData.education_grade" @input="changeData"
                               @focusout="isEditing? updateEducation(false) : ''">
                    </div>
                    <div class="item-input-cage">
                        <p class="item-input-header">City</p>
                        <input class="primary-input w-100" v-model="educationData.city" @input="changeData"
                               @focusout="isEditing? updateEducation(false) : ''">
                    </div>
                    <div class="item-input-cage">
                        <p class="item-input-header">County</p>
                        <input class="primary-input w-100" v-model="educationData.country" @input="changeData"
                               @focusout="isEditing? updateEducation(false) : ''">
                    </div>
                    <div class="d-flex flex-row">
                        <div class="item-input-cage mr-2">
                            <p class="item-input-header">Start Date <span class="item-input-optional">(optional)</span>
                            </p>
                            <div class="d-flex flex-row">
                                <input class="primary-input w-100 mr-1" v-model="educationData.start_month"
                                       placeholder="Month" @focusout="isEditing? updateEducation(false) : ''">
                                <input class="primary-input w-100" v-model="educationData.start_year"
                                       placeholder="Year" @focusout="isEditing? updateEducation(false) : ''">
                            </div>
                        </div>
                        <div class="item-input-cage">
                            <p class="item-input-header">End Date <span class="item-input-optional">(optional)</span>
                            </p>
                            <div class="d-flex flex-row">
                                <input class="primary-input w-100 mr-1" v-model="educationData.end_month"
                                       placeholder="Month" @focusout="isEditing? updateEducation(false) : ''">
                                <input class="primary-input w-100" v-model="educationData.end_year"
                                       placeholder="Year" @focusout="isEditing? updateEducation(false) : ''">
                            </div>
                        </div>
                    </div>
                    <div class="item-input-cage d-flex flex-row">
                        <div class="item-inner-cage-half mr-2">
                            <checkbox label="Don't Show" :model="educationData.show_start_date" name="show_start_date"
                                      @isChecked="educationData.show_start_date = $event"
                                      @changeEvent="isEditing? updateEducation(false) : ''"></checkbox>
                            <checkbox label="Only Year" :model="educationData.start_only_year" name="start_only_year"
                                      @isChecked="educationData.start_only_year = $event"
                                      @changeEvent="isEditing? updateEducation(false) : ''"></checkbox>
                        </div>
                        <div class="item-inner-cage-half">
                            <checkbox label="Present" :model="educationData.present" name="present"
                                      @isChecked="educationData.present = $event"
                                      @changeEvent="isEditing? updateEducation(false) : ''"></checkbox>
                            <checkbox label="Don't Show" :model="educationData.show_end_date" name="show_end_date"
                                      @isChecked="educationData.show_end_date = $event"
                                      @changeEvent="isEditing? updateEducation(false) : ''"></checkbox>
                            <checkbox label="Only Year" :model="educationData.end_only_year" name="end_only_year"
                                      @isChecked="educationData.end_only_year = $event"
                                      @changeEvent="isEditing? updateEducation(false) : ''"></checkbox>

                        </div>
                    </div>
                    <div class="item-input-cage">
                        <p class="item-input-header">Grade</p>
                        <input class="primary-input w-100" v-model="educationData.grade" @input="changeData"
                               @focusout="isEditing? updateEducation(false) : ''">
                    </div>
                    <div class="item-input-cage">
                        <p class="item-input-header">Description</p>
                        <textarea class="primary-text-area w-100" rows="3"
                                  v-model="educationData.description" @input="changeData"
                                  @focusout="isEditing? updateEducation(false) : ''"></textarea>
                    </div>
                </div>
                <div class="item-footer-button-cage">
                    <v-btn class="yellow-button w-100" @click="isEditing ? updateEducation(true) : saveNewEducation()">
                        Save
                    </v-btn>
                </div>
            </div>
        </div>
        <v-snackbar v-model="showAddEducationNotification" :timeout="2000" :color="'success'" top>
            Education has been added.
        </v-snackbar>
        <v-snackbar v-model="showUpdateEducationNotification" :timeout="2000" :color="'success'" top>
            Education has been updated.
        </v-snackbar>
        <v-snackbar v-model="showDeleteEducationNotification" :timeout="2000" :color="'success'" top>
            Education has been deleted.
        </v-snackbar>
    </section>
</template>
<script>
    import {mapActions, mapState} from "vuex";
    import checkbox from "@/components/atomic/input/checkbox";
    import itemService from '../../../services/item-data';

    export default {
        name: 'educationItem',
        components: {checkbox},
        data() {
            return {
                showEducationList: true,
                showAddEducationNotification: false,
                showUpdateEducationNotification: false,
                showDeleteEducationNotification: false,
                isEditing: ''
            }
        },
        created(){
            this.showEducationList = this.educationData.myEducations.length > 0;
        },
        computed: {
            ...mapState({
                educationData: (store) => store.education.educationData,
            })
        },
        methods: {
            ...mapActions({
                updateEducationData: 'education/updateEducationData',
                addNewEducation: 'education/addNewEducation',
                deleteEducation: 'education/deleteEducation',
                editEducation: 'education/editEducation',
            }),
            changeData() {
                if (this.isEditing) {
                    const editedObject = {
                        data: this.setEditedObject()
                    };
                    this.editEducation(editedObject);
                }
            },
            editEducations(education) {
                this.isEditing = true;
                this.educationData.id = education.id;
                this.educationData.name = education.name;
                this.educationData.department = education.department;
                this.educationData.description = education.description;
                this.educationData.education_grade = education.education_grade;
                this.educationData.grade = education.grade;
                this.educationData.city = education.city;
                this.educationData.country = education.country;
                this.educationData.start_year = education.start_year;
                this.educationData.start_month = education.start_month;
                this.educationData.end_year = education.end_year;
                this.educationData.end_month = education.end_month;
                this.educationData.start_only_year = education.start_only_year;
                this.educationData.end_only_year = education.end_only_year;
                this.educationData.current = education.current;
                this.showEducationList = false;
            },
            deleteEducations(education) {
                itemService.deleteEducation(education.id).then((deleteResponse) => {
                    console.log('delete resp', deleteResponse);
                    const educationIndex = this.educationData.myEducations.indexOf(education);
                    this.deleteEducation(educationIndex);
                    this.showDeleteEducationNotification = true;
                    this.showEducationList = this.educationData.myEducations.length > 0;
                });
            },
            setEditedObject(){
                const editedObject = {
                        name: this.educationData.name,
                        id: this.educationData.id,
                        department: this.educationData.department,
                        description: this.educationData.description,
                        education_grade: this.educationData.education_grade,
                        grade: this.educationData.grade,
                        position: this.educationData.position,
                        city: this.educationData.city,
                        country: this.educationData.country,
                        start_year: this.educationData.start_year,
                        start_month: this.educationData.start_month,
                        end_year: this.educationData.end_year,
                        end_month: this.educationData.end_month,
                        start_only_year: this.educationData.start_only_year,
                        end_only_year: this.educationData.end_only_year,
                        current: this.educationData.current
                }
                return editedObject;
            },
            addEducations() {
                this.isEditing = false;
                this.educationData.name = '';
                this.educationData.department = '';
                this.educationData.description = '';
                this.educationData.education_grade = '';
                this.educationData.grade = '';
                this.educationData.city = '';
                this.educationData.country = '';
                this.educationData.start_year = '';
                this.educationData.start_month = '';
                this.educationData.end_year = '';
                this.educationData.end_month = '';
                this.educationData.start_only_year = false;
                this.educationData.end_only_year = false;
                this.educationData.current = false;
                this.showEducationList = false;
            },
            updateEducation(goBack) {
                const editedEducation = {
                    education: {
                        name: this.educationData.name,
                        id: this.educationData.id,
                        department: this.educationData.department,
                        description: this.educationData.description,
                        education_grade: this.educationData.education_grade,
                        grade: this.educationData.grade,
                        position: this.educationData.position,
                        city: this.educationData.city,
                        country: this.educationData.country,
                        start_year: this.educationData.start_year,
                        start_month: this.educationData.start_month,
                        end_year: this.educationData.end_year,
                        end_month: this.educationData.end_month,
                        start_only_year: this.educationData.start_only_year,
                        end_only_year: this.educationData.end_only_year,
                        current: this.educationData.current
                    }
                };
                itemService.updateEducation(this.educationData.id, editedEducation).then((updateResponse) => {
                    this.editEducation(updateResponse);
                    this.showUpdateEducationNotification = true;
                    this.showEducationList = goBack;
                })
            },
            saveNewEducation() {
                const newEducation = {
                    education: {
                        name: this.educationData.name,
                        department: this.educationData.department,
                        description: this.educationData.description,
                        education_grade: this.educationData.education_grade,
                        grade: this.educationData.grade,
                        position: this.educationData.position,
                        city: this.educationData.city,
                        country: this.educationData.country,
                        start_year: this.educationData.start_year,
                        start_month: this.educationData.start_month,
                        end_year: this.educationData.end_year,
                        end_month: this.educationData.end_month,
                        start_only_year: this.educationData.start_only_year,
                        end_only_year: this.educationData.end_only_year,
                        current: this.educationData.current
                    }
                };
                itemService.createEducation(newEducation).then((createResponse) => {
                    this.addNewEducation(createResponse.data);
                    this.showAddEducationNotification = true;
                    this.showEducationList = true;
                });
            },
        }
    }
</script>
<style></style>
