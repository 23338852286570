export default {
    namespaced: true,
    state: {
        closingData: {
            item_header: 'Closing and Signature',
            item_id:'',
            html:{},
            closing_name: '',
            closing_title: '',
            id: '',
        },
    },
    actions: {
        updateClosingData({commit}, data) {
            commit('UPDATE_CLOSING_DATA', data);
        },
        editClosing({commit}, data) {
            commit('EDIT_CLOSING', data);
        },
        updateClosingHtml({commit}, data) {
            commit('UPDATE_CLOSING_HTML', data);
        },
        updateClosingTitle({commit}, data) {
            commit('UPDATE_CLOSING_TITLE', data);
        },
        updateClosingId({commit}, data) {
            commit('UPDATE_CLOSING_ID', data)
        }
    },
    mutations: {
        UPDATE_CLOSING_DATA(state, payload) {
            state.closingData.closing_name = payload.closing_name;
            state.closingData.closing_title = payload.closing_title;
            state.closingData.id = payload.id;
        },
        EDIT_CLOSING(state,payload){
            Object.assign(state.closingData,payload.data);
        },
        UPDATE_CLOSING_HTML(state, payload) {
            state.closingData.html = payload;
        },
        UPDATE_CLOSING_TITLE(state, payload) {
            state.closingData.item_header = payload;
        },
        UPDATE_CLOSING_ID(state, payload) {
            state.closingData.item_id = payload;
        }
    }
}
