
export default {
    namespaced: true,
    state: {
        strengthsData: {
            item_header: 'Strengths',
            item_id:'',
            html:{},
            name: '',
            description: '',
            id:'',
            position: 0,
            myStrengths: []

        },
    },
    actions: {
        updateStrengthsData({commit}, data) {
            commit('UPDATE_STRENGTHS_DATA', data);
        },
        addNewStrength({commit}, data) {
            commit('ADD_NEW_STRENGTH', data);
        },
        deleteStrength({commit}, data) {
            commit('DELETE_STRENGTH', data);
        },
        editStrength({commit}, data) {
            commit('EDIT_STRENGTH', data);
        },
        updateStrengthHtml({commit}, data) {
            commit('UPDATE_STRENGTH_HTML', data);
        },
        updateStrengthTitle({commit}, data) {
            commit('UPDATE_STRENGTH_TITLE', data);
        },
        updateStrengthId({commit}, data) {
            commit('UPDATE_STRENGTH_ID', data)
        }
    },
    mutations: {
        UPDATE_STRENGTHS_DATA(state, payload) {
            state.strengthsData.myStrengths = payload;
            state.strengthsData.myStrengths.sort((a, b) => (a.position) - (b.position))
        },
        ADD_NEW_STRENGTH(state, payload) {
            state.strengthsData.myStrengths.push(payload);
            state.strengthsData.myStrengths.sort((a, b) => (a.position) - (b.position))
        },
        DELETE_STRENGTH(state, payload) {
            state.strengthsData.myStrengths.splice(payload,1);
        },
        EDIT_STRENGTH(state, payload) {
            const updatedStrength = state.strengthsData.myStrengths.find(x => x.id === payload.data.id);
            Object.assign(updatedStrength,payload.data);
        },
        UPDATE_STRENGTH_HTML(state, payload) {
            state.strengthsData.html = payload;
        },
        UPDATE_STRENGTH_TITLE(state, payload) {
            state.strengthsData.item_header = payload;
        },
        UPDATE_STRENGTH_ID(state, payload) {
            state.strengthsData.item_id = payload;
        }
    }
}
