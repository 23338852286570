export default {
    namespaced: true,
    state: {
        volunteeringData: {
            item_header: 'Volunteering',
            item_id:'',
            html:{},
            id: '',
            company: '',
            name: '',
            description: '',
            start_year: '',
            start_month: '',
            end_year: '',
            end_month: '',
            current: false,
            position: 0,
            mission: '',
            myVolunteering: []
        },
    },
    actions: {
        updateVolunteeringData({commit}, data) {
            commit('UPDATE_VOLUNTEERING_DATA', data);
        },
        addNewVolunteering({commit}, data) {
            commit('ADD_NEW_VOLUNTEERING', data);
        },
        deleteVolunteer({commit}, data) {
            commit('DELETE_VOLUNTEERING', data);
        },
        editVolunteer({commit}, data) {
            commit('EDIT_VOLUNTEER', data)
        },
        updateVolunteerHtml({commit}, data) {
            commit('UPDATE_VOLUNTEER_HTML', data);
        },
        updateVolunteerTitle({commit}, data) {
            commit('UPDATE_VOLUNTEER_TITLE', data);
        },
        updateVolunteerId({commit}, data) {
            commit('UPDATE_VOLUNTEER_ID', data)
        }
    },
    mutations: {
        UPDATE_VOLUNTEERING_DATA(state, payload) {
            state.volunteeringData.myVolunteering = payload;
        },
        ADD_NEW_VOLUNTEERING(state, payload) {
            state.volunteeringData.myVolunteering.push(payload);
        },
        DELETE_VOLUNTEERING(state, payload) {
            state.volunteeringData.myVolunteering.splice(payload, 1);
        },
        EDIT_VOLUNTEER(state, payload) {
            const updatedVolunteering = state.volunteeringData.myVolunteering.find(x => x.id === payload.data.id);
            Object.assign(updatedVolunteering,payload.data);
        },
        UPDATE_VOLUNTEER_HTML(state, payload) {
            state.volunteeringData.html = payload;
        },
        UPDATE_VOLUNTEER_TITLE(state, payload) {
            state.volunteeringData.item_header = payload;
        },
        UPDATE_VOLUNTEER_ID(state, payload) {
            state.volunteeringData.item_id = payload;
        }
    }
}
