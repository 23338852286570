<template>
    <div class="theme-main-cage" id="element-to-print"
         :style="[{backgroundColor:resumeOptions.backgroundColorModel},{backgroundImage:'url('+resumeOptions.backgroundDecoration.url+')'}]">
        <div ref="headerItem" id="headerItem" :class="{'active-editor':editorList.headerEditor}">
            <div class="mc-theme header-cage mobile-cage-content">
                <div class="mc-theme header-info-cage d-flex align-start flex-column">
                    <div class="w-100">
                        <p class="mc-theme theme-resume-header name-text"
                           @click="openEditor('headerEditor',$event, summaryData, 'full_name','summary')"
                           :class="summaryMenuData.html ? summaryMenuData.html.headerEditor : ''"
                           @focusout="updateItemData($event, summaryData, 'full_name','summary')" spellcheck="false"
                           contenteditable>
                            {{summaryData.full_name}}</p>
                        <div class="d-flex  align-center">
                            <p class="mc-theme title-text">{{currentUserTemplate.title}}</p>
                            <div class="mc-theme light-hr hide-mobile-content"></div>
                        </div>

                    </div>
                </div>
                <img :src="summaryData.image" class="mc-theme img-cage">
            </div>
            <div class="mc-theme communication-area mobile-cage-content"
                 @click="openEditor('communicationEditor', $event, summaryData, 'email','summary')"
                 :class="{'active-editor':editorList.communicationEditor}">
                <div class="d-flex align-items-center" v-if="summaryData.email">
                    <img class="mc-theme communication-icon"
                         src="../../../../assets/custom-icons/themes/max-chandler/mail-icon.svg">
                    <p class="mc-theme communication-text  communication-area-text"
                       :class="summaryMenuData.html ? summaryMenuData.html.communicationEditor : ''"
                       @focusout="updateItemData($event, summaryData, 'email','summary')" spellcheck="false"
                       contenteditable>
                        {{summaryData.email}}</p>
                </div>
                <div class="d-flex align-items-center" v-if="summaryData.phone">
                    <img class="mc-theme communication-icon"
                         src="../../../../assets/custom-icons/themes/max-chandler/phone-icon.svg">
                    <p class="mc-theme communication-text communication-area-text"
                       :class="summaryMenuData.html ? summaryMenuData.html.communicationEditor : ''"
                       @focusout="updateItemData($event, summaryData, 'phone','summary')" spellcheck="false"
                       contenteditable>
                        {{summaryData.phone}}</p>
                </div>
                <div class="d-flex align-items-center" v-if="summaryData.address">
                    <img class="mc-theme communication-icon"
                         src="../../../../assets/custom-icons/themes/max-chandler/location-icon.svg">
                    <p class="mc-theme communication-text communication-area-text"
                       :class="summaryMenuData.html ? summaryMenuData.html.communicationEditor : ''"
                       @focusout="updateItemData($event, summaryData, 'address', 'summary')"
                       spellcheck="false"
                       contenteditable>
                        {{summaryData.address}}</p>
                </div>
                 <text-editor @addPoint="addListPoint()" v-if="editorList.communicationEditor" :id-name="'communication-area-text'"
                             @addClass="updateEditorClass()"></text-editor>
            </div>
            <div class="mc-theme full-cage about-me-area mt-35">
                <div @click="openEditor('summarySectionEditor',$event, summaryMenuData, 'item_header', 'summary')"
                     :class="{'active-editor':editorList.summarySectionEditor}">
                    <p class="mc-theme section-header about-me-header" :style="primaryFontStyle"
                       @focusout="updateItemSectionHeader($event, summaryMenuData)"
                       spellcheck="false" contenteditable>
                        {{summaryMenuData.item_header}}</p>
                </div>
                <div @click="openEditor('profileTextEditor', $event, summaryData, 'summary','summary')"
                     v-if="summaryData.summary"
                     :class="[(editorList.profileTextEditor ?'active-editor':'')]">
                    <p class="mc-theme content-text about-me-text"
                       :class="summaryMenuData.html ? summaryMenuData.html.profileTextEditor : ''"
                       @focusout="updateItemData($event, summaryData, 'summary','summary')"
                       spellcheck="false" contenteditable>{{summaryData.summary.toString()}}</p>
                </div>
                 <text-editor @addPoint="addListPoint()" v-if="editorList.profileHeaderEditor" :id-name="'about-me-header'"
                             @addClass="updateEditorClass()"></text-editor>
                 <text-editor @addPoint="addListPoint()" v-if="editorList.profileTextEditor" :id-name="'about-me-text'"
                             @addClass="updateEditorClass()"></text-editor>
            </div>
        </div>
        <section>
            <div class="mc-theme list-items mobile-cage-content" ref="listItem" id="listItem"
                 v-if="listedMenu.length > 0">
                <div class="mc-theme full-side">
                    <div class="mc-theme full-cage experience-area"
                         :style="{order:listedMenu.find(x => x.name ==='Experience').position}"
                         v-if="experienceData.myExperience.length > 0">
                        <div class="mc-theme section-head-cage" @click="openEditor('experienceSectionEditor',$event, experienceData, 'item_header', 'experience')"
                             :class="{'active-editor':editorList.experienceSectionEditor}">
                            <img class="mc-theme communication-icon"
                                 src="../../../../assets/custom-icons/themes/max-chandler/section-icon.svg">
                            <p class="mc-theme section-header" :style="primaryFontStyle"
                               @focusout="updateItemSectionHeader($event, experienceData)"
                               spellcheck="false" contenteditable>
                                {{experienceData.item_header}}</p>
                        </div>

                        <div class="mc-theme item-data-row" v-for="(experience,i) in experienceData.myExperience"
                             :key="i">
                            <div class="d-flex justify-space-between mb-3">
                                <div>
                                    <div @click="openEditor('experienceTitleEditor',$event, experience, 'title', 'experience')"
                                         :class="{'active-editor':editorList.experienceTitleEditor}">
                                        <p class="mc-theme secondary-head experience-title"
                                           :class="experienceData.html ? experienceData.html.experienceTitleEditor : ''"
                                           @focusout="updateItemData($event, experience, 'title', 'experience')"
                                           spellcheck="false" contenteditable>{{experience.title}}</p>
                                    </div>
                                    <div class="d-flex"
                                         @click="openEditor('experienceSubTitleEditor',$event, experience, 'company', 'experience')"
                                         :class="{'active-editor':editorList.experienceSubTitleEditor}">
                                        <p class="mc-theme content-text mr-1 experience-sub-title"

                                           :class="experienceData.html ? experienceData.html.experienceSubTitleEditor : ''"
                                           @focusout="updateItemData($event, experience, 'company', 'experience')"
                                           spellcheck="false" contenteditable>
                                            {{experience.company}}</p>
                                        <p class="mc-theme content-text mr-1 experience-sub-title"
                                           v-if="experience.country"
                                           :class="experienceData.html ? experienceData.html.experienceSubTitleEditor : ''"
                                        >/</p>
                                        <p class="mc-theme content-text experience-sub-title"
                                           :class="experienceData.html ? experienceData.html.experienceSubTitleEditor : ''"
                                           @focusout="updateItemData($event, experience, 'country', 'experience', 'experienceSubTitle')"
                                           spellcheck="false" contenteditable>{{experience.country}}</p>
                                    </div>
                                </div>
                                <div class="d-flex"
                                     @click="openEditor('experienceDateEditor',$event, experience, 'start_year', 'experience')"
                                     :class="{'active-editor':editorList.experienceDateEditor}">
                                    <p class="mc-theme secondary-head mr-1 experience-date-text" v-if="!experience.show_start_date"
                                       :class="experienceData.html ? experienceData.html.experienceDateEditor : ''"
                                       @focusout="updateItemData($event, experience, 'start_year', 'experience')"
                                       spellcheck="false" contenteditable>
                                        {{experience.start_year}}</p>
                                    <p class="mc-theme secondary-head mr-1 experience-date-text"
                                       v-if="experience.end_year && experience.start_year && !experience.show_start_date && !experience.show_end_date"
                                       :class="experienceData.html ? experienceData.html.experienceDateEditor : ''"
                                    >-</p>
                                    <p class="mc-theme secondary-head experience-date-text" v-if="!experience.show_end_date"
                                       :class="experienceData.html ? experienceData.html.experienceDateEditor : ''"
                                       @focusout="updateItemData($event, experience, 'end_year', 'experience')"
                                       spellcheck="false" contenteditable>
                                        {{experience.end_year}}</p>
                                </div>
                            </div>
                            <div @click="openEditor('experienceDescriptionEditor',$event, experience, 'description', 'experience')"
                                 :class="{'active-editor':editorList.experienceDescriptionEditor}">
                                <p class="mc-theme content-text experience-description-text"
                                   v-if="experience.description"
                                   :class="experienceData.html ? experienceData.html.experienceDescriptionEditor : ''"
                                   @focusout="updateItemData($event, experience, 'description', 'experience')"
                                   spellcheck="false" contenteditable>{{experience.description}}</p>
                            </div>
                        </div>
                         <text-editor @addPoint="addListPoint()" v-if="editorList.experienceTitleEditor"
                                     :id-name="'experience-title'" @addClass="updateEditorClass()"></text-editor>
                         <text-editor @addPoint="addListPoint()" v-if="editorList.experienceSubTitleEditor"
                                     :id-name="'experience-sub-title'"
                                     @addClass="updateEditorClass()"></text-editor>
                         <text-editor @addPoint="addListPoint()" v-if="editorList.experienceDateEditor"
                                     :id-name="'experience-date-text'"
                                     @addClass="updateEditorClass()"></text-editor>
                         <text-editor @addPoint="addListPoint()" v-if="editorList.experienceDescriptionEditor"
                                     :id-name="'experience-description-text'"
                                     @addClass="updateEditorClass()"></text-editor>
                    </div>
                    <div class="mc-theme full-cage education-area"
                         :style="{order:listedMenu.find(x => x.name ==='Education').position}"
                         v-if="educationData.myEducations.length > 0">
                        <div class="mc-theme section-head-cage"  @click="openEditor('educationSectionEditor',$event, educationData, 'item_header', 'education')"
                              :class="{'active-editor':editorList.educationSectionEditor}">
                            <img class="mc-theme communication-icon"
                                 src="../../../../assets/custom-icons/themes/max-chandler/section-icon.svg">
                            <p class="mc-theme section-header" :style="primaryFontStyle"
                               @focusout="updateItemSectionHeader($event, educationData)"
                               spellcheck="false" contenteditable>
                                {{educationData.item_header}}</p>
                        </div>
                        <div class="mc-theme item-data-row" v-for="(education,i) in educationData.myEducations"
                             :key="i">
                            <div class="d-flex justify-space-between mb-3">
                                <div>
                                    <div @click="openEditor('educationTitleEditor',$event, education, 'name', 'education')"
                                         :class="{'active-editor':editorList.educationTitleEditor}">
                                        <p class="mc-theme secondary-head education-title"
                                           :class="educationData.html ? educationData.html.educationTitleEditor : ''"

                                           @focusout="updateItemData($event, education, 'name', 'education')"
                                           spellcheck="false" contenteditable>{{education.name}}</p>
                                    </div>
                                    <div class="d-flex"
                                         @click="openEditor('educationSubTitleEditor',$event, education, 'grade', 'education')"
                                         :class="{'active-editor':editorList.educationSubTitleEditor}">
                                        <p class="mc-theme content-text mr-1 education-sub-title"
                                           :class="educationData.html ? educationData.html.educationSubTitleEditor : ''"

                                           @focusout="updateItemData($event, education, 'grade', 'education')"
                                           spellcheck="false" contenteditable>{{education.grade}}</p>
                                        <p class="mc-theme content-text mr-1 education-sub-title"
                                           v-if="education.grade && education.country"
                                           :class="educationData.html ? educationData.html.educationSubTitleEditor : ''"
                                        >/</p>
                                        <p class="mc-theme content-text education-sub-title"
                                           v-if="education.country"
                                           :class="educationData.html ? educationData.html.educationSubTitleEditor : ''"
                                           @focusout="updateItemData($event, education, 'country', 'education')"
                                           spellcheck="false" contenteditable>{{education.country}}</p>
                                    </div>
                                </div>
                                <div class="d-flex"
                                     @click="openEditor('educationDateEditor', $event, education, 'start_year', 'education')"
                                     :class="{'active-editor':editorList.educationDateEditor}">
                                    <p class="mc-theme secondary-head mr-1 education-date-text" v-if="!education.show_start_date"
                                       :class="educationData.html ? educationData.html.educationDateEditor : ''"
                                       @focusout="updateItemData($event, education, 'start_year', 'education')"
                                       spellcheck="false" contenteditable>{{education.start_year}}</p>
                                    <p class="mc-theme secondary-head mr-1 education-date-text"
                                       v-if="education.start_year && education.end_year && !education.show_start_date && !education.show_end_date"
                                       :class="educationData.html ? educationData.html.educationDateEditor : ''"
                                    >-</p>
                                    <p class="mc-theme secondary-head mr-1 education-date-text" v-if="!education.show_end_date"
                                       :class="educationData.html ? educationData.html.educationDateEditor : ''"
                                       @focusout="updateItemData($event, education, 'end_year', 'education')"
                                       spellcheck="false" contenteditable>{{education.end_year}}</p>
                                </div>
                            </div>
                            <div @click="openEditor('educationDescriptionEditor',$event, education, 'description', 'education')"
                                 :class="{'active-editor':editorList.educationDescriptionEditor}">
                                <p class="mc-theme content-text education-description-text"
                                   v-if="education.description"
                                   :class="educationData.html ? educationData.html.educationDescriptionEditor : ''"
                                   @focusout="updateItemData($event, education, 'description', 'education')"
                                   spellcheck="false" contenteditable>{{education.description}}</p>
                            </div>
                        </div>
                         <text-editor @addPoint="addListPoint()" v-if="editorList.educationTitleEditor"
                                     :id-name="'education-title'" @addClass="updateEditorClass()"></text-editor>
                         <text-editor @addPoint="addListPoint()" v-if="editorList.educationSubTitleEditor"
                                     :id-name="'education-sub-title'" @addClass="updateEditorClass()"></text-editor>
                         <text-editor @addPoint="addListPoint()" v-if="editorList.educationDateEditor"
                                     :id-name="'education-date-text'" @addClass="updateEditorClass()"></text-editor>
                         <text-editor @addPoint="addListPoint()" v-if="editorList.educationDescriptionEditor"
                                     :id-name="'education-description-text'"
                                     @addClass="updateEditorClass()"></text-editor>
                    </div>
                    <div class="mc-theme full-cage project-area"
                         :style="{order:listedMenu.find(x => x.name ==='Projects').position}"
                         v-if="projectsData.myProjects.length > 0">
                        <div class="mc-theme section-head-cage" @click="openEditor('projectSectionEditor',$event, projectsData, 'item_header', 'projects')"
                             :class="{'active-editor':editorList.projectSectionEditor}">
                            <img class="mc-theme communication-icon"
                                 src="../../../../assets/custom-icons/themes/max-chandler/section-icon.svg">
                            <p class="mc-theme section-header" :style="primaryFontStyle"
                               @focusout="updateItemSectionHeader($event, projectsData)"
                               spellcheck="false" contenteditable>
                                {{projectsData.item_header}}</p>
                        </div>
                        <div class="mc-theme item-data-row" v-for="(project,i) in projectsData.myProjects"
                             :key="i">
                            <div class="d-flex justify-space-between mb-3">
                                <div>
                                    <div @click="openEditor('projectTitleEditor',$event, project, 'name', 'projects')"
                                         :class="{'active-editor':editorList.projectTitleEditor}">
                                        <p class="mc-theme secondary-head project-title"
                                           :class="projectsData.html ? projectsData.html.projectTitleEditor : ''"
                                           @focusout="updateItemData($event, project, 'name', 'projects')"
                                           spellcheck="false" contenteditable>{{project.name}}</p>
                                    </div>
                                    <div @click="openEditor('projectSubTitleEditor',$event, project, 'url', 'projects')"
                                         :class="{'active-editor':editorList.projectSubTitleEditor}">
                                        <p class="mc-theme content-text project-sub-title"
                                           :class="projectsData.html ? projectsData.html.projectSubTitleEditor : ''"
                                           @focusout="updateItemData($event, project, 'url', 'projects')"
                                           spellcheck="false" contenteditable>{{project.url}}</p>
                                    </div>
                                </div>
                                <div class="d-flex"
                                     @click="openEditor('projectDateEditor',$event, project, 'start_year', 'projects')"
                                     :class="{'active-editor':editorList.projectDateEditor}">
                                    <p class="mc-theme secondary-head mr-1 project-date-text"
                                       v-if="!project.show_start_date"
                                       :class="projectsData.html ? projectsData.html.projectDateEditor : ''"
                                       @focusout="updateItemData($event, project, 'start_year', 'projects')"
                                       spellcheck="false" contenteditable>{{project.start_year}}</p>
                                    <p class="mc-theme secondary-head mr-1 project-date-text"
                                       v-if="project.end_year && project.start_year && !project.show_start_date && !project.show_end_date"
                                       :class="projectsData.html ? projectsData.html.projectDateEditor : ''">
                                        -</p>
                                    <p class="mc-theme secondary-head mr-1 project-date-text"
                                       v-if="!project.show_end_date"
                                       :class="projectsData.html ? projectsData.html.projectDateEditor : ''"
                                       @focusout="updateItemData($event, project, 'end_year', 'projects')"
                                       spellcheck="false" contenteditable>{{project.end_year}}</p>
                                </div>
                            </div>
                            <div @click="openEditor('projectDescriptionEditor',$event, project, 'description', 'projects')"
                                 :class="{'active-editor':editorList.projectDescriptionEditor}">
                                <p class="mc-theme content-text project-description-text" v-if="project.description"
                                   :class="projectsData.html ? projectsData.html.projectDescriptionEditor : ''"
                                   @focusout="updateItemData($event, project, 'description', 'projects')"
                                   spellcheck="false" contenteditable>{{project.description}}</p>
                            </div>
                        </div>
                         <text-editor @addPoint="addListPoint()" v-if="editorList.projectTitleEditor"
                                     :id-name="'project-title'" @addClass="updateEditorClass()"></text-editor>
                         <text-editor @addPoint="addListPoint()" v-if="editorList.projectSubTitleEditor"
                                     :id-name="'project-sub-title'" @addClass="updateEditorClass()"></text-editor>
                         <text-editor @addPoint="addListPoint()" v-if="editorList.projectDateEditor"
                                     :id-name="'project-date-text'" @addClass="updateEditorClass()"></text-editor>
                         <text-editor @addPoint="addListPoint()" v-if="editorList.projectDescriptionEditor"
                                     :id-name="'project-description-text'"
                                     @addClass="updateEditorClass()"></text-editor>
                    </div>
                    <div class="mc-theme full-cage publications-area"
                         :style="{order:listedMenu.find(x => x.name ==='Publications').position}"
                         v-if="publicationData.myPublications.length > 0">
                        <div class="mc-theme section-head-cage" @click="openEditor('publicationSectionEditor',$event, publicationData, 'item_header', 'publications')"
                             :class="{'active-editor':editorList.publicationSectionEditor}">
                            <img class="mc-theme communication-icon"
                                 src="../../../../assets/custom-icons/themes/max-chandler/section-icon.svg">
                            <p class="mc-theme section-header" :style="primaryFontStyle"
                               @focusout="updateItemSectionHeader($event, publicationData)"
                               spellcheck="false" contenteditable>
                                {{publicationData.item_header}}</p>
                        </div>
                        <div class="mc-theme item-data-row"
                             v-for="(publication,i) in publicationData.myPublications"
                             :key="i">
                            <div class="d-flex justify-space-between mb-3">
                                <div>
                                    <div @click="openEditor('publicationTitleEditor',$event, publication, 'name', 'publications')"
                                         :class="{'active-editor':editorList.publicationTitleEditor}">
                                        <p class="mc-theme secondary-head publication-title"
                                           :class="publicationData.html ? publicationData.html.publicationTitleEditor : ''"
                                           @focusout="updateItemData($event, publication, 'name', 'publications')"
                                           spellcheck="false" contenteditable>{{publication.name}}</p>
                                    </div>
                                    <div @click="openEditor('publicationSubTitleEditor', $event, publication, 'publisher', 'publications')"
                                         :class="{'active-editor':editorList.publicationSubTitleEditor}">
                                        <p class="mc-theme content-text publication-sub-title"
                                           :class="publicationData.html ? publicationData.html.publicationSubTitleEditor : ''"
                                           @focusout="updateItemData($event, publication, 'publisher', 'publications')"
                                           spellcheck="false" contenteditable>{{publication.publisher}}</p>
                                        <p class="mc-theme content-text publication-sub-title"
                                           :class="publicationData.html ? publicationData.html.publicationSubTitleEditor : ''"
                                           @focusout="updateItemData($event, publication, 'contributors', 'publications')"
                                           spellcheck="false" contenteditable>{{publication.contributors}}</p>
                                        <p class="mc-theme content-text publication-sub-title"
                                           :class="publicationData.html ? publicationData.html.publicationSubTitleEditor : ''"
                                           @focusout="updateItemData($event, publication, 'url', 'publications')"
                                           spellcheck="false" contenteditable>{{publication.url}}</p>
                                    </div>
                                </div>
                            </div>
                            <div @click="openEditor('publicationDescriptionEditor',$event, publication, 'description', 'publications')"
                                 :class="{'active-editor':editorList.publicationDescriptionEditor}">
                                <p class="mc-theme content-text publication-description-text"
                                   v-if="publication.description"
                                   :class="publicationData.html ? publicationData.html.publicationDescriptionEditor : ''"
                                   @focusout="updateItemData($event, publication, 'description', 'publications')"
                                   spellcheck="false" contenteditable>{{publication.description}}</p>
                            </div>
                        </div>
                         <text-editor @addPoint="addListPoint()" v-if="editorList.publicationTitleEditor"
                                     :id-name="'publication-title'" @addClass="updateEditorClass()"></text-editor>
                         <text-editor @addPoint="addListPoint()" v-if="editorList.publicationSubTitleEditor"
                                     :id-name="'publication-sub-title'"
                                     @addClass="updateEditorClass()"></text-editor>
                         <text-editor @addPoint="addListPoint()" v-if="editorList.publicationDescriptionEditor"
                                     :id-name="'publication-description-text'"
                                     @addClass="updateEditorClass()"></text-editor>
                    </div>
                    <div class="mc-theme full-cage my-time-area"
                         :style="{order:listedMenu.find(x => x.name ==='My Time').position}"
                         v-if="myTimeData.myTime.length > 0">
                        <div class="mc-theme section-head-cage" @click="openEditor('myTimeSectionEditor',$event, myTimeData, 'item_header', 'myTime')"
                             :class="{'active-editor':editorList.myTimeSectionEditor}">
                            <img class="mc-theme communication-icon"
                                 src="../../../../assets/custom-icons/themes/max-chandler/section-icon.svg">
                            <p class="mc-theme section-header" :style="primaryFontStyle"
                               @focusout="updateItemSectionHeader($event, myTimeData)"
                               spellcheck="false" contenteditable>
                                {{myTimeData.item_header}}</p>
                        </div>
                        <div style="height: 35px">
                            <pm-stacked-chart style="height: 35px"
                                              :my-time-data="myTimeData.myTime"></pm-stacked-chart>
                        </div>
                        <div class="mc-theme item-data-row" v-for="(myTime,i) in myTimeData.myTime"
                             :key="i">
                            <div class="d-flex flex-row align-items-center">
                                <p class="mc-theme my-time-rate" :id="'my-time-rate-'+ i"></p>
                                <div @click="openEditor('myTimeTitleEditor',$event, myTime, 'name', 'myTime')"
                                     :class="{'active-editor':editorList.myTimeTitleEditor}">
                                    <p class="mc-theme secondary-head my-time-title"
                                       :class="myTimeData.html ? myTimeData.html.myTimeTitleEditor : ''"
                                       @focusout="updateItemData($event, myTime, 'name', 'myTime')"
                                       spellcheck="false" contenteditable>{{myTime.name}}</p>
                                </div>
                            </div>
                        </div>
                         <text-editor @addPoint="addListPoint()" v-if="editorList.myTimeTitleEditor" @addClass="updateEditorClass()"
                                     :id-name="'my-time-title'"></text-editor>
                    </div>
                    <div class="mc-theme full-cage motto-area"
                         :style="{order:listedMenu.find(x => x.name ==='Mottos').position}"
                         v-if="mottoData.myMottos.length > 0">
                        <div  class="mc-theme section-head-cage" @click="openEditor('mottoSectionEditor',$event, mottoData, 'item_header', 'motto')"
                             :class="{'active-editor':editorList.mottoSectionEditor}">
                            <img class="mc-theme communication-icon"
                                 src="../../../../assets/custom-icons/themes/max-chandler/section-icon.svg">
                            <p class="mc-theme section-header" :style="primaryFontStyle"
                               @focusout="updateItemSectionHeader($event, mottoData)"
                               spellcheck="false" contenteditable>
                                {{mottoData.item_header}}</p>
                        </div>
                        <div class="mc-theme item-data-row" v-for="(motto,i) in mottoData.myMottos"
                             :key="i">
                            <div @click="openEditor('mottoTitleEditor',$event, motto, 'description', 'motto')"
                                 :class="{'active-editor':editorList.mottoTitleEditor}">
                                <p class="mc-theme secondary-head"
                                   :class="mottoData.html ? mottoData.html.mottoTitleEditor : ''"
                                   @focusout="updateItemData($event, motto, 'description', 'motto')"
                                   spellcheck="false" contenteditable>{{motto.description}}</p>
                            </div>
                            <div @click="openEditor('mottoTextEditor',$event, motto, 'author', 'motto')"
                                 :class="{'active-editor':editorList.mottoTextEditor}">
                                <p class="mc-theme content-text motto-text"
                                   :class="mottoData.html ? mottoData.html.mottoTextEditor : ''"
                                   @focusout="updateItemData($event, motto, 'author', 'motto')"
                                   contenteditable>{{motto.author}}</p>
                            </div>
                        </div>
                         <text-editor @addPoint="addListPoint()" v-if="editorList.mottoTitleEditor" @addClass="updateEditorClass()"
                                     :id-name="'motto-title'"></text-editor>
                         <text-editor @addPoint="addListPoint()" v-if="editorList.mottoTextEditor" @addClass="updateEditorClass()"
                                     :id-name="'motto-text'"></text-editor>
                    </div>
                    <div class="mc-theme full-cage volunteer-area"
                         :style="{order:listedMenu.find(x => x.name ==='Volunteering').position}"
                         v-if="volunteeringData.myVolunteering.length > 0">
                        <div class="mc-theme section-head-cage" @click="openEditor('volunteerSectionEditor',$event, volunteeringData, 'item_header', 'volunteering')"
                             :class="{'active-editor':editorList.volunteerSectionEditor}">
                            <img class="mc-theme communication-icon"
                                 src="../../../../assets/custom-icons/themes/max-chandler/section-icon.svg">
                            <p class="mc-theme section-header" :style="primaryFontStyle"
                               @focusout="updateItemSectionHeader($event, volunteeringData)"
                               spellcheck="false" contenteditable>
                                {{volunteeringData.item_header}}</p>
                        </div>
                        <div class="mc-theme item-data-row" v-for="(volunteering,i) in volunteeringData.myVolunteering"
                             :key="i">
                            <div class="d-flex justify-space-between mb-3">
                                <div>
                                    <div @click="openEditor('volunteeringTitleEditor',$event, volunteering, 'company', 'volunteering')"
                                         :class="{'active-editor':editorList.volunteeringTitleEditor}">
                                        <p class="mc-theme secondary-head volunteering-title"
                                           :class="volunteeringData.html ? volunteeringData.html.volunteeringTitleEditor : ''"
                                           @focusout="updateItemData($event, volunteering, 'company', 'volunteering')"
                                           spellcheck="false" contenteditable>{{volunteering.company}}</p>
                                    </div>
                                    <div @click="openEditor('volunteeringSubTitleEditor',$event, volunteering, 'mission', 'volunteering')"
                                         :class="{'active-editor':editorList.volunteeringSubTitleEditor}">
                                        <p class="mc-theme content-text volunteering-sub-title"
                                           :class="volunteeringData.html ? volunteeringData.html.volunteeringSubTitleEditor : ''"

                                           @focusout="updateItemData($event, volunteering, 'mission', 'volunteering')"
                                           spellcheck="false" contenteditable>{{volunteering.mission}}</p>
                                    </div>
                                </div>
                                <div class="d-flex"
                                     @click="openEditor('volunteeringDateEditor', $event, volunteering, 'start_year', 'volunteering')"
                                     :class="{'active-editor':editorList.volunteeringDateEditor}">
                                    <p class="mc-theme secondary-head mr-1 volunteering-date-text"
                                       :class="volunteeringData.html ? volunteeringData.html.volunteeringDateEditor : ''"

                                       @focusout="updateItemData($event, volunteering, 'start_year', 'volunteering')"
                                       spellcheck="false" contenteditable>{{volunteering.start_year}}</p>
                                    <p class="mc-theme secondary-head mr-1 volunteering-date-text"
                                       :class="volunteeringData.html ? volunteeringData.html.volunteeringDateEditor : ''"
                                    >-</p>
                                    <p class="mc-theme secondary-head mr-1 volunteering-date-text"
                                       :class="volunteeringData.html ? volunteeringData.html.volunteeringDateEditor : ''"

                                       @focusout="updateItemData($event, volunteering, 'end_year', 'volunteering')"
                                       spellcheck="false" contenteditable>{{volunteering.end_year}}</p>
                                </div>
                            </div>
                            <div @click="openEditor('volunteeringDescriptionEditor', $event, volunteering, 'description', 'volunteering')"
                                 :class="{'active-editor':editorList.volunteeringDescriptionEditor}">
                                <p class="mc-theme content-text volunteering-description-text"
                                   v-if="volunteering.description"
                                   :class="volunteeringData.html ? volunteeringData.html.volunteeringDescriptionEditor : ''"
                                   @focusout="updateItemData($event, volunteering, 'description', 'volunteering')"
                                   spellcheck="false" contenteditable>{{volunteering.description}}</p>
                            </div>
                        </div>
                         <text-editor @addPoint="addListPoint()" v-if="editorList.volunteeringTitleEditor" @addClass="updateEditorClass()"
                                     :id-name="'volunteering-title'"></text-editor>
                         <text-editor @addPoint="addListPoint()" v-if="editorList.volunteeringSubTitleEditor" @addClass="updateEditorClass()"
                                     :id-name="'volunteering-sub-title'"></text-editor>
                         <text-editor @addPoint="addListPoint()" v-if="editorList.volunteeringDateEditor" @addClass="updateEditorClass()"
                                     :id-name="'volunteering-date-text'"></text-editor>
                         <text-editor @addPoint="addListPoint()" v-if="editorList.volunteeringDescriptionEditor" @addClass="updateEditorClass()"
                                     :id-name="'volunteering-description-text'"></text-editor>
                    </div>
                </div>
                <div class="mc-theme half-side">
                    <div class="mc-theme strength-area mgb-30" v-if="strengthsData.myStrengths.length > 0"
                         :style="{order:listedMenu.find(x => x.name ==='Strengths').position}">
                        <div class="mc-theme section-head-cage" @click="openEditor('strengthSectionEditor',$event, strengthsData, 'item_header', 'strength')"
                             :class="{'active-editor':editorList.strengthSectionEditor}">
                            <img class="mc-theme communication-icon"
                                 src="../../../../assets/custom-icons/themes/max-chandler/section-icon.svg">
                            <p class="mc-theme section-header strength-header" :style="primaryFontStyle"
                               @focusout="updateItemSectionHeader($event, strengthsData)"
                               spellcheck="false" contenteditable>
                                {{strengthsData.item_header}}</p>
                        </div>
                        <div class="mc-theme item-data-row" v-for="(strength,i) in strengthsData.myStrengths" :key="i">
                            <div class="d-flex align-items-center"
                                 @click="openEditor('strengthTextEditor',$event, strength, 'name', 'strength')"
                                 :class="{'active-editor':editorList.strengthTextEditor}">
                                <p class="mc-theme secondary-content-text strength-text w-100"
                                   :class="strengthsData.html ? strengthsData.html.strengthTextEditor : ''"
                                   @focusout="updateItemData($event, strength, 'name', 'strength')"
                                   spellcheck="false" contenteditable>{{strength.name}}</p>
                            </div>
                            <div @click="openEditor('strengthDescriptionEditor',$event, strength, 'description', 'strength')"
                                 :class="{'active-editor':editorList.strengthDescriptionEditor}">
                                <p class="mc-theme content-text strength-description-text w-100"
                                   :class="strengthsData.html ? strengthsData.html.strengthDescriptionEditor : ''"
                                   @focusout="updateItemData($event, strength, 'description', 'strength')"
                                   spellcheck="false" contenteditable>{{strength.description}}</p>
                            </div>
                        </div>
                         <text-editor @addPoint="addListPoint()" v-if="editorList.strengthHeaderEditor" @addClass="updateEditorClass()"
                                     :id-name="'strength-header'"></text-editor>
                         <text-editor @addPoint="addListPoint()" v-if="editorList.strengthTextEditor" @addClass="updateEditorClass()"
                                     :id-name="'strength-text'">
                        </text-editor>
                         <text-editor @addPoint="addListPoint()" v-if="editorList.strengthDescriptionEditor" @addClass="updateEditorClass()"
                                     :id-name="'strength-description-text'">
                        </text-editor>
                    </div>
                    <div class="mc-theme hobbies-area mgb-30" v-if="hobbiesData.myHobbies.length > 0"
                         :style="{order:listedMenu.find(x => x.name ==='Hobbies').position}">
                        <div class="mc-theme section-head-cage" @click="openEditor('hobbiesSectionEditor',$event, hobbiesData, 'item_header', 'hobby')"
                             :class="{'active-editor':editorList.hobbiesSectionEditor}">
                            <img class="mc-theme communication-icon"
                                 src="../../../../assets/custom-icons/themes/max-chandler/section-icon.svg">
                            <p class="mc-theme section-header hobby-header " :style="primaryFontStyle"
                               @focusout="updateItemSectionHeader($event, hobbiesData)"
                               spellcheck="false" contenteditable>
                                {{hobbiesData.item_header}}</p>
                        </div>
                        <div class="mc-theme  item-data-row" v-for="(hobby,i) in hobbiesData.myHobbies" :key="i">
                            <div class="d-flex align-items-center mc-theme grey-row"
                                 @click="openEditor('hobbyTitleEditor',$event, hobby, 'name', 'hobby')"
                                 :class="{'active-editor':editorList.hobbyTitleEditor}">
                                <span class="material-icons hobbies-icon mr-2">{{hobby.icon}}</span>
                                <p class="mc-theme content-text hobby-title w-100"
                                   :class="hobbiesData.html ? hobbiesData.html.hobbyTitleEditor : ''"
                                   @focusout="updateItemData($event, hobby, 'name', 'hobby')"
                                   spellcheck="false" contenteditable>{{hobby.name}}</p>
                            </div>
                        </div>
                         <text-editor @addPoint="addListPoint()" v-if="editorList.hobbyTitleEditor" @addClass="updateEditorClass()"
                                     :id-name="'hobby-title'"></text-editor>
                    </div>
                    <div class="mc-theme languages-are mgb-30" v-if="languagesData.myLanguages.length > 0"
                         :style="{order:listedMenu.find(x => x.name ==='Language Skills').position}">
                        <div class="mc-theme section-head-cage" @click="openEditor('languageSectionEditor',$event, languagesData, 'item_header', 'language')"
                             :class="{'active-editor':editorList.languageSectionEditor}">
                            <img class="mc-theme communication-icon"
                                 src="../../../../assets/custom-icons/themes/max-chandler/section-icon.svg">
                            <p class="mc-theme section-header align-end hobby-header" :style="primaryFontStyle"
                               @focusout="updateItemSectionHeader($event, languagesData)"
                               spellcheck="false" contenteditable>
                                {{languagesData.item_header}}</p>
                        </div>
                        <div class="mc-theme item-data-row" v-for="(language,i) in languagesData.myLanguages" :key="i">
                            <div class="d-flex align-center justify-content-between">
                                <div class="d-flex align-center"
                                     @click="openEditor('languageTitleEditor',$event, language, 'name', 'language')"
                                     :class="{'active-editor':editorList.languageTitleEditor}">
                                    <p class="mc-theme secondary-content-text t-red language-title w-100"
                                       :class="languagesData.html ? languagesData.html.languageTitleEditor : ''"
                                       @focusout="updateItemData($event, language, 'name', 'language')"
                                       spellcheck="false" contenteditable>{{language.name}}</p>
                                </div>
                                <v-progress-linear class="w-100 mb-2 mt-1 mw-64"
                                                   height="4"
                                                   color="#000000"
                                                   :value="rateNumber(language.rate)"></v-progress-linear>
                            </div>
                            <div class="d-flex align-items-center" v-if="language.information || language.success_point"
                                 @click="openEditor('languageInfoEditor',$event, language, 'information', 'language')"
                                 :class="{'active-editor':editorList.languageInfoEditor}">
                                <p class="mc-theme content-text language-info-text"
                                   :class="languagesData.html ? languagesData.html.languageInfoEditor : ''"
                                   @focusout="updateItemData($event, language, 'information', 'language')"
                                   spellcheck="false" contenteditable>{{language.information}}</p>
                                <p class="mc-theme content-text language-info-text ml-1"
                                   :class="languagesData.html ? languagesData.html.languageInfoEditor : ''"
                                   @focusout="updateItemData($event, language, 'success_point', 'language')"
                                   spellcheck="false" contenteditable>{{language.success_point}}</p>
                            </div>
                        </div>
                         <text-editor @addPoint="addListPoint()" v-if="editorList.languageInfoEditor" @addClass="updateEditorClass()"
                                     :id-name="'language-info-text'"></text-editor>
                         <text-editor @addPoint="addListPoint()" v-if="editorList.languageTitleEditor" @addClass="updateEditorClass()"
                                     :id-name="'language-title'"></text-editor>
                    </div>
                    <div class="mc-theme technical-skills-area mgb-30" v-if="technicalSkillsData.mySkills.length > 0"
                         :style="{order:listedMenu.find(x => x.name ==='Technical Skills').position}">
                        <div class="mc-theme section-head-cage" @click="openEditor('technicalSkillSectionEditor',$event, technicalSkillsData, 'item_header', 'technicalSkill')"
                             :class="{'active-editor':editorList.technicalSkillSectionEditor}">
                            <img class="mc-theme communication-icon"
                                 src="../../../../assets/custom-icons/themes/max-chandler/section-icon.svg">
                            <p class="mc-theme section-header hobby-header" :style="primaryFontStyle"
                               @focusout="updateItemSectionHeader($event, technicalSkillsData)"
                               spellcheck="false" contenteditable>
                                {{technicalSkillsData.item_header}}</p>
                        </div>
                        <div class="mc-theme item-data-row" v-for="(skill,i) in technicalSkillsData.mySkills" :key="i">
                            <div @click="openEditor('skillTitleEditor',$event, skill, 'name', 'technicalSkill')"
                                 :class="{'active-editor':editorList.skillTitleEditor}">
                                <p class="mc-theme secondary-content-text skill-title-text w-100"
                                   :class="technicalSkillsData.html ? technicalSkillsData.html.skillTitleEditor : ''"
                                   @focusout="updateItemData($event, skill, 'name', 'technicalSkill')"
                                   spellcheck="false" contenteditable>{{skill.name}}</p>
                            </div>
                            <v-progress-linear class="w-100 mb-2 mt-1"
                                               height="4"
                                               color="#000000"
                                               :value="rateNumber(skill.rate)"
                            ></v-progress-linear>
                        </div>
                         <text-editor @addPoint="addListPoint()" v-if="editorList.skillTitleEditor" @addClass="updateEditorClass()"
                                     :id-name="'skill-title-text'"></text-editor>
                    </div>
                    <div class="mc-theme awards-area mgb-30" v-if="awardsData.myAwards.length > 0"
                         :style="{order:listedMenu.find(x => x.name ==='Awards').position}">
                        <div class="mc-theme section-head-cage" @click="openEditor('awardSectionEditor',$event, awardsData, 'item_header', 'awards')"
                             :class="{'active-editor':editorList.awardSectionEditor}">
                            <img class="mc-theme communication-icon"
                                 src="../../../../assets/custom-icons/themes/max-chandler/section-icon.svg">
                            <p class="mc-theme section-header  hobby-header" :style="primaryFontStyle"
                               @focusout="updateItemSectionHeader($event, awardsData)"
                               spellcheck="false" contenteditable>
                                {{awardsData.item_header}}</p>
                        </div>
                        <div class="mc-theme item-data-row" v-for="(award,i) in awardsData.myAwards" :key="i">
                            <div @click="openEditor('awardTitleEditor',$event, award, 'name', 'awards')"
                                 :class="{'active-editor':editorList.awardTitleEditor}">
                                <p class="mc-theme secondary-content-text award-title w-100"
                                   :class="awardsData.html ? awardsData.html.awardTitleEditor : ''"
                                   @focusout="updateItemData($event, award, 'name', 'awards')"
                                   spellcheck="false" contenteditable>{{award.name}}</p>
                            </div>
                            <div class="mc-theme content-text-cage">
                                <div class="d-flex"
                                     @click="openEditor('awardDateEditor',$event, award, 'issue_month', 'awards')"
                                     :class="{'active-editor':editorList.awardDateEditor}">
                                    <p class="mc-theme content-text award-date-text"
                                       :class="awardsData.html ? awardsData.html.awardDateEditor : ''"
                                       @focusout="updateItemData($event, award, 'issue_month', 'awards')"
                                       spellcheck="false" contenteditable>{{award.issue_month}}</p>
                                    <p class="mc-theme content-text award-date-text"
                                       :class="awardsData.html ? awardsData.html.awardDateEditor : ''"
                                    >/</p>
                                    <p class="mc-theme content-text award-date-text"
                                       :class="awardsData.html ? awardsData.html.awardDateEditor : ''"
                                       @focusout="updateItemData($event, award, 'issue_year', 'awards')"
                                       spellcheck="false" contenteditable>{{award.issue_year}}</p>
                                </div>
                                <div class="d-flex"
                                     @click="openEditor('awardTextEditor',$event, award, 'information', 'awards')"
                                     :class="{'active-editor':editorList.awardTextEditor}">
                                    <p class="mc-theme content-text award-info-text w-100"
                                       :class="awardsData.html ? awardsData.html.awardTextEditor : ''"
                                       @focusout="updateItemData($event, award, 'information', 'awards')"
                                       spellcheck="false" contenteditable>{{award.description}}</p>
                                </div>
                            </div>
                        </div>
                         <text-editor @addPoint="addListPoint()" v-if="editorList.awardTitleEditor" @addClass="updateEditorClass()"
                                     :id-name="'award-title'"></text-editor>
                         <text-editor @addPoint="addListPoint()" v-if="editorList.awardDateEditor" @addClass="updateEditorClass()"
                                     :id-name="'award-date-text'"></text-editor>
                         <text-editor @addPoint="addListPoint()" v-if="editorList.awardTextEditor" @addClass="updateEditorClass()"
                                     :id-name="'award-info-text'"></text-editor>
                    </div>
                    <div class="mc-theme certificate-area mgb-30" v-if="certificateData.myCertificates.length > 0"
                         :style="{order:listedMenu.find(x => x.name ==='Certifications').position}">
                        <div class="mc-theme section-head-cage" @click="openEditor('certificateSectionEditor',$event, certificateData, 'item_header', 'certificates')"
                             :class="{'active-editor':editorList.certificateSectionEditor}">
                            <img class="mc-theme communication-icon"
                                 src="../../../../assets/custom-icons/themes/max-chandler/section-icon.svg">
                            <p class="mc-theme section-header hobby-header" :style="primaryFontStyle"
                               @focusout="updateItemSectionHeader($event, certificateData)"
                               spellcheck="false" contenteditable>
                                {{certificateData.item_header}}</p>
                        </div>
                        <div class="mc-theme item-data-row" v-for="(certificate,i) in certificateData.myCertificates"
                             :key="i">
                            <div @click="openEditor('certificateTitleEditor',$event, certificate , 'name', 'certificates')"
                                 :class="{'active-editor':editorList.certificateTitleEditor}">
                                <p class="mc-theme secondary-content-text certificate-title w-100"
                                   :class="certificateData.html ? certificateData.html.certificateTitleEditor : ''"
                                   @focusout="updateItemData($event, certificate , 'name', 'certificates')"
                                   spellcheck="false" contenteditable>{{certificate.name}}</p>
                            </div>
                            <div class="mc-theme content-text-cage">
                              <div class="d-flex"
                                   @click="openEditor('certificateCompanyEditor' ,$event, certificate, 'company', 'certificates')"
                                   :class="{'active-editor':editorList.certificateCompanyEditor}">
                                <p class="mc-theme content-text certificate-company"
                                   :class="certificateData.html ? certificateData.html.certificateCompanyEditor : ''"
                                   @focusout="updateItemData($event, certificate, 'company', 'certificates')"
                                   spellcheck="false" contenteditable>{{ certificate.company }}</p>
                              </div>
                                <div class="d-flex"
                                     @click="openEditor('certificateDateEditor', $event, certificate, 'valid_month', 'certificates')"
                                     :class="{'active-editor':editorList.certificateDateEditor}">
                                    <p class="mc-theme content-text certificate-date-text"
                                       :class="certificateData.html ? certificateData.html.certificateDateEditor : ''"
                                       @focusout="updateItemData($event, certificate, 'valid_month', 'certificates')"
                                       spellcheck="false" contenteditable>{{certificate.valid_month}}</p>
                                    <p class="mc-theme content-text certificate-date-text"
                                       :class="certificateData.html ? certificateData.html.certificateDateEditor : ''"
                                    >/</p>
                                    <p class="mc-theme content-text certificate-date-text"
                                       :class="certificateData.html ? certificateData.html.certificateDateEditor : ''"
                                       @focusout="updateItemData($event, certificate, 'valid_year', 'certificates')"
                                       spellcheck="false" contenteditable>
                                        {{certificate.valid_year}}</p>
                                </div>
                                <div class="d-flex"
                                     @click="openEditor('certificateTextEditor' ,$event, certificate, 'link', 'certificates')"
                                     :class="{'active-editor':editorList.certificateTextEditor}">
                                    <p class="mc-theme content-text certificate-text"
                                       :class="certificateData.html ? certificateData.html.certificateTextEditor : ''"
                                       @focusout="updateItemData($event, certificate, 'link', 'certificates')"
                                       spellcheck="false" contenteditable>{{certificate.link}}</p>
                                </div>
                            </div>
                        </div>
                      <text-editor @addPoint="addListPoint()" v-if="editorList.certificateCompanyEditor"
                                   @addClass="updateEditorClass()"
                                   :id-name="'certificate-company'"></text-editor>
                         <text-editor @addPoint="addListPoint()" v-if="editorList.certificateTitleEditor" @addClass="updateEditorClass()"
                                     :id-name="'certificate-title'"></text-editor>
                         <text-editor @addPoint="addListPoint()" v-if="editorList.certificateDateEditor" @addClass="updateEditorClass()"
                                     :id-name="'certificate-date-text'"></text-editor>
                         <text-editor @addPoint="addListPoint()" v-if="editorList.certificateTextEditor" @addClass="updateEditorClass()"
                                     :id-name="'certificate-text'"></text-editor>
                    </div>
                    <div class="mc-theme references-area mgb-30" v-if="referencesData.myReferences.length > 0"
                         :style="{order:listedMenu.find(x => x.name ==='References').position}">
                        <div class="mc-theme section-head-cage" @click="openEditor('referencesSectionEditor',$event, referencesData, 'item_header', 'references')"
                             :class="{'active-editor':editorList.referencesSectionEditor}">
                            <img class="mc-theme communication-icon"
                                 src="../../../../assets/custom-icons/themes/max-chandler/section-icon.svg">
                            <p class="mc-theme section-header hobby-header" :style="primaryFontStyle"
                               @focusout="updateItemSectionHeader($event, referencesData)"
                               spellcheck="false" contenteditable>
                                {{referencesData.item_header}}</p>
                        </div>
                        <div class="mc-theme item-data-row" v-for="(reference,i) in referencesData.myReferences"
                             :key="i">
                            <div @click="openEditor('referenceTitleEditor', $event, reference , 'name', 'references')"
                                 :class="{'active-editor':editorList.referenceTitleEditor}">
                                <p class="mc-theme secondary-content-text w-100 reference-title"
                                   :class="referencesData.html ? referencesData.html.referenceTitleEditor : ''"
                                   @focusout="updateItemData($event, reference , 'name', 'references')"
                                   spellcheck="false" contenteditable>{{reference.name}}</p>
                                <p class="mc-theme secondary-content-text w-100 reference-title mb-2"
                                   :class="referencesData.html ? referencesData.html.referenceTitleEditor : ''"
                                   @focusout="updateItemData($event, reference , 'title', 'references')"
                                   spellcheck="false" contenteditable>{{reference.title}}</p>
                            </div>
                            <div class="mc-theme content-text-cage">
                                <div class="d-flex align-center" v-if="reference.email"
                                     @click="openEditor('referenceEmailEditor', $event, reference , 'email', 'references')"
                                     :class="{'active-editor':editorList.referenceEmailEditor}">
                                    <p class="mc-theme content-text reference-email-text mr-1"
                                       :class="referencesData.html ? referencesData.html.referenceEmailEditor : ''">
                                        E:</p>
                                    <p class="mc-theme content-text reference-email-text"
                                       :class="referencesData.html ? referencesData.html.referenceEmailEditor : ''"
                                       @focusout="updateItemData($event, reference , 'email', 'references')"
                                       spellcheck="false" contenteditable>{{reference.email}}</p>
                                </div>
                                <div class="d-flex align-center" v-if="reference.phone"
                                     @click="openEditor('referencePhoneEditor', $event, reference , 'phone', 'references')"
                                     :class="{'active-editor':editorList.referencePhoneEditor}">
                                    <p class="mc-theme content-text reference-phone-text mr-1"
                                       :class="referencesData.html ? referencesData.html.referencePhoneEditor : ''">
                                        P: </p>
                                    <p class="mc-theme content-text reference-phone-text"
                                       :class="referencesData.html ? referencesData.html.referencePhoneEditor : ''"
                                       @focusout="updateItemData($event, reference , 'phone', 'references')"
                                       spellcheck="false" contenteditable>{{reference.phone}}</p>
                                </div>
                            </div>
                        </div>
                         <text-editor @addPoint="addListPoint()" v-if="editorList.referenceTitleEditor" @addClass="updateEditorClass()"
                                     :id-name="'reference-title'"></text-editor>
                         <text-editor @addPoint="addListPoint()" v-if="editorList.referenceEmailEditor" @addClass="updateEditorClass()"
                                     :id-name="'reference-email-text'"></text-editor>
                         <text-editor @addPoint="addListPoint()" v-if="editorList.referencePhoneEditor" @addClass="updateEditorClass()"
                                     :id-name="'reference-phone-text'"></text-editor>
                    </div>
                    <div class="mc-theme social-area mgb-30" v-if="socialData.mySocialData.length > 0"
                         :style="{order:listedMenu.find(x => x.name ==='See My Social Media').position}">
                        <div class="mc-theme section-head-cage" @click="openEditor('socialSectionEditor',$event, socialData, 'item_header', 'social')"
                             :class="{'active-editor':editorList.socialSectionEditor}">
                            <img class="mc-theme communication-icon"
                                 src="../../../../assets/custom-icons/themes/max-chandler/section-icon.svg">
                            <p class="mc-theme section-header hobby-header" :style="primaryFontStyle"
                               @focusout="updateItemSectionHeader($event, socialData)"
                               spellcheck="false" contenteditable>
                                {{socialData.item_header}}</p>
                        </div>
                        <div class="mc-theme item-data-row" v-for="(social,i) in socialData.mySocialData" :key="i">
                            <div class="d-flex align-items-center"
                                 @click="openEditor('socialUrlEditor',$event, social, 'url', 'social')"
                                 v-if="social.url !==''" :class="{'active-editor':editorList.socialUrlEditor}">
                                <img class="social-icon"
                                     :src="require(`@/assets/custom-icons/social-media-icons/s-4/${social.account}.svg`)"
                                     alt="">
                                <p class="mc-theme content-text social-url w-100"
                                   :class="socialData.html ? socialData.html.socialUrlEditor : ''"
                                   @focusout="updateItemData($event, social, 'url', 'social')"
                                   spellcheck="false" contenteditable>{{social.url}}</p>
                            </div>
                        </div>
                         <text-editor @addPoint="addListPoint()" v-if="editorList.socialUrlEditor" @addClass="updateEditorClass()"
                                     :id-name="'social-url'"></text-editor>
                    </div>
                    <div class="mc-theme conferences-area mgb-30" v-if="conferencesData.myConferences.length > 0"
                         :style="{order:listedMenu.find(x => x.name ==='Conferences').position}">
                        <div class="mc-theme section-head-cage" @click="openEditor('conferenceSectionEditor',$event, conferencesData, 'item_header', 'conferences')"
                             :class="{'active-editor':editorList.conferenceSectionEditor}">
                            <img class="mc-theme communication-icon"
                                 src="../../../../assets/custom-icons/themes/max-chandler/section-icon.svg">
                            <p class="mc-theme section-header hobby-header" :style="primaryFontStyle"
                               @focusout="updateItemSectionHeader($event, conferencesData)"
                               spellcheck="false" contenteditable>
                                {{conferencesData.item_header}}</p>
                        </div>
                        <div class="mc-theme item-data-row" v-for="(conference,i) in conferencesData.myConferences"
                             :key="i">
                            <div @click="openEditor('conferenceTitleEditor', $event, conference , 'name', 'conferences')"
                                 :class="{'active-editor':editorList.conferenceTitleEditor}">
                                <p class="mc-theme secondary-content-text w-100 conference-title"
                                   :class="conferencesData.html ? conferencesData.html.conferenceTitleEditor : ''"
                                   @focusout="updateItemData($event, conference , 'name', 'conferences')"
                                   spellcheck="false" contenteditable>{{conference.name}}</p>
                            </div>
                            <div class="mc-theme content-text-cage">
                                <div class="d-flex"
                                     @click="openEditor('conferenceCompanyEditor', $event, conference , 'company', 'conferences')"
                                     :class="{'active-editor':editorList.conferenceCompanyEditor}">
                                    <p class="mc-theme content-text conference-company-text"
                                       :class="conferencesData.html ? conferencesData.html.conferenceCompanyEditor : ''"
                                       @focusout="updateItemData($event, conference , 'company', 'conferences')"
                                       spellcheck="false" contenteditable>{{conference.company}}</p>
                                </div>
                            </div>
                        </div>
                         <text-editor @addPoint="addListPoint()" v-if="editorList.conferenceTitleEditor" @addClass="updateEditorClass()"
                                     :id-name="'conference-title'"></text-editor>
                         <text-editor @addPoint="addListPoint()" v-if="editorList.conferenceCompanyEditor" @addClass="updateEditorClass()"
                                     :id-name="'conference-company-text'"></text-editor>
                    </div>
                </div>

            </div>
        </section>

        <div class="text-editor-bg" v-if="showEditorBg" @click="closeEditor"></div>
        <div class="text-editor-close-bg" v-if="showEditorBg" @click="closeEditor"></div>
    </div>
</template>
<script>
    import textEditor from '../../../atomic/text-editor.vue'
    import {mapState} from "vuex";
    import themeUpdater from '../../../../mixins/theme-update-helper';
    import PmStackedChart from "../peter-miller/components/pm-stacked-chart";

    export default {
        name: 'maxChandlerTheme',
        components: {PmStackedChart, textEditor},
        data() {
            return {
                pageCounter: 1,
            }
        },
        mixins: [themeUpdater],
        created() {
            this.updatePageHeight();
        },
        methods: {
            rateNumber(rate) {
                const newRate = parseInt(rate, 10) * 20;
                return newRate;
            },
        },
        computed: {
            ...mapState({
                resumeOptions: (store) => store.resume.resumeOptions,
                listedMenu: (store) => store.resume.listedMenu,
            }),
            headerStyle() {
                return {
                    color: this.resumeOptions.textColorModel,
                    fontFamily: this.resumeOptions.fontFamily
                }
            },
            primaryFontStyle() {
                return {
                    color: this.resumeOptions.textColorModel,
                    fontFamily: this.resumeOptions.fontFamily
                }
            },
        }
    }
</script>
<style lang="scss" scoped>
    .theme-main-cage {
        padding: 24px 40px;
        // @media (max-width: 420px) {
        //     padding: 15px 10px;
        // }
    }

    .mc-theme {
        position: relative;

        &.section-head-cage {
            margin-bottom: 6px;
            display: flex;
            align-items: center;
        }

        &.full-cage {
            margin-bottom: 35px;
            transition: width 2s, height 2s, transform 2s;
        }

        &.list-items {
            display: flex;
            flex-direction: row;
        }

        &.full-side {
            width: 55%;
            padding-right: 35px;
            display: flex;
            flex-direction: column;
            border-right: 1px solid #000000;
            // @media (max-width: 420px) {
            //     width: 100%;
            //     border-right: none;
            // }
        }

        &.half-side {
            width: 45%;
            padding-left: 35px;
            display: flex;
            flex-direction: column;
            // @media (max-width: 420px) {
            //     width: 100%;
            //     padding-left: 0;
            // }
        }

        &.item-data-row {
            margin: 10px auto;
        }

        &.theme-resume-header {
            font-size: 40px;
            color: #010101;
            text-align: start;
            font-weight: 500;
        }

        &.title-text {
            font-size: 17px;
            color: #010101;
            font-weight: 300;
            text-align: start;
        }

        &.communication-text {
            font-size: 9px;
            color: #000000;
            font-weight: 300;
            text-align: start;
        }

        &.header-cage {
            display: flex;
            flex-direction: row;
        }

        &.light-hr {
            height: 5px;
            width: 76px;
            background: #E9BE1F;
            margin-right: 25px;
            margin-left: 25px;
        }

        &.communication-area {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border: 1px solid #010101;
            padding: 10px 17px;
            // @media (max-width: 420px) {
            //     padding: 10px;
            // }
        }

        &.communication-icon {
            margin-right: 7px;
        }

        &.header-info-cage {
            width: 375px;
            border-right: 1px solid #010101;
            padding-bottom: 20px;
            // @media (max-width: 420px) {
            //     width: 100%;
            //     border-right: none;
            // }
        }

        &.section-header, &.section-head {
            font-size: 20px;
            font-weight: 500;
            color: #010101;
            text-align: start;
        }

        &.secondary-content-text, &.secondary-head {
            font-size: 10.5px;
            font-weight: 500;
            text-align: start;
            color: #010101;
        }

        &.content-text {
            font-size: 8.5px;
            color: #000000;
            font-weight: 300;
            text-align: start;
        }

        &.img-cage {
            width: 95px;
            height: 95px;
            display: block;
            margin: 0 auto;
            // @media (max-width: 420px) {
            //     margin-bottom: 15px;
            // }
        }

        &.my-time-rate {
            font-size: 12px;
            color: #ffffff;
            border-radius: 50%;
            padding: 5px;
            width: 25px;
            height: 25px;
            margin-right: 10px;
        }
    }

    .mt-35 {
        margin-top: 35px;
    }

    .mgb-30 {
        margin-bottom: 30px;
    }

    .hobbies-icon {
        color: #010101;
        font-size: 15px;
    }

    .mw-64 {
        max-width: 80px;
    }
</style>