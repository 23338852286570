<template>
    <section>
        <div class="theme-selector cage">
            <div class="theme-selector template" v-for="(template,i) in themeArray" :key="i"
                 :class="{'active':template.id === currentUserTemplate.theme_id}"
                 @click="setActive(template)">
                <div class="theme-selector image-cage">
                    <img class="theme-selector preview-image"
                         :class="{'disabled': template.paid_version && !userInfo.isUserPro}" :src="template.url" alt=""
                         crossorigin="anonymous">
                    <img class="theme-selector lock-icon" v-if="template.paid_version && !userInfo.isUserPro"
                         src="../../../../assets/custom-icons/lock-icon.svg">
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    import {mapActions, mapState} from "vuex";
    import resumeService from "../../../../services/resume";
    import themeUpdater from "../../../../mixins/theme-update-helper.js";

    export default {
        name: 'themeSelector',
        created() {
            console.log(this.resume)
        },
        methods: {
            ...mapActions({
                updateSelectedTheme: 'resume/updateSelectedTheme',
                updateCurrentUserTemplateThemeId: 'resume/updateCurrentUserTemplateThemeId',
                updateCheckoutModalShow: 'general/updateCheckoutModalShow'
            }),
            setActive(selectedTemplate) {
                if (selectedTemplate.usageType === 'pro' && !this.userInfo.isUserPro) {
                    this.updateCheckoutModalShow(true);
                } else {
                    this.themeArray.forEach(template => {
                        if (template.id === selectedTemplate.id) {
                            this.updateSelectedTheme(selectedTemplate.id);
                            template.isActive = true;
                            // Send service

                            const templateModel = {
                                theme_id: selectedTemplate.id
                            };
                            resumeService.updateHeader(templateModel).then(() => {
                                this.updateCurrentUserTemplateThemeId(selectedTemplate.id);
                                this.updatePageHeight();
                            });
                        } else {
                            template.isActive = false;
                        }
                    })
                }
            }
        },
        mixins: [themeUpdater],

        computed: {
            ...mapState({
                userInfo: (store) => store.authorization.userInfo,
                currentUserTemplate: (store) => store.resume.currentUserTemplate,
                themeArray: (store) => store.resume.themeArray,
                resume: (store) => store.resume,
            }),
        },
        watch: {}
    }
</script>
<style lang="scss" scoped>
    .theme-selector {
        &.cage {
            max-width: 821px;
            width: 100%;
            padding: 20px 14px 20px 22px;
            background: #ffffff !important;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            max-height: 572px;
            overflow: scroll;
        }

        &.active {
            outline: 2px solid #742774;
            border-radius: 4px;
        }

        &.image-cage {
            position: relative;
        }

        &.lock-icon {
            position: absolute;
            bottom: 20px;
            right: 20px;
        }

        &.template {
            margin-right: 8px;
            margin-bottom: 15px;

            > img {
                width: 181px;
                height: 257px;
            }
        }

        &.preview-image {
            width: 183px;
            height: 258px;
        }
    }

    .disabled {
        filter: opacity(40%) !important;
    }
</style>
