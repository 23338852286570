<template>
        <div class="d-flex">
                <input :id="'checkbox-' + name" class="checkbox-custom" :name="name" type="checkbox"  v-model="model" @input="$emit('isChecked',$event.target.checked)" @change="$emit('changeEvent')">
                <label :for="'checkbox-' + name" class="checkbox-custom-label">{{label}}</label>
        </div>
</template>
<script>
    export default {
        name: 'customCheckbox',
        props: ['label','model','name']
    }
</script>
<style lang="scss" scoped>
</style>
