<template>
    <section>
        <div :class="{'container': !fromModal}">
            <v-stepper value="3" v-model="currentStep" class="mt-12">
                <v-stepper-header>
                    <v-stepper-step step="1" color="#36C36D" :complete="selectedPlan !== ''" @click="currentStep = 1">
                        Select Pricing
                    </v-stepper-step>
                    <v-divider class="stepper-divider" :class="{'step-done':selectedPlan !== ''}"></v-divider>

                    <v-stepper-step step="2" color="#36C36D" :complete="billingStep" disabled>Billing
                        Details
                    </v-stepper-step>
                    <v-divider class="stepper-divider"></v-divider>

                    <v-stepper-step step="3" disabled>Payment Method</v-stepper-step>
                </v-stepper-header>
                <v-stepper-content step="1">
                    <plan-selector @setSelectedPlan="selectedPlan=$event" :selected-plan="selectedPlan"></plan-selector>
                </v-stepper-content>
            </v-stepper>
        </div>
        <div class="navigate-controller cage" :class="[{'position-static': fromModal}, {'p-0': fromModal}]">
            <div class="container justify-content-end d-flex align-items-center">
                <a class="back-button">Back</a>
                <v-btn class="yellow-button" :disabled="selectedPlan === ''">Next</v-btn>
            </div>
        </div>
    </section>
</template>
<script>
    import planSelector from '../../components/website/checkout-page/plan-selector.vue'

    export default {
        name: 'checkoutPage',
        components: {planSelector},
        props: ['fromModal'],
        data() {
            return {
                currentStep: 1,
                selectedPlan: '',
                billingStep: false,
            }
        }
    }
</script>
<style lang="scss" scoped>
    .stepper-divider {
        margin-top: 20px !important;
    }

    .navigate-controller {
        &.cage {
            border-top: 2px solid #F1F7FF;
            position: fixed;
            left: 0;
            bottom: 0;
            width: 100%;
            padding: 19px 0;
            background: #fff;
        }
    }

    .back-button {
        text-decoration: none;
        cursor: pointer;
        font-family: Roboto;
        font-size: 14px;
        color: #9EA2B1;
        margin-right: 25px;
    }

    .step-done {
        border-color: #30DB37 !important;
    }

    ::v-deep .v-stepper, .v-stepper, .v-stepper__header {
        box-shadow: none !important;
    }

    ::v-deep .v-stepper__step {
        flex-direction: column-reverse !important;
    }

    ::v-deep .v-stepper__label {
        margin-bottom: 20px;
    }

    ::v-deep .v-stepper__header {
        height: auto !important;
    }

    ::v-deep .v-divider {
        border-width: medium !important;
    }

    ::v-deep .v-stepper__step {
        bottom: 10px;
    }

    @media only screen and (max-width: 959px) {
        ::v-deep .v-stepper__step {
            bottom: -10px !important;
        }
    }
</style>
