<template>
    <section>
        <div class="main">
      <!-- <div class="header">
        <div
          class="container d-flex flex-row align-items-center justify-content-between mt-20"
        >
          <div>
            <img
              class="anycv-logo"
              src="../../assets/img/anycv-logo.svg"
              alt="anycv-logo"
            /> 
          </div>
          <div class="d-flex flex-row">
            <a class="header-item hide-mobile-content">Home</a>
            <a class="header-item hide-mobile-content" href="#features"
              >Features</a
            >
            <a
              class="header-item hide-mobile-content"
              href="https://cv.anycv.com/website/pricing/"
              >Pricing</a
            >
            <a
              class="header-item hide-mobile-content"
              href="https://www.anycv.com/blog"
              >Blog</a
            >
          </div>
          <div class="d-flex align-items-center">
            <div
              id="login-cage"
              class="align-items-center"
              style="display: flex"
            >
              <a
                class="header-item login-button"
                id="login-button"
                href="https://cv.anycv.com/website/auth"
                >Login</a
              >
              <a
                class="primary-button"
                href="https://cv.anycv.com/website/create-resume"
                >Start</a
              >
            </div>
            <div
              id="user-cage"
              class="align-items-center justify-content-center"
              style="display: none"
            >
              <div
                id="dropdown-cage"
                class="dropdown dropdown-menu-center"
                style="display: none"
              >
                <a
                  href="#"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span id="user-name"></span>
                  <img
                    id="dropdown-icon"
                    class="arrow-down-icon"
                    src="../../assets/img/arrow-down.svg"
                    alt=""
                  />
                </a>
                <div class="dropdown-menu" aria-labelledby="user-name">
                  <a
                    class="dropdown-item"
                    href="https://cv.anycv.com/website/create-resume"
                    >Create Resume</a
                  >
                  <a
                    class="dropdown-item"
                    href="https://cv.anycv.com/website/resume/"
                    >My Resumes</a
                  >
                  <a class="dropdown-item" onclick="signOut()">Sign Out</a>
                </div>
              </div>
              <p></p>
            </div>
          </div>
        </div>
      </div> -->
      <div class="content">
        <div class="header-content">
          <div class="d-flex justify-content-center flex-column container">
            <p class="content-head">
              Get your next career move off to a great start by
              <span class="purple-text" style="display: inline;"> building your perfect resume.</span>
            </p>
            <p class="content-head-text">
              There's no need for effort for a resume design that stands out.
              Simply select one of our nest templates we created for you and
              follow the instructions.
            </p>
           <div class="button-container"> <a
              class="primary-button create-resume-button"
              href="/website/create-resume"
              >Create a Job Winning Resume!</a
            >
            OR
                        <a
              class="primary-button create-resume-button"
              href="/website/create-resume?type=cl"
              > Cover Letter!</a
            >
        </div>
          </div>
          <div><img class="w-100 h-100" src="../../assets/img/dashboard-main.svg" /></div>
        </div>
        <div class="feature-content container">
          <p class="purple-header text-center">FEATURES</p>
          <p class="feature-content-description">Simple.Easy.Fast.</p>
          <div class="feature-list-cage mobile-cage-content">
            <div class="mobile-cage-content" style="text-align: center;">
              <img class="feature-icon" src="../../assets/img/drag-drop.svg" />
              <p class="feature-head">Drag &amp; Drop</p>
              <p class="feature-description">
                Create your CV easily with drag &amp; drop in a few minutes.
              </p>
            </div>
            <div class="mobile-cage-content" style="text-align: center;">
              <img class="feature-icon" src="../../assets/img/palette.svg" />
              <p class="feature-head">Unlimited Colors</p>
              <p class="feature-description">
                The color palette allows you to choose any color you like for
                the theme and font you wish to include in your CV.
              </p>
            </div>
            <div class="mobile-cage-content" style="text-align: center;">
              <img class="feature-icon" src="../../assets/img/file-selector.svg" />
              <p class="feature-head">Professional Themes</p>
              <p class="feature-description">
                Select one of our professional themes to amaze everyone.
              </p>
            </div>
            <div class="mobile-cage-content" style="text-align: center;">
              <img class="feature-icon" src="../../assets/img/color-eye.svg" />
              <p class="feature-head">Create Your Own Layout</p>
              <p class="feature-description">
                Feel free to customize the layout if you think it will work
                better for you.
              </p>
            </div>
          </div>
        </div>
        <div class="feature-templates-cage" id="features">
          <div
            class="container d-flex justify-content-around mobile-cage-content"
          >
            <div>
              <p class="purple-header">FEATURES</p>
              <p class="feature-template-head">Templates</p>
              <p class="feature-template-text">
                “With the perfect template, you can create the perfect resume.”
              </p>
            </div>
            <div class="template-list mobile-cage-content">
              <div data-aos="fade-up" class="aos-init">
                <img
                  class="template-img"
                  src="../../assets/img/blue-template.png"
                  alt="AnyCvTemplates"
                />
                <p class="template-name">Minimal</p>
                <a class="create-template-button"
                href="https://cv.anycv.com/website/create-resume"
                  >Create <img src="../../assets/img/arrow-purple-right.svg"
                /></a>
              </div>
              <div data-aos="fade-up" class="aos-init">
                <img
                  class="template-img"
                  src="../../assets/img/white-template.png"
                  alt="AnyCvTemplates"
                />
                <p class="template-name">Simple</p>
                <a class="create-template-button"
                href="https://cv.anycv.com/website/create-resume"
                  >Create <img src="../../assets/img/arrow-purple-right.svg"
                /></a>
              </div>
              <div data-aos="fade-up" class="aos-init">
                <img
                  class="template-img"
                  src="../../assets/img/green-template.png"
                  alt="AnyCvTemplates"
                />
                <p class="template-name">Business</p>
                <a class="create-template-button"
                href="https://cv.anycv.com/website/create-resume"
                  >Create <img src="../../assets/img/arrow-purple-right.svg"
                /></a>
              </div>
            </div>
          </div>
        </div>
        <div class="how-it-works-content container" id="howWorks">
          <p class="purple-header text-center">HOW IT WORKS?</p>
          <p class="how-it-works-secondary-head">Step by step guide</p>
          <div class="d-flex justify-content-evenly mobile-cage-content">
            <div class="d-flex flex-column align-items-center">
              <img class="step-icon" src="../../assets/img/b1.svg" />
              <p class="how-it-works-text">Select your template</p>
              <p class="how-it-works-sub-text">
                Choose the best-looking template, change the colors and font.
              </p>
            </div>
            <div class="d-flex flex-column align-items-center">
              <img class="step-icon" src="../../assets/img/b2.svg" />
              <p class="how-it-works-text">
                Create Your Resume, Cover Letter 
              </p>
              <p class="how-it-works-sub-text">
                Make sure to include information about your education, skills,
                languages, hobbies, etc. Don’t forget to include a cover letter
                as it will increase your chances of finding a
                good job.
              </p>
            </div>
            <div class="d-flex flex-column align-items-center">
              <img class="step-icon" src="../../assets/img/b3.svg" />
              <p class="how-it-works-text">
                Export Or Share
              </p>
              <p class="how-it-works-sub-text">
                You can download your resume as a PDF file or just share your
                personal link with recruiters. You can also get feedback from
                other AnyCV users.
              </p>
            </div>
          </div>
        </div>
        <div class="feature-drag-drop mobile-cage-content">
          <div class="drag-drop-image-cage aos-init" data-aos="fade-right">
            
            <img class="purple-icon" src="../../assets/img/drag-drop.svg" />
            <p class="purple-header">FEATURES</p>
            <p class="drag-drop-head">Drag &amp; Drop</p>
          </div>
          <div class="mobile-cage-content">
           
            <p class="drag-drop-text">
              The Drag and Drop feature lets you easily add the elements you
              want to your resume and share it with others. And it's completely
              free.
            </p>
            <div class="d-flex justify-content-between mobile-cage-content">
              <div class="d-flex flex-column">
                <div class="d-flex flex-row align-items-center">
                  <img class="orange-icon" src="../../assets/img/share-cv.svg" />
                  <p class="drag-drop-secondary-head">Share CV</p>
                </div>
                <p class="drag-drop-description">
                  Share your resume with others today and get feedback.
                </p>
              </div>
              <div class="d-flex flex-column">
                <div class="d-flex flex-row align-items-center">
                  <img class="blue-icon" src="../../assets/img/unlimited.svg" />
                  <p class="drag-drop-secondary-head">Various Elements</p>
                </div>
                <p class="drag-drop-description">
                  You can choose from a wide variety of resume elements.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="experience-content" id="experience">
          <div class="container">
            <div class="d-flex flex-column">
              <p class="purple-header text-center">EXPERIENCE</p>
              <p class="experience-header text-center">
                The best resumes are made with features that make them stand
                out.
              </p>
              <p class="experience-text">
                There are plenty of features available with AnyCV's resume
                builder. No other CV builder can compare.
              </p>
            </div>
            <div class="d-flex justify-content-evenly mobile-cage-content">
              <div class="mobile-cage-content">
               
                <p class="experience-content-text">
                  You can interact with industry-friendly themes and use color
                  palettes to design your resume optimally. If you wish, you can
                  create your own color palette, or use color palettes available
                  online.
                </p>
                <p class="experience-content-text">
                  Color palettes are designed and presented to you optimally for
                  firms and individuals. You can also add your color palette
                  with a link from the outside.
                </p>
                <a class="create-experience-button"
                href="https://cv.anycv.com/website/create-resume"
                  >Create Now<img src="../../assets/img/arrow-yellow-right.svg"
                /></a>
              </div>
                
          <div class="drag-drop-image-cage aos-init" data-aos="fade-left">
            <img
                  class="purple-icon"
                  src="../../assets/img/palette.svg"
                  alt="palette"
                />
                <p class="experience-content-head">
                  With numerous themes and enhanced color options, create your
                  perfect resume!
                </p>
          </div>
            </div>
            <div
              class="d-flex justify-content-evenly mt-130 mobile-cage-content"
            >
              <div>
             
                       
          <div class="drag-drop-image-cage aos-init" data-aos="fade-right">
            <img
                  class="purple-icon"
                  src="../../assets/img/share-purple.svg"
                  alt="share"
                />
                <p class="experience-content-head">
                  Employers can access your resume with one click! Simply save
                  it or send the URL to them.
                </p>
          </div>
              </div>
              <div class="mobile-cage-content">
               
                <p class="experience-content-text">
                  You can download your CV in PDF format or you can use URL
                  sharing option to impress recruiters. Your personal link
                  includes your resume.
                </p>
                <div class="d-flex flex-row justify-content-between">
                  <div>
                    <div class="d-flex flex-row align-items-center mb-20">
                      <img src="../../assets/img/done-icon.svg" alt="done-icon" />
                      <p class="share-description">PDF Download</p>
                    </div>
                  </div>
                  <div>
                    <div class="d-flex flex-row align-items-center mb-20">
                      <img src="../../assets/img/done-icon.svg" alt="done-icon" />
                      <p class="share-description">Cover Letter</p>
                    </div>
                    <div class="d-flex flex-row align-items-center mb-20">
                      <img src="../../assets/img/done-icon.svg" alt="done-icon" />
                      <p class="share-description">Customized URL</p>
                    </div>
                  </div>
                </div>
                <a class="create-experience-button"
                href="https://cv.anycv.com/website/create-resume"
                  >Create Now<img src="../../assets/img/arrow-yellow-right.svg"
                /></a>
              </div>
            </div>
          </div>
        </div>
        <div class="testimonials-content container">
          <p class="purple-header text-center">TESTIMONIALS</p>
          <p class="testimonials-head">What do our users think about AnyCV?</p>
          <div class="d-flex justify-content-evenly mobile-cage-content">
            <div class="comment-cage mt-30">
              <img
                class="comment-icon"
                src="../../assets/img/comment-icon.svg"
                alt="comment-icon"
              />
              <p class="comment-text">
                I wrote my resume with anycv.com and got the job I dreamed
                of.<span class="comment-bold">
                  I wouldn't have done it without them.</span
                >
                Thanks!
              </p>
              <div class="d-flex flex-row cage-bottom">
                <img
                  class="comment-author-img"
                  src="../../assets/img/justin.png"
                  alt="justin"
                />
                <div>
                  <p class="comment-author-name">Justin</p>
                  <p class="comment-author-firm">Founder, YZ</p>
                </div>
              </div>
            </div>
            <div class="comment-cage">
              <img
                class="comment-icon"
                src="../../assets/img/comment-icon.svg"
                alt="comment-icon"
              />
              <p class="comment-text">
                The features and flexibility of anycv.com have set it above
                other alternatives.<span class="comment-bold">
                  Users can easily express themselves with its features and
                  options.</span
                >
              </p>
              <div class="d-flex flex-row cage-bottom">
                <img
                  class="comment-author-img"
                  src="../../assets/img/lauren.png"
                  alt="lauren"
                />
                <div>
                  <p class="comment-author-name">Lauren</p>
                  <p class="comment-author-firm">Founder, Nimble</p>
                </div>
              </div>
            </div>
            <div class="comment-cage mt-30">
              <img
                class="comment-icon"
                src="../../assets/img/comment-icon.svg"
                alt="comment-icon"
              />
              <p class="comment-text">
                It’s so nice to have everything in one place. AnyCV.com offers
                users everything in a package.
                <span class="comment-bold"
                  >You can build your CV, cover letter and share it
                  with just a mouse click.</span
                >
              </p>
              <br>
              <div class="d-flex flex-row cage-bottom">
                <img
                  class="comment-author-img"
                  src="../../assets/img/steve.png"
                  alt="steve"
                />
                <div>
                  <p class="comment-author-name">Steve</p>
                  <p class="comment-author-firm">Co-founder, Gem</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer container">
          <div class="footer-container">
            <img src="../../assets/img/anycv-logo.svg" alt="anycv-logo" />
            <div class="d-flex">
              <a
                class="footer-text mr-2"
                href="https://anycv.com/Privacy.html"
                >Privacy Policy</a
              >
              <a
                class="footer-text mr-2"
                href="https://anycv.com/Terms.html"
                >Terms Of Service</a
              >
              <a
                class="footer-text mr-2"
                href="https://anycv.com/Refund.html"
                >Refund Policy</a
              >
              <a
                class="footer-text"
                href="https://anycv.com/support.html"
                >Support</a
              >
            </div>
            <div style="margin-top: 10px !important;display: flex;">
              <a
                class="social-icon-cage"
                href="https://www.instagram.com/anycvcom"
                target="_blank"
              >
                <img
                  class="social-icons"
                  src="../../assets/img/instagram-icon.svg"
                  alt="instagram"
                />
              </a>
              <a
                class="social-icon-cage"
                href="https://twitter.com/anycvcom"
                target="_blank"
              >
                <img
                  class="social-icons"
                  src="../../assets/img/twitter-icon.svg"
                  alt="twitter"
                />
              </a>
              <a
                class="social-icon-cage"
                href="https://www.linkedin.com/company/anycv"
                target="_blank"
              >
                <img
                  class="social-icons"
                  src="../../assets/img/linkedin-icon.svg"
                  alt="linkedin"
                />
              </a>
              <a
                class="social-icon-cage"
                href="https://www.facebook.com/anycvcom"
                target="_blank"
              >
                <img
                  class="social-icons"
                  src="../../assets/img/facebook-icon.svg"
                  alt="facebook"
                />
              </a>
            </div>
            <p class="copyright-text">All rights reserved. AnyCV.com - 2023</p>
          </div>
        </div>
      </div>
    
    </div>
        <checkout-modal></checkout-modal>
        <full-loader v-if="showLoader"></full-loader>
    </section>
</template>
<script>
    // import resumeStartSelector from '../../components/website/create-resume-page/start-selector.vue'
    // import industrySelector from '../../components/website/create-resume-page/industry-selector.vue'
    // import themeSelector from '../../components/website/create-resume-page/theme-selector.vue'
    // import linkedinImport from '../../components/website/create-resume-page/linkedin-import.vue'
    // import navigateController from '../../components/website/create-resume-page/navigate-controller.vue'
     import checkoutModal from '../../components/modal-views/checkout-modal.vue'
    // import fullLoader from '../../components/atomic/full-loader.vue'
    import resumeService from '../../services/resume'
    import {mapActions} from "vuex";

    export default {
        name: 'createResumePage',
        components: {
            // resumeStartSelector,
            // industrySelector,
            // themeSelector,
            // navigateController,
             checkoutModal,
            // linkedinImport,
            // fullLoader
        },
        data() {
            return {
                currentTab: 'startTypeSelector',
                startType: '',
                selectedJob: {
                    mainCategory: 1,
                    subCategory: '',
                    experienceId: 1,
                },
                linkedinUrl: '',
                selectedTheme: 1,
                showLoader: false,
            }
        },
        created(){

            
            if(this.$route.query.type=='cl')this.createCoverLetter();
            window.AOS.init({
            duration: 1200,
          });

          onElementHeightChange(document.body, function () {
            window.AOS.refresh();
          });

          function onElementHeightChange(elm, callback) {
            var lastHeight = elm.clientHeight;
            var newHeight;

            (function run() {
              newHeight = elm.clientHeight;
              if (lastHeight !== newHeight) callback();
              lastHeight = newHeight;

              if (elm.onElementHeightChangeTimer) {
                clearTimeout(elm.onElementHeightChangeTimer);
              }

              elm.onElementHeightChangeTimer = setTimeout(run, 200);
            })();
          }
        },
        methods: {
            ...mapActions({
                updateResumeList: 'resume/updateResumeList',
            }),
            createCoverLetter() {
                const createObject = {
                    job_id: 22,
                    theme_id: 23,
                    experience: 0,
                    linkedin_url: '',
                    template_type: 'cover_letter'
                };
                
                resumeService.createResume(createObject).then((createResponse) => {
                    
                    const createdResumeId = createResponse.data[0].current_user_template.id;
                    this.$router.push('/app/dashboard/' + createdResumeId);
                    // this.showLoader = false;
                });
            },
            setStartType(type) {
                this.startType = type;
                if (type === 'industrySelector') {
                    this.currentTab = type;
                } else {
                    this.currentTab = type;
                }
            },
            setSelectedTheme(theme) {
                this.selectedTheme = theme;
            },
            createBlankResume() {
                this.showLoader = true;
                const createObject = {
                    job_id: 1,
                    theme_id: 1,
                    experience: 0,
                    linkedin_url: '',
                    template_type:'resume'
                };
                resumeService.createResume(createObject).then((createResponse) => {
                    const createdResumeId = createResponse.data[0].current_user_template.id;
                    this.$router.push('/app/dashboard/' + createdResumeId);
                    this.showLoader = false;
                });
            },
            changeTab(tabName) {
                if (tabName === 'createResume') {
                    this.showLoader = true;
                    const createObject = {
                        job_id: this.selectedJob.subCategory,
                        theme_id: this.selectedTheme,
                        experience: this.selectedJob.experienceId,
                        linkedin_url: this.linkedinUrl,
                        template_type:'resume'
                    };
                    resumeService.createResume(createObject).then((createResponse) => {
                        const createdResumeId = createResponse.data[0].current_user_template.id;
                        this.$router.push('/app/dashboard/' + createdResumeId);
                        this.showLoader = false;
                    });
                } else {
                    this.currentTab = tabName;
                }

            }
        }
    }
</script>
<style lang="scss">
.main .content{
  text-align: left;
  background: #efefef;
}
@media (min-width: 1200px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1440px !important
    }
}
@media only screen and (max-width: 500px) {
    body {
      width:100%;
      overflow-x: hidden;
    }
    .button-container {
        display: grid;
    }
    .create-resume-button {
        max-width: 290px;
        margin: 3px auto 0 !important;
    }
    .h-100.w-100{
        height: auto !important;
    }
  }
html {
    scroll-behavior: smooth
}

.anycv-logo {
    width: 144px;
    height: 50px;

}

.purple-text {
    color: #742774
}

.purple-header {
    font-family: 'Segoe UI Regular';
    color: #742774;
    font-size: 14px;
    margin-bottom: 0 !important
}

.mt-20 {
    padding-top: 20px
}

.mt-30 {
    margin-top: 30px !important
}

.mt-130 {
    margin-top: 130px !important
}

.mb-20 {
    margin-bottom: 20px !important
}

.primary-button, .primary-button:hover {
    background: #f2c811;
    border-radius: 5px !important;
    padding: 12px 33px  !important;
    text-decoration: none !important;
    color: #282b34 !important;
    font-family: Roboto, sans-serif;
    font-weight: 500 !important;
    cursor: pointer
}

.create-resume-button {
    max-width: 290px !important;
    margin: 30px auto 0 !important
}

.create-template-button, .create-template-button:hover {
    color: #742774;
    font-size: 16px !important;
    cursor: pointer;
    font-weight: 400 !important;
    font-family: 'Segoe UI Regular' !important;
    text-decoration: none !important
}

.create-template-button > img {
    margin-left: 5px !important;
}

.create-experience-button {
    color: #f2c811 !important;
    font-size: 16px !important;
    margin-left: 15px !important;
    cursor: pointer !important;
    font-weight: 400 !important;
    font-family: 'Segoe UI Regular' !important;
    text-decoration: none !important;
    margin-top: 45px !important
}

.create-experience-button > img {
    margin-left: 15px !important
}

.justify-content-evenly {
    justify-content: space-evenly !important
}

.header {
    background: #f8fbff
}

.card {
    border: none !important;
    border-bottom: 1px solid rgba(196, 196, 196, .34) !important;
    background: 0 0 !important
}

.card-header {
    background: 0 0 !important;
    border: none !important
}

.panel-title > a:before {
    font-family: FontAwesome;
    content: "\f07c";
    padding-right: 5px !important
}

.panel-title > a.collapsed:before {
    content: "\f07b"
}

.btn-link {
    color: #282b34 !important;
    font-family: 'Segoe UI Bold';
    font-weight: 700;
    font-size: 18px
}

.question-button:before {
    content: "\f068";
    font-family: FontAwesome;
    float: right;
    transition: all .5s
}

.question-button.collapsed:before {
    content: "\f067";
    font-family: FontAwesome;
    float: right;
    transition: all .5s
}

.btn-link.focus, .btn-link:focus, .btn-link:hover {
    text-decoration: none !important
}

.header-content {
    padding-top: 52px;
    background: linear-gradient(180deg, #f8fbff 0, rgba(250, 252, 255, 0) 100%)
}

.feature-content {
    display: flex;
    flex-direction: column
}

.main-logo {
    color: #742774;
    font-size: 21px;
    font-weight: 600;
    font-family: Roboto, sans-serif
}

.header-item {
    font-size: 14px;
    font-family: Roboto, sans-serif;
    color: #282b34 !important;
    margin-right: 30px !important;
    opacity: 60%;
    cursor: pointer;
    text-decoration: none !important
}

.header-item:last-child {
    margin-right: 0 !important
}

.content-head {
    font-size: 36px;
    font-weight: 700;
    font-family: 'Segoe UI Bold';
    color: #282b34;
    text-align: center;
    max-width: 770px;
    display: block;
    margin: 0 auto
}

.content-head-text {
    font-size: 16px !important;
    font-weight: 400;
    font-family: 'Segoe UI Regular';
    color: #7c7f85;
    max-width: 470px;
    display: block;
    margin: 30px auto 0 !important;
    text-align: center
}

.feature-content-description {
    margin: 10px auto !important;
    color: #282b34;
    font-weight: 700;
    font-family: 'Segoe UI Bold';
    font-size: 24px;
    text-align: center
}

.feature-icon {
    background: #f1e9f1;
    border-radius: 5px !important;
    padding: 25px;
    height: 74px;
    width: 74px;
    margin-bottom: 20px
}

.feature-list-cage {
    display: flex;
    justify-content: space-between;
    margin-top: 61px !important
}

.feature-head {
    font-family: 'Segoe UI Bold';
    font-weight: 700;
    color: #282b34;
    font-size: 16px !important
}

.feature-description {
    font-family: 'Segoe UI Regular';
    font-size: 14px;
    opacity: 60% !important;
    color: #282b34;
    max-width: 270px
}

.feature-templates-cage {
    padding: 68px 0;
    margin-top: 150px !important;
    background: linear-gradient(180deg, #f8fbff 0, rgba(250, 252, 255, 0) 100%)
}

.feature-template-head {
    font-size: 32px;
    color: #282b34;
    font-family: 'Segoe UI Bold';
    font-weight: 700;
    margin-bottom: 10px !important
}

.feature-template-text {
    font-family: 'Segoe UI Regular';
    font-weight: 400;
    font-size: 18px;
    color: #282b34;
    opacity: 60%
}

.template-list {
    display: flex;
    flex-direction: row
}

.template-img {
    box-shadow: 11px 10px  9px -4px #b8b8b8;
    margin-right: 55px !important;
    border-radius: 5px !important;
    margin-bottom: 13px !important
}

.template-img:last-child {
    margin-right: 0 !important
}

.template-name {
    font-family: 'Segoe UI Regular';
    font-size: 16px !important;
    font-weight: 400;
    color: #000
}

.how-it-works-content {
    margin-top: 70px
}

.how-it-works-secondary-head {
    font-family: 'Segoe UI Bold';
    font-weight: 700;
    font-size: 24px;
    color: #282b34;
    text-align: center;
    margin-bottom: 70px !important;
    margin-top: 5px !important
}

.how-it-works-text {
    font-family: 'Segoe UI Bold';
    font-weight: 700;
    font-size: 16px !important;
    color: #282b34;
    margin-bottom: 8px !important
}

.how-it-works-sub-text {
    font-family: 'Segoe UI Regular';
    font-size: 14px;
    color: #282b34;
    opacity: 60%;
    max-width: 270px;
    text-align: center
}

.step-icon {
    width: 68px;
    height: 70px;
    margin-bottom: 10px !important
}
.button-container{
    
           text-align: center;
           padding: 25px;
       
}
.feature-drag-drop {
    /* background-image: url(../images/purple-shape.svg); */
    background-repeat: no-repeat;
    height: 600px !important;
    background-position: left;
    background-size: 300px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 140px auto !important;
}

.purple-icon {
    background: rgba(116, 39, 116, .1);
    padding: 15px !important;
    border-radius: 5px !important;
    margin-bottom: 22px !important
}

.orange-icon {
    background: #efa268;
    border-radius: 5px !important;
    padding: 10px !important
}

.blue-icon {
    background: #2a61f6;
    border-radius: 5px !important;
    padding: 10px 13px
}

.drag-drop-image-cage {
    margin-right: 65px  !important;
}

.drag-drop-head {
    font-family: 'Segoe UI Bold';
    font-weight: 700;
    font-size: 25px;
    color: #282b34
}

.drag-drop-secondary-head {
    font-family: 'Segoe UI Bold';
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 0 !important;
    color: #282b34;
    margin-left: 16px !important
}

.drag-drop-text {
    font-family: 'Segoe UI Regular';
    font-size: 14px;
    color: #282b34;
    opacity: 60%;
    max-width: 534px;
    letter-spacing: .5px;
    margin-bottom: 16px !important;
}

.drag-drop-description {
    font-family: 'Segoe UI Regular';
    font-size: 16px !important;
    color: #282b34;
    opacity: 60%;
    max-width: 235px;
    margin-top: 15px !important;
    letter-spacing: .5px
}

.experience-content {
    background: linear-gradient(180deg, #f8fbff 0, rgba(250, 252, 255, 0) 100%);
    padding: 83px 114px
}

.experience-header {
    font-family: 'Segoe UI Bold';
    font-weight: 700;
    font-size: 24px;
    color: #282b34;
    max-width: 498px;
    text-align: center;
    display: block;
    margin: 0 auto 17px !important
}

.experience-text {
    font-family: 'Segoe UI Regular';
    font-size: 16px !important;
    color: #9ea2b1;
    max-width: 470px;
    text-align: center;
    display: block;
    margin: 0 auto 78px !important
}

.experience-img {
    box-shadow: 11px 10px  9px -4px #b8b8b8;
    border-radius: 5px !important;
    margin-left: 30px !important;
    width: 100%;
}

.experience-img:last-child {
    margin-left: 0 !important;
    margin-right: 30px !important
}

.experience-content-head {
    font-family: 'Segoe UI Bold';
    font-weight: 700;
    font-size: 24px;
    color: #282b34;
    max-width: 423px;
    text-align: left;
    margin-bottom: 30px !important
}

.experience-content-text {
    font-family: 'Segoe UI Regular';
    font-size: 15px !important;
    color: #282b34;
    opacity: 60%;
    max-width: 470px;
    margin-bottom: 30px !important
}

.share-description {
    margin-bottom: 0 !important;
    font-family: 'Segoe UI Bold';
    font-weight: 700;
    font-size: 15px !important;
    color: #282b34;
    margin-left: 10px !important
}

.testimonials-head {
    margin-bottom: 24px !important;
    font-family: 'Segoe UI Bold';
    font-weight: 700;
    font-size: 24px;
    text-align: center
}

.testimonials-content {
    margin-top: 150px !important
}

.comment-icon {
    margin-bottom: 28px !important
}

.comment-cage {
    padding: 35px;
    border: 1px solid rgba(0, 0, 0, .08);
    border-radius: 5px !important;
    max-width: 370px;
    position: relative;
    margin-bottom: 15px !important;
}

.comment-text {
    font-family: 'Segoe UI Regular';
    font-size: 15px !important;
    color: #000;
    line-height: 32px
}

.comment-bold {
    font-family: 'Segoe UI Bold';
    font-weight: 700;
    font-size: 15px !important
}

.comment-author-name {
    font-family: 'Segoe UI Bold';
    font-weight: 600;
    font-size: 13px;
    color: #000;
    margin-bottom: 0 !important
}

.comment-author-firm {
    font-family: 'Segoe UI Regular';
    font-size: 13px;
    color: #000;
    margin-bottom: 0 !important
}

.comment-author-img {
    margin-right: 20px !important
}

.cage-bottom {
    position: absolute;
    bottom: 20px
}

.question-cage {
    padding: 52px 135px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 160px auto 0 !important
}

.question-content {
    background: linear-gradient(180deg, #f8fbff 0, rgba(250, 252, 255, 0) 100%)
}

.question-head {
    font-family: 'Segoe UI Bold' !important;
    font-weight: 700;
    font-size: 24px
}

.question-area-head {
    font-family: 'Segoe UI Bold' !important;
    font-weight: 700;
    font-size: 18px;
    color: #282b34;
    margin-bottom: 0 !important
}

.question-answer-text {
    font-family: 'Segoe UI Regular' !important;
    font-size: 15px !important;
    color: #9ea2b1;
    margin-bottom: 0 !important
}

.question-sub-head {
    font-family: 'Segoe UI Regular' !important;
    font-size: 15px !important;
    color: #282b34;
    opacity: 60%;
    max-width: 470px;
    margin-bottom: 35px !important
}

.question-done-icon {
    margin-right: 10px !important
}

.question-object-head {
    font-family: 'Segoe UI Bold' !important;
    font-weight: 700;
    font-size: 15px !important;
    color: #282b34
}

.question-object-text {
    font-family: 'Segoe UI Regular' !important;
    font-size: 15px !important;
    color: #282b34;
    opacity: 60%;
    max-width: 470px;
    margin-bottom: 50px !important
}

.question-right-cage {
    border-left: 1px solid rgba(196, 196, 196, .34);
    padding-left: 30px
}

.footer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 67px;
    border-bottom: 1px solid rgba(196, 196, 196, .34);
    border-top: 1px solid rgba(196, 196, 196, .34);
    margin-bottom: 30px !important;
    margin-top: 30px !important;
}

.footer-text {
    font-family: 'Segoe UI Regular';
    color: #9ea2b1 !important;
    font-size: 14px;
    margin-top: 10px !important;
    text-decoration: none !important;
}

.social-icon-cage {
    margin-right: 19px !important;
    text-decoration: none !important
}

.social-icons {
    width: 20px;
    height: 20px
}

.social-icon-cage:last-child {
    margin-right: 0 !important
}

.copyright-text {
    font-family: 'Segoe UI Regular';
    color: #282b34;
    font-size: 14px;
    margin-top: 10px !important
}

#user-name {
    font-family: Roboto, sans-serif !important;
    font-size: 14px;
    font-weight: 500;
    color: #282B34;
    margin-bottom: 0 !important;
    text-decoration: none !important;
}

.dropdown-menu {
    background: #ffffff;
    padding: 5px !important;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, .2), 0 0 0 0 rgba(0, 0, 0, .14), 0 0 0 0 rgba(0, 0, 0, .12);
    border-radius: 4px;
}

.dropdown-item {
    font-family: Roboto, sans-serif !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #282B34 !important;
    text-decoration: none !important;
    margin-bottom: 0 !important;
    padding: 0 16px !important;
    border-bottom: 1px solid #F2F2F3 !important;
    cursor: pointer;
    min-height: 48px;
    display: flex !important;
    align-items: center;
}

.dropdown > a {
    display: flex;
    align-items: center;
    text-decoration: none !important;
}

.dropdown-item:last-child {
    border-bottom: none !important;
}

.arrow-down-icon {
    width: 10px !important;
    height: 10px !important;
    margin-left: 10px !important;
}

.dropdown-menu {
    transform: translate3d(-21px, 21px, 0px) !important;
}

@media screen and (max-width: 500px) {
    .mobile-cage-content {
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
        width: 100%;
    }

    .experience-content {
        padding: 5px !important;
    }

    .hide-mobile-content {
        display: none !important;
    }

    .feature-drag-drop {
        background-image: none !important;
    }

    .feature-description {
        text-align: center;
    }

    .flex-row {
        flex-direction: column !important;
    }

    .create-experience-button {
        margin-bottom: 10px !important;
    }

    .experience-img:last-child {
        margin-bottom: 15px !important;
    }

    .cage-bottom {
        margin-top: 30px !important;
        position: relative;
    }

    .drag-drop-image-cage {
        margin-right: 0 !important;
    }

    p {
        text-align: center !important;
    }
}


</style>
