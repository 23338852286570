<template>
    <div class="dashboard-main">
        <div :class="open?'side-menu-cage hide-mobile-content open':'side-menu-cage hide-mobile-content '">
            <side-menu v-if="requestDone"></side-menu>
        </div>

  <i v-if="isMobile()" :class="open?'fa-angle-down open':'fa-angle-down'" @click="openside"><span>&#x3e;</span></i> 
 
        <div :class="isMobile() ? 'mobiledisplay content-side-cage': 'content-side-cage'">
            <contents></contents>
            <login></login>
        </div>
    </div>
</template>
<script>
    import SideMenu from '../../components/side-menu.vue';
    import Contents from '../../components/contents.vue';
    import Login from '../../components/login/login.vue'
    import themeUpdater from "@/mixins/theme-update-helper";
    import dashboardHelper from "@/mixins/dashboard-helper.js";

    export default {
        name: 'mainDashboard',
        components: {
            SideMenu,
            Contents,
            Login
        },
        data() {
            return {
                requestDone: false,
                open:false
            }
        },
        created() {
            this.getResumeDetail();
        },
        mixins: [themeUpdater,dashboardHelper],
        methods: {
            isMobile() {
    return this.$vuetify.breakpoint.sm||this.$vuetify.breakpoint.xs
 },
 openside(){
    this.open=!this.open
 }
        },
    }
</script>
<style lang="scss">
    .dashboard-main {
        height: calc(100vh - 65px);
        display: flex;
    }

    .side-menu-cage {
        width: 100%;
        max-width: 305px;
        max-height: 100%;
        @media (max-width: 420px) {
            max-width: 100%;
        }
    }

    .content-side-cage {
        width: 100%;
        background: #f0f0f0;
    }
    .mobiledisplay #element-to-print{
        //display: flex;
    scale: 0.5;
    }
    .mobiledisplay .mobile-cage-content {
    display: flex !important;
    flex-direction: unset !important;
}
.hide-mobile-content.open{
    top: 83px;
    position: fixed;
    height: 85%;
    z-index: 99;
    background: white;
    width: 90%;
    display: block !important;
}
.hide-mobile-content{
    
  transition: display 3s;
  transition-timing-function: linear;
  
  transition: visibility 0s, opacity 0.5s linear;
}
.fa-angle-down.open{ 
left:90%;
}   
.fa-angle-down.open span{ 
transform: rotate(180deg);
}   

.fa-angle-down{    
    position: fixed;
    left: 0px;
    top: 130px;
    height: 50px;
    background: rgba(0,0,0,0.5);
    width: 30px;
    border-radius: 0 10px 10px 0px;
    font-family: none;
    font-weight: bolder;
    font-size: 40px;
    color: white;
    line-height: 50px;
    z-index: 99;
    }
</style>
