<template>
  <div class="theme-main-cage" id="element-to-print"
       :style="[{backgroundColor:resumeOptions.backgroundColorModel},{backgroundImage:'url('+resumeOptions.backgroundDecoration.url+')'}]">
    <div ref="headerItem" id="headerItem">
      <div class="pm-theme header-cage mobile-cage-content" :class="{'active-editor':editorList.headerEditor}">
        <p class="pm-theme theme-resume-header name-text"
           @click="openEditor('headerEditor',$event, summaryData, 'full_name','summary')"
           :class="summaryMenuData.html ? summaryMenuData.html.headerEditor : ''"
           @focusout="updateItemData($event, summaryData, 'full_name','summary')" spellcheck="false"
           contenteditable>
          {{ summaryData.full_name }}</p>
        <img :src="summaryData.image" class="pm-theme img-cage">
        <text-editor @addPoint="addListPoint()" v-if="editorList.headerEditor" id-name="theme-resume-header"
                     @addClass="updateEditorClass()"></text-editor>
      </div>
      <div class="pm-theme header-black-row"></div>
      <div class="pm-theme communication-area"
           @click="openEditor('communicationEditor', $event, summaryData, 'email','summary')"
           :class="{'active-editor':editorList.communicationEditor}">
        <div class="d-flex align-items-center" v-if="summaryData.email">
          <img class="pm-theme communication-icon"
               src="../../../../assets/custom-icons/themes/peter-miller/envelope.svg">
          <p class="pm-theme communication-text  communication-area-text"
             :class="summaryMenuData.html ? summaryMenuData.html.communicationEditor : ''"
             @focusout="updateItemData($event, summaryData, 'email','summary')" spellcheck="false"
             contenteditable>
            {{ summaryData.email }}</p>
        </div>
        <div class="d-flex align-items-center" v-if="summaryData.phone">
          <img class="pm-theme communication-icon"
               src="../../../../assets/custom-icons/themes/peter-miller/phone.svg">
          <p class="pm-theme communication-text communication-area-text"
             :class="summaryMenuData.html ? summaryMenuData.html.communicationEditor : ''"
             @focusout="updateItemData($event, summaryData, 'phone','summary')" spellcheck="false"
             contenteditable>
            {{ summaryData.phone }}</p>
        </div>
        <div class="d-flex align-items-center" v-if="summaryData.address">
          <img class="pm-theme communication-icon"
               src="../../../../assets/custom-icons/themes/peter-miller/location.svg">
          <p class="pm-theme communication-text communication-area-text"
             :class="summaryMenuData.html ? summaryMenuData.html.communicationEditor : ''"
             @focusout="updateItemData($event, summaryData, 'address', 'summary')" spellcheck="false"
             contenteditable>
            {{ summaryData.address }}</p>
        </div>
        <text-editor @addPoint="addListPoint()" v-if="editorList.communicationEditor"
                     :id-name="'communication-area-text'"
                     @addClass="updateEditorClass()"></text-editor>
      </div>
      <div class="pm-theme about-me-area">
        <div @click="openEditor('summarySectionEditor',$event, summaryMenuData, 'item_header', 'summary')"
             :class="{'active-editor':editorList.summarySectionEditor}">
          <p class="pm-theme section-header hobby-header" :style="primaryFontStyle"
             @focusout="updateItemSectionHeader($event, summaryMenuData)"
             spellcheck="false" contenteditable>
            {{ summaryMenuData.item_header }}</p>
        </div>
        <div @click="openEditor('profileTextEditor', $event, summaryData, 'summary','summary')"
             v-if="summaryData.summary"
             :class="[(editorList.profileTextEditor ?'active-editor':'')]">
          <p class="pm-theme theme-about-me about-me-text"
             :class="summaryMenuData.html ? summaryMenuData.html.profileTextEditor : ''"
             @focusout="updateItemData($event, summaryData, 'summary','summary')"
             spellcheck="false" contenteditable>{{ summaryData.summary.toString() }}</p>
        </div>
        <text-editor @addPoint="addListPoint()" v-if="editorList.profileHeaderEditor" :id-name="'about-me-header'"
                     @addClass="updateEditorClass()"></text-editor>
        <text-editor @addPoint="addListPoint()" v-if="editorList.profileTextEditor" :id-name="'about-me-text'"
                     @addClass="updateEditorClass()"></text-editor>
      </div>
    </div>

    <section>
      <div class="pm-theme list-items mobile-cage-content" ref="listItem" id="listItem"
           v-if="listedMenu.length > 0">
        <!-- !!!!!  FULL SIDE !!!!!!!!!!!-->
        <div class="pm-theme full-side">
          <div class="pm-theme full-cage experience-area"
               :style="{order:listedMenu.find(x => x.name ==='Experience').position}"
               v-if="experienceData.myExperience.length > 0">
            <div @click="openEditor('experienceSectionEditor',$event, experienceData, 'item_header', 'experience')"
                 :class="{'active-editor':editorList.experienceSectionEditor}">
              <p class="pm-theme section-header" :style="primaryFontStyle"
                 @focusout="updateItemSectionHeader($event, experienceData)"
                 spellcheck="false" contenteditable>
                {{ experienceData.item_header }}</p>
            </div>
            <div class="pm-theme item-data-row" v-for="(experience,i) in experienceData.myExperience"
                 :key="i">
              <div class="d-flex justify-space-between mb-3">
                <div>
                  <div @click="openEditor('experienceTitleEditor',$event, experience, 'title', 'experience')"
                       :class="{'active-editor':editorList.experienceTitleEditor}">
                    <p class="pm-theme secondary-head experience-title"
                       :class="experienceData.html ? experienceData.html.experienceTitleEditor : ''"
                       @focusout="updateItemData($event, experience, 'title', 'experience')"
                       spellcheck="false" contenteditable>{{ experience.title }}</p>
                  </div>
                  <div class="d-flex"
                       @click="openEditor('experienceSubTitleEditor',$event, experience, 'company', 'experience')"
                       :class="{'active-editor':editorList.experienceSubTitleEditor}">
                    <p class="pm-theme content-text mr-1 experience-sub-title mw-130"

                       :class="experienceData.html ? experienceData.html.experienceSubTitleEditor : ''"
                       @focusout="updateItemData($event, experience, 'company', 'experience')"
                       spellcheck="false" contenteditable>
                      {{ experience.company }}</p>
                    <p class="pm-theme content-text mr-1 experience-sub-title"
                       v-if="experience.country"
                       :class="experienceData.html ? experienceData.html.experienceSubTitleEditor : ''"
                    >/</p>
                    <p class="pm-theme content-text experience-sub-title mw-130"
                       :class="experienceData.html ? experienceData.html.experienceSubTitleEditor : ''"
                       @focusout="updateItemData($event, experience, 'country', 'experience', 'experienceSubTitle')"
                       spellcheck="false" contenteditable>{{ experience.country }}</p>
                  </div>
                </div>
                <div class="d-flex"
                     @click="openEditor('experienceDateEditor',$event, experience, 'start_year', 'experience')"
                     :class="{'active-editor':editorList.experienceDateEditor}">
                  <p class="pm-theme secondary-head mr-1 experience-date-text" v-if="!experience.show_start_date"
                     :class="experienceData.html ? experienceData.html.experienceDateEditor : ''"
                     @focusout="updateItemData($event, experience, 'start_year', 'experience')"
                     spellcheck="false" contenteditable>
                    {{ experience.start_year }}</p>
                  <p class="pm-theme secondary-head mr-1 experience-date-text"
                     v-if="experience.end_year && experience.start_year && !experience.show_start_date && !experience.show_end_date"
                     :class="experienceData.html ? experienceData.html.experienceDateEditor : ''"
                  >-</p>
                  <p class="pm-theme secondary-head experience-date-text" v-if="!experience.show_end_date"
                     :class="experienceData.html ? experienceData.html.experienceDateEditor : ''"
                     @focusout="updateItemData($event, experience, 'end_year', 'experience')"
                     spellcheck="false" contenteditable>
                    {{ experience.end_year }}</p>
                </div>
              </div>
              <div @click="openEditor('experienceDescriptionEditor',$event, experience, 'description', 'experience')"
                   :class="{'active-editor':editorList.experienceDescriptionEditor}">
                <p class="pm-theme content-text experience-description-text"
                   v-if="experience.description"
                   :class="experienceData.html ? experienceData.html.experienceDescriptionEditor : ''"
                   @focusout="updateItemData($event, experience, 'description', 'experience')"
                   spellcheck="false" contenteditable>{{ experience.description }}</p>
              </div>
            </div>
            <text-editor @addPoint="addListPoint()" v-if="editorList.experienceTitleEditor"
                         :id-name="'experience-title'" @addClass="updateEditorClass()"></text-editor>
            <text-editor @addPoint="addListPoint()" v-if="editorList.experienceSubTitleEditor"
                         :id-name="'experience-sub-title'" @addClass="updateEditorClass()"></text-editor>
            <text-editor @addPoint="addListPoint()" v-if="editorList.experienceDateEditor"
                         :id-name="'experience-date-text'" @addClass="updateEditorClass()"></text-editor>
            <text-editor @addPoint="addListPoint()" v-if="editorList.experienceDescriptionEditor"
                         :id-name="'experience-description-text'"
                         @addClass="updateEditorClass()"></text-editor>
          </div>
          <div class="pm-theme full-cage education-area"
               :style="{order:listedMenu.find(x => x.name ==='Education').position}"
               v-if="educationData.myEducations.length > 0">
            <div @click="openEditor('educationSectionEditor',$event, educationData, 'item_header', 'education')"
                 :class="{'active-editor':editorList.educationSectionEditor}">
              <p class="pm-theme section-header" :style="primaryFontStyle"
                 @focusout="updateItemSectionHeader($event, educationData)"
                 spellcheck="false" contenteditable>
                {{ educationData.item_header }}</p>
            </div>
            <div class="pm-theme item-data-row" v-for="(education,i) in educationData.myEducations"
                 :key="i">

              <div class="d-flex justify-space-between mb-3">
                <div>
                  <div @click="openEditor('educationTitleEditor',$event, education, 'name', 'education')"
                       :class="{'active-editor':editorList.educationTitleEditor}">
                    <p class="pm-theme secondary-head education-title"
                       :class="educationData.html ? educationData.html.educationTitleEditor : ''"
                       @focusout="updateItemData($event, education, 'name', 'education')"
                       spellcheck="false" contenteditable>{{ education.name }}</p>
                  </div>
                  <div class="d-flex"
                       @click="openEditor('educationSubTitleEditor',$event, education, 'grade', 'education')"
                       :class="{'active-editor':editorList.educationSubTitleEditor}">
                    <p class="pm-theme content-text mr-1 education-sub-title mw-130"
                       :class="educationData.html ? educationData.html.educationSubTitleEditor : ''"

                       @focusout="updateItemData($event, education, 'grade', 'education')"
                       spellcheck="false" contenteditable>{{ education.grade }}</p>
                    <p class="pm-theme content-text mr-1 education-sub-title"
                       v-if="education.grade && education.country"
                       :class="educationData.html ? educationData.html.educationSubTitleEditor : ''"
                    >/</p>
                    <p class="pm-theme content-text education-sub-title mw-130" v-if="education.country"
                       :class="educationData.html ? educationData.html.educationSubTitleEditor : ''"
                       @focusout="updateItemData($event, education, 'country', 'education')"
                       spellcheck="false" contenteditable>{{ education.country }}</p>
                  </div>
                </div>
                <div class="d-flex"
                     @click="openEditor('educationDateEditor', $event, education, 'start_year', 'education')"
                     :class="{'active-editor':editorList.educationDateEditor}">
                  <p class="pm-theme secondary-head mr-1 education-date-text" v-if="!education.show_start_date"
                     :class="educationData.html ? educationData.html.educationDateEditor : ''"
                     @focusout="updateItemData($event, education, 'start_year', 'education')"
                     spellcheck="false" contenteditable>{{ education.start_year }}</p>
                  <p class="pm-theme secondary-head mr-1 education-date-text"
                     v-if="education.start_year && education.end_year && !education.show_start_date && !education.show_end_date"
                     :class="educationData.html ? educationData.html.educationDateEditor : ''"
                  >-</p>
                  <p class="pm-theme secondary-head mr-1 education-date-text" v-if="!education.show_end_date"
                     :class="educationData.html ? educationData.html.educationDateEditor : ''"
                     @focusout="updateItemData($event, education, 'end_year', 'education')"
                     spellcheck="false" contenteditable>{{ education.end_year }}</p>
                </div>
              </div>
              <div @click="openEditor('educationDescriptionEditor',$event, education, 'description', 'education')"
                   :class="{'active-editor':editorList.educationDescriptionEditor}">
                <p class="pm-theme content-text education-description-text" v-if="education.description"
                   :class="educationData.html ? educationData.html.educationDescriptionEditor : ''"
                   @focusout="updateItemData($event, education, 'description', 'education')"
                   spellcheck="false" contenteditable>{{ education.description }}</p>
              </div>
            </div>
            <text-editor @addPoint="addListPoint()" v-if="editorList.educationTitleEditor"
                         :id-name="'education-title'" @addClass="updateEditorClass()"></text-editor>
            <text-editor @addPoint="addListPoint()" v-if="editorList.educationSubTitleEditor"
                         :id-name="'education-sub-title'" @addClass="updateEditorClass()"></text-editor>
            <text-editor @addPoint="addListPoint()" v-if="editorList.educationDateEditor"
                         :id-name="'education-date-text'" @addClass="updateEditorClass()"></text-editor>
            <text-editor @addPoint="addListPoint()" v-if="editorList.educationDescriptionEditor"
                         :id-name="'education-description-text'"
                         @addClass="updateEditorClass()"></text-editor>
          </div>
          <div class="pm-theme full-cage project-area"
               :style="{order:listedMenu.find(x => x.name ==='Projects').position}"
               v-if="projectsData.myProjects.length > 0">
            <div @click="openEditor('projectSectionEditor',$event, projectsData, 'item_header', 'projects')"
                 :class="{'active-editor':editorList.projectSectionEditor}">
              <p class="pm-theme section-header" :style="primaryFontStyle"
                 @focusout="updateItemSectionHeader($event, projectsData)"
                 spellcheck="false" contenteditable>
                {{ projectsData.item_header }}</p>
            </div>
            <div class="pm-theme item-data-row" v-for="(project,i) in projectsData.myProjects"
                 :key="i">
              <div class="d-flex justify-space-between mb-3">
                <div>
                  <div @click="openEditor('projectTitleEditor',$event, project, 'name', 'projects')"
                       :class="{'active-editor':editorList.projectTitleEditor}">
                    <p class="pm-theme secondary-head project-title"
                       :class="projectsData.html ? projectsData.html.projectTitleEditor : ''"
                       @focusout="updateItemData($event, project, 'name', 'projects')"
                       spellcheck="false" contenteditable>{{ project.name }}</p>
                  </div>
                  <div @click="openEditor('projectSubTitleEditor',$event, project, 'url', 'projects')"
                       :class="{'active-editor':editorList.projectSubTitleEditor}">
                    <p class="pm-theme content-text project-sub-title mw-210"
                       :class="projectsData.html ? projectsData.html.projectSubTitleEditor : ''"
                       @focusout="updateItemData($event, project, 'url', 'projects')"
                       spellcheck="false" contenteditable>{{ project.url }}</p>
                  </div>
                </div>
                <div class="d-flex"
                     @click="openEditor('projectDateEditor',$event, project, 'start_year', 'projects')"
                     :class="{'active-editor':editorList.projectDateEditor}">
                  <p class="pm-theme secondary-head mr-1 project-date-text" v-if="!project.show_start_date"
                     :class="projectsData.html ? projectsData.html.projectDateEditor : ''"
                     @focusout="updateItemData($event, project, 'start_year', 'projects')"
                     spellcheck="false" contenteditable>{{ project.start_year }}</p>
                  <p class="pm-theme secondary-head mr-1 project-date-text"
                     v-if="project.end_year && project.start_year && !project.show_start_date && !project.show_end_date"
                     :class="projectsData.html ? projectsData.html.projectDateEditor : ''">
                    -</p>
                  <p class="pm-theme secondary-head mr-1 project-date-text" v-if="!project.show_end_date"
                     :class="projectsData.html ? projectsData.html.projectDateEditor : ''"
                     @focusout="updateItemData($event, project, 'end_year', 'projects')"
                     spellcheck="false" contenteditable>{{ project.end_year }}</p>
                </div>
              </div>
              <div @click="openEditor('projectDescriptionEditor',$event, project, 'description', 'projects')"
                   :class="{'active-editor':editorList.projectDescriptionEditor}">
                <p class="pm-theme content-text project-description-text" v-if="project.description"
                   :class="projectsData.html ? projectsData.html.projectDescriptionEditor : ''"
                   @focusout="updateItemData($event, project, 'description', 'projects')"
                   spellcheck="false" contenteditable>{{ project.description }}</p>
              </div>
            </div>
            <text-editor @addPoint="addListPoint()" v-if="editorList.projectTitleEditor"
                         :id-name="'project-title'" @addClass="updateEditorClass()"></text-editor>
            <text-editor @addPoint="addListPoint()" v-if="editorList.projectSubTitleEditor"
                         :id-name="'project-sub-title'" @addClass="updateEditorClass()"></text-editor>
            <text-editor @addPoint="addListPoint()" v-if="editorList.projectDateEditor"
                         :id-name="'project-date-text'" @addClass="updateEditorClass()"></text-editor>
            <text-editor @addPoint="addListPoint()" v-if="editorList.projectDescriptionEditor"
                         :id-name="'project-description-text'"
                         @addClass="updateEditorClass()"></text-editor>
          </div>
          <div class="pm-theme full-cage publications-area"
               :style="{order:listedMenu.find(x => x.name ==='Publications').position}"
               v-if="publicationData.myPublications.length > 0">
            <div @click="openEditor('publicationSectionEditor',$event, publicationData, 'item_header', 'publications')"
                 :class="{'active-editor':editorList.publicationSectionEditor}">
              <p class="pm-theme section-header" :style="primaryFontStyle"
                 @focusout="updateItemSectionHeader($event, publicationData)"
                 spellcheck="false" contenteditable>
                {{ publicationData.item_header }}</p>
            </div>
            <div class="pm-theme item-data-row" v-for="(publication,i) in publicationData.myPublications"
                 :key="i">
              <div class="d-flex justify-space-between mb-3">
                <div>
                  <div @click="openEditor('publicationTitleEditor',$event, publication, 'name', 'publications')"
                       :class="{'active-editor':editorList.publicationTitleEditor}">
                    <p class="pm-theme secondary-head publication-title"
                       :class="publicationData.html ? publicationData.html.publicationTitleEditor : ''"
                       @focusout="updateItemData($event, publication, 'name', 'publications')"
                       spellcheck="false" contenteditable>{{ publication.name }}</p>
                  </div>
                  <div
                      @click="openEditor('publicationSubTitleEditor', $event, publication, 'publisher', 'publications')"
                      :class="{'active-editor':editorList.publicationSubTitleEditor}">
                    <p class="pm-theme content-text publication-sub-title mw-210"
                       :class="publicationData.html ? publicationData.html.publicationSubTitleEditor : ''"
                       @focusout="updateItemData($event, publication, 'publisher', 'publications')"
                       spellcheck="false" contenteditable>{{ publication.publisher }}</p>
                    <p class="pm-theme content-text publication-sub-title mw-210"
                       :class="publicationData.html ? publicationData.html.publicationSubTitleEditor : ''"
                       @focusout="updateItemData($event, publication, 'contributors', 'publications')"
                       spellcheck="false" contenteditable>{{ publication.contributors }}</p>
                    <p class="pm-theme content-text publication-sub-title mw-210"
                       :class="publicationData.html ? publicationData.html.publicationSubTitleEditor : ''"
                       @focusout="updateItemData($event, publication, 'url', 'publications')"
                       spellcheck="false" contenteditable>{{ publication.url }}</p>
                  </div>
                </div>
              </div>
              <div
                  @click="openEditor('publicationDescriptionEditor',$event, publication, 'description', 'publications')"
                  :class="{'active-editor':editorList.publicationDescriptionEditor}">
                <p class="pm-theme content-text publication-description-text"
                   v-if="publication.description"
                   :class="publicationData.html ? publicationData.html.publicationDescriptionEditor : ''"
                   @focusout="updateItemData($event, publication, 'description', 'publications')"
                   spellcheck="false" contenteditable>{{ publication.description }}</p>
              </div>
            </div>
            <text-editor @addPoint="addListPoint()" v-if="editorList.publicationTitleEditor"
                         :id-name="'publication-title'" @addClass="updateEditorClass()"></text-editor>
            <text-editor @addPoint="addListPoint()" v-if="editorList.publicationSubTitleEditor"
                         :id-name="'publication-sub-title'" @addClass="updateEditorClass()"></text-editor>
            <text-editor @addPoint="addListPoint()" v-if="editorList.publicationDescriptionEditor"
                         :id-name="'publication-description-text'"
                         @addClass="updateEditorClass()"></text-editor>
          </div>
          <div class="pm-theme full-cage my-time-area"
               :style="{order:listedMenu.find(x => x.name ==='My Time').position}"
               v-if="myTimeData.myTime.length > 0">
            <div @click="openEditor('myTimeSectionEditor',$event, myTimeData, 'item_header', 'myTime')"
                 :class="{'active-editor':editorList.myTimeSectionEditor}">
              <p class="pm-theme section-header" :style="primaryFontStyle"
                 @focusout="updateItemSectionHeader($event, myTimeData)"
                 spellcheck="false" contenteditable>
                {{ myTimeData.item_header }}</p>
            </div>
            <div style="height: 35px">
              <pm-stacked-chart style="height: 35px" :my-time-data="myTimeData.myTime"></pm-stacked-chart>
            </div>
            <div class="pm-theme item-data-row" v-for="(myTime,i) in myTimeData.myTime"
                 :key="i">
              <div class="d-flex flex-row align-items-center">
                <p class="pm-theme my-time-rate" :id="'my-time-rate-'+ i"></p>
                <div @click="openEditor('myTimeTitleEditor',$event, myTime, 'name', 'myTime')"
                     :class="{'active-editor':editorList.myTimeTitleEditor}">
                  <p class="pm-theme secondary-head my-time-title"
                     :class="myTimeData.html ? myTimeData.html.myTimeTitleEditor : ''"
                     @focusout="updateItemData($event, myTime, 'name', 'myTime')"
                     spellcheck="false" contenteditable>{{ myTime.name }}</p>
                </div>
              </div>
            </div>
            <text-editor @addPoint="addListPoint()" v-if="editorList.myTimeTitleEditor" @addClass="updateEditorClass()"
                         :id-name="'my-time-title'"></text-editor>
          </div>
          <div class="pm-theme full-cage motto-area"
               :style="{order:listedMenu.find(x => x.name ==='Mottos').position}"
               v-if="mottoData.myMottos.length > 0">
            <div @click="openEditor('mottoSectionEditor',$event, mottoData, 'item_header', 'motto')"
                 :class="{'active-editor':editorList.mottoSectionEditor}">
              <p class="pm-theme section-header" :style="primaryFontStyle"
                 @focusout="updateItemSectionHeader($event, mottoData)"
                 spellcheck="false" contenteditable>
                {{ mottoData.item_header }}</p>
            </div>
            <div class="pm-theme item-data-row" v-for="(motto,i) in mottoData.myMottos"
                 :key="i">
              <div @click="openEditor('mottoTitleEditor',$event, motto, 'description', 'motto')"
                   :class="{'active-editor':editorList.mottoTitleEditor}">
                <p class="pm-theme motto-title"
                   :class="mottoData.html ? mottoData.html.mottoTitleEditor : ''"
                   @focusout="updateItemData($event, motto, 'description', 'motto')"
                   spellcheck="false" contenteditable>{{ motto.description }}</p>
              </div>
              <div @click="openEditor('mottoTextEditor',$event, motto, 'author', 'motto')"
                   :class="{'active-editor':editorList.mottoTextEditor}">
                <p class="pm-theme content-text motto-text"
                   :class="mottoData.html ? mottoData.html.mottoTextEditor : ''"
                   @focusout="updateItemData($event, motto, 'author', 'motto')"
                   contenteditable>{{ motto.author }}</p>
              </div>
            </div>
            <text-editor @addPoint="addListPoint()" v-if="editorList.mottoTitleEditor" @addClass="updateEditorClass()"
                         :id-name="'motto-title'"></text-editor>
            <text-editor @addPoint="addListPoint()" v-if="editorList.mottoTextEditor" @addClass="updateEditorClass()"
                         :id-name="'motto-text'"></text-editor>
          </div>
          <div class="pm-theme full-cage volunteer-area"
               :style="{order:listedMenu.find(x => x.name ==='Volunteering').position}"
               v-if="volunteeringData.myVolunteering.length > 0">
            <div @click="openEditor('volunteerSectionEditor',$event, volunteeringData, 'item_header', 'volunteering')"
                 :class="{'active-editor':editorList.volunteerSectionEditor}">
              <p class="pm-theme section-header" :style="primaryFontStyle"
                 @focusout="updateItemSectionHeader($event, volunteeringData)"
                 spellcheck="false" contenteditable>
                {{ volunteeringData.item_header }}</p>
            </div>
            <div class="pm-theme item-data-row" v-for="(volunteering,i) in volunteeringData.myVolunteering"
                 :key="i">
              <div class="d-flex justify-space-between mb-3">
                <div>
                  <div @click="openEditor('volunteeringTitleEditor',$event, volunteering, 'company', 'volunteering')"
                       :class="{'active-editor':editorList.volunteeringTitleEditor}">
                    <p class="pm-theme secondary-head volunteering-title"
                       :class="volunteeringData.html ? volunteeringData.html.volunteeringTitleEditor : ''"
                       @focusout="updateItemData($event, volunteering, 'company', 'volunteering')"
                       spellcheck="false" contenteditable>{{ volunteering.company }}</p>
                  </div>
                  <div @click="openEditor('volunteeringSubTitleEditor',$event, volunteering, 'mission', 'volunteering')"
                       :class="{'active-editor':editorList.volunteeringSubTitleEditor}">
                    <p class="pm-theme content-text volunteering-sub-title mw-210"
                       :class="volunteeringData.html ? volunteeringData.html.volunteeringSubTitleEditor : ''"

                       @focusout="updateItemData($event, volunteering, 'mission', 'volunteering')"
                       spellcheck="false" contenteditable>{{ volunteering.mission }}</p>
                  </div>
                </div>
                <div class="d-flex"
                     @click="openEditor('volunteeringDateEditor', $event, volunteering, 'start_year', 'volunteering')"
                     :class="{'active-editor':editorList.volunteeringDateEditor}">
                  <p class="pm-theme secondary-head mr-1 volunteering-date-text"
                     :class="volunteeringData.html ? volunteeringData.html.volunteeringDateEditor : ''"

                     @focusout="updateItemData($event, volunteering, 'start_year', 'volunteering')"
                     spellcheck="false" contenteditable>{{ volunteering.start_year }}</p>
                  <p class="pm-theme secondary-head mr-1 volunteering-date-text"
                     :class="volunteeringData.html ? volunteeringData.html.volunteeringDateEditor : ''"
                  >-</p>
                  <p class="pm-theme secondary-head mr-1 volunteering-date-text"
                     :class="volunteeringData.html ? volunteeringData.html.volunteeringDateEditor : ''"

                     @focusout="updateItemData($event, volunteering, 'end_year', 'volunteering')"
                     spellcheck="false" contenteditable>{{ volunteering.end_year }}</p>
                </div>
              </div>
              <div
                  @click="openEditor('volunteeringDescriptionEditor', $event, volunteering, 'description', 'volunteering')"
                  :class="{'active-editor':editorList.volunteeringDescriptionEditor}">
                <p class="pm-theme content-text volunteering-description-text"
                   v-if="volunteering.description"
                   :class="volunteeringData.html ? volunteeringData.html.volunteeringDescriptionEditor : ''"
                   @focusout="updateItemData($event, volunteering, 'description', 'volunteering')"
                   spellcheck="false" contenteditable>{{ volunteering.description }}</p>
              </div>
            </div>
            <text-editor @addPoint="addListPoint()" v-if="editorList.volunteeringTitleEditor"
                         @addClass="updateEditorClass()"
                         :id-name="'volunteering-title'"></text-editor>
            <text-editor @addPoint="addListPoint()" v-if="editorList.volunteeringSubTitleEditor"
                         @addClass="updateEditorClass()"
                         :id-name="'volunteering-sub-title'"></text-editor>
            <text-editor @addPoint="addListPoint()" v-if="editorList.volunteeringDateEditor"
                         @addClass="updateEditorClass()"
                         :id-name="'volunteering-date-text'"></text-editor>
            <text-editor @addPoint="addListPoint()" v-if="editorList.volunteeringDescriptionEditor"
                         @addClass="updateEditorClass()"
                         :id-name="'volunteering-description-text'"></text-editor>
          </div>
        </div>
        <!-- !!!!! HALF SIDE !!!!!!!!-->
        <div class="pm-theme half-side">
          <div class="pm-theme strength-area mrgb-20" v-if="strengthsData.myStrengths.length > 0"
               :style="{order:listedMenu.find(x => x.name ==='Strengths').position}">
            <div @click="openEditor('strengthSectionEditor',$event, strengthsData, 'item_header', 'strength')"
                 :class="{'active-editor':editorList.strengthSectionEditor}">
              <p class="pm-theme section-header" :style="primaryFontStyle"
                 @focusout="updateItemSectionHeader($event, strengthsData)"
                 spellcheck="false" contenteditable>
                {{ strengthsData.item_header }}</p>
            </div>
            <div class="pm-theme item-data-row" v-for="(strength,i) in strengthsData.myStrengths" :key="i">
              <div class="d-flex align-items-center"
                   @click="openEditor('strengthTextEditor',$event, strength, 'name', 'strength')"
                   :class="{'active-editor':editorList.strengthTextEditor}">
                                <span class="material-icons mr-5 list-dot"
                                >fiber_manual_record</span>
                <p class="pm-theme content-text strength-text w-100"
                   :class="strengthsData.html ? strengthsData.html.strengthTextEditor : ''"
                   @focusout="updateItemData($event, strength, 'name', 'strength')"
                   spellcheck="false" contenteditable>{{ strength.name }}</p>
              </div>
            </div>
            <text-editor @addPoint="addListPoint()" v-if="editorList.strengthHeaderEditor"
                         @addClass="updateEditorClass()"
                         :id-name="'strength-header'"></text-editor>
            <text-editor @addPoint="addListPoint()" v-if="editorList.strengthTextEditor" @addClass="updateEditorClass()"
                         :id-name="'strength-text'"></text-editor>
          </div>
          <div class="pm-theme hobbies-area mrgb-20" v-if="hobbiesData.myHobbies.length > 0"
               :style="{order:listedMenu.find(x => x.name ==='Hobbies').position}">
            <div @click="openEditor('hobbiesSectionEditor',$event, hobbiesData, 'item_header', 'hobby')"
                 :class="{'active-editor':editorList.hobbiesSectionEditor}">
              <p class="pm-theme section-header hobby-header mrgb-20" :style="primaryFontStyle"
                 @focusout="updateItemSectionHeader($event, hobbiesData)"
                 spellcheck="false" contenteditable>
                {{ hobbiesData.item_header }}</p>
            </div>
            <div class="pm-theme item-data-row" v-for="(hobby,i) in hobbiesData.myHobbies" :key="i">
              <div class="d-flex align-items-center"
                   @click="openEditor('hobbyTitleEditor',$event, hobby, 'name', 'hobby')"
                   :class="{'active-editor':editorList.hobbyTitleEditor}">
                <span class="material-icons mr-2">{{ hobby.icon }}</span>
                <p class="pm-theme content-text hobby-title w-100"
                   :class="hobbiesData.html ? hobbiesData.html.hobbyTitleEditor : ''"
                   @focusout="updateItemData($event, hobby, 'name', 'hobby')"
                   spellcheck="false" contenteditable>{{ hobby.name }}</p>
              </div>
            </div>
            <text-editor @addPoint="addListPoint()" v-if="editorList.hobbyTitleEditor" @addClass="updateEditorClass()"
                         :id-name="'hobby-title'"></text-editor>
          </div>
          <div class="pm-theme languages-are mrgb-20" v-if="languagesData.myLanguages.length > 0"
               :style="{order:listedMenu.find(x => x.name ==='Language Skills').position}">
            <div @click="openEditor('languageSectionEditor',$event, languagesData, 'item_header', 'language')"
                 :class="{'active-editor':editorList.languageSectionEditor}">
              <p class="pm-theme section-header mrgb-20" :style="primaryFontStyle"
                 @focusout="updateItemSectionHeader($event, languagesData)"
                 spellcheck="false" contenteditable>
                {{ languagesData.item_header }}</p>
            </div>
            <div class="pm-theme item-data-row" v-for="(language,i) in languagesData.myLanguages" :key="i">
              <div @click="openEditor('languageTitleEditor',$event, language, 'name', 'language')"
                   :class="{'active-editor':editorList.languageTitleEditor}">
                <p class="pm-theme content-head language-title w-100"
                   :class="languagesData.html ? languagesData.html.languageTitleEditor : ''"
                   @focusout="updateItemData($event, language, 'name', 'language')"
                   spellcheck="false" contenteditable>{{ language.name }}</p>
              </div>
              <div class="d-flex align-items-center"
                   @click="openEditor('languageInfoEditor',$event, language, 'information', 'language')"
                   :class="{'active-editor':editorList.languageInfoEditor}">
                                <span class="material-icons mr-5 list-dot"
                                >fiber_manual_record</span>
                <p class="pm-theme content-text language-info-text"
                   :class="languagesData.html ? languagesData.html.languageInfoEditor : ''"
                   @focusout="updateItemData($event, language, 'information', 'language')"
                   spellcheck="false" contenteditable>{{ language.information }}</p>
                <p class="pm-theme content-text language-info-text ml-1"
                   :class="languagesData.html ? languagesData.html.languageInfoEditor : ''"
                   @focusout="updateItemData($event, language, 'success_point', 'language')"
                   spellcheck="false" contenteditable>{{ language.success_point }}</p>
              </div>
            </div>
            <text-editor @addPoint="addListPoint()" v-if="editorList.languageInfoEditor" @addClass="updateEditorClass()"
                         :id-name="'language-info-text'"></text-editor>
            <text-editor @addPoint="addListPoint()" v-if="editorList.languageTitleEditor"
                         @addClass="updateEditorClass()"
                         :id-name="'language-title'"></text-editor>
          </div>
          <div class="pm-theme technical-skills-area mrgb-20" v-if="technicalSkillsData.mySkills.length > 0"
               :style="{order:listedMenu.find(x => x.name ==='Technical Skills').position}">
            <div
                @click="openEditor('technicalSkillSectionEditor',$event, technicalSkillsData, 'item_header', 'technicalSkill')"
                :class="{'active-editor':editorList.technicalSkillSectionEditor}">
              <p class="pm-theme section-header hobby-header mrgb-20" :style="primaryFontStyle"
                 @focusout="updateItemSectionHeader($event, technicalSkillsData)"
                 spellcheck="false" contenteditable>
                {{ technicalSkillsData.item_header }}</p>
            </div>
            <div class="ph-theme item-data-row" v-for="(skill,i) in technicalSkillsData.mySkills" :key="i">
              <div @click="openEditor('skillTitleEditor',$event, skill, 'name', 'technicalSkill')"
                   :class="{'active-editor':editorList.skillTitleEditor}">
                <p class="pm-theme content-text skill-title-text w-100"
                   :class="technicalSkillsData.html ? technicalSkillsData.html.skillTitleEditor : ''"
                   @focusout="updateItemData($event, skill, 'name', 'technicalSkill')"
                   spellcheck="false" contenteditable>{{ skill.name }}</p>
              </div>
              <v-rating v-model="skill.rate" background-color="black lighten-3" color="black"
                        @input="updateItemData($event, skill, 'rate', 'technicalSkill')"
                        medium></v-rating>
            </div>
            <text-editor @addPoint="addListPoint()" v-if="editorList.skillTitleEditor" @addClass="updateEditorClass()"
                         :id-name="'skill-title-text'"></text-editor>
          </div>
          <div class="pm-theme awards-area mrgb-20" v-if="awardsData.myAwards.length > 0"
               :style="{order:listedMenu.find(x => x.name ==='Awards').position}">
            <div @click="openEditor('awardSectionEditor',$event, awardsData, 'item_header', 'awards')"
                 :class="{'active-editor':editorList.awardSectionEditor}">
              <p class="pm-theme section-header hobby-header mrgb-20" :style="primaryFontStyle"
                 @focusout="updateItemSectionHeader($event, awardsData)"
                 spellcheck="false" contenteditable>
                {{ awardsData.item_header }}</p>
            </div>
            <div class="ph-theme item-data-row" v-for="(award,i) in awardsData.myAwards" :key="i">
              <div @click="openEditor('awardTitleEditor',$event, award, 'name', 'awards')"
                   :class="{'active-editor':editorList.awardTitleEditor}">
                <p class="pm-theme content-head award-title w-100"
                   :class="awardsData.html ? awardsData.html.awardTitleEditor : ''"
                   @focusout="updateItemData($event, award, 'name', 'awards')"
                   spellcheck="false" contenteditable>{{ award.name }}</p>
              </div>
              <div class="pm-theme content-text-cage">
                <div class="d-flex"
                     @click="openEditor('awardDateEditor',$event, award, 'issue_month', 'awards')"
                     :class="{'active-editor':editorList.awardDateEditor}">
                  <p class="pm-theme content-text award-date-text"
                     :class="awardsData.html ? awardsData.html.awardDateEditor : ''"
                     @focusout="updateItemData($event, award, 'issue_month', 'awards')"
                     spellcheck="false" contenteditable>{{ award.issue_month }}</p>
                  <p class="pm-theme content-text award-date-text"
                     :class="awardsData.html ? awardsData.html.awardDateEditor : ''"
                  >/</p>
                  <p class="pm-theme content-text award-date-text"
                     :class="awardsData.html ? awardsData.html.awardDateEditor : ''"
                     @focusout="updateItemData($event, award, 'issue_year', 'awards')"
                     spellcheck="false" contenteditable>{{ award.issue_year }}</p>
                </div>
                <div class="d-flex"
                     @click="openEditor('awardTextEditor',$event, award, 'information', 'awards')"
                     :class="{'active-editor':editorList.awardTextEditor}">
                  <p class="pm-theme content-text award-info-text w-100"
                     :class="awardsData.html ? awardsData.html.awardTextEditor : ''"
                     @focusout="updateItemData($event, award, 'information', 'awards')"
                     spellcheck="false" contenteditable>{{ award.description }}</p>
                </div>
              </div>
            </div>
            <text-editor @addPoint="addListPoint()" v-if="editorList.awardTitleEditor" @addClass="updateEditorClass()"
                         :id-name="'award-title'"></text-editor>
            <text-editor @addPoint="addListPoint()" v-if="editorList.awardDateEditor" @addClass="updateEditorClass()"
                         :id-name="'award-date-text'"></text-editor>
            <text-editor @addPoint="addListPoint()" v-if="editorList.awardTextEditor" @addClass="updateEditorClass()"
                         :id-name="'award-info-text'"></text-editor>
          </div>
          <div class="pm-theme certificate-area mrgb-20" v-if="certificateData.myCertificates.length > 0"
               :style="{order:listedMenu.find(x => x.name ==='Certifications').position}">
            <div @click="openEditor('certificateSectionEditor',$event, certificateData, 'item_header', 'certificates')"
                 :class="{'active-editor':editorList.certificateSectionEditor}">
              <p class="pm-theme section-header hobby-header mrgb-20" :style="primaryFontStyle"
                 @focusout="updateItemSectionHeader($event, certificateData)"
                 spellcheck="false" contenteditable>
                {{ certificateData.item_header }}</p>
            </div>
            <div class="ph-theme item-data-row" v-for="(certificate,i) in certificateData.myCertificates"
                 :key="i">
              <div @click="openEditor('certificateTitleEditor',$event, certificate , 'name', 'certificates')"
                   :class="{'active-editor':editorList.certificateTitleEditor}">
                <p class="pm-theme content-head certificate-title w-100"
                   :class="certificateData.html ? certificateData.html.certificateTitleEditor : ''"
                   @focusout="updateItemData($event, certificate , 'name', 'certificates')"
                   spellcheck="false" contenteditable>{{ certificate.name }}</p>
              </div>

              <div class="pm-theme content-text-cage">
                <div class="d-flex"
                     @click="openEditor('certificateCompanyEditor' ,$event, certificate, 'company', 'certificates')"
                     :class="{'active-editor':editorList.certificateCompanyEditor}">
                  <p class="pm-theme content-text certificate-company"
                     :class="certificateData.html ? certificateData.html.certificateCompanyEditor : ''"
                     @focusout="updateItemData($event, certificate, 'company', 'certificates')"
                     spellcheck="false" contenteditable>{{ certificate.company }}</p>
                </div>
                <div class="d-flex"
                     @click="openEditor('certificateDateEditor', $event, certificate, 'valid_month', 'certificates')"
                     :class="{'active-editor':editorList.certificateDateEditor}">
                  <p class="pm-theme content-text certificate-date-text"
                     :class="certificateData.html ? certificateData.html.certificateDateEditor : ''"
                     @focusout="updateItemData($event, certificate, 'valid_month', 'certificates')"
                     spellcheck="false" contenteditable>{{ certificate.valid_month }}</p>
                  <p class="pm-theme content-text certificate-date-text"
                     :class="certificateData.html ? certificateData.html.certificateDateEditor : ''"
                  >/</p>
                  <p class="pm-theme content-text certificate-date-text"
                     :class="certificateData.html ? certificateData.html.certificateDateEditor : ''"
                     @focusout="updateItemData($event, certificate, 'valid_year', 'certificates')"
                     spellcheck="false" contenteditable>
                    {{ certificate.valid_year }}</p>
                </div>
                <div class="d-flex"
                     @click="openEditor('certificateTextEditor' ,$event, certificate, 'link', 'certificates')"
                     :class="{'active-editor':editorList.certificateTextEditor}">
                  <p class="pm-theme content-text certificate-text"
                     :class="certificateData.html ? certificateData.html.certificateTextEditor : ''"
                     @focusout="updateItemData($event, certificate, 'link', 'certificates')"
                     spellcheck="false" contenteditable>{{ certificate.link }}</p>
                </div>
              </div>
            </div>
            <text-editor @addPoint="addListPoint()" v-if="editorList.certificateCompanyEditor"
                         @addClass="updateEditorClass()"
                         :id-name="'certificate-company'"></text-editor>
            <text-editor @addPoint="addListPoint()" v-if="editorList.certificateTitleEditor"
                         @addClass="updateEditorClass()"
                         :id-name="'certificate-title'"></text-editor>
            <text-editor @addPoint="addListPoint()" v-if="editorList.certificateDateEditor"
                         @addClass="updateEditorClass()"
                         :id-name="'certificate-date-text'"></text-editor>
            <text-editor @addPoint="addListPoint()" v-if="editorList.certificateTextEditor"
                         @addClass="updateEditorClass()"
                         :id-name="'certificate-text'"></text-editor>
          </div>
          <div class="pm-theme references-area mrgb-20" v-if="referencesData.myReferences.length > 0"
               :style="{order:listedMenu.find(x => x.name ==='References').position}">
            <div @click="openEditor('referencesSectionEditor',$event, referencesData, 'item_header', 'references')"
                 :class="{'active-editor':editorList.referencesSectionEditor}">
              <p class="pm-theme section-header hobby-header mrgb-20" :style="primaryFontStyle"
                 @focusout="updateItemSectionHeader($event, referencesData)"
                 spellcheck="false" contenteditable>
                {{ referencesData.item_header }}</p>
            </div>
            <div class="ph-theme item-data-row" v-for="(reference,i) in referencesData.myReferences"
                 :key="i">
              <div @click="openEditor('referenceTitleEditor', $event, reference , 'name', 'references')"
                   :class="{'active-editor':editorList.referenceTitleEditor}">
                <p class="pm-theme content-head w-100 reference-title"
                   :class="referencesData.html ? referencesData.html.referenceTitleEditor : ''"
                   @focusout="updateItemData($event, reference , 'name', 'references')"
                   spellcheck="false" contenteditable>{{ reference.name }}</p>
              </div>
              <div class="pm-theme content-text-cage">
                <div class="d-flex"
                     @click="openEditor('referenceEmailEditor', $event, reference , 'email', 'references')"
                     :class="{'active-editor':editorList.referenceEmailEditor}">
                  <p class="pm-theme content-text reference-email-text"
                     :class="referencesData.html ? referencesData.html.referenceEmailEditor : ''"
                     @focusout="updateItemData($event, reference , 'email', 'references')"
                     spellcheck="false" contenteditable>{{ reference.email }}</p>
                </div>
                <div class="d-flex"
                     @click="openEditor('referencePhoneEditor', $event, reference , 'phone', 'references')"
                     :class="{'active-editor':editorList.referencePhoneEditor}">
                  <p class="pm-theme content-text reference-phone-text"
                     :class="referencesData.html ? referencesData.html.referencePhoneEditor : ''"
                     @focusout="updateItemData($event, reference , 'phone', 'references')"
                     spellcheck="false" contenteditable>{{ reference.phone }}</p>
                </div>


              </div>
            </div>
            <text-editor @addPoint="addListPoint()" v-if="editorList.referenceTitleEditor"
                         @addClass="updateEditorClass()"
                         :id-name="'reference-title'"></text-editor>
            <text-editor @addPoint="addListPoint()" v-if="editorList.referenceEmailEditor"
                         @addClass="updateEditorClass()"
                         :id-name="'reference-email-text'"></text-editor>
            <text-editor @addPoint="addListPoint()" v-if="editorList.referencePhoneEditor"
                         @addClass="updateEditorClass()"
                         :id-name="'reference-phone-text'"></text-editor>
          </div>
          <div class="pm-theme social-area mrgb-20" v-if="socialData.mySocialData.length > 0"
               :style="{order:listedMenu.find(x => x.name ==='See My Social Media').position}">
            <div @click="openEditor('socialSectionEditor',$event, socialData, 'item_header', 'social')"
                 :class="{'active-editor':editorList.socialSectionEditor}">
              <p class="pm-theme section-header hobby-header mrgb-20" :style="primaryFontStyle"
                 @focusout="updateItemSectionHeader($event, socialData)"
                 spellcheck="false" contenteditable>
                {{ socialData.item_header }}</p>
            </div>
            <div class="pm-theme item-data-row" v-for="(social,i) in socialData.mySocialData" :key="i">
              <div class="d-flex align-items-center"
                   @click="openEditor('socialUrlEditor',$event, social, 'url', 'social')"
                   v-if="social.url !==''" :class="{'active-editor':editorList.socialUrlEditor}">
                <img class="social-icon"
                     :src="require(`@/assets/custom-icons/social-media-icons/s-3/${social.account}.svg`)"
                     alt="">
                <p class="pm-theme content-text social-url w-100"
                   :class="socialData.html ? socialData.html.socialUrlEditor : ''"
                   @focusout="updateItemData($event, social, 'url', 'social')"
                   spellcheck="false" contenteditable>{{ social.url }}</p>
              </div>
            </div>
            <text-editor @addPoint="addListPoint()" v-if="editorList.socialUrlEditor" @addClass="updateEditorClass()"
                         :id-name="'social-url'"></text-editor>
          </div>
          <div class="pm-theme conferences-area mrgb-20" v-if="conferencesData.myConferences.length > 0"
               :style="{order:listedMenu.find(x => x.name ==='Conferences').position}">
            <div @click="openEditor('conferenceSectionEditor',$event, conferencesData, 'item_header', 'conferences')"
                 :class="{'active-editor':editorList.conferenceSectionEditor}">
              <p class="pm-theme section-header hobby-header mrgb-20" :style="primaryFontStyle"
                 @focusout="updateItemSectionHeader($event, conferencesData)"
                 spellcheck="false" contenteditable>
                {{ conferencesData.item_header }}</p>
            </div>
            <div class="ph-theme item-data-row" v-for="(conference,i) in conferencesData.myConferences"
                 :key="i">
              <div @click="openEditor('conferenceTitleEditor', $event, conference , 'name', 'conferences')"
                   :class="{'active-editor':editorList.conferenceTitleEditor}">
                <p class="pm-theme content-head w-100 conference-title"
                   :class="conferencesData.html ? conferencesData.html.conferenceTitleEditor : ''"
                   @focusout="updateItemData($event, conference , 'name', 'conferences')"
                   spellcheck="false" contenteditable>{{ conference.name }}</p>
              </div>
              <div class="pm-theme content-text-cage">
                <div class="d-flex"
                     @click="openEditor('conferenceCompanyEditor', $event, conference , 'company', 'conferences')"
                     :class="{'active-editor':editorList.conferenceCompanyEditor}">
                  <p class="pm-theme content-text conference-company-text"
                     :class="conferencesData.html ? conferencesData.html.conferenceCompanyEditor : ''"
                     @focusout="updateItemData($event, conference , 'company', 'conferences')"
                     spellcheck="false" contenteditable>{{ conference.company }}</p>
                </div>
              </div>
            </div>
            <text-editor @addPoint="addListPoint()" v-if="editorList.conferenceTitleEditor"
                         @addClass="updateEditorClass()"
                         :id-name="'conference-title'"></text-editor>
            <text-editor @addPoint="addListPoint()" v-if="editorList.conferenceCompanyEditor"
                         @addClass="updateEditorClass()"
                         :id-name="'conference-company-text'"></text-editor>
          </div>
        </div>
      </div>
    </section>
    <div class="text-editor-bg" v-if="showEditorBg" @click="closeEditor"></div>
    <div class="text-editor-close-bg" v-if="showEditorBg" @click="closeEditor"></div>
  </div>
</template>
<script>
import textEditor from '../../../atomic/text-editor.vue'
import {mapState} from "vuex";
import themeUpdater from '../../../../mixins/theme-update-helper';
import PmStackedChart from "../peter-miller/components/pm-stacked-chart";

export default {
  name: 'peterMillerTheme',
  components: {PmStackedChart, textEditor},
  data() {
    return {
      pageCounter: 1,
    }
  },
  mixins: [themeUpdater],
  created() {
    this.updatePageHeight();
  },
  methods: {},
  computed: {
    ...mapState({
      resumeOptions: (store) => store.resume.resumeOptions,
      listedMenu: (store) => store.resume.listedMenu,
    }),
    headerStyle() {
      return {
        color: this.resumeOptions.textColorModel,
        fontFamily: this.resumeOptions.fontFamily
      }
    },
    primaryFontStyle() {
      return {
        color: this.resumeOptions.textColorModel,
        fontFamily: this.resumeOptions.fontFamily
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.pm-theme {
  position: relative;

  &.header-cage {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 35px;
  }

  &.item-data-row {
    margin: 10px auto;
    padding-left: 5px;
  }

  &.secondary-head {
    font-size: 15px;
    font-weight: 500;
    color: #000000;
    text-align: start;
    max-width: 210px;
  }

  &.content-head {
    font-size: 15px;
    color: #000000;
    font-weight: 500;
    text-align: start;
  }

  &.content-text {
    font-size: 13px;
    color: #000000;
    font-weight: 300;
    text-align: start;
  }

  &.content-text-cage {
    margin-bottom: 10px;
  }

  &.name-text {
    font-size: 36px;
    font-weight: 600;
    color: #000000;
    text-transform: uppercase;
  }

  &.img-cage {
    width: 150px;
    height: 160px;
  }

  &.header-black-row {
    width: 285px;
    height: 40px;
    background: #000000;
    // @media (max-width: 420px) {
    //   width: 100% !important;
    // }
  }

  &.communication-area {
    padding: 10px 35px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    // @media (max-width: 420px) {
    //   align-items: start !important;
    //   flex-direction: column;
    //   > div {
    //     margin-bottom: 10px;
    //   }
    // }
  }

  &.communication-text {
    font-size: 14px;
    font-weight: 300;
    max-width: 160px;
    text-align: start;
  }

  &.communication-icon {
    background: #000000;
    border-radius: 50%;
    padding: 7px;
    width: 25px;
    height: 25px;
    margin-right: 5px;
  }

  &.section-header {
    text-transform: uppercase;
    font-size: 19px;
    color: #000000;
    font-weight: 500;
    text-align: start;
  }

  &.list-items {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
  }

  &.full-side {
    width: 65%;
    margin-left: 10px;
    padding-top: 15px;
    border-top: 1px solid #000000;
    border-right: 1px solid #000000;
    display: flex;
    flex-direction: column;
    // @media (max-width: 420px) {
    //   margin: 0;
    //   padding: 0;
    //   width: 100%;
    //   border: none;
    // }
  }

  &.half-side {
    width: 35%;
    margin-left: 10px;
    padding-top: 15px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    // @media (max-width: 420px) {
    //   padding: 0;
    //   margin-right: 0;
    //   width: 100%;
    //   border: none;
    //   padding: 0px 25px;

    // }
  }

  &.about-me-area {
    padding: 0px 35px;
  }

  &.about-me-text {
    font-size: 16px;
    font-weight: 300;
    text-align: start;
  }

  &.full-cage {
    padding: 0px 25px;
    margin-bottom: 35px;
    transition: width 2s, height 2s, transform 2s;
  }

  &.my-time-rate {
    font-size: 13px;
    color: #ffffff;
    border-radius: 50%;
    padding: 5px;
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }
}


.color-rate {
  &.cage {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 35px;
    margin: 25px 0px;

    > div {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
    }
  }

  &.dark-black-box {
    background-color: #000000;
    height: 100%;
    width: 100%;
  }

  &.darker-black-box {
    background-color: #4d4d4d;
    height: 100%;
    width: 100%;
  }

  &.black-box {
    background-color: #808080;
    height: 100%;
    width: 100%;
  }

  &.lighter-black-box {
    background-color: #b2b2b2;
    height: 100%;
    width: 100%;
  }

  &.light-black-box {
    background-color: #c4c4c4;
    height: 100%;
    width: 100%;
  }

  &.number-text {
    background-color: #000000;
    color: #ffffff;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    font-size: 11px;
    margin-bottom: 5px !important;
    margin-left: 5px !important;
  }
}

.motto-title {
  font-size: 21px;
  font-weight: 500;
  color: #000000;
  text-align: start;

  &:before {
    content: '“';
  }

  &:after {
    content: '”';
  }
}

#my-time-rate-0 {
  background-color: #000000 !important;
}

#my-time-rate-1 {
  background-color: #4d4d4d !important;
}

#my-time-rate-2 {
  background-color: #808080 !important;
}

#my-time-rate-3 {
  background-color: #b2b2b2 !important;
}

#my-time-rate-4 {
  background-color: #c4c4c4 !important;
}
</style>
