import gateWay from './index.js';

export default {
    logIn(authRequest) {
        // return gateWay.post('/auth/sign_in', authRequest);
        return gateWay.post('v1/web/app/merge_login', authRequest)

    },
    register(registerObject) {
        //return gateWay.post('/auth', registerObject);
        return gateWay.post('v1/web/app/merge_register', registerObject);

    },
    validateToken() {
        const params = {
            uid: localStorage.getItem('uid'),
            client: localStorage.getItem('client'),
            'access-token': localStorage.getItem('access-token')
        };
        return gateWay.get('auth/validate_token', {params: params})
    },
    mergeStart() {
        const startObject = {
            job_id: 1
        };
        return gateWay.post('v1/web/app/start', startObject)
    },
    changePassword(passwordObject) {
        return gateWay.put('auth/password', passwordObject);
    },
    getOauth(authObject){
        return gateWay.post('v1/web/app/oauth', authObject);
    },
    getUserInfo(){
        return gateWay.get('v1/web/users/information')
    }
}
