import {mapActions, mapState} from 'vuex';

export default {
    data() {
        return {};
    },
    computed: {
        ...mapState({
            resumeOptions: (store) => store.resume.resumeOptions,
            menuItems: (store) => store.resume.itemOrderList,
            summaryData: (store) => store.summary.summaryData,
            experienceData: (store) => store.experience.experienceData,
            educationData: (store) => store.education.educationData,
            strengthsData: (store) => store.strengths.strengthsData,
            hobbiesData: (store) => store.hobbies.hobbiesData,
            languagesData: (store) => store.languageSkills.languageSkillsData,
            technicalSkillsData: (store) => store.technicalSkills.technicalSkillsData,
            awardsData: (store) => store.awards.awardsData,
            certificateData: (store) => store.certificate.certificateData,
            referencesData: (store) => store.references.referencesData,
            projectsData: (store) => store.projects.projectsData,
            publicationData: (store) => store.publications.publicationData,
            myTimeData: (store) => store.myTime.myTimeData,
            mottoData: (store) => store.philosophy.mottoData,
            volunteeringData: (store) => store.volunteering.volunteeringData,
            socialData: (store) => store.social.socialData
        }),
    },
    methods: {
        ...mapActions({
            updateSummaryHtml: 'summary/updateSummaryHtml',
            updateSummaryTitle: 'summary/updateSummaryTitle',
            updateSummaryId: 'summary/updateSummaryId',
            updateExperienceHtml: 'experience/updateExperienceHtml',
            updateExperienceTitle: 'experience/updateExperienceTitle',
            updateExperienceId: 'experience/updateExperienceId',
            updateEducationHtml: 'education/updateEducationHtml',
            updateEducationTitle: 'education/updateEducationTitle',
            updateEducationId: 'education/updateEducationId',
            updateProjectHtml: 'projects/updateProjectHtml',
            updateProjectTitle: 'projects/updateProjectTitle',
            updateProjectId: 'projects/updateProjectId',
            updatePublicationHtml: 'publications/updatePublicationHtml',
            updatePublicationTitle: 'publications/updatePublicationTitle',
            updatePublicationId: 'publications/updatePublicationId',
            updateMyTimeHtml: 'myTime/updateMyTimeHtml',
            updateMyTimeTitle: 'myTime/updateMyTimeTitle',
            updateMyTimeId: 'myTime/updateMyTimeId',
            updateMottoHtml: 'philosophy/updateMottoHtml',
            updateMottoTitle: 'philosophy/updateMottoTitle',
            updateMottoId: 'philosophy/updateMottoId',
            updateVolunteerHtml: 'volunteering/updateVolunteerHtml',
            updateVolunteerTitle: 'volunteering/updateVolunteerTitle',
            updateVolunteerId: 'volunteering/updateVolunteerId',
            updateStrengthHtml: 'strengths/updateStrengthHtml',
            updateStrengthTitle: 'strengths/updateStrengthTitle',
            updateStrengthId: 'strengths/updateStrengthId',
            updateHobbiesHtml: 'hobbies/updateHobbiesHtml',
            updateHobbiesTitle: 'hobbies/updateHobbiesTitle',
            updateHobbiesId: 'hobbies/updateHobbiesId',
            updateLanguageHtml: 'languageSkills/updateLanguageHtml',
            updateLanguageTitle: 'languageSkills/updateLanguageTitle',
            updateLanguageId: 'languageSkills/updateLanguageId',
            updateSkillHtml: 'technicalSkills/updateSkillHtml',
            updateSkillTitle: 'technicalSkills/updateSkillTitle',
            updateSkillId: 'technicalSkills/updateSkillId',
            updateAwardHtml: 'awards/updateAwardHtml',
            updateAwardTitle: 'awards/updateAwardTitle',
            updateAwardId: 'awards/updateAwardId',
            updateCertificateHtml: 'certificate/updateCertificateHtml',
            updateCertificateTitle: 'certificate/updateCertificateTitle',
            updateCertificateId: 'certificate/updateCertificateId',
            updateReferenceHtml: 'references/updateReferenceHtml',
            updateReferenceTitle: 'references/updateReferenceTitle',
            updateReferenceId: 'references/updateReferenceId',
            updateConferenceHtml: 'conferences/updateConferenceHtml',
            updateConferenceTitle: 'conferences/updateConferenceTitle',
            updateConferenceId: 'conferences/updateConferenceId',
            editSocialData: 'social/editSocialData',
            updateSocialHtml: 'social/updateSocialHtml',
            updateSocialTitle: 'social/updateSocialTitle',
            updateSocialId: 'social/updateSocialId',
            updateGreetingHtml: 'greeting/updateGreetingHtml',
            updateGreetingTitle: 'greeting/updateGreetingTitle',
            updateGreetingId: 'greeting/updateGreetingId',
            updateOpeningHtml: 'opening/updateOpeningHtml',
            updateOpeningTitle: 'opening/updateOpeningTitle',
            updateOpeningId: 'opening/updateOpeningId',
            updateBodyHtml: 'body/updateBodyHtml',
            updateBodyTitle: 'body/updateBodyTitle',
            updateBodyId: 'body/updateBodyId',
            updateClosingHtml: 'closing/updateClosingHtml',
            updateClosingTitle: 'closing/updateClosingTitle',
            updateClosingId: 'closing/updateClosingId',
            setTotalPage: 'resume/setTotalPage',

        }),
        setMenuItemDetail(menu) {
            if (menu.static_title === 'Summary') {
                this.updateSummaryHtml(menu.html);
                this.updateSummaryTitle(menu.title);
                this.updateSummaryId(menu.id);
            }
            if (menu.static_title === 'Experience') {
                this.updateExperienceHtml(menu.html);
                this.updateExperienceTitle(menu.title);
                this.updateExperienceId(menu.id);
            }
            if (menu.static_title === 'Education') {
                this.updateEducationHtml(menu.html);
                this.updateEducationTitle(menu.title);
                this.updateEducationId(menu.id);
            }
            if (menu.static_title === 'Projects') {
                this.updateProjectHtml(menu.html);
                this.updateProjectTitle(menu.title);
                this.updateProjectId(menu.id);
            }
            if (menu.static_title === 'Publications') {
                this.updatePublicationHtml(menu.html);
                this.updatePublicationTitle(menu.title);
                this.updatePublicationId(menu.id);
            }
            if (menu.static_title === 'My Time') {
                this.updateMyTimeHtml(menu.html);
                this.updateMyTimeTitle(menu.title);
                this.updateMyTimeId(menu.id);
            }
            if (menu.static_title === 'Mottos') {
                this.updateMottoHtml(menu.html);
                this.updateMottoTitle(menu.title);
                this.updateMottoId(menu.id);
            }
            if (menu.static_title === 'Volunteering') {
                this.updateVolunteerHtml(menu.html);
                this.updateVolunteerTitle(menu.title);
                this.updateVolunteerId(menu.id);
            }
            if (menu.static_title === 'Strengths') {
                this.updateStrengthHtml(menu.html);
                this.updateStrengthTitle(menu.title);
                this.updateStrengthId(menu.id);
            }
            if (menu.static_title === 'Hobbies') {
                this.updateHobbiesHtml(menu.html);
                this.updateHobbiesTitle(menu.title);
                this.updateHobbiesId(menu.id);
            }
            if (menu.static_title === 'Language Skills') {
                this.updateLanguageHtml(menu.html);
                this.updateLanguageTitle(menu.title);
                this.updateLanguageId(menu.id);
            }
            if (menu.static_title === 'Technical Skills') {
                this.updateSkillHtml(menu.html);
                this.updateSkillTitle(menu.title);
                this.updateSkillId(menu.id);
            }
            if (menu.static_title === 'Awards') {
                this.updateAwardHtml(menu.html);
                this.updateAwardTitle(menu.title);
                this.updateAwardId(menu.id);
            }
            if (menu.static_title === 'Certifications') {
                this.updateCertificateHtml(menu.html);
                this.updateCertificateTitle(menu.title);
                this.updateCertificateId(menu.id);
            }
            if (menu.static_title === 'References') {
                this.updateReferenceHtml(menu.html);
                this.updateReferenceTitle(menu.title);
                this.updateReferenceId(menu.id);
            }
            if (menu.static_title === 'Conferences') {
                this.updateConferenceHtml(menu.html);
                this.updateConferenceTitle(menu.title);
                this.updateConferenceId(menu.id);
            }
            if (menu.static_title === 'See My Social Media') {
                this.updateSocialHtml(menu.html);
                this.updateSocialTitle(menu.title);
                this.updateSocialId(menu.id);
            }

            // Cover Letter
            if (menu.static_title === 'Salutation and Greeting') {
                this.updateGreetingHtml(menu.html);
                this.updateGreetingTitle(menu.title);
                this.updateGreetingId(menu.id);
            }
            if (menu.static_title === 'Opening Paragraph') {
                this.updateOpeningHtml(menu.html);
                this.updateOpeningTitle(menu.title);
                this.updateOpeningId(menu.id);
            }
            if (menu.static_title === 'Cover Letter Body') {
                this.updateBodyHtml(menu.html);
                this.updateBodyTitle(menu.title);
                this.updateBodyId(menu.id);
            }
            if (menu.static_title === 'Closing and Signature') {
                this.updateClosingHtml(menu.html);
                this.updateClosingTitle(menu.title);
                this.updateClosingId(menu.id);
            }
        },
    },

};
