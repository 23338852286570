export default {
    namespaced: true,
    state: {
        greetingData: {
            item_header: 'Salutation and Greeting',
            item_id: '',
            html: {},
            name: '',
            title: '',
            id: '',
        },
    },
    actions: {
        updateGreetingData({commit}, data) {
            commit('UPDATE_GREETING_DATA', data);
        },
        updateGreetingHtml({commit}, data) {
            commit('UPDATE_GREETING_HTML', data);
        },
        editGreeting({commit}, data) {
            commit('EDIT_GREETING', data);
        },
        updateGreetingTitle({commit}, data) {
            commit('UPDATE_GREETING_TITLE', data);
        },
        updateGreetingId({commit}, data) {
            commit('UPDATE_GREETING_ID', data)
        }
    },
    mutations: {
        UPDATE_GREETING_DATA(state, payload) {
            state.greetingData.name = payload.name;
            state.greetingData.title = payload.title;
            state.greetingData.id = payload.id;
        },
        EDIT_GREETING(state,payload){
            Object.assign(state.greetingData,payload.data);
        },
        UPDATE_GREETING_HTML(state, payload) {
            state.greetingData.html = payload;
        },
        UPDATE_GREETING_TITLE(state, payload) {
            state.greetingData.item_header = payload;
        },
        UPDATE_GREETING_ID(state, payload) {
            state.greetingData.item_id = payload;
        }
    }
}
