<template>
    <section>
        <div class="text-editor main-cage">
            <div class="text-editor inner-cage">
                <img class="flip-icon" src="../../assets/custom-icons/text-editor-icons/text-align-right-icon.svg"
                     @click="setAlignmentClass('txt-align-left')"
                     :class="{'active-icon': classList.indexOf('txt-align-left') !== -1}">
                <img src="../../assets/custom-icons/text-editor-icons/text-align-center-icon.svg"
                     @click="setAlignmentClass('txt-align-center')"
                     :class="{'active-icon': classList.indexOf('txt-align-center') !== -1}">
                <img src="../../assets/custom-icons/text-editor-icons/text-align-right-icon.svg"
                     @click="setAlignmentClass('txt-align-right')"
                     :class="{'active-icon': classList.indexOf('txt-align-right') !== -1}">
                <img src="../../assets/custom-icons/text-editor-icons/text-type-list-icon.svg"
                     @click="addPoint()">
            </div>
            <div class="text-editor inner-cage">
                <img src="../../assets/custom-icons/text-editor-icons/bold-icon.svg"
                     @click="setClassName('txt-bold')"
                     :class="{'active-icon': classList.indexOf('txt-bold') !== -1}">
                <img src="../../assets/custom-icons/text-editor-icons/italic-icon.svg"
                     @click="setClassName('txt-italic')"
                     :class="{'active-icon': classList.indexOf('txt-italic') !== -1}">
                <img src="../../assets/custom-icons/text-editor-icons/underline-icon.svg"
                     @click="setClassName('txt-underline')"
                     :class="{'active-icon': classList.indexOf('txt-underline') !== -1}">
                <img src="../../assets/custom-icons/text-editor-icons/capitalize-icon.svg"
                     @click="setClassName('txt-upperCase')"
                     :class="{'active-icon': classList.indexOf('txt-upperCase') !== -1}">
            </div>
            <div class="text-editor inner-cage">
                <div>
                    <p class="text-editor inner-header">Font</p>
                    <div class="text-editor font-selector-cage">
                        <v-select
                                v-model="selectedFont"
                                @change="setFontClassName(selectedFont)"
                                :items="fontList"
                                :item-text="item => item.name"
                                item-value="class"
                                :hide-details="true"
                                label="Change Font"
                                solo
                        ></v-select>
                    </div>
                </div>
            </div>
            <div class="text-editor inner-cage">
                <div>
                    <p class="text-editor inner-header">Size</p>
                    <div class="text-editor size-cage">
                        <v-sheet max-width="217">
                            <v-slide-group v-model="selectedSize">
                                <v-slide-item v-for="(size,i) in sizeList" :key="i">
                                    <p class="text-editor size-input" @click="setSizeClass(size.class)"
                                       :class="{'active-icon': classList.indexOf(size.class) !== -1}">{{size.name}}</p>
                                </v-slide-item>
                            </v-slide-group>
                        </v-sheet>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

    import themeUpdater from "../../mixins/theme-update-helper";

    export default {
        name: 'textEditor',
        props: ['idName'],
        mixins: [themeUpdater],
        data() {
            return {
                selectedFont: '',
                selectedSize: '',
                selectedElementList: [],
                classList: [],
                fontList: [
                    {name: 'Open Sans', class: 'font-open-sans'},
                    {name: 'Calibri', class: 'font-calibri'},
                    {name: 'Georgia', class: 'font-georgia'},
                    {name: 'Playfair Display', class: 'font-play-fair-display'},
                    {name: 'Poppins', class: 'font-poppins'},
                    {name: 'Oswald', class: 'font-oswald'},
                    {name: 'Montserrat', class: 'font-montserrat'}
                ],
                sizeList: [
                    {name: '12', class: 'fs-12'},
                    {name: '14', class: 'fs-14'},
                    {name: '16', class: 'fs-16'},
                    {name: '18', class: 'fs-18'},
                    {name: '20', class: 'fs-20'},
                    {name: '22', class: 'fs-22'},
                    {name: '24', class: 'fs-24'},
                    {name: '26', class: 'fs-26'},
                    {name: '28', class: 'fs-28'},
                    {name: '30', class: 'fs-30'},
                    {name: '32', class: 'fs-32'},
                    {name: '34', class: 'fs-34'},
                    {name: '36', class: 'fs-36'},
                ]
            }
        },
        created() {
            console.log(this.idName,document.getElementsByClassName(this.idName));
            [...document.getElementsByClassName(this.idName)].forEach(obj => { 
                this.selectedElementList.push(obj);
            });
            this.setClassList();
        },
        methods: {
            setClassList() {
                this.classList = [];
                this.selectedElementList[0].classList.forEach(innerClass => {
                    this.classList.push(innerClass);
                });
                this.classList.forEach(className => {
                    this.fontList.forEach(font => {
                        if (className === font.class) {
                            this.selectedFont = font;
                        }
                    });
                });
            },
            setClassName(className) {
                if (this.classList.indexOf(className) === -1) {
                    this.selectedElementList.forEach(selectedElement => {
                        selectedElement.classList.add(className);
                    });
                    this.classList.push(className)
                } else {
                    this.selectedElementList.forEach(selectedElement => {
                        selectedElement.classList.remove(className);
                    });
                    const classIndex = this.classList.indexOf(className);
                    this.classList.splice(classIndex, 1);
                }
                this.$emit('addClass');
            },
            setAlignmentClass(className) {
                this.selectedElementList.forEach(selectedTextElement => {
                    selectedTextElement.classList.remove('txt-align-left');
                    selectedTextElement.classList.remove('txt-align-center');
                    selectedTextElement.classList.remove('txt-align-right');
                });
                const leftIndex = this.classList.indexOf('txt-align-left');
                const centerIndex = this.classList.indexOf('txt-align-center');
                const rightIndex = this.classList.indexOf('txt-align-right');
                if (leftIndex !== -1) {
                    this.classList.splice(leftIndex, 1);
                }
                if (centerIndex !== -1) {
                    this.classList.splice(centerIndex, 1);
                }
                if (rightIndex !== -1) {
                    this.classList.splice(rightIndex, 1);
                }

                this.selectedElementList.forEach(selectedElement => {
                    selectedElement.classList.add(className);
                });
                this.classList.push(className);
                this.$emit('addClass');
            },
            setFontClassName(className) {
                this.fontList.forEach(font => {
                    const fontIndex = this.classList.indexOf(font.class);
                    if (fontIndex !== -1) {
                        this.classList.splice(fontIndex, 1);
                        this.selectedElementList.forEach(selectedElement => {
                            selectedElement.classList.remove(font.class);
                        });
                    }
                });
                this.classList.push(className);
                this.selectedElementList.forEach(selectedElement => {
                    selectedElement.classList.add(className);
                });
                this.$emit('addClass');
            },
            setSizeClass(className) {
                this.sizeList.forEach(size => {
                    const sizeIndex = this.classList.indexOf(size.class);
                    if (sizeIndex !== -1) {
                        this.classList.splice(sizeIndex, 1);
                        this.selectedElementList.forEach(selectedElement => {
                            selectedElement.classList.remove(size.class);
                        })
                    }
                });
                this.classList.push(className);
                this.selectedElementList.forEach(selectedElement => {
                    selectedElement.classList.add(className);
                });
                this.$emit('addClass');
            },
            addPoint() {
               this.$emit('addPoint')
            }
        }
    }
</script>
<style lang="scss" scoped>
    ::v-deep .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
        box-shadow: none !important;
        margin-bottom: 0;
        padding: 0 0 0 10px;
    }

    ::v-deep .v-input__control {
        min-height: auto !important;
    }

    ::v-deep .v-slide-group__next,
    ::v-deep .v-slide-group__prev {
        min-width: 15px !important;
    }

    ::v-deep .v-select__slot {
        overflow: hidden !important;
    }

    .text-editor {
        &.main-cage {
            background-color: #ffffff;
            padding: 0px 10px;
            position: absolute;
            z-index: 999;
            border-radius: 5px;
          //  top: 40%;
           top: 0;
            right: -253px;
        }

        &.inner-cage {
            display: flex;
            align-items: center;
            height: 65px;
            border-top: 1px solid #F1F7FF;

            &:first-child {
                padding-top: 0;
                border-top: none !important;
            }

            &:last-child {
                padding-bottom: 0;
            }

            > img {
                margin-right: 22px;
                cursor: pointer;
                padding: 8px 7px;

                &:last-child {
                    margin-right: 0px;
                }
            }
        }

        &.inner-header {
            font-size: 10px;
            color: #9EA2B1;
            font-weight: 500;
            text-align: start;
        }

        &.font-selector-cage {
            max-height: 200px;
            max-width: 200px !important;
            overflow: auto;
        }

        &.size-input {
            padding: 3.5px 6.5px;
            font-size: 14px;
            font-weight: 500;
            color: #000000;
            margin-right: 15px;
            cursor: pointer;

            &:last-child {
                margin-right: 0 !important;
            }
        }
    }

    .active-icon {
        background-color: rgba(116, 39, 116, 0.1) !important;
        border-radius: 2.5px;
    }
</style>
