import gateWay from './index.js';


export default {
    getVideoCv() {
        return gateWay.get('v1/web/video_cvs/details')
    },
    getVideoCvByCode(code){
        return gateWay.get('v1/web/video_cvs/details_public?code='+ code)
    },
    createVideoCv(videoCvObject) {
        return gateWay.post('v1/web/video_cvs', videoCvObject)
    },
}
