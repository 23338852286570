<template>
    <section>
        <p class="create-resume main-header">Select Industry</p>
        <p class="create-resume main-description">Select industry and experience</p>
        <div class="create-resume">
            <p class="create-resume table-header mb-20">Job Categories</p>
        </div>
        <div class="create-resume table-cage">
            <div class="d-flex row">
                <div :class="isMobile() ? 'create-resume main-category-list col-4 small': 'create-resume main-category-list col-2'">
                    <p class="create-resume main-category-head">Categories</p>
                    <div class="create-resume main-category-cage">
                        <p class="create-resume main-category-text" v-for="(mainCategory,i) in mainCategories" :key="i"
                           :class="{'active-main':selectedJob.mainCategory === mainCategory.id}"
                           @click="setMainCategory(mainCategory.id)">
                            {{mainCategory.title}}</p>
                    </div>
                </div>
                <div :class="isMobile() ?'col-8 small':'col-10'">
                    <input class="primary-input w-100" placeholder="Search" v-model="searchKey">

                    <div class="create-resume sub-category-main-cage row">
                        <div class="create-resume sub-category-text-cage col-6" v-for="(subCategory,i) in filteredList"
                             :key="i">
                            <input class="checkbox-custom" type="checkbox"
                                   @input="setSubCategory(subCategory.id)"
                                   :disabled="selectedJob.subCategory === subCategory.id"
                                   :name="'category'" :id="'checkbox' + i"
                                   :checked="selectedJob.subCategory === subCategory.id">
                            <label class="checkbox-custom-label" :for="'checkbox' + i">{{subCategory.title}}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="create-resume table-cage mt-3 mb-80">
            <div class="create-resume table-head-line">
                <p class="create-resume table-header">Experience</p>
            </div>
            <div class="experience-cage" id="experienceCage">
                <div class="experience-text-cage">
                    <input class="checkbox-custom" type="checkbox" :name="'experience-1'" :id="'experience-1'"
                           @input="setExperience(0)" :checked="selectedJob.experienceId === 0"
                           :disabled="selectedJob.experienceId === 0">
                    <label class="checkbox-custom-label" :for="'experience-1'">1 - 2 Years</label>
                </div>
                <div class="experience-text-cage">
                    <input class="checkbox-custom" type="checkbox" :name="'experience-2'" :id="'experience-2'"
                           @input="setExperience(1)" :checked="selectedJob.experienceId === 1"
                           :disabled="selectedJob.experienceId === 1">
                    <label class="checkbox-custom-label" :for="'experience-2'">3 - 6 Years</label>
                </div>
                <div class="experience-text-cage">
                    <input class="checkbox-custom" type="checkbox" :name="'experience-3'" :id="'experience-3'"
                           @input="setExperience(2)" :checked="selectedJob.experienceId === 2"
                           :disabled="selectedJob.experienceId === 2">
                    <label class="checkbox-custom-label" :for="'experience-3'">6 - 10 Years</label>
                </div>
                <div class="experience-text-cage">
                    <input class="checkbox-custom" type="checkbox" :name="'experience-4'" :id="'experience-4'"
                           @input="setExperience(3)" :checked="selectedJob.experienceId === 3"
                           :disabled="selectedJob.experienceId === 3">
                    <label class="checkbox-custom-label" :for="'experience-4'">10+ Years</label>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    import resumeService from '../../../services/resume';

    export default {
        name: 'industrySelector',
        data() {
            return {
                selectedJob: {
                    mainCategory: 28,
                    subCategory: '',
                    experienceId: '',
                },
                mainCategories: [],
                subCategories: [],
                searchKey: ''
            }
        },
        created() {
            this.getMainCategories();
            this.getSubCategories();
        },
        methods: {
            getMainCategories() {
                resumeService.getJobMainCategories().then((mainCategories) => {
                    this.mainCategories = mainCategories.data.data;
                })
            },
            getSubCategories() {
                resumeService.getJobSubCategories(this.selectedJob.mainCategory).then((subCategories) => {
                    this.subCategories = [];
                    this.subCategories = subCategories.data.data;
                })
            },
            setMainCategory(mainCategoryId) {
                this.selectedJob.mainCategory = mainCategoryId;
                this.selectedJob.subCategory = '';
                this.getSubCategories();
            },
            setSubCategory(subCategoryId) {
                this.selectedJob.subCategory = subCategoryId;
                document.getElementById("experienceCage").scrollIntoView();
            },  isMobile() {
    return this.$vuetify.breakpoint.sm||this.$vuetify.breakpoint.xs
 },
            setExperience(experienceId) {
                this.selectedJob.experienceId = experienceId;
            }
        },
        watch: {
            'selectedJob.mainCategory': function () {
                this.getSubCategories();
            },
            selectedJob: {
                deep: true,
                handler() {
                    this.$emit('changeCategory', this.selectedJob);
                }
            }
        },
        computed: {
            filteredList() {
                return this.subCategories.filter(item => {
                    return item.title.toLocaleLowerCase().includes(this.searchKey.toLocaleLowerCase())
                })
            }
        }
    }
</script>
<style lang="scss" scoped>
    .create-resume {
        &.main-category-head {
            font-size: 14px;
            font-weight: 600;
            font-family: "Segoe UI";
            color: #000000;
            text-align: start;
            padding-bottom: 13px;
            border-bottom: 1px solid rgba(196, 196, 196, 0.12);
        }

        &.main-category-list {
            border-right: 2px solid #F1F7FF;
            padding: 20px !important;
            padding-bottom: 3px !important;
            padding-right: 5px !important;
        }

        &.main-category-cage {
            max-height: 342px;
            height: 100%;
            overflow-y: auto;
            margin-top: 10px;
        }

        &.sub-category-main-cage {
            padding: 20px;
            display: flex;
            max-height: 400px;
            overflow-y: auto;
        }

        &.sub-category-text-cage {
            display: flex;
        }

        &.main-category-text {
            font-size: 12px;
            font-weight: 500;
            font-family: "Segoe UI";
            text-align: start;
            cursor: pointer;
            margin-top: 14px;
            color: #000000;
            line-height: 13px;

            &.active-main {
                font-weight: 600;
                color: #742774 !important;
            }
        }
    }

    .experience-text-cage {
        margin-right: 30px;

        > label {
            margin-bottom: 0 !important;
        }
    }

    .experience-cage {
        display: flex;
        padding: 20px;
    }
    .mb-80{
        margin-bottom: 80px;
    }
    .mb-20{
        margin-bottom: 15px !important;
    }
</style>
