import { render, staticRenderFns } from "./daisy-gibbs.vue?vue&type=template&id=0af35ef7&scoped=true&"
import script from "./daisy-gibbs.vue?vue&type=script&lang=js&"
export * from "./daisy-gibbs.vue?vue&type=script&lang=js&"
import style0 from "./daisy-gibbs.vue?vue&type=style&index=0&id=0af35ef7&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0af35ef7",
  null
  
)

export default component.exports