<template>
    <section>
        <div class="item-greeting cage">
            <div>
                <div class="item-input-sub-cage">
                    <div class="item-input-cage">
                        <p class="item-input-header">Dear</p>
                        <input class="primary-input w-100" v-model="greetingData.title" @input="changeData"
                               @focusout="updateGreeting()">
                    </div>
                    <div class="item-input-cage">
                        <p class="item-input-header">Name, Surname</p>
                        <input class="primary-input w-100" v-model="greetingData.name" @input="changeData"
                               @focusout="updateGreeting()">
                    </div>

                </div>
                <div class="item-footer-button-cage">
                    <v-btn class="yellow-button w-100" @click="updateGreeting()">
                        Save
                    </v-btn>
                </div>
            </div>
        </div>
        <v-snackbar v-model="showUpdateGreetingNotification" :timeout="2000" :color="'success'" top>
            Greeting has been updated.
        </v-snackbar>
    </section>
</template>
<script>
    import {mapActions, mapState} from "vuex";
    import itemService from '../../../services/item-data'

    export default {
        name: 'coverLetterGreeting',
        data() {
            return {
                showUpdateGreetingNotification: false,
            }
        },
        computed: {
            ...mapState({
                greetingData: (store) => store.greeting.greetingData
            })
        },
        methods: {
            ...mapActions({
                updateGreetingData: 'greeting/updateGreetingData',
            }),
            changeData() {
                const editedObject = {
                    name: this.greetingData.name,
                    title: this.greetingData.title,
                    id: this.greetingData.id,
                };
                this.updateGreetingData(editedObject);
            },
            setEditedObject() {
                const editedObject = {
                    name: this.greetingData.name,
                    title: this.greetingData.title,
                    id: this.greetingData.id,
                };
                return editedObject;
            },
            updateGreeting() {
                const editedGreeting = this.setEditedObject();
                itemService.updateGreeting(editedGreeting.id, editedGreeting).then((updateResponse) => {
                    this.updateGreetingData(updateResponse.data);
                    this.showUpdateGreetingNotification = true;
                });
            },
        }
    }
</script>
<style lang="scss" scoped>
    .item-input-sub-cage{
        height: calc(100vh - 260px) !important;
    }
</style>
