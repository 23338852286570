<template>
    <section>
        <div class="color-palette cage" :class="{'more-color-active':showMoreColors}">
            <div class="color-palette picker" v-for="color in colorArray" :key="color.id"
                 :style="{backgroundColor:color.color}" @click="selectedColor=color">
            </div>
            <img @click="showMoreColors = !showMoreColors" src="../../../../assets/custom-icons/icon-plus.svg">
        </div>
        <div v-if="showMoreColors">
            <p>Colors</p>
            <v-color-picker v-model="selectedColor" :hide-inputs="true"  class="ma-2" show-swatches></v-color-picker>
        </div>
    </section>
</template>
<script>
    import resumeService from "../../../../services/resume";

    export default {
        name:'colorSelector',
        data(){
            return{
                selectedColor:'',
                showMoreColors: false,
                colorArray: [{color: '#fff', id: 0},
                    {color: '#F2C811', id: '1'},
                    {color: '#282B34', id: '2'},
                    {color: '#7e8085', id: '3'},
                    {color: '#FC605C', id: '4'},
                    {color: '#4C9F88', id: '5'},
                    {color: '#69F0AE', id: '6'},
                    {color: '#34C749', id: '7'},
                    {color: '#1BADAD', id: '8'},
                    {color: '#571CCA', id: '9'},
                    {color: '#F0D868', id: '10'}
                ]
            }
        },
        watch:{
            selectedColor:{
                deep:true,
                handler(){
                    const colorObject = {
                        color_code: this.selectedColor.color
                    };
                    resumeService.updateHeader(colorObject).then(() => {
                        this.$emit('colorModelChange', this.selectedColor.color)
                    });
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    .v-color-picker {
        display: flex !important;
        flex-direction: column-reverse !important;
    }

    .color-palette {
        &.cage {
            display: flex;
            min-width: 217px;
            width: 100%;
            max-width: 217px;
            flex-direction: row;
            flex-wrap: wrap;
            padding: 20px 10px 20px 15px;

            &.more-color-active {
                max-width: 316px !important;
            }
        }

        &.picker {
            height: 38px;
            width: 38px;
            border-radius: 50%;
            margin-right: 10px;
            margin-bottom: 10px;
        }
    }
</style>
