<template>
    <section>
        <div class="resume-preview cage">
            <div class="d-flex flex-row align-items-start justify-content-center h-100 overflow-auto p-20-0" v-if="!showLoader">
                <div class="resume-preview image-cage">
                    
        <div class="resume-content cage" v-if="resumeType === 'cover_letter'">
            <cl-peter-miller v-if="resumeOptions.selectedTemplate === 1"/>
            <cl-arthur-bekers v-if="resumeOptions.selectedTemplate === 2"/>
            <cl-piper-walker v-if="resumeOptions.selectedTemplate === 3"/>
            <cl-francesca-young v-if="resumeOptions.selectedTemplate === 4"/>
            <cl-madison-parry v-if="resumeOptions.selectedTemplate === 5"/>
            <cl-harvey-bell v-if="resumeOptions.selectedTemplate === 6"/>
            <cl-eloise-winter v-if="resumeOptions.selectedTemplate === 7"/>
            <cl-brooke-yates v-if="resumeOptions.selectedTemplate === 8"/>
            <cl-paula-york v-if="resumeOptions.selectedTemplate === 9"/>
            <cl-anton-trotier v-if="resumeOptions.selectedTemplate === 0"/>
            <cl-ella-dale v-if="resumeOptions.selectedTemplate === 11"/>
            <cl-daisy-gibbs v-if="resumeOptions.selectedTemplate === 12"/>
            <cl-olympia-bonnet v-if="resumeOptions.selectedTemplate === 13"/>
            <cl-jay-jarvis v-if="resumeOptions.selectedTemplate === 14"/>
            <cl-sophie-bond v-if="resumeOptions.selectedTemplate === 15"/>
            <cl-jake-sutton v-if="resumeOptions.selectedTemplate === 16"/>
            <cl-kiera-lucas v-if="resumeOptions.selectedTemplate === 17"/>
            <cl-robert-anders v-if="resumeOptions.selectedTemplate === 18"/>
            <cl-libby-shaw v-if="resumeOptions.selectedTemplate === 19"/>
            <cl-max-chandler v-if="resumeOptions.selectedTemplate === 20"/>

        </div>
        <div class="resume-content cage" v-else>
            <green-theme v-if="resumeOptions.selectedTemplate === 'greenTemplate'"
                         :resumeOptions="resumeOptions"></green-theme>
            <peter-miller v-if="resumeOptions.selectedTemplate === 1"></peter-miller>
            <arthur-bekers v-if="resumeOptions.selectedTemplate === 2"></arthur-bekers>
            <piper-walker v-if="resumeOptions.selectedTemplate === 3"></piper-walker>
            <francesca-young v-if="resumeOptions.selectedTemplate === 4"></francesca-young>
            <madison-parry v-if="resumeOptions.selectedTemplate === 5"></madison-parry>
            <harvey-bell v-if="resumeOptions.selectedTemplate === 6"></harvey-bell>
            <eloise-winter v-if="resumeOptions.selectedTemplate === 7"></eloise-winter>
            <brooke-yates v-if="resumeOptions.selectedTemplate === 8"></brooke-yates>
            <paula-york v-if="resumeOptions.selectedTemplate === 9"></paula-york>
            <anton-trottier v-if="resumeOptions.selectedTemplate === 10"></anton-trottier>
            <ella-dale v-if="resumeOptions.selectedTemplate === 11"></ella-dale>
            <daisy-gibbs v-if="resumeOptions.selectedTemplate === 12"></daisy-gibbs>
            <olmypia-bonnet v-if="resumeOptions.selectedTemplate === 13"></olmypia-bonnet>
            <jay-jarvis v-if="resumeOptions.selectedTemplate === 14"></jay-jarvis>
            <sophie-bond v-if="resumeOptions.selectedTemplate === 15"></sophie-bond>
            <jake-sutton v-if="resumeOptions.selectedTemplate === 16"></jake-sutton>
            <kiera-lucas v-if="resumeOptions.selectedTemplate === 17"></kiera-lucas>
            <robart-anders v-if="resumeOptions.selectedTemplate === 18"></robart-anders>
            <libby-shaw v-if="resumeOptions.selectedTemplate === 19"></libby-shaw>
            <max-chandler v-if="resumeOptions.selectedTemplate === 20"></max-chandler>
        </div>
                </div>
                <a class="resume-preview close-icon" @click="$router.push(prevRoute.path)">
                    <img src="../../../../assets/custom-icons/close-icon-black.svg">
                </a>
            </div>
            <div class="resume-preview loader-cage" v-if="showLoader">
                <p class="resume-preview loader-text">Preparing Resume Preview</p>
                <v-progress-linear color="deep-purple accent-4" indeterminate rounded height="6"></v-progress-linear>
            </div>
        </div>
    </section>
</template>
<script>
    import html2Canvas from 'html2canvas';
    import {mapState} from "vuex";
    // import checkoutModal from '../components/modal-views/checkout-modal.vue';
    import greenTheme from '../../../themes/resumes/green-theme.vue';
    import peterMiller from '../../../themes/resumes/peter-miller/peter-miller.vue';
    import francescaYoung from '../../../themes/resumes/francesca-young/francesca-young.vue';
    import harveyBell from '../../../themes/resumes/harvey-bell/harvey-bell.vue';
    import madisonParry from '../../../themes/resumes/madison-parry/madison-parry.vue';
    import daisyGibbs from '../../../themes/resumes/daisy-gibbs/daisy-gibbs.vue';
    import brookeYates from '../../../themes/resumes/brooke-yates/brooke-yates.vue';
    import piperWalker from '../../../themes/resumes/piper-walker/piper-walker.vue';
    import paulaYork from '../../../themes/resumes/paula-york/paula-york.vue';
    import eloiseWinter from '../../../themes/resumes/eloise-winter/eloise-winter.vue';
    import antonTrottier from '../../../themes/resumes/anton-trottier/anton-trottier.vue';
    import ellaDale from '../../../themes/resumes/ella-dale/ella-dale.vue';
    import olmypiaBonnet from '../../../themes/resumes/olympia-bonnet/olympia-bonnet.vue';
    import jayJarvis from '../../../themes/resumes/jay-jarvis/jay-jarvis.vue';
    import sophieBond from '../../../themes/resumes/sophie-bond/sophie-bond.vue';
    import jakeSutton from '../../../themes/resumes/jake-sutton/jake-sutton.vue';
    import kieraLucas from '../../../themes/resumes/kiera-lucas/kiera-lucas.vue';
    import arthurBekers from '../../../themes/resumes/arthur-bekers/arthur-bekers.vue';
    import robartAnders from '../../../themes/resumes/robart-anders/robart-anders.vue';
    import maxChandler from '../../../themes/resumes/max-chandler/max-chandler.vue';
    import libbyShaw from '../../../themes/resumes/libby-shaw/libby-shaw.vue';

    // Cover Letters
    import clPeterMiller from '../../../themes/cover-letters/peter-miller/cl-peter-miller.vue';
    import clBrookeYates from '../../../themes/cover-letters/brooke-yates/cl-brooke-yates.vue';
    import clPiperWalker from '../../../themes/cover-letters/piper-walker/cl-piper-walker.vue';
    import clMadisonParry from '../../../themes/cover-letters/madison-parry/cl-madison-parry.vue';
    import clPaulaYork from '../../../themes/cover-letters/paula-york/cl-paula-york.vue';
    import clEloiseWinter from '../../../themes/cover-letters/eloise-winter/cl-eloise-winter.vue';
    import clHarveyBell from '../../../themes/cover-letters/harvey-bell/cl-harvey-bell.vue';
    import clAntonTrotier from '../../../themes/cover-letters/anton-trottier/cl-anton-trotier.vue';
    import clEllaDale from '../../../themes/cover-letters/ella-dale/cl-ella-dale.vue';
    import clDaisyGibbs from '../../../themes/cover-letters/daisy-gibbs/cl-daisy-gibbs.vue';
    import clOlympiaBonnet from '../../../themes/cover-letters/olympia-bonnet/cl-olympia-bonnet.vue';
    import clJayJarvis from '../../../themes/cover-letters/jay-jarvis/cl-jay-jarvis.vue';
    import clSophieBond from '../../../themes/cover-letters/sophie-bond/cl-sophie-bond.vue';
    import clJakeSutton from '../../../themes/cover-letters/jake-sutton/cl-jake-sutton.vue';
    import clKieraLucas from '../../../themes/cover-letters/kiera-lucas/cl-kiera-lucas.vue';
    import clArthurBekers from '../../../themes/cover-letters/arthur-bekers/cl-arthur-bekers.vue';
    import clRobertAnders from '../../../themes/cover-letters/robert-anders/cl-robert-anders.vue';
    import clLibbyShaw from '../../../themes/cover-letters/libby-shaw/cl-libby-shaw.vue';
    import clMaxChandler from '../../../themes/cover-letters/max-chandler/cl-max-chandler.vue';
    import clFrancescaYoung from '../../../themes/cover-letters/francesca-young/cl-francesca-young.vue';

    export default {
        name: 'resumePreview',
        components: {
            // checkoutModal,
            // Resume Themes
            greenTheme, peterMiller, francescaYoung, harveyBell, madisonParry,
            daisyGibbs, brookeYates, piperWalker, paulaYork, eloiseWinter,
            antonTrottier, ellaDale, olmypiaBonnet, jayJarvis, sophieBond,
            jakeSutton, kieraLucas, arthurBekers, robartAnders, maxChandler,
            libbyShaw,
            // Cover Letter Themes
            clPeterMiller, clBrookeYates, clPiperWalker, clMadisonParry,
            clPaulaYork, clEloiseWinter, clHarveyBell, clAntonTrotier,
            clEllaDale, clDaisyGibbs, clOlympiaBonnet, clJayJarvis,
            clSophieBond, clJakeSutton, clKieraLucas, clArthurBekers,
            clRobertAnders, clLibbyShaw, clMaxChandler, clFrancescaYoung
        },
        created() {
            this.getCanvas();
        },
        data() {
            return {
                imageUrl: '',
                showLoader: true,
                prevRoute: '',
            }
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.prevRoute = from
            })
        },
        computed: {
            ...mapState({
                resumeOptions: (store) => store.resume.resumeOptions,
                resumeType: (store) => store.resume.resumeType,
                listedMenu: (store) => store.resume.listedMenu,
                currentUserTemplate: (store) => store.resume.currentUserTemplate,
            }),
        },
        methods: {
            async getCanvas() {
                let url = '';
                const opt = {
                    dpi: 300,
                    allowTaint: false,
                    useCORS: true,
                };
                html2Canvas(document.getElementById('element-to-print'), opt).then(function (canvas) {
                    url = canvas.toDataURL("image/png")
                }, () => {
                    this.$router.push({name:'resumeDashboard'})
                }).then(() => {
                    this.setImageUrl(url);
                    this.showLoader = false;
                })
            },
            setImageUrl(urlData) {
                this.imageUrl = urlData;
            }
        }
    }
</script>
<style lang="scss" scoped>
.resume-content{
    &.cage {
            pointer-events: none;
        }
}
    .resume-preview {
        &.cage {
            background: #ffffff;
            top: 0;
            left: 0;
            position: fixed;
            width: 100%;
            height: 100%;
            z-index: 9999;
            padding-top: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &.image-cage {
            height: 842px;
            width: 595px;
            overflow: hidden;
            box-shadow: 5px 2px 20px -10px rgba(0, 0, 0, 0.75);
        }

        &.close-icon {
            margin-top: -11px;
            margin-left: -11px;
            z-index: 999;
        }

        &.loader-cage {
            max-width: 500px;
        }

        &.loader-text {
            font-size: 15px;
            font-family: "Segoe UI";
            letter-spacing: 1px;
            color: #000000;
            margin-bottom: 10px !important;
        }
    }
    .p-20-0{
        padding: 20px 0;
    }
</style>
