<template>
    <section class="profile-main-cage">
        <div class="d-flex row mb-5">
            <div class="col-md-8 col-sm-12">
                <p class="web-site section-head">General Settings</p>
                <div>
                    <div class="d-flex">
                        <div class="item-input-cage">
                            <p class="item-input-header">Name</p>
                            <input class="primary-input w-100" v-model="name">
                        </div>
                        <div class="item-input-cage">
                            <p class="item-input-header">Surname</p>
                            <input class="primary-input w-100" v-model="surname">
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="item-input-cage">
                            <p class="item-input-header">Email Address</p>
                            <input class="primary-input w-100" v-model="email">
                        </div>
                        <div class="item-input-cage">
                            <p class="item-input-header">Phone Number</p>
                            <input class="primary-input w-100" v-model="phoneNumber">
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="item-input-cage">
                            <p class="item-input-header">Public Page Url</p>
                            <input class="primary-input w-100" v-model="pageUrl">
                        </div>
                        <div class="item-input-cage">
                            <p class="item-input-header">Photo</p>
                            <v-btn class="grey-button w-100">Upload</v-btn>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-md-4 col-sm-12">
                <p class="web-site section-head">Subscription</p>
                <div class="subscription-cage" v-if="userInfo.subscription_type === 'pro'">
                    <p class="subscription-head">Pro plan</p>
                    <p class="subscription-head">Expires in {{ expirein }} days ({{expiredate}})</p>
                    <p class="subscription-text t-purple mb-1">Change Your Payment Info</p>
                    <p class="subscription-text t-purple">Cancel Subscription</p>
                </div>
                <div class="subscription-cage" v-else>
                    <p class="subscription-head">Free plan</p>
                    <p class="subscription-text">After your free plan ends, you won't be able to edit your resume until
                        you subscribe to a paid
                        plan.</p>
                    <p class="subscription-text t-purple" @click="$router.push({name: 'pricingPage'})">Upgrade to a paid
                        plan</p>
                </div>
            </div>

        </div>
        <div>
            <p class="web-site section-head">Password</p>
            <div>
                <div class="item-input-cage">
                    <p class="item-input-header">Old Password</p>
                    <input class="primary-input w-100" type="password" v-model="oldPassword">
                </div>
                <div class="d-flex">
                    <div class="item-input-cage">
                        <p class="item-input-header">New Password</p>
                        <input class="primary-input w-100" type="password" v-model="newPassword">
                    </div>
                    <div class="item-input-cage">
                        <p class="item-input-header">New Password Retry</p>
                        <input class="primary-input w-100" type="password" v-model="reNewPassword">
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="d-flex justify-content-end">
            <v-btn class="yellow-button" @click="updateUserInfo()">Save Changes</v-btn>
        </div>
        <v-snackbar v-model="showErrorMessage" :timeout="2000" :color="'error'" top>
            {{errorMessage}}
        </v-snackbar>
    </section>
</template>
<script>
    import {mapActions, mapState} from "vuex";
    import itemService from '../../../services/item-data.js'
    import authService from "../../../services/auth.js";
    import publicPageService from "../../../services/public-page.js";

    export default {
        name: 'profilePage',
        data() {
            return {
                name: '',
                surname: '',
                email: '',
                phoneNumber: '',
                photo: '',
                oldPassword: '',
                newPassword: '',
                reNewPassword: '',
                pageUrl: '',
                showErrorMessage: false,
                errorMessage: '',
                expirein:0,
                expiredate:''
            }
        },
        created() {
            this.setUserInfo();
        },
        methods: {
            ...mapActions({
                updateUserInfo: 'authorization/updateUserInfo',
            }),
            setUserInfo() {
                this.name = this.userInfo.name;
                this.surname = this.userInfo.surname;
                this.email = this.userInfo.email;
                this.phoneNumber = this.userInfo.phone;
                this.photo = this.userInfo.image;
                this.pageUrl = this.userInfo.code
              if(this.userInfo.subscription_type === 'pro')  {
                    
    // To set two dates to two variables
    var date1 = new Date();
    var date2 = new Date(this.userInfo.subscription_expire_date);
      
    // To calculate the time difference of two dates
    var Difference_In_Time = date2.getTime() - date1.getTime();
      
    // To calculate the no. of days between two dates
    this.expirein = parseInt(Difference_In_Time / (1000 * 3600 * 24));
   this.expiredate=date2.toDateString()
                }
            },
            updateUserInfo() {
                const profileData = {
                    image: this.photo,
                    name: this.name,
                    surname: this.surname,
                    phone: this.phoneNumber,
                    email: this.email,
                    address: this.summaryData.address,
                    summary: this.summaryData.summary
                };
                const passwordObject = {
                    current_password: this.oldPassword,
                    password: this.newPassword,
                    password_confirmation: this.reNewPassword
                };
                if (passwordObject.current_password || passwordObject.password || passwordObject.password_confirmation) {
                    authService.changePassword(passwordObject).then(() => {
                        itemService.updateUserData(profileData).then(() => {
                            this.updatePageUrl();
                        });
                    }).catch(err => {
                        if (err.response) {
                            this.errorMessage = err.response.data.errors.full_messages[0];
                            this.showErrorMessage = true;
                        }
                    })
                } else {
                    itemService.updateUserData(profileData).then(() => {
                        this.updatePageUrl();
                    });
                }
            },
            async updatePageUrl() {
                const codeObject = {
                    code: this.pageUrl
                };
                publicPageService.updatePageCode(codeObject).then(() => {
                    window.location.reload();
                })
            }
        },
        computed: {
            ...mapState({
                summaryData: (store) => store.summary.summaryData,
                userInfo: (store) => store.authorization.userInfo,
                dummyUser: (store) => store.authorization.dummyUser,
            })
        },
        watch: {
            userInfo() {
                this.setUserInfo();
            }
        }
    }
</script>
<style lang="scss" scoped>
    .item-input-cage {
        max-width: 266px;
        width: 100%;
        margin-right: 35px;
    }

    .profile-main-cage {
        max-width: 930px;
        margin: 65px auto 0px;
    }

    .subscription-cage {
        border: 1px solid #EBEBEB;
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.03);
        border-radius: 5px;
        padding: 17px 16px;
        width: 370px;
    }

    .subscription-head {
        font-weight: 600;
        font-family: "Segoe UI";
        font-size: 12px;
        color: #000000;
        text-align: start;
        margin-bottom: 5px !important;
    }

    .subscription-text {
        text-align: start;
        font-weight: 400;
        font-family: "Segoe UI";
        font-size: 12px;
        color: #000000;

        &.t-purple {
            color: #742774;
            font-weight: 600;
            cursor: pointer;
        }
    }

</style>
