<template>
    <section>
        <modal :show="showFeedbackModal" refs="feedbackModal" :ext="'modal-dialog--medium'"
               @close="updateFeedbackModalStatus(false)" :modal-head="'Feedback Resume'">
            <div class="feedback-modal cage">
                <p>Do you want to get feedback your CV?</p>
                <div class="d-flex">
                    <toggle-box :first-label="'No'" :second-label="'Yes'"
                                :toggleValue="userInfo.feedBackActive"
                                @changeToggle="changeFeedBack($event)">
                    </toggle-box>
                </div>
            </div>
            <div class="head-hr"></div>
            <div class="feedback-secondary cage" v-if="userInfo.feedBackActive">
                <p class="feedback-secondary info-text">In order to get reviews to your resume, you need to review
                    5 other users</p>
                <v-btn class="yellow-button">Review Others</v-btn>
            </div>
        </modal>
    </section>
</template>
<script>
    import {mapActions, mapState} from "vuex";
    import modal from '../../components/atomic/modal.vue';
    import toggleBox from '../../components/atomic/input/toggle-box.vue';

    export default {
        name: 'feedbacModal',
        components: {modal, toggleBox},
        methods: {
            ...mapActions({
                updateFeedBackOption: 'authorization/updateFeedBackOption',
                updateFeedbackModalStatus: 'general/updateFeedbackModalStatus'
            }),
            changeFeedBack(val) {
                this.updateFeedBackOption(val);
            },
        },
        computed: {
            ...mapState({
                userInfo: (store) => store.authorization.userInfo,
                dummyUser: (store) => store.authorization.dummyUser,
                showFeedbackModal: (store) => store.general.showFeedBackModal,
            }),
        }
    }
</script>
<style></style>
