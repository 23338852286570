<template>
    <section>
        <div class="row header-cage">
            <div class="info-cage head-center">
                <info></info>
            </div>
            <div class="col head-center specifications">
                <specifications></specifications>
            </div>
            <div class="col head-center actions">
                <actions></actions>
            </div>
        </div>
    </section>
</template>
<script>
    import Actions from './header/app-header/actions.vue'
    import Specifications from './header/app-header/specifications.vue'
    import Info from './header/app-header/info.vue'
    export default {
        name: 'dashboardHeader',
        components: {Actions,Specifications,Info}
    }
</script>
<style lang="scss" scoped>
    .header-cage{
        padding: 10px;
    }
    .head-center{
        display: flex;
        align-items: center;
        max-height: 45px;
    }
    .head-center.specifications{
        @media (max-width: 420px) {
            overflow: auto;
    width: 100%;
    position: absolute;
    top: 46px;
    z-index: 100;
    background: white;
                }
    }
    .head-center.actions{
        @media (max-width: 420px) {
            position: absolute;
    right: -92px;
    top: 0px;
    width: 307px;
                }
    }
    
    
    .info-cage{
        max-width: 295px;
        width: 100%;
    }
</style>
