<template>
    <section>
        <div id="modal" v-if="showLoginModal">
            <div class="modal d-block">
                <div class="modal-dialog modal-dialog-centered modal-dialog--login">
                    <div class="modal-content">
                        <div class="login-modal adv-side" v-if="!isMobile()">
                            <p class="bolder-white-text">AnyCv</p>
                            <p class="bold-white-text">Let's create a resume for
                                your dream job</p>
                        </div>
                        <div class="login-modal form-side">
                            <img class="login-modal close-icon" src="../../assets/custom-icons/modal-close-button.svg"
                                 @click="closeLoginModal">
                            <div v-if="currentLoginTab === 'login'">
                                <p class="login-modal head">Login to AnyCv</p>
                                <login-form></login-form>
                            </div>
                            <div v-if="currentLoginTab === 'register'">
                                <p class="login-modal head">Register to AnyCv</p>
                                <register-form @changeLoginTab="setCurrentLoginTab($event)"></register-form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-backdrop"/>
        </div>
    </section>
</template>
<script>
    import loginForm from '../login/user-login-form.vue';
    import registerForm from '../login/user-register-form.vue';
    import {mapActions, mapState} from "vuex";

    export default {
        name: 'userRegister',
        components: {loginForm, registerForm},
        data() {
            return {
                currentLoginTab: 'login'
            }
        },
        computed: {
            ...mapState({
                showLoginModal: (store) => store.general.showLoginModal,
            }),
        },
        methods: {
            ...mapActions({
                updateLoginModalStatus: 'general/updateLoginModalStatus'
            }),
            closeLoginModal() {
                this.updateLoginModalStatus(false)
            },
            setCurrentLoginTab(tabName) {
                this.currentLoginTab = tabName;
            },
            isMobile() {
    return this.$vuetify.breakpoint.sm||this.$vuetify.breakpoint.xs
 },
        }
    }
</script>
<style lang="scss" scoped>
    .modal-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-radius: 5px;
        height: 576px;
    }

    .modal-dialog--login {
        max-width: 896px;
    }

    .modal-backdrop {
        opacity: 0.6 !important;
    }

    .login-modal {
        &.adv-side {
            max-width: 470px;
            width: 100%;
            background: #742774;
            border-radius: 5px;
            padding: 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

        }

        &.form-side {
            position: relative;
            flex: 1;
            background-color: #ffffff;
            padding: 34px;
            border-radius: 5px;
        }

        &.close-icon {
            cursor: pointer;
            position: absolute;
            right: 18px;
            top: 15px;
        }

        &.head {
            font-size: 24px;
            color: #000000;
            font-weight: 500;
            text-align: start;
            margin-bottom: 22px !important;
        }

    }

    .bold-white-text {
        max-width: 206px;
    }
</style>
