import {mapActions, mapState} from 'vuex';
import itemService from "@/services/item-data";
import dashboardService from "../services/dashboard";
import resumeService from "../services/resume";

export default {
    data() {
        return {
            totalPage: '',
            editorList: {
                experienceSectionEditor: false,
                educationSectionEditor: false,
                projectSectionEditor: false,
                publicationSectionEditor: false,
                myTimeSectionEditor: false,
                mottoSectionEditor: false,
                volunteerSectionEditor: false,
                strengthSectionEditor: false,
                hobbiesSectionEditor: false,
                languageSectionEditor: false,
                technicalSkillSectionEditor: false,
                awardSectionEditor: false,
                certificateSectionEditor: false,
                referencesSectionEditor: false,
                socialSectionEditor: false,
                conferenceSectionEditor: false,
                summarySectionEditor: false,
                communicationEditor: false,
                headerEditor: false,
                profileHeaderEditor: false,
                profileTextEditor: false,
                experienceTitleEditor: false,
                educationTitleEditor: false,
                strengthHeaderEditor: false,
                strengthTextEditor: false,
                strengthDescriptionEditor: false,
                experienceSubTitleEditor: false,
                experienceDateEditor: false,
                experienceDescriptionEditor: false,
                educationSubTitleEditor: false,
                educationDescriptionEditor: false,
                educationDateEditor: false,
                hobbyTitleEditor: false,
                languageInfoEditor: false,
                languageTitleEditor: false,
                skillTitleEditor: false,
                awardTitleEditor: false,
                awardDateEditor: false,
                awardTextEditor: false,
                certificateTitleEditor: false,
                certificateTextEditor: false,
                certificateCompanyEditor: false,
                certificateDateEditor: false,
                referenceTitleEditor: false,
                referenceEmailEditor: false,
                referencePhoneEditor: false,
                projectTitleEditor: false,
                projectSubTitleEditor: false,
                projectDateEditor: false,
                projectDescriptionEditor: false,
                publicationTitleEditor: false,
                publicationSubTitleEditor: false,
                publicationDescriptionEditor: false,
                myTimeTitleEditor: false,
                mottoTitleEditor: false,
                mottoTextEditor: false,
                volunteeringTitleEditor: false,
                volunteeringSubTitleEditor: false,
                volunteeringDateEditor: false,
                volunteeringDescriptionEditor: false,
                socialUrlEditor: false,
                conferenceTitleEditor: false,
                conferenceCompanyEditor: false,
                greetingNameEditor: false,
                greetingTitleEditor: false,
                bodyClEditor: false,
                openingClEditor: false,
                closingTitleEditor: false,
                closingNameEditor: false,
            },
            showEditorBg: false,
            activeObject: '',
        };
    },
    computed: {
        ...mapState({
            currentUserTemplate: (store) => store.resume.currentUserTemplate,
            resumeOptions: (store) => store.resume.resumeOptions,
            menuItems: (store) => store.resume.itemOrderList,
            summaryData: (store) => store.summary.summaryData,
            summaryMenuData: (store) => store.summary.summaryMenuData,
            experienceData: (store) => store.experience.experienceData,
            educationData: (store) => store.education.educationData,
            strengthsData: (store) => store.strengths.strengthsData,
            hobbiesData: (store) => store.hobbies.hobbiesData,
            languagesData: (store) => store.languageSkills.languageSkillsData,
            technicalSkillsData: (store) => store.technicalSkills.technicalSkillsData,
            awardsData: (store) => store.awards.awardsData,
            certificateData: (store) => store.certificate.certificateData,
            referencesData: (store) => store.references.referencesData,
            projectsData: (store) => store.projects.projectsData,
            publicationData: (store) => store.publications.publicationData,
            myTimeData: (store) => store.myTime.myTimeData,
            mottoData: (store) => store.philosophy.mottoData,
            volunteeringData: (store) => store.volunteering.volunteeringData,
            socialData: (store) => store.social.socialData,
            conferencesData: (store) => store.conferences.conferencesData,

            // Cover Letters
            greetingData: (store) => store.greeting.greetingData,
            openingData: (store) => store.opening.openingData,
            bodyData: (store) => store.body.bodyData,
            closingData: (store) => store.closing.closingData,
        }),
    },
    methods: {
        ...mapActions({
            updateThemeArray: 'resume/updateThemeArray',
            updateSummaryData: 'summary/updateSummaryData',
            editExperience: 'experience/editExperience',
            editEducation: 'education/editEducation',
            editStrength: 'strengths/editStrength',
            editHobby: 'hobbies/editHobby',
            editLanguage: 'languageSkills/editLanguage',
            editSkill: 'technicalSkills/editSkill',
            editAward: 'awards/editAward',
            editCertificate: 'certificate/editCertificate',
            editReference: 'references/editReference',
            editProject: 'projects/editProject',
            editPublication: 'publications/editPublication',
            editMyTime: 'myTime/editMyTime',
            editMotto: 'philosophy/editMotto',
            editVolunteer: 'volunteering/editVolunteer',
            editSocialData: 'social/editSocialData',
            editConference: 'conferences/editConference',
            updateSocialData: 'social/updateSocialData',
            editGreeting: 'greeting/editGreeting',
            editOpening: 'opening/editOpening',
            editBody: 'body/editBody',
            editClosing: 'closing/editClosing',
            setTotalPage: 'resume/setTotalPage',
        }),
        updatePageHeight() {
            const listDom = document.getElementById('listItem');

            this.$nextTick(() => {
                try {
                    const listHeight = listDom.offsetHeight;
                    this.totalPage = Math.ceil(listHeight / 842);
                    this.setTotalPage(this.totalPage);
                } catch (err) {
                    console.log('init')
                    console.log(err)
                }
            });
        },
        updateItemData(event, object, model, itemName) {
            this.updatePageHeight();
            if (itemName === 'summary') {
                object[model] = event.target.outerText;
                itemService.updateSummaryData(object.id, object).then((updateSummaryResponse) => {
                    this.updateSummaryData(updateSummaryResponse.data);
                    // this.showUpdateSummaryNotification = true;
                });
            }
            if (itemName === 'experience') {
                object[model] = event.target.outerText;
                itemService.updateExperience(object.id, object).then((updateResponse) => {
                    this.editExperience(updateResponse);
                    //  this.showUpdateExperienceNotification = true;
                });
            }
            if (itemName === 'education') {
                object[model] = event.target.outerText;

                itemService.updateEducation(object.id, object).then((updateResponse) => {
                    this.editEducation(updateResponse);
                    //  this.showUpdateEducationNotification = true;
                })
            }
            if (itemName === 'strength') {
                object[model] = event.target.outerText;

                itemService.updateStrength(object.id, object).then((updatedResponse) => {
                    this.editStrength(updatedResponse);
                    //  this.showUpdateStrengthsNotification = true;
                })
            }
            if (itemName === 'hobby') {
                object[model] = event.target.outerText;

                itemService.updateHobby(object.id, object).then((updateResponse) => {
                    this.editHobby(updateResponse);
                    // this.showUpdateHobbyNotification = true;
                })
            }
            if (itemName === 'language') {
                if (model !== 'rate') {
                    object[model] = event.target.outerText;
                }
                //  object[model] = event.target.outerText;


                itemService.updateLanguage(object.id, object).then((updateResponse) => {
                    this.editLanguage(updateResponse);
                    // this.showUpdateHobbyNotification = true;
                })
            }
            if (itemName === 'technicalSkill') {
                if (model !== 'rate') {
                    object[model] = event.target.outerText;
                }

                itemService.updateSkill(object.id, object).then((updateResponse) => {
                    this.editSkill(updateResponse);
                    //this.showUpdateSkill = true;
                })
            }
            if (itemName === 'awards') {
                object[model] = event.target.outerText;

                itemService.updateAwardData(object.id, object).then((updateResponse) => {
                    this.editAward(updateResponse);
                    // this.showUpdateAwardsNotification = true;
                });
            }
            if (itemName === 'certificates') {
                object[model] = event.target.outerText;

                itemService.updateCertificate(object.id, object).then((updateResponse) => {
                    this.editCertificate(updateResponse);
                    //   this.showUpdateCertificate = true;
                });
            }
            if (itemName === 'references') {
                object[model] = event.target.outerText;

                itemService.updateReference(object.id, object).then((updateResponse) => {
                    this.editReference(updateResponse);
                    // this.showUpdateReferenceNotification = true;
                });
            }
            if (itemName === 'projects') {
                object[model] = event.target.outerText;

                itemService.updateProject(object.id, object).then((updateResponse) => {
                    this.editProject(updateResponse);
                    //  this.showUpdateProjectNotification = true;
                });
            }
            if (itemName === 'publications') {
                object[model] = event.target.outerText;

                itemService.updatePublication(object.id, object).then((updateResponse) => {
                    this.editPublication(updateResponse);
                    //   this.showUpdatePublicationNotification = true;
                })
            }
            if (itemName === 'myTime') {
                object[model] = event.target.outerText;

                itemService.updateMyTime(object.id, object).then((updateResponse) => {
                    this.editMyTime(updateResponse);
                    //  this.showUpdateActivityNotification = true;
                })
            }
            if (itemName === 'motto') {
                object[model] = event.target.outerText;

                itemService.updateMotto(object.id, object).then((updateResponse) => {
                    this.editMotto(updateResponse);
                    // this.showUpdateMottosNotification = true;
                });
            }
            if (itemName === 'volunteering') {
                object[model] = event.target.outerText;

                itemService.updateVolunteering(object.id, object).then((updateResponse) => {
                    this.editVolunteer(updateResponse);
                    // this.showVolunteeringList = true;
                })
            }
            if (itemName === 'social') {
                object[model] = event.target.outerText;
                const editedObject = {
                    data: object
                };
                this.editSocialData(editedObject);
                const socialObject = {
                    socials: {socials: this.socialData.mySocialData}
                };

                itemService.updateSocials(socialObject).then((updateResponse) => {
                    this.updateSocialData(updateResponse.data.data);
                })
            }
            if (itemName === 'conferences') {
                object[model] = event.target.outerText;

                itemService.updateConference(object.id, object).then((updateResponse) => {
                    this.editConference(updateResponse);
                    // this.showVolunteeringList = true;
                })
            }

            // Cover Letter
            if (itemName === 'greeting') {
                object[model] = event.target.outerText;

                itemService.updateGreeting(object.id, object).then((updateResponse) => {
                    this.editGreeting(updateResponse);
                    // this.showUpdateMottosNotification = true;
                });
            }
            if (itemName === 'opening') {
                object[model] = event.target.outerText;

                itemService.updateOpening(object.id, object).then((updateResponse) => {
                    this.editOpening(updateResponse);
                    // this.showUpdateMottosNotification = true;
                });
            }
            if (itemName === 'body') {
                object[model] = event.target.outerText;
                itemService.updateLetterBody(object.id, object).then((updateResponse) => {
                    this.editBody(updateResponse);
                    // this.showUpdateMottosNotification = true;
                });
            }
            if (itemName === 'closing') {
                object[model] = event.target.outerText;
                itemService.updateClosing(object.id, object).then((updateResponse) => {
                    this.editClosing(updateResponse);
                    // this.showUpdateMottosNotification = true;
                });
            }
        },
        updateItemClass() {
            this.updatePageHeight();
            if (this.activeObject.itemName === 'summary') {
                const classArray = [];
                this.activeObject.event.target.classList.forEach(className => {
                    const classNameIndex = classArray.indexOf(className);
                    if (classNameIndex === -1) {
                        classArray.push(className);
                    }
                });
                let htmlObject = this.summaryMenuData.html;
                if (htmlObject === null) {
                    htmlObject = {}
                }

                const modelName = this.activeObject.editorName;
                Object.assign(htmlObject, {[modelName]: classArray.toString().replace(/,/g, ' ')});
                const menuItem = {
                    html: htmlObject,
                    title: 'Test Summary'
                };
                dashboardService.updateMenuItems(this.summaryMenuData.item_id, menuItem).then();

            }
            if (this.activeObject.itemName === 'experience') {
                const classArray = [];
                this.activeObject.event.target.classList.forEach(className => {
                    const classNameIndex = classArray.indexOf(className);
                    if (classNameIndex === -1) {
                        classArray.push(className);
                    }
                });
                let htmlObject = this.experienceData.html;
                if (htmlObject === null) {
                    htmlObject = {}
                }

                const modelName = this.activeObject.editorName;
                Object.assign(htmlObject, {[modelName]: classArray.toString().replace(/,/g, ' ')});
                const menuItem = {
                    html: htmlObject,
                };
                dashboardService.updateMenuItems(this.experienceData.item_id, menuItem).then();
            }
            if (this.activeObject.itemName === 'education') {
                const classArray = [];
                this.activeObject.event.target.classList.forEach(className => {
                    const classNameIndex = classArray.indexOf(className);
                    if (classNameIndex === -1) {
                        classArray.push(className);
                    }
                });
                let htmlObject = this.educationData.html;
                if (htmlObject === null) {
                    htmlObject = {}
                }

                const modelName = this.activeObject.editorName;
                Object.assign(htmlObject, {[modelName]: classArray.toString().replace(/,/g, ' ')});
                const menuItem = {
                    html: htmlObject,
                    title: 'Test Education'
                };
                dashboardService.updateMenuItems(this.educationData.item_id, menuItem).then();
            }
            if (this.activeObject.itemName === 'strength') {
                const classArray = [];
                this.activeObject.event.target.classList.forEach(className => {
                    const classNameIndex = classArray.indexOf(className);
                    if (classNameIndex === -1) {
                        classArray.push(className);
                    }
                });
                let htmlObject = this.strengthsData.html;
                if (htmlObject === null) {
                    htmlObject = {}
                }

                const modelName = this.activeObject.editorName;
                Object.assign(htmlObject, {[modelName]: classArray.toString().replace(/,/g, ' ')});
                const menuItem = {
                    html: htmlObject,
                    title: 'Test Strengths'
                };
                dashboardService.updateMenuItems(this.strengthsData.item_id, menuItem).then();
            }
            if (this.activeObject.itemName === 'hobby') {
                const classArray = [];
                this.activeObject.event.target.classList.forEach(className => {
                    const classNameIndex = classArray.indexOf(className);
                    if (classNameIndex === -1) {
                        classArray.push(className);
                    }
                });
                let htmlObject = this.hobbiesData.html;
                if (htmlObject === null) {
                    htmlObject = {}
                }

                const modelName = this.activeObject.editorName;
                Object.assign(htmlObject, {[modelName]: classArray.toString().replace(/,/g, ' ')});
                const menuItem = {
                    html: htmlObject,
                    title: 'Test Hobbies'
                };
                dashboardService.updateMenuItems(this.hobbiesData.item_id, menuItem).then();
            }
            if (this.activeObject.itemName === 'language') {
                const classArray = [];
                this.activeObject.event.target.classList.forEach(className => {
                    const classNameIndex = classArray.indexOf(className);
                    if (classNameIndex === -1) {
                        classArray.push(className);
                    }
                });
                let htmlObject = this.languagesData.html;
                if (htmlObject === null) {
                    htmlObject = {}
                }

                const modelName = this.activeObject.editorName;
                Object.assign(htmlObject, {[modelName]: classArray.toString().replace(/,/g, ' ')});
                const menuItem = {
                    html: htmlObject,
                    title: 'Test Language'
                };
                dashboardService.updateMenuItems(this.languagesData.item_id, menuItem).then();

            }
            if (this.activeObject.itemName === 'technicalSkill') {
                const classArray = [];
                this.activeObject.event.target.classList.forEach(className => {
                    const classNameIndex = classArray.indexOf(className);
                    if (classNameIndex === -1) {
                        classArray.push(className);
                    }
                });
                let htmlObject = this.technicalSkillsData.html;
                if (htmlObject === null) {
                    htmlObject = {}
                }

                const modelName = this.activeObject.editorName;
                Object.assign(htmlObject, {[modelName]: classArray.toString().replace(/,/g, ' ')});
                const menuItem = {
                    html: htmlObject,
                    title: 'Test Skills'
                };
                dashboardService.updateMenuItems(this.technicalSkillsData.item_id, menuItem).then();
            }
            if (this.activeObject.itemName === 'awards') {
                const classArray = [];
                this.activeObject.event.target.classList.forEach(className => {
                    const classNameIndex = classArray.indexOf(className);
                    if (classNameIndex === -1) {
                        classArray.push(className);
                    }
                });
                let htmlObject = this.awardsData.html;
                if (htmlObject === null) {
                    htmlObject = {}
                }

                const modelName = this.activeObject.editorName;
                Object.assign(htmlObject, {[modelName]: classArray.toString().replace(/,/g, ' ')});
                const menuItem = {
                    html: htmlObject,
                    title: 'Test Awards'
                };
                dashboardService.updateMenuItems(this.awardsData.item_id, menuItem).then();
            }
            if (this.activeObject.itemName === 'certificates') {
                const classArray = [];
                this.activeObject.event.target.classList.forEach(className => {
                    const classNameIndex = classArray.indexOf(className);
                    if (classNameIndex === -1) {
                        classArray.push(className);
                    }
                });
                let htmlObject = this.certificateData.html;
                if (htmlObject === null) {
                    htmlObject = {}
                }

                const modelName = this.activeObject.editorName;
                Object.assign(htmlObject, {[modelName]: classArray.toString().replace(/,/g, ' ')});
                const menuItem = {
                    html: htmlObject,
                    title: 'Test Certificate'
                };
                dashboardService.updateMenuItems(this.certificateData.item_id, menuItem).then();
            }
            if (this.activeObject.itemName === 'references') {
                const classArray = [];
                this.activeObject.event.target.classList.forEach(className => {
                    const classNameIndex = classArray.indexOf(className);
                    if (classNameIndex === -1) {
                        classArray.push(className);
                    }
                });
                let htmlObject = this.referencesData.html;
                if (htmlObject === null) {
                    htmlObject = {}
                }

                const modelName = this.activeObject.editorName;
                Object.assign(htmlObject, {[modelName]: classArray.toString().replace(/,/g, ' ')});
                const menuItem = {
                    html: htmlObject,
                    title: 'Test Reference'
                };
                dashboardService.updateMenuItems(this.referencesData.item_id, menuItem).then();
            }
            if (this.activeObject.itemName === 'projects') {
                const classArray = [];
                this.activeObject.event.target.classList.forEach(className => {
                    const classNameIndex = classArray.indexOf(className);
                    if (classNameIndex === -1) {
                        classArray.push(className);
                    }
                });
                let htmlObject = this.projectsData.html;
                if (htmlObject === null) {
                    htmlObject = {}
                }

                const modelName = this.activeObject.editorName;
                Object.assign(htmlObject, {[modelName]: classArray.toString().replace(/,/g, ' ')});
                const menuItem = {
                    html: htmlObject,
                    title: 'Test Projects'
                };
                dashboardService.updateMenuItems(this.projectsData.item_id, menuItem).then();
            }
            if (this.activeObject.itemName === 'publications') {
                const classArray = [];
                this.activeObject.event.target.classList.forEach(className => {
                    const classNameIndex = classArray.indexOf(className);
                    if (classNameIndex === -1) {
                        classArray.push(className);
                    }
                });
                let htmlObject = this.publicationData.html;
                if (htmlObject === null) {
                    htmlObject = {}
                }

                const modelName = this.activeObject.editorName;
                Object.assign(htmlObject, {[modelName]: classArray.toString().replace(/,/g, ' ')});
                const menuItem = {
                    html: htmlObject,
                    title: 'Test Publications'
                };
                dashboardService.updateMenuItems(this.publicationData.item_id, menuItem).then();
            }
            if (this.activeObject.itemName === 'myTime') {
                const classArray = [];
                this.activeObject.event.target.classList.forEach(className => {
                    const classNameIndex = classArray.indexOf(className);
                    if (classNameIndex === -1) {
                        classArray.push(className);
                    }
                });
                let htmlObject = this.myTimeData.html;
                if (htmlObject === null) {
                    htmlObject = {}
                }

                const modelName = this.activeObject.editorName;
                Object.assign(htmlObject, {[modelName]: classArray.toString().replace(/,/g, ' ')});
                const menuItem = {
                    html: htmlObject,
                    title: 'Test My Time'
                };
                dashboardService.updateMenuItems(this.myTimeData.item_id, menuItem).then();
            }
            if (this.activeObject.itemName === 'motto') {
                const classArray = [];
                this.activeObject.event.target.classList.forEach(className => {
                    const classNameIndex = classArray.indexOf(className);
                    if (classNameIndex === -1) {
                        classArray.push(className);
                    }
                });
                let htmlObject = this.mottoData.html;
                if (htmlObject === null) {
                    htmlObject = {}
                }

                const modelName = this.activeObject.editorName;
                Object.assign(htmlObject, {[modelName]: classArray.toString().replace(/,/g, ' ')});
                const menuItem = {
                    html: htmlObject,
                    title: 'Test Mottos'
                };
                dashboardService.updateMenuItems(this.mottoData.item_id, menuItem).then();
            }
            if (this.activeObject.itemName === 'volunteering') {
                const classArray = [];
                this.activeObject.event.target.classList.forEach(className => {
                    const classNameIndex = classArray.indexOf(className);
                    if (classNameIndex === -1) {
                        classArray.push(className);
                    }
                });
                let htmlObject = this.volunteeringData.html;
                if (htmlObject === null) {
                    htmlObject = {}
                }

                const modelName = this.activeObject.editorName;
                Object.assign(htmlObject, {[modelName]: classArray.toString().replace(/,/g, ' ')});
                const menuItem = {
                    html: htmlObject,
                    title: 'Test Volunteering'
                };
                dashboardService.updateMenuItems(this.volunteeringData.item_id, menuItem).then();
            }
            if (this.activeObject.itemName === 'social') {
                const classArray = [];
                this.activeObject.event.target.classList.forEach(className => {
                    const classNameIndex = classArray.indexOf(className);
                    if (classNameIndex === -1) {
                        classArray.push(className);
                    }
                });
                let htmlObject = this.socialData.html;
                if (htmlObject === null) {
                    htmlObject = {}
                }

                const modelName = this.activeObject.editorName;
                Object.assign(htmlObject, {[modelName]: classArray.toString().replace(/,/g, ' ')});
                const menuItem = {
                    html: htmlObject,
                    title: 'Test Social'
                };
                dashboardService.updateMenuItems(this.socialData.item_id, menuItem).then();

            }
            if (this.activeObject.itemName === 'conferences') {
                const classArray = [];
                this.activeObject.event.target.classList.forEach(className => {
                    const classNameIndex = classArray.indexOf(className);
                    if (classNameIndex === -1) {
                        classArray.push(className);
                    }
                });
                let htmlObject = this.conferencesData.html;
                if (htmlObject === null) {
                    htmlObject = {}
                }

                const modelName = this.activeObject.editorName;
                Object.assign(htmlObject, {[modelName]: classArray.toString().replace(/,/g, ' ')});
                const menuItem = {
                    html: htmlObject,
                    title: 'Test Conferences'
                };
                dashboardService.updateMenuItems(this.conferencesData.item_id, menuItem).then();
            }

            // Cover Letter
            if (this.activeObject.itemName === 'greeting') {
                const classArray = [];
                this.activeObject.event.target.classList.forEach(className => {
                    const classNameIndex = classArray.indexOf(className);
                    if (classNameIndex === -1) {
                        classArray.push(className);
                    }
                });
                let htmlObject = this.greetingData.html;
                if (htmlObject === null) {
                    htmlObject = {}
                }

                const modelName = this.activeObject.editorName;
                Object.assign(htmlObject, {[modelName]: classArray.toString().replace(/,/g, ' ')});
                const menuItem = {
                    html: htmlObject,
                    title: 'Test Greeting'
                };
                dashboardService.updateMenuItems(this.greetingData.item_id, menuItem).then();
            }
            if (this.activeObject.itemName === 'opening') {
                const classArray = [];
                this.activeObject.event.target.classList.forEach(className => {
                    const classNameIndex = classArray.indexOf(className);
                    if (classNameIndex === -1) {
                        classArray.push(className);
                    }
                });
                let htmlObject = this.openingData.html;
                if (htmlObject === null) {
                    htmlObject = {}
                }

                const modelName = this.activeObject.editorName;
                Object.assign(htmlObject, {[modelName]: classArray.toString().replace(/,/g, ' ')});
                const menuItem = {
                    html: htmlObject,
                    title: 'Test Opening'
                };
                dashboardService.updateMenuItems(this.openingData.item_id, menuItem).then();
            }
            if (this.activeObject.itemName === 'body') {
                const classArray = [];
                this.activeObject.event.target.classList.forEach(className => {
                    const classNameIndex = classArray.indexOf(className);
                    if (classNameIndex === -1) {
                        classArray.push(className);
                    }
                });
                let htmlObject = this.bodyData.html;
                if (htmlObject === null) {
                    htmlObject = {}
                }

                const modelName = this.activeObject.editorName;
                Object.assign(htmlObject, {[modelName]: classArray.toString().replace(/,/g, ' ')});
                const menuItem = {
                    html: htmlObject,
                    title: 'Test Body'
                };
                dashboardService.updateMenuItems(this.bodyData.item_id, menuItem).then();
            }
            if (this.activeObject.itemName === 'closing') {
                const classArray = [];
                this.activeObject.event.target.classList.forEach(className => {
                    const classNameIndex = classArray.indexOf(className);
                    if (classNameIndex === -1) {
                        classArray.push(className);
                    }
                });
                let htmlObject = this.closingData.html;
                if (htmlObject === null) {
                    htmlObject = {}
                }

                const modelName = this.activeObject.editorName;
                Object.assign(htmlObject, {[modelName]: classArray.toString().replace(/,/g, ' ')});
                const menuItem = {
                    html: htmlObject,
                    title: 'Test Closing'
                };
                dashboardService.updateMenuItems(this.closingData.item_id, menuItem).then();
            }
        },
        updateEditorClass() {
            //    this.updateItemData(this.activeObject.event, this.activeObject.object, this.activeObject.model, this.activeObject.itemName)
            this.updateItemClass();
        },
        openEditor(editorName, event, object, model, itemName) {
            this.activeObject = {event, object, model, itemName, editorName};
            this.editorList[editorName] = true;
            this.showEditorBg = Object.keys(this.editorList).find(key => this.editorList[key] === true);
        },
        closeEditor() {
            this.showEditorBg = false;
            Object.keys(this.editorList).forEach(key => this.editorList[key] = false)
        },
        updateItemSectionHeader(event, sectionInfo) {
            const menuItem = {
                title: event.target.outerText,
            };
            dashboardService.updateMenuItems(sectionInfo.item_id, menuItem).then();
        },
        addListPoint() {
            let value = this.activeObject.object[this.activeObject.model].toString();
            this.activeObject.object[this.activeObject.model] = value + ' \n ' + ' \u25CF ';
            this.updateItemData(this.activeObject);
        }
    },
    watch: {
        currentUserTemplate() {
            resumeService.getThemeList(this.currentUserTemplate.template_type).then((themeList) => {
                this.updateThemeArray(themeList.data.data);
            })
        }
    }

};
