<template>
    <section>
        <div class="d-flex align-items-center justify-content-center">
            <p class="first-text">{{firstLabel}}</p>
            <label class="switch">
                <input type="checkbox" v-model="toggleModel"  :checked="toggleModel" @change="$emit('changeToggle', toggleModel)">
                <span class="slider round"></span>
            </label>
            <p class="second-text">{{secondLabel}}</p>
        </div>
    </section>
</template>
<script>
    export default {
        name:'toggleBox',
        data(){
            return{
                toggleModel:this.toggleValue,
            }
        },
        props:['firstLabel', 'secondLabel','toggleValue']
    }
</script>
<style lang="scss" scoped>
    .switch {
        position: relative;
        display: inline-block;
        width: 33px;
        height: 17px;
        margin-bottom: 0 !important;
    }

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 15px;
        width: 15px;
        left: 2px;
        bottom: 1px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked + .slider {
        background-color: #742774;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #742774;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(15px);
        -ms-transform: translateX(15px);
        transform: translateX(15px);
    }

    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
    .first-text{
        color:#000000;
        font-size: 14px;
        font-weight: 500;
        margin-right: 12px;
    }
    .second-text{
        color:#000000;
        font-size: 14px;
        font-weight: 500;
        margin-left: 12px;
    }
</style>

