<template>
    <section>
        <div class="item-opening cage">
            <div>
                <div class="item-input-sub-cage">
                    <div class="item-input-cage">
                        <p class="item-input-header">Opening Body</p>
                        <textarea class="primary-text-area w-100" rows="10" v-model="openingData.opening_body"
                                  @input="changeData"
                                  @focusout="updateOpening()"
                        ></textarea>
                    </div>
                </div>
                <div class="item-footer-button-cage">
                    <v-btn class="yellow-button w-100" @click="updateOpening()">
                        Save
                    </v-btn>
                </div>
            </div>
        </div>
        <v-snackbar v-model="showUpdateOpeningNotification" :timeout="2000" :color="'success'" top>
            Opening has been updated.
        </v-snackbar>
    </section>
</template>
<script>
    import {mapActions, mapState} from "vuex";
    import itemService from '../../../services/item-data'

    export default {
        name: 'coverLetterOpening',
        data() {
            return {
                showUpdateOpeningNotification: false,
            }
        },
        computed: {
            ...mapState({
                openingData: (store) => store.opening.openingData
            })
        },
        methods: {
            ...mapActions({
                updateOpeningData: 'opening/updateOpeningData',
            }),
            changeData() {
                const editedObject = {
                    opening_body: this.openingData.opening_body,
                    id: this.openingData.id,
                };
                this.updateOpeningData(editedObject);
            },
            setEditedObject() {
                const editedObject = {
                    opening_body: this.openingData.opening_body,
                    id: this.openingData.id,
                };
                return editedObject;
            },
            updateOpening() {
                const editedOpening = this.setEditedObject();
                itemService.updateOpening(editedOpening.id, editedOpening).then((updateResponse) => {
                    this.updateOpeningData(updateResponse.data);
                    this.showUpdateOpeningNotification = true;
                });
            },
        }
    }
</script>
<style lang="scss" scoped>
    .item-input-sub-cage {
        height: calc(100vh - 260px) !important;
    }
</style>
