import axios from 'axios';
import gateWay from "./index";

export default {
    getGoogleFonts() {
        const googFontUrl = 'https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyD0PmRKj0drVFjfj2_eTkcs_FBFD_otyqc';
        return axios.get(googFontUrl)
    },
    getNotifications() {
        return gateWay.get('v1/web/notifications');
    },
    getNotificationDetail(notificationId){
        return gateWay.get('v1/web/notifications/' + notificationId)
    }
}
