<template>
    <section class="h-100">
        <div class="resume-list main-cage">
            <div class="container row m-auto justify-content-around">
                <div v-if="this.resumeList.length === 0">
                    <p class="resume-list text-title text-ellipsis" v-if="this.resumeListTab === 'all'">You didn't
                        create anything, yet.</p>
                    <p class="resume-list text-title text-ellipsis" v-if="this.resumeListTab === 'cv'">You have not
                        resume, you can create now.</p>
                    <p class="resume-list text-title text-ellipsis" v-if="this.resumeListTab === 'cl'">You have not
                        cover letter, you can create now.</p>
                </div>
                <div v-else class="resume-list cage" v-for="(resume,i) in resumeList" :key="i">
                    <img class="selected-icon"
                         v-if="resume.id === userDetailInfo.user_resume_id || resume.id === userDetailInfo.user_cover_letter_id"
                         src="../../assets/custom-icons/web-site-icons/price-tick-icon.svg" alt="tickIcon">
                    <img class="resume-list image" :src="resume.image" alt="">
                    <div class="d-flex flex-row justify-space-between">
                        <p class="resume-list text-title text-ellipsis">{{resume.title}}</p>
                        <v-menu bottom left offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                    <div>
                                        <span class="material-icons">more_horiz</span>
                                    </div>
                                </v-btn>
                            </template>
                            <div class="action-cage">
                                <v-list>
                                    <v-list-item class="action-item" @click="openDeleteModal(resume)">
                                        <img class="action-icon"
                                             src="../../assets/custom-icons/web-site-icons/erase-icon.svg"
                                             alt="erase-icon">
                                        <p>Delete</p>
                                    </v-list-item>
                                    <v-list-item class="action-item" @click="setPublicPageActive(resume)">
                                        <span class="material-icons public-icon">check_circle</span>
                                        <p>Make Public</p>
                                    </v-list-item>
                                    <v-list-item class="action-item" @click="redirectFeedback(resume.id)">
                                        <img class="action-icon"
                                             src="../../assets/custom-icons/web-site-icons/eye-action-icon.svg"
                                             alt="eye-icon">
                                        <p>Feedbacks</p>
                                    </v-list-item>
                                    <v-list-item class="action-item" @click="openDuplicateModal(resume)">
                                        <img class="action-icon"
                                             src="../../assets/custom-icons/web-site-icons/duplicate-icon.svg"
                                             alt="duplicate-icon">
                                        <p>Duplicate</p>
                                    </v-list-item>
                                    <v-list-item class="action-item">
                                        <img class="action-icon"
                                             src="../../assets/custom-icons/web-site-icons/share-action-icon.svg"
                                             alt="share-icon">
                                        <p>Share</p>
                                    </v-list-item>
                                </v-list>
                            </div>
                        </v-menu>
                    </div>
                    <div class="d-flex align-center justify-space-between">
                        <p class="resume-list date-text">{{formatDate(resume.created_at)}}</p>
                    </div>
                    <div class="d-flex flex-row justify-space-between align-items-center">
                        <v-btn class="head-button-group white-button w-100%" @click="continueEdit(resume.id)">Continue
                            Editing
                        </v-btn>
                        <v-btn class="head-button-group white-button" v-show="false">Download
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>
        <modal :show="eraseModalVisibility" refs="eraseModal" :ext="'modal-dialog--small'"
               @close="eraseModalVisibility = false" :modal-head="'Delete Your Resume'">
            <div class="erase-modal cage">
                <p class="erase-modal modal-text">Are you sure you want to delete your CV named <span
                        class="font-weight-bold">{{selectedResume.title}}?</span></p>
                <div>
                    <v-btn class="red-button mr-2" @click="deleteResume">Yes</v-btn>
                    <v-btn class="white-button" @click="eraseModalVisibility = false">No</v-btn>
                </div>
            </div>
        </modal>
        <modal :show="duplicateModalVisibility" refs="duplicateModal" :ext="'modal-dialog--small'"
               @close="duplicateModalVisibility = false" :modal-head="'Duplicate Your Resume'">
            <div class="erase-modal cage">
                <p class="erase-modal modal-text">Are you sure you want to duplicate your resume?</p>
                <div>
                    <v-btn class="red-button mr-2" @click="duplicateResume">Yes</v-btn>
                    <v-btn class="white-button" @click="duplicateModalVisibility = false">No</v-btn>
                </div>
            </div>
        </modal>
        <v-snackbar v-model="showDuplicateNotification" :timeout="2000" :color="'success'" top>
            Resume Duplicated
        </v-snackbar>
        <v-snackbar v-model="showEraseNotification" :timeout="2000" :color="'success'" top>
            Resume Deleted
        </v-snackbar>
        <v-snackbar v-model="showPublicPageNotification" :timeout="2000" :color="'success'" top>
            Public Page Updated
        </v-snackbar>
    </section>
</template>
<script>
    import resumeService from '../../services/resume'
    import {mapActions, mapState} from "vuex";
    import moment from 'moment';
    import modal from '../atomic/modal.vue'
    import publicPageHelper from '@/mixins/public-page-helper.js';


    export default {
        name: 'resumeList',
        components: {modal},
        mixins: [publicPageHelper],
        data() {
            return {
                eraseModalVisibility: false,
                duplicateModalVisibility: false,
                showEraseNotification: false,
                showDuplicateNotification: false,
                showPublicPageNotification: false,
                selectedResume: ''
            }
        },
        created() {
            this.getResumeList('all');
            this.clearHeaderSetups();
        },
        methods: {
            ...mapActions({
                updateResumeList: 'resume/updateResumeList',
                setUpdateOrderList: 'resume/setUpdateOrderList',
                updateBackgroundDecoration: 'resume/updateBackgroundDecoration',
                updateBackground: 'resume/updateBackground',
                updateFontId: 'resume/updateFontId',
                updateFontColor:'resume/updateFontColor',
            }),
            getResumeList(tabName) {
                resumeService.getResumeList(tabName).then((resumeList) => {
                    this.updateResumeList(resumeList.data);
                })
            },
            formatDate(time) {
                return moment(time).format('LL')
            },
            openDeleteModal(resume) {
                this.selectedResume = resume;
                this.eraseModalVisibility = true
            },
            deleteResume() {
                resumeService.deleteResume(this.selectedResume.id).then(() => {
                    this.getResumeList('all');
                    this.showEraseNotification = true;
                    this.eraseModalVisibility = false;
                })
            },
            continueEdit(resumeId) {
                this.setUpdateOrderList([]);
                this.$router.push('/app/dashboard/' + resumeId);
            },
            openDuplicateModal(resume) {
                this.selectedResume = resume;
                this.duplicateModalVisibility = true;
            },
            duplicateResume() {
                resumeService.duplicateResume(this.selectedResume.id).then(() => {
                    this.getResumeList('cv');
                    this.showDuplicateNotification = true;
                    this.duplicateModalVisibility = false
                })
            },
            redirectFeedback(resumeId) {
                this.$router.push('/website/feedback?resumeId=' + resumeId)
            },
            clearHeaderSetups() {
                this.updateBackgroundDecoration('');
                this.updateBackground('');
                this.updateFontColor('');
                this.updateFontId('')


            }
        },
        computed: {
            ...mapState({
                resumeList: (store) => store.resume.myResumes,
                resumeListTab: (store) => store.general.resumeListTab,
                userDetailInfo: (store) => store.authorization.userDetailInfo,
            })
        },
        watch: {
            resumeListTab() {
                this.getResumeList(this.resumeListTab)
            }
        }
    }
</script>
<style lang="scss" scoped>
    .row {
        margin: auto !important;
    }

    .resume-list {
        &.main-cage {
            padding-top: 30px;
            background: rgba(196, 196, 196, 0.05);
            height: 100%;
        }

        &.text-title {
            font-family: "Segoe UI", sans-serif;
            font-weight: 700;
            font-size: 18px;
        }

        &.cage {
            position: relative;
            width: 370px;
            padding: 20px;
            background: #ffffff;
            box-shadow: 5px 2px 20px -10px rgba(0, 0, 0, 0.75);
            border-radius: 5px;
            margin-bottom: 30px;
            max-height: 500px;
        }

        &.image {
            width: 100%;
            height: 330px;
            margin-bottom: 10px;
        }

        &.date-text {
            font-size: 12px;
            color: #282B34;
            opacity: 60%;
            text-align: start;
            margin-bottom: 15px !important;
        }
    }

    .action-icon {
        margin-right: 6px;
    }

    .action-cage {
        width: 150px;
    }

    .action-item {
        border-bottom: 1px solid #F2F2F3;
        cursor: pointer;

        &:last-child {
            border-bottom: none !important;
        }
    }

    ::v-deep .v-sheet.v-list {
        padding: 0 !important;
    }

    @media (min-width: 1200px) {
        ::v-deep .container {
            max-width: 1200px !important;
        }
    }

    .head-button-group.white-button {
        width: 100%;

        &:last-child {
            border: 1px solid rgba(116, 39, 116, 0.1) !important;
            color: #7e8085 !important;
            font-family: "Roboto", sans-serif;
            font-weight: 500;
        }
    }

    .erase-modal {
        &.cage {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
        }

        &.modal-text {
            width: 100%;
            text-align: center;
            margin-bottom: 15px !important;
        }
    }

    .selected-icon {
        position: absolute;
        right: 10px;
        top: 10px;
        background: #fff;
        border-radius: 50%;
    }

    .public-icon {
        font-size: 15px !important;
        margin-right: 6px;
    }
</style>
