<template>
    <section>
        <div class="linkedin-import cage card">
            <p class="linkedin-import head">Linkedin Import</p>
            <div class="d-flex flex-row justify-center">
                <img class="linkedin-import linkedin-icon"
                     src="../../../assets/custom-icons/web-site-icons/linkedin.svg" alt="linkedinIcon">
                <div class="linkedin-import step-cage">
                    <p class="linkedin-import step-text">
                        <span class="material-icons mr-2">looks_one</span>
                        Copy your Linkedin profile URL.
                    </p>
                    <p class="linkedin-import step-text">
                        <span class="material-icons mr-2">looks_two</span>
                        Paste in the field below.
                    </p>
                </div>
            </div>
            <div class="item-input-cage">
                <p class="item-input-header">Linkedin Profile URL</p>
                <input class="primary-input w-100" v-model="linkedinUrl"
                       placeholder="Example: https://www.linkedin.com/in/your-profile"
                       @input="$emit('changeLinkedinUrl', linkedinUrl)">
            </div>
        </div>
    </section>
</template>
<script>

    export default {
        name: 'linkedinImport',
        data() {
            return {
                linkedinUrl: '',
            }
        },
    }
</script>
<style lang="scss" scoped>
    .linkedin-import {
        &.head{
            font-family: "Segoe UI";
            font-size: 36px;
            font-weight: 700;
            margin-bottom: 35px !important;
        }
        &.cage {
            margin: 5% auto 0;
            max-width: 920px;
            padding: 20px;
        }

        &.step-cage {
            margin-bottom: 30px;
            display: flex;
            flex-direction: column;
        }

        &.step-text {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-family: "Segoe UI";
            font-size: 14px;
        }

        &.linkedin-icon {
            width: 50px;
            height: 50px;
            margin-right: 50px;
        }
    }
</style>
