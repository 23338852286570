<template>
    <section>
        <div class="d-flex align-items-center justify-content-start">
            <a v-if="!isMobile()" @click="openResumePreview">
                <img class="mr-20" src="../../../assets/custom-icons/eye-bg.svg">
            </a>
            <v-btn class="white-button mr-20" @click="openShareModal()">Share</v-btn>
            <v-btn color="yellow-button" @click="openDownloadModal()">Download</v-btn>
        </div>
        <modal :show="downloadModalVisibility" refs="downloadModal" :ext="'modal-dialog--medium'"
               @close="downloadModalVisibility = false" :modal-head="'Download Resume'">
            <!-- <div class="download-modal cage" v-if="userInfo.isUserPro"> -->
            <div class="download-modal cage" >
                <div v-if="loading">
                    <div class="center">
  <div class="wave"></div>
  <div class="wave"></div>
  <div class="wave"></div>
  <div class="wave"></div>
  <div class="wave"></div>
  <div class="wave"></div>
  <div class="wave"></div>
  <div class="wave"></div>
  <div class="wave"></div>
  <div class="wave"></div>
</div>
                </div>
                <div class="d-flex align-items-end">
                    <p class="download-modal file-format-cage">PDF</p>
                    <div class="download-modal file-name-cage">
                        <p class="input-text">Name</p>
                        <input class="primary-input" placeholder="File Name" v-model="fileName">
                    </div>
                </div>
                <v-btn class="yellow-button" @click="downloadPdf">Download</v-btn>
            </div>
            <div class="download-modal cage" v-if="!userInfo.isUserPro">
                <p class="action-primary-text">Download your resume.</p>
            </div>
        </modal>
        <modal :show="shareModalVisibility" refs="shareModal" :ext="'modal-dialog--medium'"
               @close="shareModalVisibility = false" :modal-head="'Share Resume'">
            <div class="share-modal social-actions-cage mt-3">
                <div class="share-modal url-cage">
                    <p class="share-modal link-text">Link</p>
                    <input class="primary-input" placeholder="File Name" v-model="resumeUrl" readonly>
                </div>
                <div class="share-modal social-actions-cage">
                    <p class="share-modal copy-link r-15" @click="copyResumeUrl">Copy Link</p>
                    <p class="share-modal or-text">or</p>
                    <a :href="'https://www.twitter.com/intent/tweet/?url='+ resumeUrl" target="_blank"
                       class="share-modal social-icons">
                        <img src="../../../assets/custom-icons/twitter-icon.svg">
                    </a>
                    <a :href="'https://www.linkedin.com/shareArticle?mini=true&url='+ resumeUrl" target="_blank"
                       class="share-modal social-icons">
                        <img src="../../../assets/custom-icons/linkedin.svg">
                    </a>
                    <a :href="'https://www.facebook.com/sharer.php?u='+ resumeUrl" target="_blank"
                       class="share-modal social-icons">
                        <img src="../../../assets/custom-icons/facebook-icon.svg">
                    </a>
                    <a :href="'mailto:?subject=CvDeck%20Resume&body=' + resumeUrl" class="share-modal social-icons">
                        <img src="../../../assets/custom-icons/mail-icon.svg">
                    </a>
                    <a :href="'https://web.whatsapp.com/send?text='+resumeUrl" target="_blank"
                       class="share-modal social-icons">
                        <img src="../../../assets/custom-icons/whatsapp-icon.svg">
                    </a>

                </div>
            </div>
        </modal>
        <feedback-modal></feedback-modal>
        <v-snackbar v-model="showCopyMessage" :timeout="2000" :color="'success'" top>
            Resume link copied
        </v-snackbar>
    </section>
</template>
<script>
    import modal from '../../atomic/modal.vue'
    import {mapActions, mapState} from "vuex";
    // import html2pdf from "html2pdf.js";
    import axios from 'axios';
    import feedbackModal from '../../modal-views/feedback-modal.vue'


    export default {
        name: 'headerActions',
        components: {modal, feedbackModal},
        data() {
            return {
                fileName: 'my-resume',
                showCopyMessage: false,
                downloadModalVisibility: false,
                shareModalVisibility: false,
                resumeUrl: '',
                loading:false
            }
        },
        methods: {
            ...mapActions({
                updateFeedBackOption: 'authorization/updateFeedBackOption',
                updateLoginModalStatus: 'general/updateLoginModalStatus',
                updateFeedbackModalStatus: 'general/updateFeedbackModalStatus',
                updateCheckoutModalShow: 'general/updateCheckoutModalShow',
            }),
            copyResumeUrl() {
                const selBox = document.createElement('textarea');
                selBox.value = this.resumeUrl;
                document.body.appendChild(selBox);
                selBox.focus();
                selBox.select();
                document.execCommand('copy');
                document.body.removeChild(selBox);
                this.showCopyMessage = !this.showCopyMessage;
            },
            changeFeedBack(val) {
                this.updateFeedBackOption(val);
            },
            downloadPdf() {
                var element = document.querySelector('html').innerHTML;
                let _vue=this;
                this.loading=true;
                var fd = new FormData();
fd.append('filename', 'test');
fd.append('data', element);
                axios.post('https://pdf.anycv.com/download/', fd)
  .then(function (response) {
    console.log(response.data)
                    let link = '/generated/'+response.data
                    var a = document.createElement("a");
                    a.setAttribute("download", _vue.fileName + '.pdf');
                    a.setAttribute("href", link);
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    _vue.loading=false;
  })
  .catch(function (error) {
    console.log(error);
    _vue.loading=false;
  });
              
            },
            openResumePreview() {
                this.$router.push({name: 'preview'});
            },
            openDownloadModal() {
                if (!this.dummyUser) {
                    if (this.userInfo.isUserPro) {
                   
                        this.downloadModalVisibility = true
                } else {
                    this.updateCheckoutModalShow(true);
                }
                } else {
                    this.updateLoginModalStatus(true);
                }
            },
            openShareModal() {
                if (!this.dummyUser) {
                    
                    if (this.userInfo.isUserPro) {
                   
                        this.shareModalVisibility = true;
           } else {
               this.updateCheckoutModalShow(true);
           }
                } else {
                    this.updateLoginModalStatus(true);
                }
            },  
            isMobile() {
                return this.$vuetify.breakpoint.sm||this.$vuetify.breakpoint.xs
            }
        },
        computed: {
            ...mapState({
                userInfo: (store) => store.authorization.userInfo,
                dummyUser: (store) => store.authorization.dummyUser,
                showFeedbackModal: (store) => store.general.showFeedBackModal,
            }),
        }, created() {
            this.resumeUrl = window.location.origin+'/user/' + this.userInfo.code
        }
    }
</script>
<style lang="scss" scoped>

.head-center.actions {
    
.align-items-center.justify-content-start{
        @media (max-width: 420px) {
           scale: 0.7; 
                }
            }
        }
.center {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background:rgba(0, 0, 0, 0.7);
    top: 0;
    left: 0;
    z-index: 10;
}
.wave {
  width: 5px;
  height: 100px;
  background: linear-gradient(45deg, cyan, #fff);
  margin: 10px;
  animation: wave 1s linear infinite;
  border-radius: 20px;
}
.wave:nth-child(2) {
  animation-delay: 0.1s;
}
.wave:nth-child(3) {
  animation-delay: 0.2s;
}
.wave:nth-child(4) {
  animation-delay: 0.3s;
}
.wave:nth-child(5) {
  animation-delay: 0.4s;
}
.wave:nth-child(6) {
  animation-delay: 0.5s;
}
.wave:nth-child(7) {
  animation-delay: 0.6s;
}
.wave:nth-child(8) {
  animation-delay: 0.7s;
}
.wave:nth-child(9) {
  animation-delay: 0.8s;
}
.wave:nth-child(10) {
  animation-delay: 0.9s;
}

@keyframes wave {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
    .action-primary-text {
        font-size: 18px;
        color: #7d7e84;
    }

    .download-modal {
        &.cage {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &.file-format-cage {
            background-color: rgba(196, 196, 196, 0.07);
            border: 1px solid #ebebeb;
            padding: 10px 17px;
            border-radius: 2.5px;
            font-size: 14px;
            color: #7d7e84;
            max-height: 39px;
            margin-right: 45px;
        }

        &.file-name-cage {
            position: relative;
            max-width: 241px;
            min-width: 100%;
        }
    }

    .share-modal {
        &.cage {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &.url-cage {
            position: relative;
            max-width: 409px;
            width: 100%;
        }

        &.copy-link {
            color: #742774;
            font-size: 18px;
            font-weight: 500;
            cursor: pointer;
        }

        &.link-text {
            position: absolute;
            top: -25px;
            margin-bottom: 15px !important;
            font-size: 12px;
            color: #7d7e84;
        }

        &.social-actions-cage {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &.social-icons {
            margin-right: 15px;
            cursor: pointer;

            &:last-child {
                margin-right: 0px;
            }
        }

        &.or-text {
            font-size: 14px;
            color: #000000;
            margin-right: 15px;
        }
    }

    .feedback-modal {
        &.cage {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    .input-text {
        position: absolute;
        top: -25px;
        display: flex;
        font-size: 12px;
        color: #7d7e84;
        margin-bottom: 15px !important;
    }

    .r-15 {
        margin-right: 15px;
    }

    .primary-input {
        width: 100% !important;
    }

    .copied-text {
        color: #fff;
        display: block;
        margin: 0 auto;
    }

    .head-hr {
        border-bottom: 1px solid rgba(196, 196, 196, 0.08);
        width: 100%;
        position: absolute;
        left: 0;
        margin-top: 30px;
    }

    .feedback-secondary {
        &.cage {
            margin-top: 60px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }


        &.info-text {
            font-size: 18px;
            color: #282B34;
            max-width: 415px;
            text-align: start;
        }
    }
</style>
