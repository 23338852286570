<template>
    <section>
        <div class="item-hobbies cage item-input-sub-cage">
            <div class="item-input-cage" v-for="(social,i) in socialData.mySocialData" :key="i">
                <div class="d-flex align-center">
                    <p class="item-input-header">{{social.account}}</p>
                    <img class="locked-icon" v-if="social.paid_version && !userInfo.isUserPro"
                         src="../../../assets/custom-icons/lock-icon.svg" alt="lockedIcon">
                </div>
                <div @click="social.paid_version && !userInfo.isUserPro ?updateCheckoutModalShow(true):''">
                    <input class="primary-input w-100" v-model="social.url" @input="changeData(social)"
                           @focusout="updateSocial()" :disabled="social.paid_version && !userInfo.isUserPro">
                </div>
            </div>
        </div>
        <div class="item-footer-button-cage">
            <v-btn class="yellow-button w-100" @click="updateSocial()">
                Save
            </v-btn>
        </div>
    </section>
</template>
<script>
    import {mapActions, mapState} from "vuex";
    import itemService from '../../../services/item-data'

    export default {
        name: 'itemSocial',
        data() {
            return {
                showUpdateSocialNotification: false,
            }
        },
        computed: {
            ...mapState({
                userInfo: (store) => store.authorization.userInfo,
                socialData: (store) => store.social.socialData
            })
        },
        methods: {
            ...mapActions({
                updateSocialData: 'social/updateSocialData',
                editSocialData: 'social/editSocialData',
                updateCheckoutModalShow: 'general/updateCheckoutModalShow'
            }),
            changeData(socialObject) {
                const editedObject = {
                    data: socialObject
                };
                this.editSocialData(editedObject);
            },
            updateSocial() {
                const socialsObject = {socials: {socials: []}};
                this.socialData.mySocialData.forEach(social => {
                    const socialObject = {
                        id: social.id,
                        account: social.account,
                        url: social.url,
                        position: social.position,
                        html: social.html,
                    };
                    socialsObject.socials.socials.push(socialObject);
                });
                itemService.updateSocials(socialsObject).then((updateResponse) => {
                    this.updateSocialData(updateResponse.data.data);
                })
            }
        }
    }
</script>
<style lang="scss" scoped>
    .locked-icon {
        margin-bottom: 10px;
        margin-left: 10px;
    }

    .primary-input:disabled {
        opacity: 0.4;
        cursor: not-allowed;
    }
</style>
