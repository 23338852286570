<template>
    <section>
        <div id="modal" v-if="showPhotoUploader">
            <div class="modal d-block">
                <div class="modal-dialog modal-dialog-centered modal-dialog--photo-uploader">
                    <div class="modal-content">
                        <div class="photo-editor header-cage">
                            <p class="photo-editor modal-head">Edit Photo</p>
                            <img class="c-pointer" @click="closePhotoUploader"
                                 src="../../assets/custom-icons/close-icon-black.svg">
                        </div>
                        <div class="photo-editor upload-cage">
                            <vue-avatar
                                    id="testId"
                                    :image="summaryData.image"
                                    :width="220"
                                    :height="220"
                                    :border="0"
                                    :rotation="Number(rotation)"
                                    :borderRadius="200"
                                    :scale="Number(scale)"
                                    ref="vueavatar"
                                    @vue-avatar-editor:image-ready="onImageReady">
                            </vue-avatar>
                        </div>
                        <div class="photo-editor settings-cage">
                            <div class="photo-editor settings-range-cage">
                                <div class="d-flex justify-space-between">
                                    <p class="photo-editor settings-text">Zoom</p>
                                    <p class="photo-editor settings-text">{{scale}}x</p>
                                </div>
                                <v-slider step="0.02" v-model="scale" min="1" max="3" :color="'#742774'"
                                          :track-color="'#c4c4c4'"></v-slider>
                            </div>
                            <div class="photo-editor settings-range-cage">
                                <div class="d-flex justify-space-between">
                                    <p class="photo-editor settings-text">Rotation</p>
                                    <p class="photo-editor settings-text">{{rotation}}°</p>
                                </div>
                                <v-slider step="1" v-model="rotation" min="0" max="360" :color="'#742774'"
                                          :track-color="'#c4c4c4'"></v-slider>
                            </div>
                        </div>
                        <hr class="m-0">
                        <div class="photo-editor action-cage">
                            <!-- <p class="photo-editor delete-button">Delete Photo</p> -->
                            <div class="d-flex justify-content-end w-100">
                                <v-btn class="white-button" @click="changePhoto">Change Photo</v-btn>
                                <v-btn class="yellow-button" @click="saveClicked">Save Photo</v-btn>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            <div class="modal-backdrop"/>
        </div>
    </section>
</template>
<script>
    import {VueAvatar} from 'vue-avatar-editor-improved'
    import {mapActions, mapState} from "vuex";
    import itemService from "../../services/item-data";
    import axios from 'axios'

    export default {
        name: 'photoUplaoder',
        components: {
            VueAvatar
        },
        props: ['showPhotoUploader'],
        data() {
            return {
                showPhotoNotification: false,
                rotation: 0,
                scale: 1,
                imgSource: '',
                imgBlob: '',
            }
        },
        methods: {
            ...mapActions({
                updateProfilePhoto: 'summary/updateProfilePhoto',
                updateSummaryData: 'summary/updateSummaryData'
            }),
            async uploadAws() {
                // const aws = require('aws-sdk');
                // aws.config.update({
                //     accessKeyId: 'AKIAYVNMFWXTEO65JXHK',
                //     secretAccessKey: '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'
                // });
                // const s3 = new aws.S3({params: {Bucket: 'anycv-prod-images'}});
                // const s3Res = await s3.upload({
                //     Bucket: 'anycv-prod-images',
                //     Key: this.getPhotoGuid(),
                //     Body: this.imgSource,
                //     ContentType: 'image/jpeg'
                // }).promise();
               // console.log(this.imgSource);
                var fd = new FormData();
fd.append('fname', this.uuidv4());
fd.append('data', this.imgSource);
                axios.post("https://pdf.anycv.com/post_pdf/", fd)
      .then((res) => {
        //console.log(res);
        
        this.updateProfilePhoto('/images/'+res.data+'.jpg');
                this.updateSummary();
      })
      .catch((err) => {
        console.log(err);
      });
// this.updateProfilePhoto(s3Res.Location);
            },
             uuidv4() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
},
            dataURItoBlob(dataURI) {
                const binary = atob(dataURI.split(',')[1]);
                const array = [];
                for (var i = 0; i < binary.length; i++) {
                    array.push(binary.charCodeAt(i));
                }
                return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
            },
            saveClicked() {
                const img = this.$refs.vueavatar.getImageScaled();
                const dataUrl = img.toDataURL("image/jpeg");
                const blobData = this.dataURItoBlob(dataUrl);
                this.imgSource = blobData;
                this.uploadAws();
                this.closePhotoUploader();
                this.$emit('showNotification', true);
            },
            onImageReady() {
                this.scale = 1;
                this.rotation = 0;
            },
            closePhotoUploader() {
                this.$emit('showUploader', false)
            },
            changePhoto() {
                this.$refs.vueavatar.clicked()
            },
            getPhotoGuid() {
                return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                    const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                    return v.toString(16);
                });
            },
            updateSummary() {
                itemService.updateSummaryData(this.summaryData.id, this.summaryData).then((updateSummaryResponse) => {
                    this.updateSummaryData(updateSummaryResponse.data);
                    this.$emit('showNotification', true);
                });
            }
        },
        computed: {
            ...mapState({
                summaryData: (store) => store.summary.summaryData
            })
        }
    }
</script>
<style lang="scss" scoped>
    .modal-content {
        border-radius: 2.5px;
    }

    .modal-dialog--photo-uploader {
        max-width: 770px;
    }

    .modal-backdrop {
        opacity: 0.6 !important;
    }

    .photo-editor {
        &.header-cage {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 25px;
        }

        &.upload-cage {
            background-color: rgb(0, 0, 0, 0.6);
        }

        &.settings-cage {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 19px 25px;
        }

        &.modal-head {
            font-family: "Segoe UI", sans-serif;
            font-size: 18px;
            font-weight: 600;
            color: #000000;
            letter-spacing: 0.5px;
        }

        &.settings-range-cage {
            width: 100%;
            margin-right: 40px;

            &:last-child {
                margin-right: 0px;
            }
        }

        &.settings-text {
            font-size: 14px;
            color: #000000;
        }

        &.image-cage {
            height: 270px;
            width: 100%;
            background: #000000;
            overflow: scroll;
            cursor: move;
        }

        &.action-cage {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 19px 25px;
        }
    }

    ::v-deep .v-slider--horizontal {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }

    .yellow-button {
        max-width: 131px;
        width: 100%;
    }

    .white-button {
        max-width: 149px;
        width: 100%;
        margin-right: 20px;
    }

    .delete-button {
        font-weight: 500;
        font-size: 14px;
        color: #000000;
        width: 100%;
        text-align: left;
    }

    .example-pic {
        border-radius: 50%;
        height: 100px;
        width: 100px;
    }
</style>
