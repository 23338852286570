<template>
    <section>
        <dialog-modal :header="'Common Phrases'" :input-tips="true">
            <template v-slot:content>
                <div class="phrases cage">
                    <div class="phrases cage-row" v-for="(tip,i) in tipsList" :key="i">
                        <a class="c-pointer" @click="addTips(tip)">
                            <img class="mr-2" src="../../../../assets/custom-icons/tips-add-icon.svg" alt="">
                        </a>
                        <p class="phrases tip-text">{{tip.title}}</p>
                    </div>
                </div>
            </template>
        </dialog-modal>
    </section>
</template>
<script>
    import dialogModal from '../../../atomic/dialog-modal.vue'
    import itemService from '../../../../services/item-data.js'

    export default {
        name: 'strengthTips',
        components: {dialogModal},
        data() {
            return {
                tipsList: ''
            }
        },
        created() {
            this.getStrengthTips();
        },
        methods: {
            getStrengthTips() {
                itemService.getStrengthTipsList().then((tipList) => {
                    this.tipsList = tipList.data;
                })
            },
            addTips(tip){
                this.$emit('addStrengthTip', tip)
            }
        }
    }
</script>
<style></style>
