<template>
    <section>
        <div class="item-strengths cage">
            <div v-if="showStrengthsList">
                <div class="item-input-sub-cage">
                    <div class="item-sub-list-cage" v-for="(strength,i) in strengthsData.myStrengths" :key="i">
                        <div>
                            <p class="item-sub-list-text">{{strength.name}}</p>
                            <p class="item-sub-list-text-secondary">{{strength.description}}</p>
                        </div>
                        <div class="d-flex">
                            <span class="material-icons item-sub-list-create-icon"
                                  @click="editStrengths(strength)">create</span>
                            <span class="material-icons item-sub-list-delete-icon"
                                  @click="deleteStrengths(strength)">delete</span>
                        </div>
                    </div>
                </div>
                <div class="item-footer-button-cage">
                    <v-btn class="yellow-button w-100" @click="addStrength">Add New Strength</v-btn>
                </div>
            </div>
            <div v-else>
                <div class="item-input-sub-cage">
                    <div class="item-input-cage">
                        <div class="item-input-tips-cage">
                            <p class="item-input-header">Strengths Name</p>
                            <strength-tips @addStrengthTip="setStrengthTips($event)"></strength-tips>
                        </div>
                        <input class="primary-input w-100" v-model="strengthsData.name" @input="changeData"
                               @focusout="isEditing? updateStrength(false) : ''">
                    </div>
                    <div class="item-input-cage">
                        <p class="item-input-header">Description</p>
                        <input class="primary-input w-100" v-model="strengthsData.description"
                               @focusout="isEditing? updateStrength(false) : ''">
                    </div>
                </div>
                <div class="item-footer-button-cage">
                    <v-btn class="yellow-button w-100" @click="isEditing ? updateStrength(true) : saveNewStrength()">
                        Save
                    </v-btn>
                </div>
            </div>
        </div>
        <v-snackbar v-model="showAddStrengthsNotification" :timeout="2000" :color="'success'" top>
            Strength has been added.
        </v-snackbar>
        <v-snackbar v-model="showUpdateStrengthsNotification" :timeout="2000" :color="'success'" top>
            Strength has been updated.
        </v-snackbar>
        <v-snackbar v-model="showDeleteStrengthsNotification" :timeout="2000" :color="'success'" top>
            Strength has been deleted.
        </v-snackbar>
    </section>
</template>
<script>
    import {mapActions, mapState} from "vuex";
    import itemService from '../../../services/item-data'
    import StrengthTips from "@/components/contents/items/tips/strength-tips";

    export default {
        name: 'itemStrengths',
        components: {StrengthTips},
        data() {
            return {
                showStrengthsList: true,
                showAddStrengthsNotification: false,
                showUpdateStrengthsNotification: false,
                showDeleteStrengthsNotification: false,
                isEditing: false,
            }
        },
        created() {
            this.showStrengthsList = this.strengthsData.myStrengths.length > 0;
        },
        computed: {
            ...mapState({
                strengthsData: (store) => store.strengths.strengthsData
            })
        },
        methods: {
            ...mapActions({
                updateStrengthsData: 'strengths/updateStrengthsData',
                addNewStrength: 'strengths/addNewStrength',
                deleteStrength: 'strengths/deleteStrength',
                editStrength: 'strengths/editStrength',
            }),
            changeData() {
                if (this.isEditing) {
                    const editedObject = {
                        data: this.setEditedObject()
                    };
                    this.editStrength(editedObject);
                }
            },
            setStrengthTips(tip){
                this.strengthsData.name = tip.title;
                if(this.isEditing){
                    this.updateStrength(false)
                }else{
                    this.changeData();
                }
            },
            editStrengths(strength) {
                this.isEditing = true;
                this.strengthsData.name = strength.name;
                this.strengthsData.description = strength.description;
                this.strengthsData.id = strength.id;
                this.showStrengthsList = false;
            },
            deleteStrengths(strength) {
                itemService.deleteStrength(strength.id).then((deleteResponse) => {
                    console.log('delete Resp', deleteResponse);
                    const strengthIndex = this.strengthsData.myStrengths.indexOf(strength);
                    this.deleteStrength(strengthIndex);
                    this.showDeleteStrengthsNotification = true;
                    this.showStrengthsList = this.strengthsData.myStrengths.length > 0;
                });
            },
            addStrength() {
                this.isEditing = false;
                this.strengthsData.name = '';
                this.strengthsData.description = '';
                this.showStrengthsList = false;
            },
            setEditedObject() {
                const editedObject = {
                    name: this.strengthsData.name,
                    description: this.strengthsData.description,
                    id: this.strengthsData.id
                };
                return editedObject;
            },
            updateStrength(goBack) {
                const editedStrength = {
                    name: this.strengthsData.name,
                    description: this.strengthsData.description,
                    id: this.strengthsData.id
                };
                itemService.updateStrength(editedStrength.id, editedStrength).then((updatedResponse) => {
                    this.editStrength(updatedResponse);
                    this.showUpdateStrengthsNotification = true;
                    this.showStrengthsList = goBack;
                })
            },
            saveNewStrength() {
                const newStrength = {
                    name: this.strengthsData.name,
                    description: this.strengthsData.description,
                };
                itemService.createStrength(newStrength).then((createResponse) => {
                    this.addNewStrength(createResponse.data);
                    this.showAddStrengthsNotification = true;
                    this.showStrengthsList = true;
                });
            }
        }
    }
</script>
<style></style>
