<template>
    <section>
        <div class="tos cage card container">
            <h3>TERMS OF SERVICE</h3>
            <h5>Disclaimer</h5>
            <p>AnyCO (Chamber of Commerce: 0700878641), hereinafter referred to as anycv.com, hereby grants you access
                to https://anycv.com. (“the Website”) and invites you to purchase the services ANYCO Ltd. ("us", "we",
                or "our").
                AnyCV.com reserves the right to change the content or remove parts of it at any time without being
                obliged to notify you.
                By using AnyCV or accessing any of our Services, you are agreeing to the following terms. Please read
                them carefully and contact us via support@anycv.com if you have any questions.</p>
            <h5>Limited liability</h5>
            <p>AnyCV.com endeavours to update and/or supplement the content of the Website on a regular basis. Despite
                our care and attention, content may be incomplete and/or incorrect.
                The materials offered on the Website are offered without any form of guarantee or claim to their
                correctness. These materials can be changed at any time without prior notice from AnyCV.com.
                Particularly, all prices on the Website are stated subject to typing and programming errors. No
                liability is assumed for the implications of such errors. No agreement is concluded on the basis of such
                errors.
                AnyCV.com shall not bear any liability for hyperlinks to websites or services of third parties included
                on the Website.</p>
            <h5>Copyrights</h5>
            <p>All intellectual property rights concerning these materials are stored in AnyCV.com.
                Copying, distribution and any other use of these materials is not permitted without the written
                permission of AnyCV.com, except and only to the extent otherwise provided in regulations of mandatory
                law (such as the right to quote), unless otherwise stated for certain materials.</p>
            <h5>Contact:</h5>
            <p>Flatofis Halic, Otakcilar Cad. No: 78 Kat: 4 No: 94
                34060 Eyupsultan / ISTANBUL
                support@anycv.com</p>
            <hr>
            <h5>Account Management</h5>
            <p>When you create an account on AnyCV, you are fully responsible for maintaining its security and any
                activities that occur under your account. Please, use a "strong" password (or authenticate via a
                third-party) to ensure that your private information, your resumes and billing information are safe.
                We cannot and will not be liable for any loss or damage arising from your failure to comply with the
                above requirements.</p>
            <h5>Content</h5>
            <p>You are solely responsible for all the content you create using our Services. We may review content to
                determine whether it violates our policies, and we may remove content in those cases. But that does not
                necessarily mean that we review content, so please don’t assume that we do.</p>
            <h5>Membership fees</h5>
            <p>AnyCV reserves the right to require payment or membership fees for any of our Services. At the beginning
                of each billing period you will be automatically charged for the entire period.
                The prices may be changed at any time, upon thirty (30) day prior notice to you, which may be sent by
                email or posted on the website. Your use of the Services following such notification constitutes your
                acceptance of any new or increased charges.
                Downgrading your plan may cause the loss of access to some capabilities of your Account. AnyCV does not
                accept any liability for such loss.
                Please know that all prices on the AnyCV website and all charges made are in US Dollars.</p>
            <h5>Cancellation</h5>
            <p>If you no longer need our Service, it is your responsibility to cancel the subscription (through the
                Billing page) before the next billing date. After successful cancellation of your subscription you will
                receive an email confirmation.</p>
            <h5>Refunds</h5>
            <p>Any monthly fees paid hereunder are non-refundable if you have used our services during that billing
                period (unless there is evidence of a technical issue on our side that prevented you from canceling or
                using our services entirely). </p>
            <h5>Trials & Discounts</h5>
            <p>Any trial or reduced pricing (promocode) must be used within the specified time. You must cancel your
                account before the end of the promotional period to avoid being charged a regular fee for next billing
                period (always refer to information in Billing page). We reserve the right to limit you to one trial
                period or a single promocode as well as to prohibit the combination of trials, promocodes, and other
                offers. Credit accumulated through inviting friends cannot be exchanged, refunded, replaced or redeemed
                for cash. All promocode-reduced fees are non-refundable.</p>
            <h5>Termination</h5>
            <p>AnyCV, in its sole discretion, has the right to terminate your account for any reason at any time. Such
                termination of the Services will result in the deactivation or deletion of your account and the
                forfeiture and relinquishment of all content in your account. In such cases, you are not eligible for
                any refunds.
                If you wish to terminate your account, you may do so through the Account page.</p>
            <h5>Privacy</h5>
            <p>Your personal information and content is governed by our Privacy Policy. By using AnyCV, you agree to our
                Privacy Policy.</p>
            <h5>Governing Law</h5>
            <p>These Terms shall be governed and construed in accordance with the laws of Turkey, without regard to its
                conflict of law provisions.
                Our failure to enforce any right or provision of these Terms will not be considered a waiver of those
                rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining
                provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us
                regarding our Service, and supersede and replace any prior agreements we might have between us regarding
                the Service.</p>
            <h5>Changes</h5>
            <p>We reserve the right to modify these terms at any time. If a revision is material, we will try to provide
                at least 15 days notice prior to any new terms taking effect. What constitutes a material change will be
                determined at our sole discretion. By continuing to access or use our Service after those revisions
                become effective, you agree to be bound by the revised terms. If you do not agree to the new terms,
                please stop using the Service.</p>
        </div>
    </section>
</template>
<script>
    export default {
        name: 'termsOfService'
    }
</script>
<style lang="scss" scoped>
    .card {
        margin-bottom: 20px;
        background: #ebecf0;
    }

    * {
        font-family: "Segoe UI";
    }

    h3 {
        margin: 20px 0px 30px;
        font-weight: 600;
    }

    h5 {
        font-weight: 600;
        text-align: start;
        margin-bottom: 20px;
    }

    p {
        margin-left: 10px;
        margin-bottom: 20px !important;
        text-align: start;
    }
    .tos {
        &.cage {
            margin-top: 54px;
        }
    }
</style>
