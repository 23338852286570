<template>
    <section>
        <div class="item-hobbies cage">
            <div v-if="showHobbiesList">
                <div class="item-input-sub-cage">
                    <div class="item-sub-list-cage" v-for="(hobby,i) in hobbiesData.myHobbies" :key="i">
                        <div>
                            <p class="item-sub-list-text">{{hobby.name}}</p>
                            <p class="item-sub-list-text-secondary d-flex align-items-center">Icon: <span
                                    class="material-icons hobby-icon">{{hobby.icon}}</span></p>
                        </div>
                        <div class="d-flex">
                            <span class="material-icons item-sub-list-create-icon"
                                  @click="editHobbies(hobby)">create</span>
                            <span class="material-icons item-sub-list-delete-icon"
                                  @click="deleteHobbies(hobby)">delete</span>
                        </div>
                    </div>
                </div>
                <div class="item-footer-button-cage">
                    <v-btn class="yellow-button w-100" @click="addHobby">Add New Hobby</v-btn>
                </div>
            </div>
            <div v-else>
                <div class="item-input-sub-cage">
                    <div class="item-input-cage">
                        <p class="item-input-header">Hobby Name</p>
                        <input class="primary-input w-100" v-model="hobbiesData.name" @input="changeData"
                               @focusout="isEditing? updateHobbies(false) : ''">
                    </div>
                    <div class="item-input-cage">
                        <p class="item-input-header">Icon</p>
                        <div class="icon-selector cage">
                        <span class="material-icons icon-selector icon" v-for="(icon, i) in iconList" :key="i"
                              @click="updateSelectedIcon(icon)"
                              :class="{'active-icon':hobbiesData.icon=== icon}">{{icon}}</span>
                        </div>
                    </div>
                </div>
                <div class="item-footer-button-cage">
                    <v-btn class="yellow-button w-100" @click="isEditing ? updateHobbies(true) : saveNewHobby()">
                        Save
                    </v-btn>
                </div>
            </div>
        </div>
        <v-snackbar v-model="showAddHobbyNotification" :timeout="2000" :color="'success'" top>
            Hobby has been added.
        </v-snackbar>
        <v-snackbar v-model="showUpdateHobbyNotification" :timeout="2000" :color="'success'" top>
            Hobby has been updated.
        </v-snackbar>
        <v-snackbar v-model="showDeleteHobbyNotification" :timeout="2000" :color="'success'" top>
            Hobby has been deleted.
        </v-snackbar>
    </section>
</template>
<script>
    import {mapActions, mapState} from "vuex";
    import itemService from '../../../services/item-data';

    export default {
        name: 'itemHobbies',
        data() {
            return {
                showHobbiesList: true,
                isEditing: false,
                showAddHobbyNotification: false,
                showUpdateHobbyNotification: false,
                showDeleteHobbyNotification: false,
                iconList: ['code', 'menu_book', 'videogame_asset', 'brush', 'favorite_border']
            }
        },
        methods: {
            ...mapActions({
                updateHobbiesData: 'hobbies/updateHobbiesData',
                addNewHobby: 'hobbies/addNewHobby',
                deleteHobby: 'hobbies/deleteHobby',
                editHobby: 'hobbies/editHobby',
            }),
            changeData() {
                if (this.isEditing) {
                    const editedObject = {
                        data: this.setEditedObject()
                    };
                    this.editHobby(editedObject);
                }
            },
            editHobbies(hobby) {
                this.isEditing = true;
                this.hobbiesData.name = hobby.name;
                this.hobbiesData.icon = hobby.icon;
                this.hobbiesData.description = hobby.description;
                this.hobbiesData.id = hobby.id;
                this.showHobbiesList = false;
            },
            deleteHobbies(hobby) {
                itemService.deleteHobby(hobby.id).then(() => {
                    const hobbyIndex = this.hobbiesData.myHobbies.indexOf(hobby);
                    this.deleteHobby(hobbyIndex);
                    this.showDeleteHobbyNotification = true;
                    this.showHobbiesList = this.hobbiesData.myHobbies.length > 0;
                })
            },
            addHobby() {
                this.isEditing = false;
                this.hobbiesData.name = '';
                this.hobbiesData.icon = '';
                this.hobbiesData.description = '';
                this.hobbiesData.id = '';
                this.showHobbiesList = false
            },
            setEditedObject(){
                const editedObject = {
                    name: this.hobbiesData.name,
                    icon: this.hobbiesData.icon,
                    description: this.hobbiesData.description,
                    id: this.hobbiesData.id
                };
                return editedObject;
            },
            updateHobbies(goBack) {
                const editedHobby = {
                    name: this.hobbiesData.name,
                    icon: this.hobbiesData.icon,
                    description: this.hobbiesData.description,
                    id: this.hobbiesData.id
                };
                itemService.updateHobby(editedHobby.id, editedHobby).then((updateResponse) => {
                    this.editHobby(updateResponse);
                    this.showUpdateHobbyNotification = true;
                    this.showHobbiesList = goBack;
                })
            },
            saveNewHobby() {
                const newHobby = {
                    name: this.hobbiesData.name,
                    icon: this.hobbiesData.icon,
                    description: this.hobbiesData.description
                };
                itemService.createHobby(newHobby).then((createResponse) => {
                    this.addNewHobby(createResponse.data);
                    this.showAddHobbyNotification = true;
                    this.showHobbiesList = true;
                })
            },
            updateSelectedIcon(icon) {
                if (this.isEditing) {
                    this.hobbiesData.icon = icon;
                    this.updateHobbies(false);
                } else {
                    this.hobbiesData.icon = icon;
                }
            }
        },
        created(){
          this.showHobbiesList = this.hobbiesData.myHobbies.length > 0;
        },
        computed: {
            ...mapState({
                hobbiesData: (store) => store.hobbies.hobbiesData
            })
        }
    }
</script>
<style lang="scss" scoped>
    .hobby-icon {
        font-size: 14px;
        margin-left: 5px;
    }

    .icon-selector {
        &.cage {
            display: flex;
            justify-content: space-around;
            padding: 10px;
            background: rgba(196, 196, 196, 0.07);
            border: 1px solid #ebebeb;
            border-radius: 2.5px;
        }

        &.icon {
            cursor: pointer;
            padding: 5px;
            border-radius: 2.5px;

            &.active-icon {
                background: #efebfa;
                border: 1px solid #742774;
            }
        }
    }
</style>
