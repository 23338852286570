<template>
    <section>
        <img src="../../../assets/img/web-site/welcome-aboard.svg" alt="">
        <p class="create-resume welcome-text">Welcome aboard!</p>
        <p class="create-resume welcome-sub-text">How would you like to start creating your resume?</p>
        <div class="create-resume start-selection-cage">
            <div class="create-resume start-selection-row" @click="$emit('setStartType','industrySelector')"
                 :class="{'active-row':startType ==='industryTemplate'}">
                <img class="create-resume selection-icon" src="../../../assets/img/web-site/industry-icon.svg" alt="">
                <div class="d-flex flex-column">
                    <p class="create-resume selection-head">Start with an industry example</p>
                    <p class="create-resume selection-description">Advisory template based on industry and experience</p>
                </div>
            </div>
            <div class="create-resume start-selection-row" @click="$emit('setStartType','linkedinImport')"
                 :class="{'active-row':startType ==='linkedinImport'}">
                <img class="create-resume selection-icon" src="../../../assets/img/web-site/create-linkedin-icon.svg"
                     alt="">
                <div class="d-flex flex-column">
                    <p class="create-resume selection-head">Linkedin Profile Import</p>
                    <p class="create-resume selection-description">Get a head start on your resume by importing your information from Linkedin</p>
                </div>
            </div>
            <p class="create-resume redirect-template-document" @click="$emit('createBlankTemplate')">...or start with a template.</p>
        </div>
    </section>
</template>
<script>
    export default {
        name: 'resumeStartSelector',
        props: ['startType']
    }
</script>
<style lang="scss" scoped>
    .create-resume {
        &.welcome-text {
            font-family: "Segoe UI";
            font-weight: 600;
            font-size: 28px;
            margin-top: 65px;
        }

        &.welcome-sub-text {
            font-family: "Segoe UI";
            font-weight: 400;
            font-size: 18px;
            color: #282B34;
            opacity: 60%;
            margin-top: 10px;
        }

        &.start-selection-cage {
            margin: 33px auto 0;
            max-width: 570px;
        }

        &.start-selection-row {
            box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.08);
            display: flex;
            flex-direction: row;
            padding: 20px;
            cursor: pointer;
            border-radius: 5px;

            &:first-child {
                margin-bottom: 23px;
            }

            &.active-row {
                outline: #742774 auto 1px;
            }
            &:hover{
                outline:1px solid #742774;
            }
        }

        &.selection-description {
            font-family: "Segoe UI";
            font-size: 14px;
            font-weight: 300;
            color: #000000;
            text-align: start;
        }

        &.selection-head {
            font-family: "Segoe UI";
            font-size: 18px;
            font-weight: 600;
            color: #000000;
            text-align: start;
        }

        &.selection-icon {
            padding: 5px 10px;
            border: 1px solid rgba(0, 0, 0, 0.07);
            border-radius: 5px;
            margin-right: 18px;
        }

        &.redirect-template-document {
            margin-top: 50px;
            color: #742774;
            font-size: 14px;
            font-weight: 500;
            cursor: pointer;
        }
    }
</style>
