<template>
    <section>
        <div class="plan-selector cage row small">
            <div class="plan-selector sub-cage col-md-5  col-sm-12">
                <div class="plan-selector head-cage">
                    <p class="plan-selector card-header">Basic</p>
                    <p class="plan-selector current-plan-text" v-if="!userInfo.isUserPro">Current Plan</p>
                </div>
                <div class="plan-selector content-cage flex-column align-items-start">
                    <p class="plan-selector price-text">0$</p>
                    <p>Forever Free</p>
                </div>
                <div class="plan-selector spec-cage">
                    <div :class="isMobile() ?' d-flex':' d-flex align-items-center w-50 mb-3'" v-for="(spec,i) in freeDataList" :key="i">
                        <img v-if="spec.name!=='PDF Download'&&spec.name!=='Personal URL'&&spec.name!=='Customized URL'&&spec.name!=='Cv Reviews / Ratings'&&spec.name!=='Professional Tips'" class="mr-2" src="../../../assets/custom-icons/web-site-icons/price-tick-icon.svg">
                        <img v-if="spec.name=='PDF Download'||spec.name=='Personal URL'||spec.name=='Customized URL'||spec.name=='Cv Reviews / Ratings'||spec.name=='Professional Tips'" class="mr-2" src="../../../assets/custom-icons/web-site-icons/Red_X.svg.png" style="width: 20px;margin-left: 7px;">
                        <p class="plan-selector spec-name-text">{{spec.name}}</p>
                    </div>
                </div>
                <div class="button-cage" v-if="userInfo.isUserPro">
                    <v-btn class="yellow-button w-100" @click="$emit('setSelectedPlan', 'basic')">
                        Select Plan
                    </v-btn>
                </div>
            </div>
            <div class="plan-selector sub-cage col-md-5 col-sm-12" :class="{'selected-plan': selectedPlan === 'pro'}">
                <div class="plan-selector head-cage">
                    <p class="plan-selector card-header">Pro</p>
                    <p class="plan-selector current-plan-text" v-if="userInfo.isUserPro">Current Plan</p>
                </div>
                <div class="plan-selector content-cage flex-row align-items-center justify-space-between">
                    <p class="plan-selector price-text purple-text" v-if="!planMonthly">19.99$ <span
                            class="plan-selector monthly-text">/mo</span></p>
                    <p class="plan-selector price-text purple-text" v-else>9.99$ <span class="plan-selector monthly-text">/mo</span>
                    </p>
                    <toggle-box class="price-table time-selector" :first-label="'Monthly'"
                                :second-label="'Yearly'"
                                :toggleValue="planMonthly"
                                @changeToggle="planMonthly = $event">
                    </toggle-box>
                </div>
                <div class="plan-selector spec-cage">
                    <div :class="isMobile() ?' d-flex ':' d-flex align-items-center w-50 mb-3'" v-for="(spec,i) in proDataList" :key="i">
                        <img class="mr-2" src="../../../assets/custom-icons/web-site-icons/price-tick-icon.svg">
                        <p class="plan-selector spec-name-text">{{spec.name}}</p>
                    </div>
                </div>
                <div class="button-cage" v-if="!userInfo.isUserPro">
                    <v-btn class="yellow-button w-100" @click="openSaleModal">
                        Select Plan
                    </v-btn>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    import toggleBox from "@/components/atomic/input/toggle-box";
    import {mapState,mapActions} from "vuex";
    import authService from '../../../services/auth';

    export default {
        name: 'checkoutPlanSelector',
        components: {toggleBox},
        props: ['selectedPlan'],
        data() {
            return {
                planMonthly: true,
                freeDataList: [
                    {name: '3 Templates'},
                    {name: '3 Fonts'},
                    {name: 'Backgrounds'},
                    {name: 'PDF Download'},
                    {name: 'Professional Tips'},
                    {name: 'Personal URL'}, 
                    {name: 'Customized URL'},
                    {name: 'Cv Reviews / Ratings'},
                ],
                proDataList: [
                    {name: '20+ Templates'},
                    {name: '10 Fonts'},
                    {name: 'Backgrounds'},
                    {name: 'PDF Download'},
                    {name: 'Professional Tips'},
                    {name: 'Personal URL'},
                    {name: 'Customized URL'},
                    {name: 'Cv Reviews / Ratings'},
                ]
            }
        },
        methods: { 
                      ...mapActions({
                updateLoginModalStatus: 'general/updateLoginModalStatus',
                updateCredentialInfo: 'authorization/updateCredentialInfo',
                updateUserInfo: 'authorization/updateUserInfo',
                updateDummyUserStatus: 'authorization/updateDummyUserStatus',
                updateUserDetail: 'authorization/updateUserDetail',
            }),
            async controlUserCredential() {
                const haveToken = localStorage.getItem('access-token') ? true : false;
                this.updateLoginModalStatus(!haveToken);
                if (haveToken) {
                    authService.validateToken().then((tokenResponse) => {
                        this.updateUserInfo(tokenResponse.data.data);
                        this.updateDummyUserStatus(tokenResponse.data.data.dummy);
                    });
                    authService.getUserInfo().then((userDetailInfo) => {
                        this.updateUserDetail(userDetailInfo.data.data)
                    })
                } else {
                    authService.mergeStart().then((loginResponse) => {
                        this.updateDummyUserStatus(loginResponse.data.user.dummy);
                        const credentialObject = {
                            access_token: loginResponse.data.auth['access-token'],
                            client: loginResponse.data.auth['client'],
                            uid: loginResponse.data.auth['uid'],
                            isDummy: loginResponse.data.user.dummy
                        };
                        this.updateCredentialInfo(credentialObject);
                        authService.validateToken().then((tokenResponse) => {
                            this.updateUserInfo(tokenResponse.data.data);
                            this.updateDummyUserStatus(tokenResponse.data.data.dummy);
                        });
                        authService.getUserInfo().then((userDetailInfo) => {
                            this.updateUserDetail(userDetailInfo.data.data)
                        });
                        window.location.reload();
                    })
                }
            },
            openSaleModal() {
                let _vue=this;
                this.$emit('setSelectedPlan', 'pro');
                
        // _vue.$emit('close', false)
                const Paddle = window.Paddle;
                Paddle.Setup({ vendor: 145899 });
                if(!this.planMonthly){

                Paddle.Checkout.open({
                    product: 808836,
                    passthrough: this.userInfo.uid,
                    email: this.userInfo.email,
  eventCallback: function(data) {
    alert();
        _vue.controlUserCredential();
    // The data.event will specify the event type
    if (data.event === "Checkout.Complete") {
        _vue.userInfo.isUserPro=true;
        _vue.$emit('close', false)
      console.log(data.eventData); // Data specifics on the event
    }
    else if (data.event === "Checkout.Close") {
      console.log(data.eventData); // Data specifics on the event
    }
  }
}
                );
                }else{
                    
                Paddle.Checkout.open({
                    product: 808837,
                    passthrough: this.userInfo.uid,
                    email: this.userInfo.email,
  successCallback: function() {
        _vue.controlUserCredential();
  },
  closeCallback:function() {
    
  },
  eventCallback: function(data) {
    
    alert();
        _vue.controlUserCredential();
    // The data.event will specify the event type
    if (data.event === "Checkout.Complete") {
        
        _vue.$emit('close', false)
        _vue.userInfo.isUserPro=true;
     // console.log(data.eventData); // Data specifics on the event
    }
    else if (data.event === "Checkout.Close") {
      //console.log(data.eventData); // Data specifics on the event
    }
  }
});
                }
            },  
            isMobile() {
                return this.$vuetify.breakpoint.sm||this.$vuetify.breakpoint.xs
            }
        },
        computed: {
            ...mapState({
                userInfo: (store) => store.authorization.userInfo,
            })
        }
        
    }
</script>
<style lang="scss" scoped>
    .plan-selector {
        &.cage {
            display: flex;
            flex-direction: row;
            justify-content: center;
        }

        &.sub-cage {
            &:first-child {
                margin-right: 35px;
            }

            width: 100%;
            background: #ffffff;
            border: 1px solid rgba(0, 0, 0, 0.05);
            border-radius: 5px;
            margin-right: 2px;
            margin-bottom: 2px;
            margin-top: 2px;
        }

        &.head-cage {
            background: rgba(248, 248, 248, 0.71);
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &.card-header {
            font-family: "Segoe UI";
            font-size: 18px;
            font-weight: 600;
            color: #000000;
            border: none !important;
            background: none !important;
        }

        &.current-plan-text {
            font-family: "Segoe UI";
            font-size: 14px;
            font-weight: 400;
            color: #742774;
            padding: 0.75rem 1.25rem;
        }

        &.content-cage {
            background: #ffffff;
            padding: 17px 20px;
            display: flex;
            @media (max-width: 420px) {
                display: inline-table  !important;
            }
        }

        &.price-text {
            font-family: "Segoe UI";
            font-size: 36px;
            font-weight: 600;
            color: #282b34;
            text-align: start;

            &.purple-text {
                color: #742774 !important;
            }
        }

        &.monthly-text {
            font-family: "Segoe UI";
            font-size: 18px;
            font-weight: 600;
            color: #742774;
        }

        &.spec-cage {
            display: flex;
            flex-wrap: wrap;
            padding: 0.75rem 1.25rem;
        }

        &.spec-name-text {
            font-family: "Segoe UI";
            font-weight: 400;
            font-size: 14px;
            color: #676666;
            text-align: start;
        }
    }

    .button-cage {
        padding: 0.75rem 1.25rem;
    }

    .selected-plan {
        outline: 2px solid #742774;
    }
    
 
</style>
