import Vue from 'vue';
import VueRouter from 'vue-router';

import Download from '../components/header/app-header/actions/resume-download.vue'

import Dashboard from '../views/application/dashboard.vue'
import ResumeDashboard from '../views/website/resume-list-page.vue'
import HomePage from '../views/website/home-page.vue'
import CreateResume from '../views/website/create-resume-page.vue'
import Pricing from '../views/website/pricing-page.vue'
import Checkout from '../views/website/checkout-page.vue'
import LoginPage from '../views/website/login-page.vue'
import TermsOfService from '../views/website/terms-of-service.vue'
import PrivacyPolicy from '../views/website/privacy-policy.vue'
import ProfileSettings from '../views/website/profile-settings.vue'
import VideoCv from '../views/website/video-cv.vue'
import Feedback from '../views/website/feedback.vue'
import Preview from '../components/header/app-header/actions/resume-preview.vue'
import PublicPage from '../views/website/public-page.vue'

Vue.use(VueRouter);
const routes = [
    {
        path: '*',
        redirect: '/website/create-resume',
    },
    {
        path: '/',
        name: '',
        component: HomePage,
        meta: {
            title: 'AnyCv - Create Resume',
            platform: 'web'
        }
    },
    {
        path: '/website/create-resume',
        name: 'createResume',
        component: CreateResume,
        meta: {
            title: 'AnyCv - Create Resume',
            platform: 'web'
        }
    },
    {
        path: '/website/resume',
        name: 'resumeDashboard',
        component: ResumeDashboard,
        meta: {
            title: 'AnyCv - Resume Dashboard',
            platform: 'web'
        }
    },
    {
        path: '/website/pricing',
        name: 'pricingPage',
        component: Pricing,
        meta: {
            title: 'AnyCv - Pricing',
            platform: 'web'
        }
    },
    {
        path: '/website/checkout',
        name: 'checkoutPage',
        component: Checkout,
        meta: {
            title: 'AnyCv - Checkout',
            platform: 'web'
        }
    },
    {
        path: '/website/auth',
        name: 'authPage',
        component: LoginPage,
        meta: {
            title: 'AnyCv - Login',
            platform: ''
        }
    },
    {
        path: '/website/privacy-policy',
        name: 'privacyPolicy',
        component: PrivacyPolicy,
        meta: {
            title: 'AnyCv - Privacy Policy',
            platform: 'web'
        }
    },
    {
        path: '/website/terms-of-service',
        name: 'termsOfService',
        component: TermsOfService,
        meta: {
            title: 'AnyCv - Terms Of Service',
            platform: 'web'
        }
    },
    {
        path: '/website/profile-settings',
        name: 'profileSettings',
        component: ProfileSettings,
        meta: {
            title: 'AnyCv - Profile Settings',
            platform: 'web'
        }
    },
    {
        path: '/website/video-cv',
        name: 'videoCv',
        component: VideoCv,
        meta: {
            title: 'AnyCv - Video Cv',
            platform: 'web'
        }
    },
    {
        path: '/website/feedback',
        name: 'feedback',
        component: Feedback,
        meta: {
            title: 'AnyCv - Feedback',
            platform: 'web'
        }
    },
    {
        path: '/website/preview',
        name: 'preview',
        component: Preview,
        meta: {
            title: 'AnyCv - Preview',
            platform: ''
        }
    },
    {
        path: '/website/download/:id',
        name: 'download',
        component: Download,
        meta: {
            title: 'AnyCv - Download',
            platform: 'web'
        },
    },

    {
        path: '/user/:user',
        name: 'publicPage',
        component: PublicPage,
        meta: {
            title: 'AnyCv - Public Page',
            platform: 'web'
        }
    },

    // Application Routes
    {
        path: '/app/dashboard/:id',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            title: 'AnyCv - Dashboard',
            platform: 'app'
        },
    }];

const router = new VueRouter({
    mode: 'history',
    routes,
});

export default router;
