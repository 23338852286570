<template>
    <div class="text-center">
        <v-menu top :offset-x="true" :nudge-left="-20" :nudge-top="-50" :close-on-content-click="false" ref="dialogModal">
            <template v-slot:activator="{ on, attrs }">
                <div v-if="!inputTips">
                <p class="dialog-modal open-button" dark v-bind="attrs" v-on="on">{{buttonName}}</p>
                </div>
                <div class="c-pointer"  v-else>
                    <img v-bind="attrs" v-on="on" src="../../assets/custom-icons/input-tip-icon.svg">
                </div>
            </template>

            <div class="dialog-modal cage">
                <div class="d-flex justify-space-between align-items-center">
                    <p class="dialog-modal header">{{header}}</p>
                        <a @click="$refs.dialogModal.isActive = false">
                            <img src="../../assets/custom-icons/dialog-modal-close.svg" alt="modal-close">
                        </a>
                </div>
                <slot name="content"></slot>
            </div>
        </v-menu>
    </div>
</template>
<script>
    export default {
        name: 'dialogModal',
        props: ['header', 'buttonName', 'inputTips']
    }
</script>
<style lang="scss" scoped>
    .dialog-modal {
        &.cage {
            padding: 25px;
            background: #ffffff;
            width: 484px;
        }

        &.header {
            font-size: 18px;
            font-weight: 600;
            font-family: "Segoe UI";
            color: #000000;
        }

        &.open-button {
            border: 1px solid rgba(40, 43, 52, 0.6);
            border-radius: 5px;
            font-weight: 500;
            color: #282B34;
            font-size: 14px;
            padding: 5px 17px;
            cursor: pointer;
        }
    }

    ::v-deep .v-menu__content {
        margin-left: 20px;
        margin-top: 20px;
    }
</style>
