export default {
    namespaced: true,
    state: {
        openingData: {
            item_header: 'Opening Paragraph',
            item_id:'',
            html:{},
            opening_body: '',
            id: '',
        },
    },
    actions: {
        updateOpeningData({commit}, data) {
            commit('UPDATE_OPENING_DATA', data);
        },
        editOpening({commit}, data) {
            commit('EDIT_OPENING', data);
        },
        updateOpeningHtml({commit}, data) {
            commit('UPDATE_OPENING_HTML', data);
        },
        updateOpeningTitle({commit}, data) {
            commit('UPDATE_OPENING_TITLE', data);
        },
        updateOpeningId({commit}, data) {
            commit('UPDATE_OPENING_ID', data)
        }
    },
    mutations: {
        UPDATE_OPENING_DATA(state, payload) {
            state.openingData.opening_body = payload.opening_body;
            state.openingData.id = payload.id;
        },
        EDIT_OPENING(state,payload){
            Object.assign(state.openingData,payload.data);
        },
        UPDATE_OPENING_HTML(state, payload) {
            state.openingData.html = payload;
        },
        UPDATE_OPENING_TITLE(state, payload) {
            state.openingData.item_header = payload;
        },
        UPDATE_OPENING_ID(state, payload) {
            state.openingData.item_id = payload;
        }
    }
}
