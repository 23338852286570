<template>
    <section>
        <div class="item-experience cage">
            <div v-if="showAwardsList">
                <div class="item-input-sub-cage">
                    <div class="item-sub-list-cage" v-for="(award,i) in awardsData.myAwards" :key="i">
                        <div>
                            <p class="item-sub-list-text">{{award.name}}</p>
                            <p class="item-sub-list-text-secondary">{{award.issue_month + '/' +
                                award.issue_year}}</p>
                        </div>
                        <div class="d-flex">
                            <span class="material-icons item-sub-list-create-icon"
                                  @click="editAwards(award)">create</span>
                            <span class="material-icons item-sub-list-delete-icon"
                                  @click="deleteAward(award)">delete</span>
                        </div>
                    </div>
                </div>
                <div class="item-footer-button-cage">
                    <v-btn class="yellow-button w-100" @click="addAwards">Add New Award</v-btn>
                </div>
            </div>
            <div v-if="!showAwardsList">
                <div class="item-input-sub-cage">
                    <div class="item-input-cage">
                        <p class="item-input-header">Awards Name</p>
                        <input class="primary-input w-100" v-model="awardsData.name" @input="changeData"
                               @focusout="isEditing? updateAward(false) : ''">
                    </div>
                    <div class="item-input-cage">
                        <p class="item-input-header">Degree</p>
                        <input class="primary-input w-100" v-model="awardsData.degree" @input="changeData"
                               @focusout="isEditing? updateAward(false) : ''">
                    </div>
                    <div class="d-flex flex-row">
                        <div class="item-input-cage mr-2">
                            <p class="item-input-header">Issue Date</p>
                            <div class="d-flex flex-row w-50">
                                <input class="primary-input w-100 mr-1" v-model="awardsData.issue_month" @input="changeData"
                                       placeholder="Month" @focusout="isEditing? updateAward(false) : ''">
                                <input class="primary-input w-100" v-model="awardsData.issue_year" @input="changeData"
                                       placeholder="Year" @focusout="isEditing? updateAward(false) : ''">
                            </div>
                        </div>
                    </div>
                    <div class="item-input-cage">
                        <p class="item-input-header">Description</p>
                        <textarea class="primary-text-area w-100" rows="3" v-model="awardsData.description" @input="changeData"
                                  @focusout="isEditing? updateAward(false) : ''"></textarea>
                    </div>
                </div>
                <div class="item-footer-button-cage">
                    <v-btn class="yellow-button w-100" @click="isEditing ? updateAward(true) : saveNewAwards()">
                        Save
                    </v-btn>
                </div>
            </div>
        </div>
        <v-snackbar v-model="showAddAwardsNotification" :timeout="2000" :color="'success'" top>
            Award has been added.
        </v-snackbar>
        <v-snackbar v-model="showUpdateAwardsNotification" :timeout="2000" :color="'success'" top>
            Award has been updated.
        </v-snackbar>
        <v-snackbar v-model="showDeleteAwardsNotification" :timeout="2000" :color="'success'" top>
            Award has been deleted.
        </v-snackbar>
    </section>
</template>
<script>
    import {mapActions, mapState} from "vuex";
    import itemService from '../../../services/item-data'

    export default {
        name: 'itemAwards',
        data() {
            return {
                showAwardsList: true,
                showAddAwardsNotification: false,
                showDeleteAwardsNotification: false,
                showUpdateAwardsNotification: false,
                isEditing: false
            }
        },
        created() {
            this.showAwardsList = this.awardsData.myAwards.length > 0;
        },
        computed: {
            ...mapState({
                awardsData: (store) => store.awards.awardsData
            })
        },
        methods: {
            ...mapActions({
                updateAwardsData: 'awards/updateAwardsData',
                addNewAwards: 'awards/addNewAwards',
                deleteAwards: 'awards/deleteAwards',
                editAward: 'awards/editAward'
            }),
            changeData() {
                if (this.isEditing) {
                    const editedObject = {
                        data: this.setEditedObject()
                    };
                    this.editAward(editedObject);
                }
            },
            editAwards(award) {
                this.isEditing = true;
                this.awardsData.name = award.name;
                this.awardsData.degree = award.degree;
                this.awardsData.issue_year = award.issue_year;
                this.awardsData.issue_month = award.issue_month;
                this.awardsData.description = award.description;
                this.awardsData.id = award.id;
                this.showAwardsList = false;
            },
            deleteAward(award) {
                itemService.deleteAward(award.id).then((deleteResponse) => {
                    console.log('delete resp', deleteResponse);
                    const awardIndex = this.awardsData.myAwards.indexOf(award);
                    this.deleteAwards(awardIndex);
                    this.showDeleteAwardsNotification = true;
                    this.showAwardsList = this.awardsData.myAwards.length > 0;
                })
            },
            addAwards() {
                this.isEditing = false;
                this.awardsData.name = '';
                this.awardsData.degree = '';
                this.awardsData.issue_year = '';
                this.awardsData.issue_month = '';
                this.awardsData.description = '';
                this.showAwardsList = false;
            },
            setEditedObject(){
                const editedObject = {
                    name: this.awardsData.name,
                    degree: this.awardsData.degree,
                    issue_year: this.awardsData.issue_year,
                    issue_month: this.awardsData.issue_month,
                    description: this.awardsData.description,
                    id: this.awardsData.id,
                };
                return editedObject;
            },
            updateAward(goBack) {
                const editedAward = {
                    name: this.awardsData.name,
                    degree: this.awardsData.degree,
                    issue_year: this.awardsData.issue_year,
                    issue_month: this.awardsData.issue_month,
                    description: this.awardsData.description,
                    id: this.awardsData.id,
                };
                itemService.updateAwardData(editedAward.id, editedAward).then((updateResponse) => {
                    this.editAward(updateResponse);
                    this.showUpdateAwardsNotification = true;
                    this.showAwardsList = goBack;
                });
            },
            saveNewAwards() {
                const newAward = {
                    name: this.awardsData.name,
                    degree: this.awardsData.degree,
                    issue_year: this.awardsData.issue_year,
                    issue_month: this.awardsData.issue_month,
                    description: this.awardsData.description,
                };
                itemService.createAward(newAward).then((createResponse) => {
                    this.addNewAwards(createResponse.data);
                    this.showAddAwardsNotification = true;
                    this.showAwardsList = true;
                });
            }
        }
    }
</script>
<style></style>
