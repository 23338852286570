<template>
    <section>
        <div class="item-summary cage">
            <div class="item-input-sub-cage">
                <div class="item-input-cage">
                    <p class="item-input-header">Photo</p>
                    <div class="d-flex">
                        <v-btn class="grey-button w-100" @click="showPhotoUploader = true">Upload</v-btn>
                    </div>
                </div>
                <div class="item-input-cage">
                    <p class="item-input-header">Full Name</p>
                    <input class="primary-input w-100" v-model="summaryData.full_name" @focusout="updateSummary">
                </div>
                <div class="item-input-cage">
                    <p class="item-input-header">Phone</p>
                    <input class="primary-input w-100" v-model="summaryData.phone" @focusout="updateSummary">
                </div>
                <div class="item-input-cage">
                    <p class="item-input-header">Display Email</p>
                    <input class="primary-input w-100" v-model="summaryData.email" @focusout="updateSummary">
                </div>
                <div class="item-input-cage">
                    <p class="item-input-header">Address</p>
                    <input class="primary-input w-100" v-model="summaryData.address" @focusout="updateSummary">
                    <input class="primary-input w-100 item-input-second" v-model="summaryData.addressSecondary"
                           @focusout="updateSummary">
                </div>
                <div class="item-input-cage">
                    <p class="item-input-header">Professional Summary</p>
                    <textarea class="primary-text-area w-100" rows="5"
                              v-model="summaryData.summary" @focusout="updateSummary"></textarea>
                </div>
            </div>
            <div class="item-footer-button-cage">
                <v-btn class="yellow-button w-100" @click="updateSummary()">Save</v-btn>
            </div>
            <photo-uploader v-if="showPhotoUploader" @showNotification="showPhotoNotification= $event"
                            @showUploader="showPhotoUploader = $event"
                            :show-photo-uploader="showPhotoUploader"></photo-uploader>
            <v-snackbar v-model="showPhotoNotification" :timeout="2000" :color="'success'" top>
                Photo Updated
            </v-snackbar>
        </div>
        <v-snackbar v-model="showUpdateSummaryNotification" :timeout="2000" :color="'success'" top>
            Summary has been updated.
        </v-snackbar>
    </section>
</template>
<script>
    import {mapActions, mapState} from "vuex";
    import photoUploader from '../../atomic/photo-uploader.vue'
    import itemService from '../../../services/item-data';

    export default {
        name: 'itemSummary',
        components: {photoUploader},
        data() {
            return {
                showPhotoUploader: false,
                showUpdateSummaryNotification: false,
                showPhotoNotification: false
            }
        },
        computed: {
            ...mapState({
                summaryData: (store) => store.summary.summaryData
            })
        },
        methods: {
            ...mapActions({
                updateSummaryData: 'summary/updateSummaryData'
            }),
            updateSummary() {
                itemService.updateSummaryData(this.summaryData.id, this.summaryData).then((updateSummaryResponse) => {
                    this.updateSummaryData(updateSummaryResponse.data);
                    this.showUpdateSummaryNotification = true;
                });
            }
        }
    }
</script>
<style></style>
