export default {
    namespaced: true,
    state: {
        languageSkillsData: {
            item_header: 'Languages',
            item_id:'',
            html:{},
            name: '',
            information: '',
            success_point:null,
            rate: 1,
            id:'',
            myLanguages: []
        },
    },
    actions: {
        updateLanguageSkillsData({commit}, data) {
            commit('UPDATE_LANGUAGE_SKILLS_DATA', data);
        },
        addNewLanguage({commit}, data) {
            commit('ADD_NEW_LANGUAGE', data);
        },
        deleteLanguage({commit}, data) {
            commit('DELETE_LANGUAGE', data);
        },
        editLanguage({commit}, data){
            commit('EDIT_LANGUAGE', data);
        },
        updateLanguageHtml({commit}, data) {
            commit('UPDATE_LANGUAGE_HTML', data);
        },
        updateLanguageTitle({commit}, data) {
            commit('UPDATE_LANGUAGE_TITLE', data);
        },
        updateLanguageId({commit}, data) {
            commit('UPDATE_LANGUAGE_ID', data)
        }
    },
    mutations: {
        UPDATE_LANGUAGE_SKILLS_DATA(state, payload) {
            state.languageSkillsData.myLanguages = payload;
        },
        ADD_NEW_LANGUAGE(state, payload) {
            state.languageSkillsData.myLanguages.push(payload);
        },
        DELETE_LANGUAGE(state, payload) {
            state.languageSkillsData.myLanguages.splice(payload, 1)
        },
        EDIT_LANGUAGE(state, payload){
            const updatedLanguage  =  state.languageSkillsData.myLanguages.find(x => x.id === payload.data.id)
            Object.assign(updatedLanguage,payload.data);
        },
        UPDATE_LANGUAGE_HTML(state, payload) {
            state.languageSkillsData.html = payload;
        },
        UPDATE_LANGUAGE_TITLE(state, payload) {
            state.languageSkillsData.item_header = payload;
        },
        UPDATE_LANGUAGE_ID(state, payload) {
            state.languageSkillsData.item_id = payload;
        }
    }
}
