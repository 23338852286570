<template>
    <section>
        <div class="login-form social-login-cage">
            <!-- <a class="login-form social-button linkedin-sign">
                <img class="login-form social-icon" src="../../assets/custom-icons/linkedin-icon.svg">
                Sign in with Linkedin</a> -->
            <a class="login-form social-button facebook-sign" @click="signInExternal('facebook')">
                <img class="login-form social-icon" src="../../assets/custom-icons/facebook-login-icon.svg">
                Sign in with Facebook</a>
            <a class="login-form social-button google-sign" @click="signInExternal('google')">
                <img class="login-form  social-icon" src="../../assets/custom-icons/google-id-icon.svg">
                Sign in with Google</a>
        </div>
        <div class="d-flex flex-row mt-4 align-center">
            <p class="login-form separator-line"></p>
            <p class="login-form separator-text">OR</p>
            <p class="login-form separator-line"></p>
        </div>
        <div class="login-form form-cage">
            <div class="login-form email-cage">
                <p class="login-form input-text">Email Address</p>
                <input class="primary-input" v-model.trim="$v.emailAddress.$model" v-on:keyup.enter="sendLogIn">
                <div class="error-text" v-if="!$v.emailAddress.required && $v.emailAddress.$dirty">Field is required
                </div>
                <div class="error-text" v-if="!$v.emailAddress.email && $v.emailAddress.$dirty">Not valid email</div>
            </div>
            <div class="login-form password-cage">
                <p class="login-form input-text">Password</p>
                <input type="password" class="primary-input" v-model.trim="$v.password.$model"
                       v-on:keyup.enter="sendLogIn">
                <div class="error-text" v-if="!$v.password.required && $v.password.$dirty">Field is required</div>
                <a class="login-form forgot-password">Forgot password?</a>
            </div>
        </div>
        <v-btn class="yellow-button w-100" @click="sendLogIn">Login</v-btn>
        <v-snackbar v-model="showErrorMessage" :timeout="2000" :color="'error'" top>
            {{errorMessage}}
        </v-snackbar>
    </section>
</template>
<script>
    import {required, email} from "vuelidate/lib/validators";
    import authService from "@/services/auth";
    import {mapActions} from "vuex";
    import firebase from 'firebase/app'
    import 'firebase/auth';

    export default {
        name: 'userLoginForm',
        data() {
            return {
                showErrorMessage: false,
                errorMessage: '',
                emailAddress: '',
                password: '',
            }
        },
        validations: {
            emailAddress: {
                required,
                email
            },
            password: {
                required
            }
        },
        methods: {
            ...mapActions({
                updateLoginModalStatus: 'general/updateLoginModalStatus',
                updateUserInfo: 'authorization/updateUserInfo',
                updateCredentialInfo: 'authorization/updateCredentialInfo',
                updateDummyUserStatus: 'authorization/updateDummyUserStatus',
            }),
            sendLogIn() {
                const loginObject = {
                    email: this.emailAddress,
                    password: this.password
                };
                authService.logIn(loginObject).then((loginResponse) => {
                    this.updateDummyUserStatus(false);
                    const credentialObject = {
                        access_token: loginResponse.data.auth['access-token'],
                        client: loginResponse.data.auth['client'],
                        uid: loginResponse.data.auth['uid'],
                        isDummy: loginResponse.data.user.dummy
                    };
                    this.updateCredentialInfo(credentialObject);
                    this.updateUserInfo(loginResponse.data.user);
                    this.updateLoginModalStatus(false);
                }).then(() => {
                    window.location.href = '/website/resume';
                }).catch(err => {
                    if (err.response) {
                        this.errorMessage = err.response.data.errors[0];
                        this.showErrorMessage = true;
                    }
                })
            },
            setLoginCredentials(loginResponse) {
                this.updateDummyUserStatus(false);
                const credentialObject = {
                    access_token: loginResponse.data.auth['access-token'],
                    client: loginResponse.data.auth['client'],
                    uid: loginResponse.data.auth['uid'],
                    isDummy: loginResponse.data.user.dummy
                };
                this.updateCredentialInfo(credentialObject);
                this.updateUserInfo(loginResponse.data.user);
                this.updateLoginModalStatus(false);
            },
            getUserCredentials(idToken){
                const authObject = {
                    access_token: idToken
                };
                authService.getOauth(authObject).then((authResponse) => {
                    this.setLoginCredentials(authResponse);
                }).then(() => {
                    window.location.href = '/website/resume';
                })
            },
            signInExternal(source) {
                if (source === 'google') {
                    const provider = new firebase.auth.GoogleAuthProvider();
                    firebase.auth().signInWithPopup(provider).then(() => {
                        firebase.auth().currentUser.getIdToken().then((idToken) => {
                            this.getUserCredentials(idToken);
                        });
                    })
                }
                if (source === 'facebook') {
                    const provider = new firebase.auth.FacebookAuthProvider();
                    firebase.auth().signInWithPopup(provider).then(() => {
                        firebase.auth().currentUser.getIdToken().then((idToken) => {
                           this.getUserCredentials(idToken);
                        });
                    })
                }
            }
        },
    }
</script>
<style lang="scss" scoped>
    .login-form {
        &.social-login-cage {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        &.social-button {
            color: #ffffff;
            text-decoration: none;
            font-size: 14px;
            font-weight: 500;
            padding: 8px 12px;
            background-color: #0077B5;
            width: 100%;
            border-radius: 5px;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: center;

            &.facebook-sign {
                background-color: #3B5998;
            }

            &.linkedin-sign {
                background-color: #0077B5;
            }

            &.google-sign {
                background-color: #5788EA;
            }

            &:last-child {
                margin-bottom: 0px !important;
            }
        }

        &.social-icon {
            margin-right: 13px;
        }

        &.form-cage {
            margin-top: 20px;
        }

        &.input-text {
            font-family: "Segoe UI", sans-serif;
            text-align: start;
            color: #000000;
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 8px !important;

            &:last-child {
                margin-top: 25px;
            }
        }

        &.password-cage {
            margin-top: 25px;
            display: flex;
            flex-direction: column;
        }

        &.forgot-password {
            font-size: 12px;
            font-weight: 500;
            color: #742774;
            margin-top: 13px;
            text-align: start;
        }

        &.separator-line {
            width: 100%;
            height: 1px;
            background: rgba(196, 196, 196, 0.25);
        }

        &.separator-text {
            font-size: 9px;
            color: #ADADAD;
        }
    }

    .primary-input {
        width: 100% !important;
    }

    .yellow-button {
        margin-top: 23px;
    }
</style>
