<template>
    <section>
        <div class="item-my-time cage">
            <div v-if="showMyTimeList">
                <div class="item-input-sub-cage">
                    <div class="item-sub-list-cage" v-for="(activity,i) in myTimeData.myTime" :key="i">
                        <div>
                            <p class="item-sub-list-text">{{activity.name}}</p>
                            <p class="item-sub-list-text-secondary">Rate: {{activity.value}}</p>
                        </div>
                        <div class="d-flex">
                            <span class="material-icons item-sub-list-create-icon"
                                  @click="editActivity(activity)">create</span>
                            <span class="material-icons item-sub-list-delete-icon"
                                  @click="deleteActivity(activity)">delete</span>
                        </div>
                    </div>
                </div>
                <div class="item-footer-button-cage">
                    <v-btn class="yellow-button w-100" @click="addActivity" :disabled="myTimeData.myTime.length >= 5">Add New Activity</v-btn>
                </div>
            </div>
            <div v-if="!showMyTimeList">
                <div class="item-input-sub-cage">
                    <div class="item-input-cage">
                        <p class="item-input-header">Activity Name</p>
                        <input class="primary-input w-100" v-model="myTimeData.name" @input="changeData"
                               @focusout="isEditing? updateActivity(false) : ''">
                    </div>
                    <div class="item-input-cage">
                        <p class="item-input-header">Activity Rate</p>
                        <v-slider
                                class="p-2"
                                v-model="myTimeData.value"
                                @change="isEditing? updateActivity(false) : ''"
                                max="5"
                                min="1"
                                thumb-label
                                :tick-labels="ticksLabels"
                                :color="'#742774'"
                                :track-color="'#c4c4c4'"
                        ></v-slider>
                    </div>
                </div>
                <div class="item-footer-button-cage">
                    <v-btn class="yellow-button w-100" @click="isEditing ? updateActivity(true) : saveNewActivity()">
                        Save
                    </v-btn>
                </div>
            </div>
        </div>
        <v-snackbar v-model="showAddActivityNotification" :timeout="2000" :color="'success'" top>
            Activity has been added.
        </v-snackbar>
        <v-snackbar v-model="showUpdateActivityNotification" :timeout="2000" :color="'success'" top>
            Activity has been updated.
        </v-snackbar>
        <v-snackbar v-model="showDeleteActivityNotification" :timeout="2000" :color="'success'" top>
            Activity has been deleted.
        </v-snackbar>
    </section>
</template>
<script>
    import {mapActions, mapState} from "vuex";
    import itemService from '../../../services/item-data'

    export default {
        name: 'itemMyTime',
        data() {
            return {
                showMyTimeList: true,
                showAddActivityNotification: false,
                showUpdateActivityNotification: false,
                showDeleteActivityNotification: false,
                isEditing: false,
                ticksLabels: ['1', '2', '3','4','5']
            }
        },
        created(){
            this.showMyTimeList = this.myTimeData.myTime.length > 0;
        },
        computed: {
            ...mapState({
                myTimeData: (store) => store.myTime.myTimeData
            })
        },
        methods: {
            ...mapActions({
                updateMyTimeData: 'myTime/updateMyTimeData',
                addNewMyTime: 'myTime/addNewMyTime',
                deleteMyTime: 'myTime/deleteMyTime',
                editMyTime: 'myTime/editMyTime',
            }),
            changeData() {
                if (this.isEditing) {
                    const editedObject = {
                        data: this.setEditedObject()
                    };
                    this.editMyTime(editedObject);
                }
            },
            editActivity(activity) {
                this.isEditing = true;
                this.myTimeData.name = activity.name;
                this.myTimeData.value = activity.value;
                this.myTimeData.color = activity.color;
                this.myTimeData.id = activity.id;
                this.showMyTimeList = false;
            },
            deleteActivity(activity) {
                itemService.deleteMyTime(activity.id).then(() => {
                    const activityIndex = this.myTimeData.myTime.indexOf(activity);
                    this.deleteMyTime(activityIndex);
                    this.showDeleteActivityNotification = true;
                    this.showMyTimeList = this.myTimeData.myTime.length > 0;
                });
            },
            addActivity() {
                this.isEditing = false;
                this.myTimeData.name = '';
                this.myTimeData.value = '';
                this.myTimeData.color = '';
                this.showMyTimeList = false;
            },
            setEditedObject(){
                const editedObject = {
                    name: this.myTimeData.name,
                    value: this.myTimeData.value,
                    color: this.myTimeData.color,
                    id: this.myTimeData.id
                };
                return editedObject;
            },
            updateActivity(goBack) {
                const editedActivity = {
                    name: this.myTimeData.name,
                    value: this.myTimeData.value,
                    color: this.myTimeData.color,
                    id: this.myTimeData.id
                };
                itemService.updateMyTime(editedActivity.id, editedActivity).then((updateResponse) => {
                    this.editMyTime(updateResponse);
                    this.showUpdateActivityNotification = true;
                    this.showMyTimeList = goBack;
                })
            },
            saveNewActivity() {
                const newActivity = {
                    name: this.myTimeData.name,
                    value: this.myTimeData.value,
                    color: this.myTimeData.color,
                };
                itemService.createMyTime(newActivity).then((createResponse) => {
                    this.addNewMyTime(createResponse.data);
                    this.showAddActivityNotification = true;
                    this.showMyTimeList = true;
                })
            }
        }
    }
</script>
<style></style>
