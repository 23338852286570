export default {
    namespaced: true,
    state: {
        referencesData: {
            item_header: 'References',
            item_id:'',
            html:{},
            id: '',
            name: '',
            email: '',
            company: '',
            phone: '',
            position: 0,
            title: '',
            myReferences: []
        },
    },
    actions: {
        updateReferencesData({commit}, data) {
            commit('UPDATE_REFERENCES_DATA', data);
        },
        addNewReference({commit}, data) {
            commit('ADD_NEW_REFERENCES', data);
        },
        deleteReference({commit}, data) {
            commit('DELETE_REFERENCE', data);
        },
        editReference({commit}, data) {
            commit('EDIT_REFERENCE', data);
        },
        updateReferenceHtml({commit}, data) {
            commit('UPDATE_REFERENCE_HTML', data);
        },
        updateReferenceTitle({commit}, data) {
            commit('UPDATE_REFERENCE_TITLE', data);
        },
        updateReferenceId({commit}, data) {
            commit('UPDATE_REFERENCE_ID', data)
        }
    },
    mutations: {
        UPDATE_REFERENCES_DATA(state, payload) {
            state.referencesData.myReferences = payload;
        },
        ADD_NEW_REFERENCES(state, payload) {
            state.referencesData.myReferences.push(payload);
        },
        DELETE_REFERENCE(state, payload) {
            state.referencesData.myReferences.splice(payload, 1);
        },
        EDIT_REFERENCE(state, payload) {
            const updatedReference = state.referencesData.myReferences.find(x => x.id === payload.data.id);
            Object.assign(updatedReference,payload.data);
        },
        UPDATE_REFERENCE_HTML(state, payload) {
            state.referencesData.html = payload;
        },
        UPDATE_REFERENCE_TITLE(state, payload) {
            state.referencesData.item_header = payload;
        },
        UPDATE_REFERENCE_ID(state, payload) {
            state.referencesData.item_id = payload;
        }

    }
}
