import {mapActions} from 'vuex';
import resumeService from "@/services/resume";
import themeUpdater from "@/mixins/theme-update-helper";

export default {
    data() {
        return {
            requestDone: false,
            videoCvDetail: '',
        };
    },
    mixins: [themeUpdater],
    methods: {
        ...mapActions({
            updateSummaryData: 'summary/updateSummaryData',
            updateAwardsData: 'awards/updateAwardsData',
            updateExperienceData: 'experience/updateExperienceData',
            updateEducationData: 'education/updateEducationData',
            updateTechnicalSkillsData: 'technicalSkills/updateTechnicalSkillsData',
            updateLanguageSkillsData: 'languageSkills/updateLanguageSkillsData',
            updateCertificateData: 'certificate/updateCertificateData',
            updateProjectsData: 'projects/updateProjectsData',
            updatePublicationData: 'publications/updatePublicationData',
            updateMyTimeData: 'myTime/updateMyTimeData',
            updateHobbiesData: 'hobbies/updateHobbiesData',
            updateMottoData: 'philosophy/updateMottoData',
            updateStrengthsData: 'strengths/updateStrengthsData',
            updateVolunteeringData: 'volunteering/updateVolunteeringData',
            updateConferenceData: 'conferences/updateConferenceData',
            updateReferencesData: 'references/updateReferencesData',
            updateSocialsData: 'social/updateSocialData',
            updateGreetingData: 'greeting/updateGreetingData',
            updateOpeningData: 'opening/updateOpeningData',
            updateBodyData: 'body/updateBodyData',
            updateClosingData: 'closing/updateClosingData',
            updateBackgroundDecoration: 'resume/updateBackgroundDecoration',
            updateResumeType: 'resume/updateResumeType',
            updateBackground: 'resume/updateBackground',
            updateFontColor:'resume/updateFontColor',
            updateBackgroundDecorationList: 'resume/updateBackgroundDecorationList',
            setUpdateOrderList: 'resume/setUpdateOrderList',
            setOrderListEmpty: 'resume/setOrderListEmpty',
            updateCurrentUserTemplate: 'resume/updateCurrentUserTemplate',
            updateSelectedTheme: 'resume/updateSelectedTheme',
            updateFontId: 'resume/updateFontId',
        }),
        getResumeDetail() {
            
            resumeService.getResumeDetail(this.$router.currentRoute.params.id).then((resumeDetailResponse) => {
                resumeDetailResponse.data.forEach(itemData => {
                    const itemKey = Object.keys(itemData)[0];
                    if (itemKey === 'current_user_template') {
                        resumeService.getBackgroundImages().then((backgroundData) => {
                            this.updateBackgroundDecorationList(backgroundData.data.data[0]);
                            this.updateSelectedTheme(itemData.current_user_template.theme_id);
                            this.updateBackgroundDecoration(itemData.current_user_template.background_id);
                            this.updateBackground(itemData.current_user_template.color_id);
                            this.updateFontColor(itemData.current_user_template.color_code);
                            this.updateResumeType(itemData.current_user_template.template_type);
                            this.updateCurrentUserTemplate(itemData.current_user_template);
                            //
                            this.updateFontId(itemData.current_user_template.font_id)
                        });
                    }
                    if (itemKey === 'profiles') {
                        this.updateSummaryData(itemData.profiles.data[0])
                    }
                    if (itemKey === 'experiences') {
                        this.updateExperienceData(itemData.experiences.data)
                    }
                    if (itemKey === 'educations') {
                        this.updateEducationData(itemData.educations.data);
                    }
                    if (itemKey === 'skills') {
                        this.updateTechnicalSkillsData(itemData.skills.data);
                    }
                    if (itemKey === 'languages') {
                        this.updateLanguageSkillsData(itemData.languages.data);
                    }
                    if (itemKey === 'certificates') {
                        this.updateCertificateData(itemData.certificates.data);
                    }
                    if (itemKey === 'awards') {
                        this.updateAwardsData(itemData.awards.data);
                    }
                    if (itemKey === 'projects') {
                        this.updateProjectsData(itemData.projects.data);
                    }
                    if (itemKey === 'publications') {
                        this.updatePublicationData(itemData.publications.data);
                    }
                    if (itemKey === 'my_times') {
                        this.updateMyTimeData(itemData.my_times.data);
                    }
                    if (itemKey === 'hobbies') {
                        this.updateHobbiesData(itemData.hobbies.data);
                    }
                    if (itemKey === 'mottos') {
                        this.updateMottoData(itemData.mottos.data);
                    }
                    if (itemKey === 'strengths') {
                        this.updateStrengthsData(itemData.strengths.data);
                    }
                    if (itemKey === 'volunteers') {
                        this.updateVolunteeringData(itemData.volunteers.data);
                    }
                    if (itemKey === 'conferences') {
                        this.updateConferenceData(itemData.conferences.data);
                    }
                    if (itemKey === 'references') {
                        this.updateReferencesData(itemData.references.data);
                    }
                    if (itemKey === 'socials') {
                        this.updateSocialsData(itemData.socials.data);
                    }
                    if (itemKey === 'letter_salutations') {
                        this.updateGreetingData(itemData.letter_salutations.data[0])
                    }
                    if (itemKey === 'letter_openings') {
                        this.updateOpeningData(itemData.letter_openings.data[0])
                    }
                    if (itemKey === 'letter_bodies') {
                        this.updateBodyData(itemData.letter_bodies.data[0])
                    }
                    if (itemKey === 'letter_closings') {
                        this.updateClosingData(itemData.letter_closings.data[0])
                    }
                    this.updatePageHeight();
                });
            }).then(() => {
                this.requestDone = true
            })
        },
        getResumeDetaildownload(createObject) {
            
            resumeService.getResumeDetaildownload(createObject).then((resumeDetailResponse) => {
                alert('download')
               console.log(resumeDetailResponse)
            }).then(() => {
                this.requestDone = true
            })
        },
        getResumeDetailFromCode(code, currentTab) {
            this.setOrderListEmpty([]);
            this.updateResumeType(currentTab);

            resumeService.getResumeDetailFromCode(code).then((resumeDetailResponse) => {
                if (currentTab === 'resume') {
                    this.setUpdateOrderList(resumeDetailResponse.data.resume_headers);
                }
                if (currentTab === 'cover_letter') {
                    this.setUpdateOrderList(resumeDetailResponse.data.cover_letter_headers);
                }
                resumeDetailResponse.data.resume.forEach(itemData => {
                    const itemKey = Object.keys(itemData)[0];
                    if (itemKey === 'current_user_template') {
                        resumeService.getBackgroundImages().then((backgroundData) => {
                            this.updateBackgroundDecorationList(backgroundData.data.data[0]);
                            this.updateBackgroundDecoration(itemData.current_user_template.background_id);
                            this.updateSelectedTheme(itemData.current_user_template.theme_id);
                            this.updateBackground(itemData.current_user_template.color_id);
                            this.updateFontColor(itemData.current_user_template.color_code);
                            this.updateCurrentUserTemplate(itemData.current_user_template);
                        });
                    }
                    if (itemKey === 'profiles') {
                        this.updateSummaryData(itemData.profiles.data[0])
                    }
                    if (itemKey === 'experiences') {
                        this.updateExperienceData(itemData.experiences.data)
                    }
                    if (itemKey === 'educations') {
                        this.updateEducationData(itemData.educations.data);
                    }
                    if (itemKey === 'skills') {
                        this.updateTechnicalSkillsData(itemData.skills.data);
                    }
                    if (itemKey === 'languages') {
                        this.updateLanguageSkillsData(itemData.languages.data);
                    }
                    if (itemKey === 'certificates') {
                        this.updateCertificateData(itemData.certificates.data);
                    }
                    if (itemKey === 'awards') {
                        this.updateAwardsData(itemData.awards.data);
                    }
                    if (itemKey === 'projects') {
                        this.updateProjectsData(itemData.projects.data);
                    }
                    if (itemKey === 'publications') {
                        this.updatePublicationData(itemData.publications.data);
                    }
                    if (itemKey === 'my_times') {
                        this.updateMyTimeData(itemData.my_times.data);
                    }
                    if (itemKey === 'hobbies') {
                        this.updateHobbiesData(itemData.hobbies.data);
                    }
                    if (itemKey === 'mottos') {
                        this.updateMottoData(itemData.mottos.data);
                    }
                    if (itemKey === 'strengths') {
                        this.updateStrengthsData(itemData.strengths.data);
                    }
                    if (itemKey === 'volunteers') {
                        this.updateVolunteeringData(itemData.volunteers.data);
                    }
                    if (itemKey === 'conferences') {
                        this.updateConferenceData(itemData.conferences.data);
                    }
                    if (itemKey === 'references') {
                        this.updateReferencesData(itemData.references.data);
                    }
                    if (itemKey === 'socials') {
                        this.updateSocialsData(itemData.socials.data);
                    }
                    this.updatePageHeight();
                });
                if (resumeDetailResponse.data.cover_letter) {
                    resumeDetailResponse.data.cover_letter.forEach(itemData => {
                        const itemKey = Object.keys(itemData)[0];
                        if (itemKey === 'letter_salutations') {
                            this.updateGreetingData(itemData.letter_salutations.data[0])
                        }
                        if (itemKey === 'letter_openings') {
                            this.updateOpeningData(itemData.letter_openings.data[0])
                        }
                        if (itemKey === 'letter_bodies') {
                            this.updateBodyData(itemData.letter_bodies.data[0])
                        }
                        if (itemKey === 'letter_closings') {
                            this.updateClosingData(itemData.letter_closings.data[0])
                        }
                        this.updatePageHeight();
                    })
                }
                if (resumeDetailResponse.data.video_cv) {
                    this.videoCvDetail = resumeDetailResponse.data.video_cv;
                }
            })
        },
    },

};
