<template>
    <section>
        <div class="color-palette cage" :class="{'more-color-active':showMoreColors}">
            <div class="color-palette picker" v-for="decoration in decorationList" :key="decoration.id"
                 :class="{'active-decoration': resumeOptions.backgroundDecoration.id === decoration.id}"
                 :style="{backgroundImage:'url('+decoration.url+ ')'}" @click="selectedUrl=decoration">
            </div>
            <div class="d-flex align-items-center c-pointer" @click="showMoreColors = !showMoreColors">
                <img  src="../../../../assets/custom-icons/icon-plus.svg">
                <p class="color-palette add-color-text">Add Colors</p>
            </div>
        </div>
        <div v-if="showMoreColors">
            <p>Colors</p>
            <v-color-picker v-model="selectedColor" :hide-inputs="true" class="ma-2" show-swatches></v-color-picker>
        </div>
    </section>
</template>
<script>
    import resumeService from "@/services/resume";
    import {mapState} from "vuex";

    export default {
        name: 'colorSelector',
        data() {
            return {
                selectedColor: '',
                selectedUrl: '',
                showMoreColors: false,
                colorArray: [{color: '#fff', id: 0},
                    {color: '#F2C811', id: '1'},
                    {color: '#282B34', id: '2'},
                    {color: '#7e8085', id: '3'},
                    {color: '#FC605C', id: '4'},
                    {color: '#4C9F88', id: '5'},
                    {color: '#69F0AE', id: '6'},
                    {color: '#34C749', id: '7'},
                    {color: '#1BADAD', id: '8'},
                    {color: '#571CCA', id: '9'},
                    {color: '#F0D868', id: '10'}
                ],
                showOnlyColor: true
            }
        },
        computed: {
            ...mapState({
                resumeOptions: (store) => store.resume.resumeOptions,
                decorationList: (store) => store.resume.decorationList
            })
        },
        created() {
            this.setSelectedBackground();
        },
        methods: {
            setSelectedBackground() {
                this.selectedUrl = this.resumeOptions.backgroundDecoration;
                this.selectedColor = this.resumeOptions.backgroundColorModel;
            }
        },
        watch: {
            selectedColor: {
                deep: true,
                handler() {
                    this.$emit('colorModelChange', this.selectedColor.hex)
                }
            },
            selectedUrl: {
                deep: true,
                handler() {
                    const backgroundObject = {
                        background_id: this.selectedUrl.id
                    };
                    resumeService.updateHeader(backgroundObject).then(() => {
                        this.$emit('decorationModelChange', this.selectedUrl.id)
                    });
                }
            },
        }
    }
</script>
<style lang="scss" scoped>
    .v-color-picker {
        display: flex !important;
        flex-direction: column-reverse !important;
    }

    .color-palette {
        &.cage {
            display: flex;
            min-width: 217px;
            width: 100%;
            max-width: 217px;
            flex-direction: row;
            flex-wrap: wrap;
            padding: 20px 10px 20px 15px;

            &.more-color-active {
                max-width: 316px !important;
            }
        }

        &.picker {
            height: 38px;
            width: 38px;
            border-radius: 50%;
            margin-right: 10px;
            margin-bottom: 10px;
            border: 1px solid rgba(176, 180, 195, 0.49);
            border-radius: 50%;
        }
        &.add-color-text{
            margin-left: 5px;
            font-size: 14px;
            font-weight: 500;
            color: #282B34;
        }
    }

    .active-decoration {
        border: 1px solid #742774 !important;
        border-radius: 50%;
    }
</style>
