<template>
    <section>
        <div class="side-menu main-cage" v-if="currentItemTab === 'mainMenu'">
            <div class="side-menu menu-tab">
                <p class="side-menu header">Elements</p>
            </div>
            <div class="side-menu menu-tab">
                <input class="primary-input w-100" placeholder="Search" v-model="searchKey">
            </div>
            <div class="side-menu item-list-cage">
                <draggable v-model="serviceResumeItems" @end="menuItemDrag()" :options="{disabled : isMobile()}">
                    <div class="side-menu menu-tab item-tab" v-for="(item,i) in filteredList" :key="i"
                         @click="openItemDetail(item)" :class="{'locked-item':item.locked}">
                        <div class="side-menu icon-cage">
                            <img class="side-menu hold-icon" src="../assets/custom-icons/item-hold.svg">
                            <img class="side-menu plus-icon" src="../assets/custom-icons/item-plus.svg">
                        </div>
                        <div>
                            <p class="side-menu item-name">{{item.static_title}}</p>
                            <p class="side-menu item-description">{{item.static_subtitle}}</p>
                        </div>
                        <img class="side-menu locked-icon" v-if="item.locked"
                             src="../../src/assets/custom-icons/lock-icon.svg" alt="lockedIcon">
                    </div>
                </draggable>
            </div>
        </div>
        <div v-else>
            <item-detail-navigator :currentItemTab="currentItemTab"
                                   @changeMenuTab="currentItemTab=$event"></item-detail-navigator>
        </div>
        <v-snackbar v-model="showResumeUpdated" :timeout="2000" :color="'success'" top>
            Resume has been updated.
        </v-snackbar>
    </section>
</template>
<script>
    import draggable from 'vuedraggable';
    import itemDetailNavigator from '../components/side-menu/item-detail-navigator.vue';
    import dashboardService from "@/services/dashboard";
    import menuHelper from '@/mixins/side-menu-helper.js';
    import {mapActions} from "vuex";

    export default {
        name: 'mainContent',
        components: {draggable, itemDetailNavigator},
        mixins: [menuHelper],
        data() {
            return {
                showResumeUpdated: false,
                currentItemTab: 'mainMenu',
                searchKey: '',
                serviceResumeItems: [],
            }
        },
        created() {
            this.getSideMenuItems();
        },
        methods: {
            ...mapActions({
                setUpdateOrderList: 'resume/setUpdateOrderList',
                updateCheckoutModalShow: 'general/updateCheckoutModalShow',
            }),
            isMobile() {
    return this.$vuetify.breakpoint.sm||this.$vuetify.breakpoint.xs
 },
            openItemDetail(itemTab) {
                if (!itemTab.locked) {
                    this.currentItemTab = itemTab.static_title;
                } else {
                    this.updateCheckoutModalShow(true);
                }

            },
            getSideMenuItems() {
                dashboardService.getSideMenuList().then((dashboardList) => {
                    this.serviceResumeItems = dashboardList.data.data;
                    this.serviceResumeItems.forEach(item => {
                        this.setMenuItemDetail(item);
                    });
                    this.setUpdateOrderList(this.serviceResumeItems);
                })
            },
            menuItemDrag() {
                const menuItemOrderList = [];
                this.serviceResumeItems.forEach(item => {
                    const itemObject = {
                        id: item.id,
                        position: this.serviceResumeItems.indexOf(item) + 1
                    };
                    menuItemOrderList.push(itemObject);
                });
                const subjects = {
                    subjects: {subjects: menuItemOrderList}
                };
                dashboardService.updateSideMenuList(subjects).then((response) => {
                    this.serviceResumeItems = response.data.data;
                    this.showResumeUpdated = true;
                    this.setUpdateOrderList(response.data.data);
                });
            }
        },
        computed: {
            filteredList() {
                return this.serviceResumeItems.filter(item => {
                    return item.static_title.toLocaleLowerCase().includes(this.searchKey.toLocaleLowerCase())
                })
            }
        }
    }
</script>
<style lang="scss" scoped>
    .side-menu {
        &.main-cage {
            height: 100%;
        }

        &.item-list-cage {
            height: calc(100vh - 211px);
            overflow-y: scroll;
        }

        &.header {
            font-size: 17px;
            color: #353535;
            font-weight: 600;
            text-align: start;
        }

        &.menu-tab {
            border-top: 1px solid #F1F7FF;
            padding: 20px 15px;
        }

        &.item-tab {
            cursor: pointer;
            display: flex;
            justify-content: flex-start;
        }

        &.icon-cage {
            display: flex;
            align-items: flex-start;
        }

        &.hold-icon {
            margin-right: 15px;
            margin-top: 10px;
        }

        &.plus-icon {
            margin-right: 15px;
        }

        &.item-name {
            font-size: 16px;
            font-weight: 500;
            color: #353535;
            text-align: start;
        }

        &.item-description {
            font-size: 14px;
            color: #9EA2B1;
            font-weight: 400;
            text-align: start;
        }

        &.locked-item {
            filter: opacity(40%) !important;
            position: relative;
        }

        &.locked-icon {
            position: absolute;
            right: 5px;
            bottom: 5px;
        }

    }

    hr {
        margin: 0 !important;
    }
</style>
