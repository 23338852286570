export default {
    namespaced: true,
    state: {
        userInfo: {
            allow_password_change: '',
            dummy: '',
            email: '',
            experience: '',
            id: '',
            image: '',
            job_category: '',
            name: '',
            phone: '',
            provier: '',
            subscription_expire_date: '',
            subscription_model: '',
            subscription_type: '',
            surname: '',
            uid: '',
            updated_at: '',
            isUserPro: '',
        },
        credential: {
            uid: '',
            client: '',
            access_token: ''
        },
        userDetailInfo: {},
        feedBackActive: '',
        dummyUser: ''
    },
    actions: {
        updateUserInfo({commit}, data) {
            commit('UPDATE_USER_INFO', data);
        },
        updateUserDetail({commit}, data) {
            commit('UPDATE_USER_DETAIL', data);
        },
        updateFeedBackOption({commit}, data) {
            commit('UPDATE_FEEDBACK_OPTION', data);
        },
        updateCredentialInfo({commit}, data) {
            commit('UPDATE_CREDENTIAL_INFO', data);
        },
        updateDummyUserStatus({commit}, data) {
            commit('UPDATE_DUMMY_USER_STATUS', data);
        }
    },
    mutations: {
        UPDATE_USER_INFO(state, payload) {
            state.userInfo = payload;
            state.userInfo.isUserPro = payload.subscription_type === 'free' ? false : true;
        },
        UPDATE_FEEDBACK_OPTION(state, payload) {
            state.userInfo.feedBackActive = payload;
        },
        UPDATE_CREDENTIAL_INFO(state, payload) {
            state.credential = payload;
            state.userInfo.client = payload.client;
            state.userInfo.uid = payload.uid;
            localStorage.setItem('access-token', payload.access_token);
            localStorage.setItem('uid', payload.uid);
            localStorage.setItem('client', payload.client);
            localStorage.setItem('isDummy', payload.isDummy);
            localStorage.setItem('userName', payload.userName);
        },
        UPDATE_DUMMY_USER_STATUS(state, payload) {
            state.dummyUser = payload;
        },
        UPDATE_USER_DETAIL(state, payload) {
            state.userDetailInfo = payload;
        }
    }
}
