export default {
    namespaced: true,
    state: {
        myTimeData: {
            item_header: 'My Time',
            item_id:'',
            html:{},
            name: '',
            value: '',
            id: '',
            color: '',
            position: '',
            myTime: []
        },
    },
    actions: {
        updateMyTimeData({commit}, data) {
            commit('UPDATE_MY_TIME_DATA', data);
        },
        addNewMyTime({commit}, data) {
            commit('ADD_NEW_MY_TIME', data);
        },
        deleteMyTime({commit}, data) {
            commit('DELETE_MY_TIME', data);
        },
        editMyTime({commit}, data) {
            commit('EDIT_MY_TIME', data)
        },
        updateMyTimeHtml({commit}, data) {
            commit('UPDATE_MY_TIME_HTML', data);
        },
        updateMyTimeTitle({commit}, data) {
            commit('UPDATE_MY_TIME_TITLE', data);
        },
        updateMyTimeId({commit}, data) {
            commit('UPDATE_MY_TIME_ID', data)
        }
    },
    mutations: {
        UPDATE_MY_TIME_DATA(state, payload) {
            state.myTimeData.myTime = payload;
        },
        ADD_NEW_MY_TIME(state, payload) {
            state.myTimeData.myTime.push(payload);
        },
        DELETE_MY_TIME(state, payload) {
            state.myTimeData.myTime.splice(payload, 1);
        },
        EDIT_MY_TIME(state, payload) {
            const updatedMyTime = state.myTimeData.myTime.find(x => x.id === payload.data.id);
            Object.assign(updatedMyTime, payload.data);
        },
        UPDATE_MY_TIME_HTML(state, payload) {
            state.myTimeData.html = payload;
        },
        UPDATE_MY_TIME_TITLE(state, payload) {
            state.myTimeData.item_header = payload;
        },
        UPDATE_MY_TIME_ID(state, payload) {
            state.myTimeData.item_id = payload;
        }
    }
}
