export default {
    namespaced: true,
    state: {
        technicalSkillsData: {
            item_header: 'Skills',
            item_id:'',
            html:{},
            name: '',
            rate: 0,
            position: 1,
            mySkills: []
        },
    },
    actions: {
        updateTechnicalSkillsData({commit}, data) {
            commit('UPDATE_TECHNICAL_SKILLS_DATA', data);
        },
        addNewSkill({commit}, data) {
            commit('ADD_NEW_SKILL', data);
        },
        deleteSkill({commit}, data) {
            commit('DELETE_SKILL', data);
        },
        editSkill({commit}, data) {
            commit('EDIT_SKILL', data);
        },
        updateSkillHtml({commit}, data) {
            commit('UPDATE_SKILL_HTML', data);
        },
        updateSkillTitle({commit}, data) {
            commit('UPDATE_SKILL_TITLE', data);
        },
        updateSkillId({commit}, data) {
            commit('UPDATE_SKILL_ID', data)
        }
    },
    mutations: {
        UPDATE_TECHNICAL_SKILLS_DATA(state, payload) {
            state.technicalSkillsData.mySkills = payload;
            state.technicalSkillsData.mySkills.sort((a, b) => (a.position) - (b.position));

        },
        ADD_NEW_SKILL(state, payload) {
            state.technicalSkillsData.mySkills.push(payload);
            state.technicalSkillsData.mySkills.sort((a, b) => (a.position) - (b.position));
        },
        DELETE_SKILL(state, payload) {
            state.technicalSkillsData.mySkills.splice(payload, 1);
        },
        EDIT_SKILL(state,payload){
            const updatedSkill = state.technicalSkillsData.mySkills.find(x => x.id === payload.data.id);
            Object.assign(updatedSkill,payload.data);
        },
        UPDATE_SKILL_HTML(state, payload) {
            state.technicalSkillsData.html = payload;
        },
        UPDATE_SKILL_TITLE(state, payload) {
            state.technicalSkillsData.item_header = payload;
        },
        UPDATE_SKILL_ID(state, payload) {
            state.technicalSkillsData.item_id = payload;
        }
    }
}
