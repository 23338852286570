<template>
    <section>
        <div class="create-resume cage">
            <div class="create-resume container">
                <resume-start-selector :startType.sync="startType" v-if="currentTab==='startTypeSelector'"
                                       @setStartType="setStartType($event)"
                                       @createBlankTemplate="createBlankResume()"></resume-start-selector>
                <linkedin-import v-if="currentTab === 'linkedinImport'"
                                 @changeLinkedinUrl="linkedinUrl = $event"></linkedin-import>
                <industry-selector v-if="currentTab === 'industrySelector'"
                                   @changeCategory="selectedJob = $event"
                ></industry-selector>
                <theme-selector v-if="currentTab === 'themeSelector'" :selected-theme-data="selectedTheme"
                                @changeTheme="setSelectedTheme($event)"></theme-selector>
                <navigate-controller v-if="currentTab !== 'startTypeSelector'" :current-tab="currentTab"
                                     :selected-job="selectedJob"
                                     :likedinUrl="linkedinUrl"
                                     @changeCurrentTab="changeTab($event)"></navigate-controller>
            </div>
        </div>
        <checkout-modal></checkout-modal>
        <full-loader v-if="showLoader"></full-loader>
    </section>
</template>
<script>
    import resumeStartSelector from '../../components/website/create-resume-page/start-selector.vue'
    import industrySelector from '../../components/website/create-resume-page/industry-selector.vue'
    import themeSelector from '../../components/website/create-resume-page/theme-selector.vue'
    import linkedinImport from '../../components/website/create-resume-page/linkedin-import.vue'
    import navigateController from '../../components/website/create-resume-page/navigate-controller.vue'
    import checkoutModal from '../../components/modal-views/checkout-modal.vue'
    import fullLoader from '../../components/atomic/full-loader.vue'
    import resumeService from '../../services/resume'
    import {mapActions} from "vuex";

    export default {
        name: 'createResumePage',
        components: {
            resumeStartSelector,
            industrySelector,
            themeSelector,
            navigateController,
            checkoutModal,
            linkedinImport,
            fullLoader
        },
        data() {
            return {
                currentTab: 'startTypeSelector',
                startType: '',
                selectedJob: {
                    mainCategory: 1,
                    subCategory: '',
                    experienceId: 1,
                },
                linkedinUrl: '',
                selectedTheme: 1,
                showLoader: false,
            }
        },
        created(){

            
            if(this.$route.query.type=='cl')this.createCoverLetter();
        },
        methods: {
            ...mapActions({
                updateResumeList: 'resume/updateResumeList',
            }),
            createCoverLetter() {
                const createObject = {
                    job_id: 22,
                    theme_id: 23,
                    experience: 0,
                    linkedin_url: '',
                    template_type: 'cover_letter'
                };
                
                resumeService.createResume(createObject).then((createResponse) => {
                    
                    const createdResumeId = createResponse.data[0].current_user_template.id;
                    this.$router.push('/app/dashboard/' + createdResumeId);
                    // this.showLoader = false;
                });
            },
            setStartType(type) {
                this.startType = type;
                if (type === 'industrySelector') {
                    this.currentTab = type;
                } else {
                    this.currentTab = type;
                }
            },
            setSelectedTheme(theme) {
                this.selectedTheme = theme;
            },
            createBlankResume() {
                this.showLoader = true;
                const createObject = {
                    job_id: 1,
                    theme_id: 1,
                    experience: 0,
                    linkedin_url: '',
                    template_type:'resume'
                };
                resumeService.createResume(createObject).then((createResponse) => {
                    const createdResumeId = createResponse.data[0].current_user_template.id;
                    this.$router.push('/app/dashboard/' + createdResumeId);
                    this.showLoader = false;
                });
            },
            changeTab(tabName) {
                if (tabName === 'createResume') {
                    this.showLoader = true;
                    const createObject = {
                        job_id: this.selectedJob.subCategory,
                        theme_id: this.selectedTheme,
                        experience: this.selectedJob.experienceId,
                        linkedin_url: this.linkedinUrl,
                        template_type:'resume'
                    };
                    resumeService.createResume(createObject).then((createResponse) => {
                        const createdResumeId = createResponse.data[0].current_user_template.id;
                        this.$router.push('/app/dashboard/' + createdResumeId);
                        this.showLoader = false;
                    });
                } else {
                    this.currentTab = tabName;
                }

            }
        }
    }
</script>
<style lang="scss">
    .create-resume {
        &.cage {
            background: linear-gradient(180deg, #F8FBFF 0%, rgba(250, 252, 255, 0) 100%);
            position: absolute;
            width: 100%;
            top: 0;
            height: 100%;
            overflow-y: auto;
        }

        &.container {
            margin-top: 44px;
            overflow-y: auto;
            scroll-behavior: smooth !important;
        }

        &.main-header {
            color: #282b34;
            font-weight: 700;
            font-family: "Segoe UI Bold";
            font-size: 36px;
            margin-bottom: 15px !important;
        }

        &.main-description {
            color: #9EA2B1;
            font-size: 15px;
            font-family: "Segoe UI";
            font-weight: 400;
            margin-bottom: 45px !important;
        }

        &.table-cage {
            background: #ffffff;
            width: 100%;
            border: 2px solid #F1F7FF;
            border-radius: 5px;
        }

        &.table-head-line {
            padding: 20px;
            border-bottom: 2px solid #F1F7FF;
            width: 100%;
        }

        &.table-header {
            font-weight: 600;
            font-family: "Segoe UI";
            font-size: 16px;
            color: #000000;
            text-align: start;
        }
    }
</style>
