<script>
    import {HorizontalBar} from "vue-chartjs";

    export default {
        extends: HorizontalBar,
        props: ['myTimeData'],
        data() {
            return {
                localDataSets: [],
                chartOptions: {
                    responsive: true,
                    maintainAspectRatio: true,
                    legend: {
                        display: false
                    },
                    scales: {
                        xAxes: [
                            {
                                gridLines: {
                                    display: false,
                                    drawBorder: false
                                },
                                scaleLabel: {
                                    display: false
                                },
                                stacked: true,
                                ticks: {
                                    display: false,
                                    beginAtZero: false,
                                    fontFamily: "Helvetica, sans-serif",
                                    fontSize: 14
                                }
                            }
                        ],
                        yAxes: [
                            {
                                gridLines: {
                                    display: false,
                                    drawBorder: false
                                },
                                scaleLabel: {
                                    display: false
                                },
                                stacked: true,
                                ticks: {
                                    display: false,
                                    fontFamily: "Helvetica, sans-serif",
                                    fontSize: 14
                                }
                            }
                        ]
                    },
                    tooltips: {
                        enabled: false
                    },
                    hover: {
                        mode: false
                    }
                }

            };
        },
        beforeCreate: function () {
            this.$nextTick(function () {
                this.setData();
            })
        },
        methods: {
            async setData() {
                this.localDataSets = [];
                this.myTimeData.forEach((data, i) => {
                    let color = '#000000';
                    if (i === 1) {
                        color = '#4d4d4d';
                    }
                    if (i === 2) {
                        color = '#808080';
                    }
                    if (i === 3) {
                        color = '#b2b2b2';
                    }
                    if (i === 4) {
                        color = '#c4c4c4';
                    }
                    const obj = {
                        label: data.name,
                        borderColor: color,
                        pointBackgroundColor: color,
                        borderWidth: 1,
                        pointBorderColor: color,
                        backgroundColor: color,
                        data: [data.value],
                    };
                    this.localDataSets.push(obj)
                });
                this.renderChart(
                    {
                        datasets: this.localDataSets ? this.localDataSets : [1, 1, 1, 1]
                    },
                    this.chartOptions
                )
            }
        },
        watch: {
            myTimeData: {
                deep: true,
                async handler() {
                    await this.setData().then(
                        this.renderChart(
                            {
                                datasets: this.localDataSets ? this.localDataSets : [1, 1, 1, 1]
                            },
                            this.chartOptions
                        )
                    );
                }

            }
        }
    }
</script>
